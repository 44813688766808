import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators , AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router, UrlTree } from '@angular/router';
import { UserService } from '../service/user.service';
import { eventType } from 'aws-sdk/clients/health';
//import { DatepickerModule } from 'ng2-datepicker';


@Component({
  selector: 'app-sc',
  templateUrl: './sc.component.html',
  styleUrls: ['./sc.component.css']
})
export class ScComponent implements OnInit {

 // options: DatepickerOptions;
  isHovered: { [key: string]: boolean } = {};
  isalgebra: boolean = false
  openForm: boolean = false;
  isModalOpen: boolean = false;
  isloading: boolean = false;
  isLoading3: boolean = false;
  isloaderpricing:boolean = false;
  selectedDate: string | null = null;
  error: any = "";
  storedLogin : any;
  isOpen = false;
  newLoader:boolean = false
  prodDetail: any[] = [];

  @ViewChild('heroVideo') heroVideo!: ElementRef<HTMLVideoElement>;
  openSuccessPopup: boolean = false;
  showParent: boolean = false;
  age: number=0;
  datePickerInstance: any;
  isScDisabled: boolean =false;
  email_id: any
  subscribedbutton: boolean = false;
  IPv4: any;
  plan: string | null = null;
  storageListener: any
  isLoading2: boolean = false;
  counter: any = 1;
  
  constructor(private router: Router, private service: UserService) {  
   
  }
 
  ngOnInit(): void {
   // this.isModalOpen = true
    let payload ={

      "env":"live"}
      this.service.stripe(payload).subscribe((res: any) => {
       this.prodDetail = res.body
        console.log(res,'proddetail')

     })
    //  this.storedLogin = localStorage.getItem('user');;
    // console.log('state',this.storedLogin)
    this.showParent = false
    let user = localStorage.getItem('user') 
    this.email_id = localStorage.getItem('user.email') 
    let subscribed = localStorage.getItem('subscription') || ''
    // if( subscribed){
    //   console.log(subscribed)
    //   if(subscribed.length !== 0){
    //     this.subscribedbutton = true
    //    // this.subscribedbutton  = true
    //   }else{
    //     this.subscribedbutton = false
    //     //this.subscribedbutton  = false
    //   }
    // }

      // Initial fetch from local storage
      this.plan = localStorage.getItem('subscription');

      // Listen for changes in local storage
      this.storageListener = this.handleStorageEvent.bind(this);

      // window.addEventListener('storage', this.storageListener);

  //  let subscribed = localStorage.getItem('subscription')
    if( subscribed){
    //  console.log('goinin')
      if(subscribed.length > 0){
      //  this.subscribedbutton = true
    //  console.log('goinin')
        this.isModalOpen =true
       // this.subscribedbutton  = truesub
      }else{
       // this.subscribedbutton = false
        this.isModalOpen = false
        //this.subscribedbutton  = false
      }
    }
    if(user){
    let sc = localStorage.getItem('url') 
    //this.router.url.includes('/sc')

    this.isScDisabled = sc && sc.includes('sc') ? false : true;
    }
  }

  handleStorageEvent() {
    let subscribed = localStorage.getItem('subscription');
  //  console.log(subscribed, 'ggddfffsfsfsf');
  
    // Parse JSON if it's not null
    let parsedSubscribed = subscribed ? JSON.parse(subscribed) : '';
  
    if (parsedSubscribed && Array.isArray(parsedSubscribed) && parsedSubscribed.length > 0) {
      console.log('Going inside because array is not empty');
      this.subscribedbutton = true;
      if(this.counter == 1){
        this.isModalOpen = true;
        }
        this.counter = 2;
     //this.isModalOpen = true;
    } else {
      console.log('Either empty array or no subscription');
      this.subscribedbutton = false;
      this.isModalOpen = false;
    }
  }

  async ngAfterViewInit() { 

    let subscribed = localStorage.getItem('subscription');
    console.log(subscribed, 'ggddfffsfsfsf');
  
    // Parse JSON if it's not null
    let parsedSubscribed = subscribed ? JSON.parse(subscribed) : '';
  
    if (parsedSubscribed && Array.isArray(parsedSubscribed) && parsedSubscribed.length > 0) {
      console.log('Going inside because array is not empty');
      this.subscribedbutton = true;
      if(this.counter == 1){
        this.isModalOpen = true;
        }
        this.counter = 2;
   //   this.isModalOpen = true;
    } else {
      console.log('Either empty array or no subscription');
      this.subscribedbutton = false;
      this.isModalOpen = false;
    }
  


    this.storedLogin = localStorage.getItem('user');;
    //kanx video changes 
    const video = this.heroVideo.nativeElement;
   
    // Set volume to 0
    video.volume = 0;
    video.muted = true;
    
    video.play().catch(error => {
  //    console.error('Video playback failed:', error);
    });
    video.addEventListener('volumechange', () => {
      this.handleVolumeChange(video);
    });
   // this.navigateAndReplaceClass();
    // Autoplay the video by triggering a play event
    
    // if (this.isBrowser) {
    //   const videoIframe = document.getElementById('widget2') as HTMLIFrameElement;
    //   if (videoIframe) {
    //     console.log('hey');
    //     videoIframe.contentWindow?.postMessage('{"event":"command","func":"mute","args":""}', '*');
    //     videoIframe.contentWindow?.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    //   }
    // }
  }
 
  handleVolumeChange(video: HTMLVideoElement) {
    // Check if the video is muted
    if (video.muted) {
      video.volume = 0;
    //  console.log('Video is muted');
    } else {
      video.volume = 1;
   //   console.log(Video volume is set to ${video.volume});
    }
  }

  subjectform = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
    firstName: new FormControl('', Validators.required),
    promocode: new FormControl('',Validators.required),
    parentEmail: new FormControl('', [Validators.required,Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
    lastName: new FormControl('', Validators.required),
    date: new FormControl('', [Validators.required, this.dateNotInFuture()])
  })


  get firstName() {
    return this.subjectform.controls['firstName'];
  }


  get lastName() {
    return this.subjectform.controls['lastName'];
  }


  get email() {
    return this.subjectform.controls['email'];
  }

  get date() {
    return this.subjectform.controls['date'];
  }

  get parentEmail() {
    return this.subjectform.controls['parentEmail'];
  }

  /* COURSES NAVIGATION */
  navigateToCoursePage(url: string | UrlTree) {
 this.router.navigateByUrl(url);
 //  this.router.navigate(['sc/sc-us-history']);
  }

  // toggleGif(hovered: boolean) {
  //   this.isHovered = hovered;
  // }

  toggleGif(course: string, hovered: boolean) {
   this.isHovered[course] = hovered;
  }

  togglealgebra(hovered: boolean) {
   // this.isalgebra = hovered
  }


  scrollToPricing(): void {
    const element = document.getElementById('pricing_section_id');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  // pricing 

  ModalOpen(event:Event) {
    // window.open('https://buy.stripe.com/test_5kAdSpdiJcr8awEcMM', '_blank');
    // window.location.href = 'https://buy.stripe.com/test_5kAdSpdiJcr8awEcMM';
  
    this.storedLogin = localStorage.getItem('user');
   this.email_id = localStorage.getItem('email');
  //  console.log('state',this.storedLogin,this.email_id)

  localStorage.setItem("PlanSelected", "Trailblazers");
    if(this.storedLogin){
   //   this.buyPackage();
  
  
    this.planAPI(this.email_id)
   
   
      
    }else{
      this.openLoginPopup(event,false)
  //  this.openForm = !this.openForm
    }
  }
  openLoginPopup(event: any, isLogin: boolean): void {
    event.stopPropagation();
    this.service.showPopup(isLogin);
  }
  // closeModal(): void {
  //   this.isModalOpen = false;
  // }
  closeForm() {
   // this.openForm = !this.openForm
    this.subjectform.reset()
    this.isloading = false
    this.showParent = false;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


 
// Trailblazer - prod_QpTSBxSdj6Z0BT - price_1PxoVxALy7MM11rqUZ3kCKxz
// devTrailblazer - prod_QofbY9vz5uizFD - price_1Px2G4ALy7MM11rqM4TsGY5P

  planAPI(email: string) {
    this.isloaderpricing = true;
    this.newLoader = true
    let payload 
   // live test
    payload ={
      "env":"live","product":"Trailblazers"}
    console.log(payload)
    // let payload = {
    //   "email": email,
    //   "prod_id": "prod_QofbY9vz5uizFD",
    //   "plan": "Trailblazers",
    //   "price_id": "price_1Px2G4ALy7MM11rqM4TsGY5P",
    //   "mode": "setup",
    //   "price": "price_1Px2G4ALy7MM11rqM4TsGY5P",
    //   "price_amount": "199.00",
    //   "belong_to" :"sc",
          
    //   "type":"setup"
    // }
   this.service.stripe(payload).subscribe((res: any) => {
      
     
      if (res.statusCode == 200) {
        let resvalue = res;
        console.log(resvalue)
       let payload1 ={ "email": email,
           "prod_id": res.body.prod_id,
    
           "plan": res.body.product,
           "price_id": res.body.price_id,
           "mode": "setup",
          "price": res.body.amount ,
          "price_amount": res.body.amount,
        
           "belong_to" :"sc",

           "type":"subscription"
       }
      
       this.service.stripe_checkout(payload1).subscribe((res: any) => {
      //  console.log(payload)
        if (res.statusCode == 303) {
        this.newLoader = false
        // window.location.href = res.headers.Location;
        }else{
          return
        }
       })
        this.closeForm()
       
        
     
       
       
        this.isloading =false;
      }else{
        return
      }
    })


  }

  closeSuccessPopup() {
     this.openSuccessPopup = !this.openSuccessPopup
   }
  validateAge(control: any): { [key: string]: boolean } | null {
  //  console.log(control,this.subjectform.controls['date'].value)
    
   
    const dob = new Date(this.subjectform.controls['date'].value);
  
    const currentDate = new Date();
    this.age = currentDate.getFullYear() - dob.getFullYear();
    const monthDifference = currentDate.getMonth() - dob.getMonth();
    const dayDifference = currentDate.getDate() - dob.getDate();
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
   this.age  = currentDate.getFullYear() - dob.getFullYear();
   this.age = this.age - 1;

   }
    // If less than 13 years, require parent's email
    if (this.age < 13) {
      // Require the parent email to be filled in
      this.showParent = true
      // this.subjectform.controls['parentEmail'].setValidators([Validators.required, Validators.email]);
      // this.subjectform.controls['parentEmail'].updateValueAndValidity();
    } else {
      // If age is 13 or above, remove the parent email validation
      this.showParent = false
      // this.subjectform.controls['parentEmail'].clearValidators();
      // this.subjectform.controls['parentEmail'].updateValueAndValidity();
    }

    return this.age < 13 ? { 'underage': true } : null;
  }
  dateNotInFuture(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const inputDate = new Date(control.value);
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set to midnight for comparison
  
      // Check if the input date is in the future
      return inputDate >= today ? { futureDate: true } : null;
    };}
    openDatepicker() {
     
    }
    openModal() {
      this.isModalOpen = true;
    }
  
    closeModal() {
      this.isModalOpen = false;
    }
    linkToedyouUser(){
      let user  = localStorage.getItem('user');
      if (user) {
        // Parse the string back into a JavaScript object
       let user1 = JSON.parse(user); 
      
        // Now you can access the 'link' property
        console.log(user1.link, 'urltomove');
       // const userLink: string = user1.link;
  
        // If you want to navigate, uncomment this:
        // window.location.href = user1.link;
      }else{
        // window.location.href= 'https://smdev.edyou.com/' 
      }
    
  
      console.log(user)
      // if (user) {
      //   // Parse the string back into a JavaScript object
      //  let user1 = JSON.parse(user); 
      
        // Now you can access the 'link' property
      //  console.log(user1.link, 'urltomove', this.user);
       // const userLink: string = user1.link;
  
        // If you want to navigate, uncomment this:
        
      //   this.service.getIpAdress().subscribe((f: any) => {
      //     console.log(f)
      //     this.IPv4 = f.IPv4
      //     localStorage.setItem('IPAdress', this.IPv4)
      //   })
      //   // this.actRouter.queryParams.subscribe((queryParams) => {
      //   //   this.newEmail = queryParams['email'] || null;
      //   //   var newPassword = queryParams['password'] || null;
      //   //   if (newPassword) {
      //   //     this.newPassword = newPassword.split("___")
      //   //   }
      //   // })
    
      //     // Collect user data
      // var browserName = this.getBrowserName();
      // var deviceType = this.getDeviceType();
      // let DeviceInfo = {
      //   browser: browserName,
      //   device: deviceType
      // }; 
      // let loginPayload = {
      //   "email":  user1.email,
      //   "password": user1.password,
      //   "policy": true,
      //   "Ipaddress": this.IPv4,
      //   "device_info":DeviceInfo,
      //   "avatar":"NOVA"
      // }
      // this.isLoading2 = true
      // let url  = localStorage.getItem('appurl');
      // this.service.appLogin(loginPayload).subscribe(
      //   (data :any) =>  { if (data.statusCode == 200) {
      //     this.isLoading2 = false
      //     if(url){
      //     window.location.href = url;
      //     }else{
      //       alert('there is some issue')
      //     }
      // }else {
      //   this.isLoading2 = false
      //   alert(data.body)
      //   this.closeModal()
      // }})
      // }else{
      //   window.location.href= 'https://smdev.edyou.com/' 
      // }
    
     
  
    }
    getBrowserName() {
      var userAgent = navigator.userAgent;
      var browsers: any = {
        Chrome: /Chrome/i,
        Safari: /Safari/i,
        Firefox: /Firefox/i,
        IE: /Internet Explorer/i,
        Edge: /Edge/i,
        Opera: /Opera|OPR/i
      };
  
      for (var key in browsers) {
        if (browsers[key].test(userAgent)) {
          return key;
        }
      }
  
      return "Unknown";
    }
  
    // Function to get the device type
    getDeviceType() {
      var userAgent = navigator.userAgent;
      if (/(iPhone|iPod|iPad)/.test(userAgent)) {
        return "iOS";
      } else if (/Android/.test(userAgent)) {
        return "Android";
      } else if (/Mac/.test(userAgent)) {
        return "Mac OS";
      } else if (/Windows/.test(userAgent)) {
        return "Windows";
      } else {
        return "Unknown";
      }
    }
    navigateToProfile(){
      //this.menuToggle()
     // this.sc=true
      let url1 
       url1  = localStorage.getItem('url');
       if(url1 && url1.includes('/sc')){
        this.router.navigate(['/SC/profile']);
       }else{
        this.router.navigate(['/profile']);
       }
   
   
    }
}
