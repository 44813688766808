<div class="mainDIv">

  <div class="hero-section-enterprise">
    <!-- Video Background -->
    <video autoplay muted loop playsInline webkit-playsinline class="background-video" id="backgroundVideo">
      <!-- <source autoplay muted loop src="https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/buisness-people-meeting.mov" --> 
      <source autoplay muted loop src="../../assets/video/buisness-people-meeting.mov"
        >
      Your browser does not support the video tag.
    </video>
    <!-- Overlay and Content -->
    <div class="overlay"></div>
      <div class="hero-content">
        <h1>edYOU <br>Enterprise Solutions </h1>


        <!-- <div class="mobilebtn-h">
            <button class="cta-button"><span class="innerTextbtn" (click)="navigateToAIAvatar()"> Try Free Now </span></button>
          </div> -->
        <div class="mobilebtn-t">

        </div>
      </div>
    <!-- </div> -->
  </div>


  
  <div class="banner">
    <div class="div-container">
      <div class="scrolling-belt">
        <img class="" src="../../assets/logos-institutions/imi-1 1.png" />
        <img class="-4" src="../../assets/logos-institutions/rm-logo-white (1).png" />
        <img class="-3" src="../../assets/logos-institutions/image 52.png" />
        <img class="-6" src="../../assets/logos-institutions/image 53.png" />
        <!-- <img class="-7" src="https://c.animaapp.com/fXulaZA9/img/image-61@2x.png" /> -->
      </div>
    </div>
  </div>
 <div class="banner-bottom">
  <h2>*As trusted by alumni of</h2>
</div>
  <!-- edyou intro  -->
   <section class="edyouwork">
    <!--<div class="container">
      <div class="content">
        <h1>How edYOU Works</h1>
        <ul class="features-list">
          <li>Choose a learning path</li>
          <li>Engage with personalized AI tutors and course</li>
          <li>Test your knowledge</li>
          <li>Monitor progress with real-time analytics</li>
          <li>Improve grades & earn certification</li>
        </ul>
      </div>

      <div class="image-container">
        <img src="../../assets/newDesignImage/Macbook.png" alt="Laptop showing platform demo" class="laptop-mockup">
        <video class="overlay-video" autoplay muted loop>
          <source src="https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/-8d7b-42fe-8ef8-bcb0a6196074.mp4"
            type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>

    </div>-->
    <div class="section-title"><h2>How edYOU Works for Business</h2></div>
    <div class="workContainer">
      <div class="col-md-12 mb-4 mobilecard">
        <div class="card galleryrowreverse">
          <div class="card-content reverse-padding">
            <div class="content">
              <div class="description">
                <ul class="features-list">
                  <li><b>Tailored Training:</b> edYOU’s AI boosts skills and productivity while cutting training costs.</li>
                  <li><b>Simplified Certification:</b> On-demand prep ensures compliance and workforce excellence.</li>
                  <li><b>Workforce Insights:</b> Real-time analytics identify skill gaps and predict needs.</li>
                </ul>
              </div>
            
            </div>
          </div>
          <img class="reverse-img" src="../../assets/newb2cmedia/buisness-1.png" alt="Parents Image">
        </div>
      </div>

      <div class="col-md-12 mb-4 mobilecard">
        <div class="card galleryrow">
          <div class="card-content rightPadding">
            <div class="content">
              <div class="description">
                <ul class="features-list">
                  <li><b>Web Concierge:</b> Transform your website with AI for personalized customer interactions and retention.</li>
                  <li><b>Global Reach:</b> Multilingual AI delivers consistent training for all teams.</li>
                  <li><b>Wellness Support:</b> AI tools enhance mental health and stress management.</li>
                </ul>
              </div>
              
            </div>
          </div>
          <img class="rightimage" src="../../assets/newb2cmedia/buisness-2.png" alt="Professionals Image">
        </div>
      </div>


    </div>
    
  </section> 

  <!--  -->
  <!-- trynow avatarppt -->








  <!-- gallery -->

  <div class="outer-card">
    <div class="section-title"><h2>Your AI Enterprise Solution</h2></div>

    <div class="content-section ">
      <!-- Students - Content Left, Image Right -->
      <div class="col-md-12 mb-4 mobilecard">
        <div class="card galleryrow">
          <div class="card-content rightPadding">
            <div class="description">
              <p>
                edYOU delivers tailored AI solutions for enterprise and government, providing secure data for compliance
                and improvement. We create 24/7 personalized training modules for onboarding, compliance, and skill
                development, with real-time performance data to enhance efficiency and support employee wellness. </p>
            </div>
          </div>
          <img class="rightimage" src="../../assets/newb2cmedia/es1.png" alt="Students Image">
        </div>
      </div>

      <!-- Parents - Image Left, Content Right -->
      <div class="col-md-12 mb-4 mobilecard">
        <div class="card galleryrowreverse">
          <div class="card-content reverse-padding">
            <div class="description">
              <p>
                On customer-facing websites, edYOU’s AI beings can be deployed as virtual concierges, guiding users from
                onboarding to purchase while remembering past interactions for a personalized experience. </p>
            </div>
          </div>
          <img class="reverse-img" src="../../assets/newb2cmedia/es2.png" alt="Parents Image">
        </div>
      </div>

      <!-- Professionals - Content Left, Image Right -->
      <div class="col-md-12 mb-4 mobilecard">
        <div class="card galleryrow">
          <div class="card-content rightPadding">
            <div class="description">
              <p>
                The platform collects valuable data on both employee learning and customer behavior, offering real-time
                insights for businesses. edYOU combines adaptive training with seamless customer engagement, driving
                efficiency and growth across industries. </p>
            </div>
          </div>
          <img class="rightimage" src="../../assets/newb2cmedia/es3.png" alt="Professionals Image">
        </div>
      </div>


    </div>
    <!-- <p class="professionalfooter" >This ensures students study exactly what’s taught in class, with
      real-time oversight for teachers and parents.</p> -->
  </div>

  <!-- research -->






  <!-- Video Background -->
  <div class="bottom-sc">
    <video autoplay muted loop playsInline webkit-playsinline class="background-video">
      <source autoplay muted loop src="https://pollydemo2022.s3.us-west-2.amazonaws.com/new-b2c/course-bg.mov"
        type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <!-- Overlay and Content -->
    <div class="overlay-bottom">
      <div class="bottom-content">
        <h2>Ready to bring edYOU’s AI-powered learning to your enterprise?</h2>


        <div>
          <button class="cta-button" (click)="calendly()"><span class="innerTextbtn" (click)="calendly()"> Contact Sales
            </span></button>
        </div>

      </div>
    </div>
  </div>
  <!-- bio -->
  <div class="bio-section">
    <hr>
    <div class="scbio">
      <div class="card1">
        <div class="child1">
          <img src="./assets/newb2cmedia/star.svg" alt="">
          <img src="./assets/newb2cmedia/star.svg" alt="">
          <img src="./assets/newb2cmedia/star.svg" alt="">
          <img src="./assets/newb2cmedia/star.svg" alt="">
          <img src="./assets/newb2cmedia/star.svg" alt="">
        </div>
        <div class="child2">
          <p>“ASCP is proud to combine innovation and expertise to create a modern, state-of-the-art living textbook for our members, providing an invaluable resource in psychopharmacology.”</p>
        </div>
        <!-- <div class="child3">
          <p>Joseph F. Goldberg, <br>M.D. President of ASCP</p>
        </div> -->
        <div class="child3">
          <h5 class="headofSchool">Joseph F. Goldberg,&nbsp; M.D.</h5>
          <h6 class="headofSchoolposition">President of ASCP</h6>
          
        </div>
      </div>
      <div class="card2">
        <img src="./assets/newb2cmedia/revolution.png" alt="">
      </div>
    </div>
    <hr>
  </div>
  <!-- testimonial -->

  <app-testimonial-card></app-testimonial-card>

  <!-- patent -->
  <section class="patent">
    <div class="patent1">

      <h2>edYOU’s Patented Technology </h2>

      <img src="../../assets/newb2cmedia/patent-1.png" alt="Icon A" class="icon">

      <h3>Proprietary Ingestion Engine</h3>

      <p>
        PIE powers edYOU’s learning platform, processing vast data to enable personalized education. Using machine
        learning and human insight, PIE extracts key insights, generates quizzes, and tags metadata to make content
        interactive.<br><br>
        A human-in-the-loop approach ensures all content aligns with educational standards before reaching learners. PIE
        then hands off curated information to the Personalized AI module (PAl) for tailored, conversational
        learning.<br><br>
        This seamless integration transforms limitless information into personalized knowledge, redefining education for
        the digital age.
      </p>
    </div>
    <div class="patent1">

      <img src="../../assets/newb2cmedia/patent-2.png" alt="Icon A" class="icon">
      <h3>Intelligent Curation Engine</h3>
      <p>
        The Intelligent Curation Engine (ICE) ensures safe, ethical AI by safeguarding against toxic content,
        misinformation, and bias. Combining metadata and human oversight, ICE curates information for edYOU learners,
        flags inappropriate content, and blocks offensive language.<br><br>

        ICE tracks data origins, offering transparency and empowering educators to adjust AI responses. Its
        security-first approach prevents hacking and ensures diverse, trustworthy training data.
        <br><br>
        ICE reflects our commitment to ethical, transparent AI. By prioritizing human accountability, it sets a new
        standard for responsible technology that uplifts and aligns with educators’ values.
      </p>
    </div>
  </section>
  <!-- faq -->
  <section class="faq">
    <div class="faqsection">
      <h2 class="payForwordsectionHeader faqmargin">Frequently Asked Questions</h2>

      <div class="accordion-item">
        <input type="checkbox" id="question1">
        <label class="accordion-header" for="question1">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>Is edYOU safe for my child?</span>

        </label>
        <div class="accordion-content">
          <p>We take learner safety very seriously. Our Intelligent Curation Engine uses automation and human reviewers
            to block inappropriate content. Educators have full visibility into conversations.</p>
        </div>
      </div>

      <div class="accordion-item">
        <input type="checkbox" id="question2">
        <label class="accordion-header" for="question2">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>How does edYOU’s AI work?</span>

        </label>
        <div class="accordion-content">
          <p>Our AI uses natural language processing to have personalized conversations tailored to each learner's
            interests and knowledge. It doesn't simply regurgitate internet information.</p>
        </div>
      </div>

      <div class="accordion-item">
        <input type="checkbox" id="question3">
        <label class="accordion-header" for="question3">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>What makes edYOU different than other education apps?</span>

        </label>
        <div class="accordion-content">
          <p>Our conversational AI beings build mentor-like relationships with students to provide truly customized
            education. We focus on transparency and ethical AI deployment.</p>
        </div>
      </div>
      <div class="accordion-item">
        <input type="checkbox" id="question4">
        <label class="accordion-header" for="question4">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>How does edYOU impact learning outcomes?</span>

        </label>
        <div class="accordion-content">
          <p>By providing adaptive and engaging education, edYOU increases knowledge retention, improves grades, and
            makes students excited to learn.</p>
        </div>
      </div>
      <div class="accordion-item">
        <input type="checkbox" id="question5">
        <label class="accordion-header" for="question5">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>Can edYOU integrate with our existing tools?</span>

        </label>
        <div class="accordion-content">
          <p>Yes, edYOU has APIs that allow easy integration with common education software, learning management
            systems, and enterprise tools.</p>
        </div>
      </div>
      <div class="accordion-item">
        <input type="checkbox" id="question6">
        <label class="accordion-header" for="question6">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>Is there live human support if I have an issue?</span>

        </label>
        <div class="accordion-content">
          <p>Yes, we have dedicated support agents available 24/7 via in-app chat, email, and phone support. Students
            and parents can always reach a real person.</p>
        </div>
      </div>
      <div class="accordion-item">
        <input type="checkbox" id="question7">
        <label class="accordion-header" for="question7">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>How does edYOU handle student privacy and security?</span>

        </label>
        <div class="accordion-content">
          <p>We take data privacy very seriously. All conversations are encrypted. Student data is anonymized and
            aggregated before internal use. Our practices follow all applicable regulations.</p>
        </div>
      </div>
      <div class="accordion-item">
        <input type="checkbox" id="question8">
        <label class="accordion-header" for="question8">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>What credentials do your team have?</span>

        </label>
        <div class="accordion-content">
          <p>Our team consists of PhD scientists, former teachers, child development experts, data ethicists, and
            experienced AI researchers and engineers.</p>
        </div>
      </div>
      <div class="accordion-item">
        <input type="checkbox" id="question9">
        <label class="accordion-header" for="question9">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>How do you ensure educational content stays up-to-date?</span>

        </label>
        <div class="accordion-content">
          <p>Our content team continuously reviews and updates study materials to reflect the latest educational
            standards and scientific knowledge. We also leverage automation and APIs.</p>
        </div>
      </div>
      <div class="accordion-item">
        <input type="checkbox" id="question10">
        <label class="accordion-header" for="question10">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>Can parents monitor conversations?</span>

        </label>
        <div class="accordion-content">
          <p>Yes, the edYOU parent dashboard allows you to view transcripts and recordings of your child's sessions,
            providing transparency into lessons.</p>
        </div>
      </div>
      <div class="accordion-item">
        <input type="checkbox" id="question11">
        <label class="accordion-header" for="question11">
          <span class="accordion-icon"><i class="fa-solid fa-caret-right"></i></span>
          <span>How is edYOU different from ChatGPT and why is it a superior learning tool?</span>
          
        </label>
        <div class="accordion-content">
            <p>edYOU is fundamentally different from ChatGPT, delivering a superior learning experience through its proprietary technologies, PIE (Proprietary Ingestion Engine) and ICE (Intelligent Curation Engine). Unlike ChatGPT’s broad, unstructured responses, edYOU curates information from curriculum-aligned materials, ensuring zero hallucinations and academic integrity. Its teacher-driven methodology guides learners step-by-step through expertly designed content. edYOU discourages plagiarism, focusing on adaptive, personalized mastery. Students gain trusted, curriculum-based support for real understanding and success.</p>
        </div>
      </div>
      <div class="hrline"></div>
    </div>
  </section>

</div>
<!-- modal -->