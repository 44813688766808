import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.setTitle('edYOU Terms of Use | Educational Technology Platform');
    this.setMetaDescription("Read the Terms of Use for edYOU's educational technology platform. Understand your rights, responsibilities, and the guidelines for using our services to enhance your learning experience.")
  }
  
  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  setMetaDescription(description: string) {
    this.metaService.updateTag({ name: 'description', content: description });
  }
}
