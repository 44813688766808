

<header class="navbar" [ngStyle]="backgroundColor">
  <div class="logo">
    <img (click)="homePage()" class="imgIcon mobileLogo" src="../../assets/allnewedyoulogo.png"
      alt="Logo" />
  </div>
  <div class="mainheader">
  <nav *ngIf="!isb2b" id="menu" class="main-menu">
    <div class="d-sm-none d-flex justify-content-end" (click)="toggleMenu()" style="    right: 25px;
    top: 20px;

    position: absolute;">
      <svg width="25" height="25" fill="none" stroke="#fbf9f9" stroke-linecap="round" stroke-linejoin="round"
        stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 6 6 18"></path>
        <path d="m6 6 12 12"></path>
      </svg>
    </div>
    <a (click)="navigateToCourse()">Courses</a>
    <a (click)="navigateTOAboutUs()">About Us</a>
    <a (click)="navigateToNews()">Media</a>
    <a *ngIf="this.screenSize == 'large' && !isLoggedIn" (click)="openLoginPopup($event, true)" class="loginBtn" id="loginNavBtn">Login</a>
    
  </nav>
  <!-- <div  *ngIf="!isb2b" > -->
  <div *ngIf="mobileNav && !isb2b" id="subid" class="loginDiv">
    <a *ngIf="isLoggedIn &&   !isb2b" class="action">
      <div class="profile" (click)="menuToggle($event)">
        <img src="../../assets/images/holder.png" />
      </div>
      <div class="menu" id="menuId">
        <h3>{{ userName }}</h3>
        <ul>
          <li *ngIf="enableB2cPlan">
            <a style="cursor: pointer;" (click)="navigateToProfile()">Profile</a>
          </li>
          <li>
            <a style="cursor: pointer;color:#D32F2F !important;" (click)="logOut()">Logout</a>
          </li>
        </ul>
      </div>
    </a>
    <button *ngIf="!isLoggedIn" class="nav-button" (click)="navigateTosubscription()">
     Buy Now
    </button>
    <button *ngIf="isLoggedIn && this.buttonName == 'Learn'" class="nav-button" (click)="linkToedyouUser()">
      Dashboard
    </button>

    <button *ngIf="isLoggedIn && this.buttonName == 'Startfree'" class="nav-button" (click)="navigateTosubscription()">
      Buy Now
    </button>
  </div>
  <a *ngIf="!isLoggedIn &&  !isb2b && this.screenSize == 'small' " (click)="openLoginPopup($event, true)" class="loginBtn" id="loginNavBtn">Login</a>

 
  <button *ngIf="isb2b" class="nav-button-b2b" (click)="calendly()">
    Contact Sales
  </button>
  <div *ngIf="!isb2b" class="hamburger" (click)="toggleMenu()">&#9776;</div>
  </div>
</header>