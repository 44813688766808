import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CoursePageComponent } from './courses-page/course.component'
import { SuccessPaymentComponent } from './success-payment/success-payment.component';
import { ScRedirectGuard } from './sc-redirect.guard';
import { SuccesspaymentComponent } from './successpayment/successpayment.component';
import { FailedpaymentComponent } from './failedpayment/failedpayment.component';
import { ProfileComponent } from './profile/profile.component';
import { NewletterComponent } from './newletter/newletter.component';
import { ConsentsuccessComponent } from './consentsuccess/consentsuccess.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { LabsComponent } from './labs/labs.component';
import { PlaypptComponent } from './playppt/playppt.component';

import { NewsPageComponent } from './news-page/news-page.component';
import { EdyouaiComponent } from './edyouai/edyouai.component';
import { CourseDescriptionComponent } from './course-description/course-description.component';
import { B2bInstitutionComponent } from './b2b-institution/b2b-institution.component';
import { EnterpriseSolutionComponent } from './enterprise-solution/enterprise-solution.component';



const routes: Routes = [


  {path:'', redirectTo:'/', pathMatch:'full'},
  {path:'', component: MainComponent},
  {path:'course',component:CoursePageComponent},
 
  {path: 'profile', component:ProfileComponent},
 
  {path: 'labs', component:LabsComponent},
  {path: 'terms', component:TermsComponent},
  {path: 'privacy', component:PrivacyComponent},
  {path: 'payment-success', component:SuccessPaymentComponent},
  {path: 'about-us', component:AboutUsComponent},
  {path: 'sc/payment-success', component:SuccesspaymentComponent},
  {path: 'newsletter-success', component:NewletterComponent},
  {path: 'news', component:NewsPageComponent},
  {path: 'payment-failed', component:FailedpaymentComponent},
  {path: 'sc/payment-failed', component:FailedpaymentComponent},
  {path: 'consentsuccess', component:ConsentsuccessComponent},
  {path: 'how-edyous-ai-is-reimagining-the-future-of-personalized-learning',component:EdyouaiComponent},
  {path: 'revolutionizing-education-with-edyou-bridging-the-post-pandemic-learning-gap',component:EdyouaiComponent},
  {path: 'algebra', component:CourseDescriptionComponent},
  {path: 'us-history', component:CourseDescriptionComponent},
  {path: 'english', component:CourseDescriptionComponent},
  {path: 'leadership', component:CourseDescriptionComponent},
  {path: 'communications', component:CourseDescriptionComponent},
  {path: 'entrepreneurship', component:CourseDescriptionComponent},
  {path: 'artificial-intelligence', component:CourseDescriptionComponent},
  {path: 'computer-science', component:CourseDescriptionComponent},
  {path: 'project-management', component:CourseDescriptionComponent},
  {path: 'Cardiology', component:CourseDescriptionComponent},
  {path: 'Endocrinology', component:CourseDescriptionComponent},
  {path: 'Gastroenterology', component:CourseDescriptionComponent},
  {path: 'Intro-to-Internal-Medicine', component:CourseDescriptionComponent},
  {path: 'Geriatric-Medicine', component:CourseDescriptionComponent},
  {path: 'Hematology-and-Oncology', component:CourseDescriptionComponent},
  {path: 'Infectious-Diseases', component:CourseDescriptionComponent},
  {path: 'Nephrology', component:CourseDescriptionComponent},
  {path: 'Intro-to-Neurology', component:CourseDescriptionComponent},
  {path: 'Pulmonary-and-Critical-Care-Medicine', component:CourseDescriptionComponent},
  {path: 'Rheumatology', component:CourseDescriptionComponent},
  {path: 'Intro-to-Family-Medicine', component:CourseDescriptionComponent},
  {path: 'Intro-to-Surgery', component:CourseDescriptionComponent},
  {path: 'Intro-to-Obstetrics/Gynaecology', component:CourseDescriptionComponent},
  {path: 'Intro-to-Pediatrics', component:CourseDescriptionComponent},
  {path: 'Intro-to-Psychiatry', component:CourseDescriptionComponent},
  {path: 'Exam-anxiety', component:CourseDescriptionComponent},
  {path: 'USMLE Step 2', component:CourseDescriptionComponent},
  {path: 'USMLE Step 3', component:CourseDescriptionComponent},
  {path: 'Toxicology', component:CourseDescriptionComponent},
  {path: 'Neurology', component:CourseDescriptionComponent},
  {path: 'Sexually-Transmitted-Diseases', component:CourseDescriptionComponent},
  {path: 'Heart-Failure', component:CourseDescriptionComponent},
  {path: 'Toxicology', component:CourseDescriptionComponent},
  {path: 'Patient-Care', component:CourseDescriptionComponent},
  {path: 'Medical-Knowledge', component:CourseDescriptionComponent},
  {path: 'Radiology', component:CourseDescriptionComponent},
  {path: 'Outcomes-Ethics', component:CourseDescriptionComponent},
  {path: 'Neuroscience', component:CourseDescriptionComponent},
  {path: 'Clinical-Neuro/Psych', component:CourseDescriptionComponent},
  {path: 'Behavioral/Social-Science', component:CourseDescriptionComponent},
  {path: 'Issues-in-Practice', component:CourseDescriptionComponent},
  {path: 'Mathematics', component:CourseDescriptionComponent},
  {path: 'Biology', component:CourseDescriptionComponent},
  {path: 'Poetry', component:CourseDescriptionComponent},
  {path: 'Writing', component:CourseDescriptionComponent},
  {path: 'Word-Problems', component:CourseDescriptionComponent},
  {path: 'Pre-Algebra', component:CourseDescriptionComponent},
  {path: 'Politics', component:CourseDescriptionComponent},
  {path: 'Interview-Prep', component:CourseDescriptionComponent},
  {path: 'Bully-Help', component:CourseDescriptionComponent},
  {path: 'Accent-Reduction', component:CourseDescriptionComponent},
  {path: 'Conflict-Resolution', component:CourseDescriptionComponent},
  {path: 'Time-Management', component:CourseDescriptionComponent},
  {path: 'Self-Care', component:CourseDescriptionComponent},
  {path: 'High-School-ACT', component:CourseDescriptionComponent},
  {path: 'High-School-SAT', component:CourseDescriptionComponent},
  {path: 'Exam-Anxiety', component:CourseDescriptionComponent},
  {path: 'Test-prep', component:CourseDescriptionComponent},
  {path: 'educational-institute', component:B2bInstitutionComponent},
  {path: 'enterprise-solutions', component:EnterpriseSolutionComponent},
  
  {path: 'AI_Tutor', component:PlaypptComponent},
  
  {path: '**', redirectTo: '/'},
  
];




@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
   
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
