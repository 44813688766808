import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonial-card',
  templateUrl: './testimonial-card.component.html',
  styleUrls: ['./testimonial-card.component.css']
})
export class TestimonialCardComponent implements OnInit {

  testimonials = [
    { text: 'Sierra Canyon is happy to utilize edYOU, as this is the future of learning.', author: 'Jim Skrumbis', role: 'Head of School at Sierra Canyon' },
    { text: 'The greatest performance enhancement tool we’ve used in a long time.', author: 'Coach Trent Dilfer', role: 'Superbowl Champion Head Coach at UAB Football' },
    { text: 'edYOU helps me study and get my homework done so I feel ready to take tests in school.', author: 'Dr. Shakirat', role: 'Doctor of Medicine' },
    { text: '"edYOU changed my children\'s educational and studying life."<br>Agata E.', author: 'Agata E.', role: 'Sierra Canyon Parent' },
    { text: 'With edYOU I can tackle problems step by step, to better learn new material.', author: 'Ever Y.', role: 'Sierra Canyon Student' },


    
    
    
    
  ];

  
  currentIndex = 0;
  transformStyle = 'translateX(0)';
  private intervalId: any;
  
  constructor() { }

  ngOnInit(): void { 
    this.testimonials = [...this.testimonials, ...this.testimonials];
  }

}
