<div class="course-page">

  <div class="course-container">

    <div class="video-background">
      <video autoplay muted loop  playsInline webkit-playsinline id="bgVideo">
        <!-- <source src="https://pollydemo2022.s3.us-west-2.amazonaws.com/new-b2c/course-bg.mov" type="video/mp4"> --> 
        <source loop muted  src="../../assets/video/course-bg.mov" >
        <!-- <source src="https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/Compressed/course-bg.mov" > -->
        Your browser does not support HTML5 video.
      </video>
      <div class="content-overlay">
        <!-- Your content goes here -->
        <h1 class="main-heading" id = "course_page">Courses</h1>
        <div class="sub-heading">
          <p>Select your <a style="color:#648EB5;cursor:pointer" (click)='navigateToPricing()'> Subscription Plan</a>
           <br style="font-size: 78px;"> Subscriptions give full access to All Courses </p>
           <p></p>
        </div>
      </div>
    </div>
  </div>

  <div class="inner-course">

    <div class="course-container2" [ngStyle]="{'background-image': 'url(./assets/newb2cmedia/bg-gradient.png)'}">
      <div *ngFor="let card of cards; let i = index" class="course-card" style="cursor: pointer;"
        (click)="toggleOverlay(card)" [class.active]="card.showOverlay" [ngClass]="{
        'expanded-card': card.title === 'CME Certification' && card.showOverlay,
        'adjusted-margin ':(cards[i - 1]?.title === 'CME Certification' && cards[i - 1]?.showOverlay),
        'expanded-card-1': card.title === 'Test Prep' && card.showOverlay,
        'expanded-card-3': (card.title === 'Academic' && card.showOverlay),
        'expanded-card-2': card.title === 'Skills For Life' && card.showOverlay
        }" >
        <img [src]="card.img" alt="{{ card.title }}" />

        <!-- Arrow icon to toggle overlay -->
        <div class="inner-icon" (click)="toggleOverlay(card, $event)">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 16 17" fill="none">
            <path d="M12 6.5L8 10.5L4 6.5" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>

        <!-- Overlay content, shown only if showOverlay is true -->
        <div class="card-overlay" *ngIf="card.showOverlay">

          <ng-container *ngIf="card.overlayContent; else defaultContent">
            <div class="icon-overlay">

              <img class ="course-card-icon"[src]="card.icon"  alt="{{ card.title }}" />
              <p>{{ card.title }}</p>
              <div class="inner-icon-overlay" (click)="toggleOverlay(card, $event)">
                <i class="fas fa-chevron-up"></i>
              </div>
            </div>

            <ul>
              <div class="content-box" [ngStyle]="{'margin-top': card.title === 'Skills For Life' || card.title === 'Academic' ? '40px' : card.title === 'Test Prep' ? '60px' : '0'}">
                <li [ngClass]="{'coming-soon': isComingSoon(content.text)}"
                  (click)="!isComingSoon(content.text) && navigateToCoursePage(content.id)" style="cursor: pointer;"
                  *ngFor="let content of card.overlayContent" (click)="navigateToCoursePage(content.id)">
                  {{ content.text }}
                </li>
              </div>
            </ul>

          </ng-container>
          <ng-template #defaultContent>
            <p>Overlay content for {{ card.title }}</p>
          </ng-template>
        </div>

        <div class="icon">
          <img class ="course-card-icon" [src]="card.icon" alt="{{ card.title }}" />
          <p class="course-title">{{ card.title }}</p>
        </div>
      </div>
    </div>
    <div class="scbio">
      <div class="card1">
        <div class="child1">
          <img src="./assets/newb2cmedia/star.svg" alt="">
          <img src="./assets/newb2cmedia/star.svg" alt="">
          <img src="./assets/newb2cmedia/star.svg" alt="">
          <img src="./assets/newb2cmedia/star.svg" alt="">
          <img src="./assets/newb2cmedia/star.svg" alt="">
        </div>
        <div class="child2">
          <p>“edYOU is the future of learning. We are happy to utilize it at Sierra Canyon.”</p>
        </div>
        <div class="child3">
          <h5 class="headofSchool">Jim Skrumbis </h5>
          <h6 class="headofSchoolposition">Head of School at Sierra Canyon</h6>
        </div>
      </div>
      <div class="card2">
        <img src="./assets/newb2cmedia/Jim-Skrumbis-1.png" alt="">
      </div>
    </div>
  </div>

  <app-testimonial-card></app-testimonial-card>
  <div class="subscription-container" [ngStyle]="{'background-image': 'url(./assets/newb2cmedia/bg-gradient.png)'}">
    <!-- PRICING -->
    <div id="pricing_section_id" #pricingSection class="pricing-section">
      <div class="pricing-title-section">

        <h2 class="buynowheader">Subscription Plans</h2>
        <img class="highlight" src="./assets/newb2cmedia/pricing-bg.png" alt="Test Prep" />
        <!-- <img class="pre-launch-special-badge" src="./assets/pricing-section/pre-launch-special-badge.png" alt="pre-launch-special-badge"> -->
      </div>
      <div class="pricing-cards-container">

        <!-- DAY PLAN -->
        <div class="pricing-card silver">
          <!-- PRE-LAUNCH RIBBON -->
          <div class="maincontentforplan">
            <div class="topsection">
              <div class="pricing-card-title">
                <div class="pricing-card-title-left">
                  <h4>Silver </h4>
                </div>
                <div class="pricing-card-title-right">
                  <span class="strikethrough">
                    <span class="strikethrough-text">${{this.prodDetail[2]?.Actual_amount}}</span>
                  </span>
                  <span><strong class="pricing-number">${{this.prodDetail[2]?.amount}}</strong></span>
                </div>
              </div>

              <!-- GO TO STRIPE! -->
              <div class="pricing-card-description">

                <ul class="pricing-card-description-list-items">
                  <!-- 1-Day access Learning Module Testing Module Wellness Companion Conversation history Gamification Infinite Test Prep -->
                  <li>1-Day access</li>
                  <li>Courses Module</li>
                  <li>Quiz Module</li>
                  <li>Wellness Companion</li>
                  <li>Conversation history</li>
                  <li>Gamification</li>
                  <li>Infinite Test Prep</li>
                </ul>

              </div>
            </div>
            <div class="bottomsection">
              <button (click)=" preEnroll('Silver',$event)"
                [disabled]="(!enableB2cPlan &&  this.isLoggedIn) || silverloader"
                [ngClass]="{'disabled-button': !enableB2cPlan &&  this.isLoggedIn}" class="pricing-button"><img class=""
                  style="width : 24px;" *ngIf="silverloader" src="../../../assets/Rolling-1s-200px (2).gif"> <span
                  *ngIf="!silverloader">Buy Now</span></button>
            </div>
          </div>


        </div>

        <!-- WEEK PLAN -->
        <div class="pricing-card gold">
          <!-- PRE-LAUNCH RIBBON -->
          <div class="maincontentforplan">
            <div class="topsection">
              <div class="pricing-card-title">
                <div class="pricing-card-title-left">
                  <h4>Gold </h4>
                </div>
                <div class="pricing-card-title-right">
                  <span class="strikethrough">
                    <span class="strikethrough-text">${{this.prodDetail[0]?.Actual_amount}}</span>
                  </span>
                  <span><strong class="pricing-number">${{this.prodDetail[0]?.amount}}</strong>/week</span>
                </div>
              </div>

              <div class="pricing-card-description">

                <ul class="pricing-card-description-list-items">
                  <!-- Full month access Learning Module Testing Module Wellness Companion Conversation history Gamification Certificates of Completion (coming 12/15/24) -->
                  <li>Full week access</li>
                  <li>Courses Module</li>
                  <li>Quiz Module</li>
                  <li>Wellness Companion</li>
                  <li>Conversation history</li>
                  <li>Gamification</li>
                  <li>Infinite Test Prep</li>
                </ul>
              </div>
            </div>
            <div class="bottomsection">
              <button class="pricing-button" (click)=" preEnroll('Gold',$event)"
                [disabled]="(!enableB2cPlan &&  this.isLoggedIn) || goldloader"
                [ngClass]="{'disabled-button': !enableB2cPlan &&  this.isLoggedIn}"><img class="" style="width : 24px;"
                  *ngIf="goldloader" src="../../../assets/Rolling-1s-200px (2).gif"> <span *ngIf="!goldloader">Buy
                  Now</span></button>
            </div>
          </div>
          <img class="bottom-position" src="./assets/newb2cmedia/7-day-free-trial-ribbonnew.png" alt="pre-launch-special-ribbon">
        </div>

        <!-- UNLIMITED PLAN -->
        <div class="pricing-card unlimited">
          <!-- PRE-LAUNCH RIBBON -->
          <div class="maincontentforplan">
            <div class="topsection">
              <div class="pricing-card-title">
                <div class="pricing-card-title-left">
                  <h4>Platinum</h4>
                </div>
                <div class="pricing-card-title-right">
                  <span class="strikethrough">
                    <span class="strikethrough-text">${{this.prodDetail[1]?.Actual_amount}}</span>
                  </span>
                  <span><strong class="pricing-number">${{this.prodDetail[1]?.amount}}</strong>/month</span>
                </div>
              </div>

              <div class="pricing-card-description">

                <ul class="pricing-card-description-list-items">
                  <li>Full month access</li>
                  <li>Courses Module</li>
                  <li>Quiz Module</li>
                  <li>Wellness Companion</li>
                  <li>Conversation history</li>
                  <li>Gamification</li>
                  <li>Infinite Test Prep</li>
                  <li>Certificates of Completion (coming 12/15/24)</li>
                </ul>
              </div>
            </div>
            <div class="bottomsection">
              <button class="pricing-button" (click)=" preEnroll('Platinum',$event)"
                [disabled]="(!enableB2cPlan &&  this.isLoggedIn) || platinamloader"
                [ngClass]="{'disabled-button': !enableB2cPlan &&  this.isLoggedIn}"><img class="" style="width : 24px;"
                  *ngIf="platinamloader" src="../../../assets/Rolling-1s-200px (2).gif"> <span
                  *ngIf="!platinamloader">Buy Now</span></button>
            </div>
          </div>
          <img class="bottom-position" src="./assets/newb2cmedia/7-day-free-trial-ribbonnew.png" alt="pre-launch-special-ribbon">
        </div>



      </div>
    </div>

  </div>
  <div class="bottomb2b">
  <div class="bottom-container">
    <div class="bottom-content-container" >
      <h2 >For Educational Institutions </h2>
      <button class="pricing-button-bottom" (click)=" navigateToInstitutePage()">Click Here
      </button>
    </div>
  </div>
  <div class="bottom-container">
    <div class="bottom-content-container" >
      <h2 >For Enterprise Solutions</h2>
      <button class="pricing-button-bottom" (click)=" navigateToEnterprisePage()">Click Here
      </button>
    </div>
  </div>
</div>
</div>
  <!-- <button class="btn2" [disabled]="!isRestartClickable" *ngIf="(isRecordValue == 'Restart' || isRecordValue == 'Resume')" (click)="startLearningFunction('Restart')">  <img class="" style="width : 24px;" *ngIf="isLoading4"
    src="../../../assets/Rolling-1s-200px (2).gif"> <span
    *ngIf="!isLoading4">Restart</span></button> -->