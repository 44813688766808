<div class="main" *ngIf="isCourseRoute" >
    <div class="img" *ngIf="!isMobileScreen" >
        <img  src="./assets/newb2cmedia/ssblog.png" alt="blog" />
    </div>
    <div class="main-container">
        <div>
       <a  class="icon"  (click)="navigateToBlog()">   
    <svg  style="margin-top: 7px;"  xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none" >
        <path d="M0.702566 10.3768C0.116779 10.9626 0.116779 11.9124 0.702566 12.4982L10.2485 22.0441C10.8343 22.6299 11.784 22.6299 12.3698 22.0441C12.9556 21.4583 12.9556 20.5086 12.3698 19.9228L3.88455 11.4375L12.3698 2.95222C12.9556 2.36643 12.9556 1.41669 12.3698 0.830898C11.784 0.245111 10.8343 0.245111 10.2485 0.830898L0.702566 10.3768ZM21.7109 9.9375L1.76323 9.9375V12.9375L21.7109 12.9375V9.9375Z" fill="white"/>
        </svg>
        <a style="color: #FFF;font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;">BLOG</a></a>
        </div>
        <div  *ngIf="isMobileScreen" >
            <img  src="./assets/newb2cmedia/mobile-how.png" alt="blog" />
        </div>
<div class="section">
    <div class="heading">
    <h2>Introduction</h2>
    </div>
    <p>
        The edYOU learning platform represents a revolutionary innovation in AI-powered educational technology. edYOU provides groundbreaking capabilities for personalized learning through its trifecta of proprietary technologies: the Personal Ingestion Engine, Personal AI assistant, and Intelligent Curation Engine. As we explore edYOU’s unique architecture, it becomes clear this platform could redefine how humans interact with AI to learn and grow.
    </p>
    <div class="heading">
        <h2>The Power of Personalized Ingestion</h2>
    </div>
    <p>The Personal Ingestion Engine (PIE) enables highly customized learning experiences. Specialized bots and algorithms ingest content, then transform it into personalized learning aids based on user preferences. By combining comprehensive data ingestion with personalization, PIE allows edYOU to provide tailored learning pathways, tutorials, assessments and more. This personalized approach to ingestion and curation is a key innovation.</p>
    <div class="heading">
        <h2>Conversational AI That Cares About You</h2>
    </div>
    <p>The Personal AI (PAI) assistant delivers personalized and transparent AI discussions. EdYOU’s proprietary natural language processing integrates curated data and speech processing for more natural conversations. PAI also employs explainable AI to provide transparency, outlining the sources and provenance behind responses. Additionally, PAI uses sentiment analysis to adjust responses based on emotional cues, understanding users’ needs.</p>
    <div class="heading">
        <h2>Safeguarding Users with Intelligent Curation</h2>
    </div>
    <p>The Intelligent Curation Engine (ICE) allows edYOU to address critical issues like bias and safety. ICE integrates curated data with toxicity filters to intercept inappropriate content pre-emptively. ICE also enables monitoring of conversations to trace and resolve problems. By combining proactive and reactive guardrails, ICE keeps learner discussions safe.</p>
    <div class="heading">
      <h2>The Future of Personalized AI Learning</h2>
   </div>
   <p>EdYOU has bold plans to continue advancing its personalized, transparent and safe AI learning platform, with aspirations beyond education alone. The vision is empowering learners from all backgrounds by democratizing access to edYOU’s capabilities. With its human-centric AI, edYOU could catalyze an educational revolution.</p>
</div>
</div>
</div>
<div class="main" *ngIf="!isCourseRoute">
    <div class="img" *ngIf="!isMobileScreen" >
        <img  src="./assets/newb2cmedia/revolution-news.png" alt="blog" />
    </div>
    <div class="main-container">
        <div>
       <a  class="icon" (click)="navigateToBlog()">   
    <svg    xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none" >
        <path d="M0.702566 10.3768C0.116779 10.9626 0.116779 11.9124 0.702566 12.4982L10.2485 22.0441C10.8343 22.6299 11.784 22.6299 12.3698 22.0441C12.9556 21.4583 12.9556 20.5086 12.3698 19.9228L3.88455 11.4375L12.3698 2.95222C12.9556 2.36643 12.9556 1.41669 12.3698 0.830898C11.784 0.245111 10.8343 0.245111 10.2485 0.830898L0.702566 10.3768ZM21.7109 9.9375L1.76323 9.9375V12.9375L21.7109 12.9375V9.9375Z" fill="white"/>
        </svg>
        <a style="color: #FFF;font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;">BLOG</a></a>
        </div>
        <div  *ngIf="isMobileScreen" >
            <img  src="./assets/newb2cmedia/revolution-mobile.png" alt="blog" />
        </div>
    <div class="section">
        <div class="heading">
     
        </div>
        <p>
            The COVID-19 pandemic has had a profound impact on education worldwide, leaving American students facing significant learning loss. Disastrous test scores and widening disparities have underscored the urgent need to address the educational challenges posed by the pandemic. In this blog post, we will explore the concerning statistics outlined in Alec MacGillis’ article and introduce edYOU, an innovative education solution that combines cutting-edge technology, personalized AI assistance, and seamless integration to help students recover and thrive.
        </p>
        <div class="heading">
            <h2>The Learning Gap Exposed</h2>
            <h2>Accessible Anywhere, Anytime</h2>
        </div>
        <p>edYOU revolutionizes education by providing browser and mobile app access, ensuring that students can access high-quality educational resources and personalized support whenever and wherever they need it. With Edyou, students can learn at their own pace, catching up on missed concepts and reinforcing key skills.</p>
        <div class="heading">
            <h2>Introducing edYOU: Empowering Education with AI</h2>
        </div>
        <p>The Personal AI (PAI) assistant delivers personalized and transparent AI discussions. EdYOU’s proprietary natural language processing integrates curated data and speech processing for more natural conversations. PAI also employs explainable AI to provide transparency, outlining the sources and provenance behind responses. Additionally, PAI uses sentiment analysis to adjust responses based on emotional cues, understanding users’ needs.</p>
        <div class="heading">
            <h2>Personalized AI Assistance</h2>
        </div>
        <p>edYOU’s patent-pending Personal AI Tech takes personalized learning to the next level. By leveraging a proprietary Ingestion Engine, edYOU can import personal user data and a wide range of inputs, including real-time IoT data. This holistic approach enables edYOU ‘s AI to understand each student’s unique strengths, weaknesses, and learning preferences, tailoring educational content and recommendations accordingly.</p>
        <div class="heading">
          <h2>Intelligent Curation Engine</h2>
       </div>
       <p>edYOU’s Intelligent Curation Engine ensures that students receive curated content that aligns with their individual needs and learning goals. By filtering large language models like ChatGPT when necessary, edYOU provides accurate and reliable information while prioritizing student safety and well-being.</p>
       <div class="heading">
        <h2>Friendly Humanlike AI Beings</h2>
        </div>
        <p>Recognizing that interacting with AI can sometimes feel impersonal, edYOU employs friendly and humanlike AI beings to make the learning experience more comfortable and engaging. These AI beings serve as virtual companions, offering guidance, motivation, and support throughout the educational journey.</p>
        <div class="heading">
            <h2>Seamless Integration with Enterprise Apps</h2>
         </div>
         <p>edYOU’s robust APIs enable seamless integration into various enterprise applications, making it easy for schools, districts, and educational institutions to incorporate edYOU ‘s powerful features into their existing systems. This integration allows for a comprehensive and cohesive educational experience, empowering educators and students alike.</p>
         <div class="heading">
            <h2>Conclusion
            </h2>
         </div>
         <p>As we navigate the aftermath of the COVID-19 pandemic, the urgency to bridge the educational gap and empower students has never been greater. edYOU’s innovative approach, with its browser and mobile app accessibility, patent-pending Personal AI Tech, Intelligent Curation Engine, friendly AI beings, and seamless integration capabilities, offers a transformative solution to address the challenges faced by students. By leveraging the power of AI and personalized learning, edYOU ensures that every student receives the support and resources needed to recover what was lost during the pandemic. Together, we can revolutionize education and build a brighter future for all learners.</p>
   
   
   
   
   
   
    </div>
    </div>
    </div>