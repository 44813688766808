<!-- <div class="testimonials-section">
     
    <h2>Testimonials</h2>
    <div class="testimonials-wrapper">
    <div class="testimonials-container">
      
      <div class="testimonial-card">
       
        <div class="">
        <div class="stars">★★★★★</div>
        <p>Sierra Canyon is happy to utilize edYOU, as this is the future of learning.</p>
        <h3>Jim Skrumbis</h3>
        <h4>Head of School at Sierra Canyon </h4>
        </div>
      </div>
    
      <div class="testimonial-card">
        <div class="stars">★★★★★</div>
        <p>The greatest performance enhancement tool we’ve used in a long time.</p>
        <h3>Coach Trent Dilfer</h3>
        <h4> Superbowl Champion
          Head Coach at UAB Football</h4>
      </div>
      <div class="testimonial-card">
        <div class="stars">★★★★★</div>
        <p>edYOU helps me study and get my homework done so I feel ready to take tests in school.</p>
        <h3>Dr. Shakirat</h3>
        <h4>Doctor of Medine </h4>
      </div>
      <div class="testimonial-card">
        <div class="stars">★★★★★</div>
        <p>"edYOU changed my children's educational and studying life." <br>  &nbsp;</p>
        <h3>Agata E.</h3>
        <h4> Sierra Canyon Parent</h4>
      </div>
      <div class="testimonial-card">
        <div class="stars">★★★★★</div>
        <p>With edYOU I can tackle problems step by step, to better learn new material.</p>
        <h3> Ever Y.</h3> 
        <h4>Sierra Canyon Student </h4>
      </div>
    </div>
</div>
  </div> -->



  <div class="testimonials-section">
    <h2>Testimonials</h2>
    <div class="slider">
      <div class="testimonials-container">
          <div class="testimonial-card" *ngFor="let testimonial of testimonials">
            <div class="stars">★★★★★</div>
            <p [innerHTML]="testimonial.text"></p>
            <h3>{{ testimonial.author }}</h3>
            <h4>{{ testimonial.role }}</h4>
          </div>
        </div>
    </div>
  </div>


  