export const courses = [
  {
    id: "us-history",
    title: "US History",
    images : [
      { src: '../assets/course-banners/course-slides/us-history-1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/us-history-2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/us-history-3.png', alt: 'Slide 3' },
    ],
    description: `Dive into America’s past, exploring major events and movements that shaped the nation.<br>
<li>	Duration: 9 chapters</li>
<li>	Estimated Completion Time: 3 weeks</li>`,
    icon: '',
  },
 
  {
    id: "english",
    title: "Introduction to English",
    images : [
      { src: '../assets/course-banners/course-slides/english-1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/english-2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/english-3.png', alt: 'Slide 3' },
    ],
    description: `Build reading, writing, and speaking skills for academic, professional, and personal contexts.
<li>	Duration: 5 chapters</li>
<li> Estimated Completion Time: 2 weeks</li>`

  },
  {
    id: "computer-science",
    title: "Computer Science",
    images : [
      { src: '../assets/course-banners/course-slides/computer-science-1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/computer-science-2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/computer-3.jpg', alt: 'Slide 3' },
    ],
    description: `Explore programming, algorithms, and data structures to solve problems and innovate.<br>
<li>	Duration: 5 chapters</li>
<li>	Estimated Completion Time: 2 weeks</li>`

  }, 
 
  {
    id: "leadership",
    title: "Leadership",
    images : [
      { src: '../assets/course-banners/course-slides/leadership-1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/leadership-2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/leadership-3.png', alt: 'Slide 3' },
    ],
    description: `Learn leadership styles, emotional intelligence, and effective communication for any situation.<br>
<li>	Duration: 5 chapters</li>
<li>	Estimated Completion Time: 2 weeks </li>`

  },
  {
    id: "communications",
    title: "Communications",
    images : [
      { src: '../assets/course-banners/course-slides/communication-1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/communication-2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/communication-3.png', alt: 'Slide 3' },
    ],
    description: `Master effective interpersonal and professional communication skills with practical scenarios.<br>
    <li>	Duration: 5 chapters</li>
    <li>	Estimated Completion Time: 2 weeks</li>`

  },
  {
    id: "entrepreneurship",
    title: "Entrepreneurship",
    images : [
      { src: '../assets/course-banners/course-slides/E1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/Entre2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/E3.png', alt: 'Slide 3' },
    ],
    description: `Turn ideas into reality with lessons on market research, branding, and business fundamentals.<br>
<li>	Duration: 5 chapters</li>
<li> Estimated Completion Time: 2 weeks</li>`

  },
  {
    id: "project-management",
    title: "Project Management",
    images : [
      { src: '../assets/course-banners/course-slides/project-management-1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/project-management-2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/pm3.jpg', alt: 'Slide 3' },
    ],
    description: `Learn to plan, execute, and lead projects successfully with real-world applications.<br>
    <li>	Duration: 5 chapters</li>
    <li>	Estimated Completion Time: 2 weeks</li>`

  },
 
  {
    id: "algebra",
    title: "Algebra",
    images : [
      { src: '../assets/course-banners/course-slides/algebra-1.jpg', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/algebra-2.jpg', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/algebra3.jpg', alt: 'Slide 3' },
    ],
    description: `Master algebraic concepts like equations, inequalities, and graphing for academic and real-world use.<br>
   <li> Duration: 12 chapters</li>
  <li>Estimated Completion Time: 4 weeks</li>`,
  },
  {
    id: 'Cardiology',
    title: 'Cardiology (Xpress CME Certification Course)',
    images : [
      { src: '../assets//course-banners/course-slides/C1.jpeg', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/C2.jpeg', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/C3.png', alt: 'Slide 3' },
    ],
    description: `Master cardiovascular health, focusing on diagnosis and treatment of heart conditions.
<li>	Duration: 1 chapter</li>
<li>Estimated Completion Time: 1 week</li>
`
    
  },
  {
    id: 'Endocrinology',
    title: 'Endocrinology (Xpress CME Certification Course)',
    images : [
      { src: '../assets/course-banners/course-slides/E1.jpeg', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/E2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/E3.jpeg', alt: 'Slide 3' },
    ],
 
    description: `Understand hormonal systems, focusing on diabetes and thyroid disorders.<br><li>	Duration: 1 chapter</li>
<li>	Estimated Completion Time: 1 week</li>`,
  
  },
  {
    id: 'Gastroenterology',
    title: 'Gastroenterology (Xpress CME Certification Course)',
    images : [
      { src: '../assets/course-banners/course-slides/G1.jpeg', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/G2.jpeg', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/G3.jpeg', alt: 'Slide 3' },
    ],
 
    description: `Explore digestive health, focusing on disorders like IBS and Crohn’s disease.<br>
<li>	Duration: 4 chapters</li>
<li>	Estimated Completion Time: 2 weeks</li>
`
  },
  
 
 
 
 
  {
    id: 'rheumatology',
    title: 'Rheumatology (Xpress CME Certification Course)',
    image: '../../assets/course-icons/Framer.png',
    hoverImage: '../../assets/course-icons/course-images/11_rheumatology.jpeg',
    description: `Understand autoimmune and joint disorders like lupus and rheumatoid arthritis in focused detail.
<li>Duration: 2 chapters</li>
<li>Estimated Completion Time: 1 week</li>`,
   
  },
  {
    id: 'intro-to-family-medicine',
    title: 'Intro to Family Medicine',
    image: '../../assets/course-icons/Family Medicine.png',
    hoverImage: '../../assets/course-icons/course-images/12_intro_to_family_medicine.jpeg',
    description: `Get a comprehensive overview of primary care with our Intro to Family Medicine course! This course covers the diagnosis, treatment, and prevention of common health conditions across all age groups, focusing on holistic and long-term patient care. You’ll learn about managing chronic diseases, preventive care, and addressing both physical and mental health needs. Perfect for medical students, healthcare professionals, or those interested in primary care, this course provides a strong foundation for delivering patient-centered healthcare in a family medicine setting.`,
    bullet1: `Learn holistic primary care practices.`,
    bullet2: `Diagnose and manage common conditions.`,
  },
  {
    id: 'intro-to-surgery',
    title: 'Intro to Surgery',
    image: '../../assets/course-icons/Surgery.png',
    hoverImage: '../../assets/course-icons/course-images/13_intro_to_surgery.jpeg',
    description: `Explore the fundamentals of surgery with our Intro to Surgery course! This course provides an overview of essential surgical principles, techniques, and procedures. You’ll learn about preoperative and postoperative care, sterile techniques, and the basics of suturing and wound management. Covering both minor and major surgeries, the course is ideal for medical students, healthcare professionals, or anyone interested in gaining foundational knowledge in the field of surgery. Equip yourself with the skills needed to navigate the operating room and understand the surgical care process.`,
    bullet1: `Learn basic surgical techniques.`,
    bullet2: `Understand pre-and post-operative care.`,
  },
  {
    id: 'intro-to-obstetrics-gynaecology',
    title: 'Intro to Obstetrics / Gynaecology',
    image: '../../assets/course-icons/gyno.png',
    hoverImage: '../../assets/course-icons/course-images/14_intro_to_obstetrics_gynaecology.jpeg',
    description: `Comprehensive women’s health covering prenatal care, labor, and gynecological conditions.<br>
<li>	Duration: 5 chapters</li>
<li>	Estimated Completion Time: 2 weeks</li>`,
    bullet1: `Study women's reproductive health.`,
    bullet2: `Learn pregnancy and childbirth management`,
  },
  {
    id: 'intro-to-pediatrics',
    title: 'Intro to Pediatrics',
    image: '../../assets/course-icons/Pediatrics.png',
    hoverImage: '../../assets/course-icons/course-images/15_intro_to_pediatrics.jpeg',
    description: `Start your exploration of children's healthcare with our Intro to Pediatrics course! This course covers the fundamentals of diagnosing, treating, and managing the health of infants, children, and adolescents. You’ll learn about growth and development, common pediatric diseases, immunizations, and preventive care. The course also includes guidance on managing acute and chronic conditions in young patients. Ideal for medical students, healthcare professionals, or anyone interested in child healthcare, this course provides the foundation needed to deliver compassionate and effective pediatric care.`,
    bullet1: `Focus on child healthcare.`,
    bullet2: `Understand common pediatric conditions.`,
  },
  {
    id: 'intro-to-psychiatry',
    title: 'Intro to Psychiatry',
    image: '../../assets/course-icons/Psychiatry.png',
    hoverImage: '../../assets/course-icons/course-images/16_intro_to_psychiatry.jpeg',
    description: `Begin your journey into mental health care with our Intro to Psychiatry course! This course provides an overview of key concepts in psychiatry, covering mental health disorders, diagnostic criteria, and treatment approaches. You’ll explore conditions like depression, anxiety, bipolar disorder, and schizophrenia, while learning about therapeutic techniques such as psychotherapy, medication management, and behavioral interventions. Ideal for medical students, healthcare professionals, or anyone interested in mental health, this course equips you with the foundational knowledge to understand and manage psychiatric conditions effectively.`,
    bullet1: `Study mental health disorders.`,
    bullet2: `Learn therapeutic and treatment methods.`,
  },
  
  {
    id: 'Poetry',
    title: 'Poetry',
    images : [
      { src: '../assets/course-banners/course-slides/P1.1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/P1.2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/P1.3.png', alt: 'Slide 3' },
    ],
    description: `Discover poetry’s forms, techniques, and themes while developing creative expression.<br>
<li>	Duration: 5 chapters</li>
<li>	Estimated Completion Time: 2 weeks</li>`

    
  },
  {
    id: 'Politics',
    title: 'Politics',
    images : [
      { src: 'https://edyouwebsite.s3.us-west-2.amazonaws.com/course-slide/Po1+.jpeg', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/PO2.png', alt: 'Slide 2' },
      { src: 'https://edyouwebsite.s3.us-west-2.amazonaws.com/course-slide/Po3.jpeg', alt: 'Slide 3' },
    ],
    description: `Understand political systems and decisions with real-world case studies and discussions.<br>
    <li>	Duration: 5 chapters</li>
    <li>	Estimated Completion Time: 2 weeks</li>`


  
  },
  {
    id: "Time-Management",
    title: "Time Management",
    images : [
      { src: '../assets/course-banners/course-slides/TM 1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/TM 2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/TM 3.png', alt: 'Slide 3' },
    ],
    description: `Prioritize tasks, reduce stress, and boost productivity with proven techniques.<br>
<li>	Duration: 5 chapters</li>
<li>	Estimated Completion Time: 2 weeks</li>`

  },
  {
    id: "Toxicology",
    title: "Toxicology",
    images : [
      { src: '../assets/course-banners/course-slides/T1 (1).jpeg', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/T2 (1).jpeg', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/T3.png', alt: 'Slide 3' },
    ],
    description: `Learn to detect, diagnose, and treat toxin and drug effects on the body.<br>
<li>Duration: 5 chapters</li>
<li>Estimated Completion Time: 2 weeks</li>`

   
  },
  {
    id: "Writing",
    title: "Writing",
    images : [
      { src: '../assets/course-banners/course-slides/W1.1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/W1.2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/W1.3.png', alt: 'Slide 3' },
    ],
    description: `Refine narrative, academic, and professional writing skills with practical exercises.<br>
<li>	Duration: 5 chapters</li>
<li>	Estimated Completion Time: 2 weeks</li>`

   
  },
  {
    id: "Word-Problems",
    title: "Word Problems",
    images : [
      { src: 'https://edyouwebsite.s3.us-west-2.amazonaws.com/course-slide/WP1.jpeg', alt: 'Slide 1' },
      { src: 'https://edyouwebsite.s3.us-west-2.amazonaws.com/course-slide/WP2.jpeg', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/WP3.png', alt: 'Slide 3' },
    ],
    description: `Develop problem-solving skills to tackle real-world mathematical challenges.<br>
<li>	Duration: 5 chapters</li>
<li>	Estimated Completion Time: 2 weeks</li>`

   
  },
  {
    id: "Pre-Algebra",
    title: "Pre-Algebra",
    images : [
      { src: '../assets/course-banners/course-slides/PA1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/PA2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/PA3.png', alt: 'Slide 3' },
    ],
    description: `Master foundational math concepts like integers and fractions to prepare for advanced topics.<br>
    <li>Duration: 10 chapters</li>
    <li>Estimated Completion Time: 4 weeks</li>`


   
  },
  {
    id: "Test-prep",
    title: "Test Prep",
    images : [
      { src: '../assets/course-banners/course-slides/TP 1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/TP 2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/TP 3 (1).png', alt: 'Slide 3' },
    ],
    description: `Ace your exams with our Test Prep course! This course equips you with the strategies and tools needed to succeed on a wide range of standardized tests. You’ll learn how to approach different question types, manage time effectively, and reduce test anxiety. Through practice tests, study guides, and expert tips, you'll improve your problem-solving skills and build confidence. Whether you're preparing for school exams, entrance tests, or professional certifications, this course will help you perform at your best.`

   
  }, {
    id: "Sexually-Transmitted-Diseases",
    title: "Sexually Transmitted Diseases",
    images : [
      { src: '../assets/course-banners/course-slides/S1.1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/S1.2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/S1.3.png', alt: 'Slide 3' },
    ],
    description: `Study the diagnosis, treatment, and prevention of STDs like HIV and syphilis.<br>
<li>	Duration: 5 chapters</li>
<li>	Estimated Completion Time: 2 weeks</li>`

   
  },
//   {
//     id: "Sexually-Transmitted-Diseases",
//     title: "Sexually Transmitted Diseases",
//     images : [
//       { src: '../assets/course-banners/course-slides/S1.jpeg', alt: 'Slide 1' },
//       { src: '../assets/course-banners/course-slides/S2.jpeg', alt: 'Slide 2' },
//       { src: '../assets/course-banners/course-slides/S3.jpeg', alt: 'Slide 3' },
//     ],
//     description: `Understand and manage the complexities of sexually transmitted infections with our Sexually Transmitted Diseases course! This course covers the diagnosis, treatment, and prevention of common STDs such as HIV, chlamydia, gonorrhea, syphilis, and herpes. You’ll learn about the transmission, symptoms, complications, and public health strategies for controlling outbreaks. Ideal for medical students, healthcare professionals, or anyone interested in sexual health, this course equips you with the knowledge to provide effective patient care and promote awareness in the prevention of STDs.

// `

   
//   },
  {
    id: "Self-Care",
    title: "Self-Care",
    images : [
      { src: '../assets/course-banners/course-slides/SC 1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/SC 2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/SC 3 (1).png', alt: 'Slide 3' },
    ],
    description: `Build sustainable habits for mental, emotional, and physical well-being.<br>
<li>	Duration: 5 chapters</li>
<li>	Estimated Completion Time: 2 weeks</li>


`

   
  },
  {
    id: "Rheumatology",
    title: "Rheumatology (Xpress CME Certification Course)",
    images : [
      { src: '../assets/course-banners/course-slides/R1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/R2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/R3.png', alt: 'Slide 3' },
    ],
    description: `Understand autoimmune and joint disorders like lupus and rheumatoid arthritis in focused detail.
<li>Duration: 2 chapters</li>
<li>Estimated Completion Time: 1 week</li>`


   
  },

  {
    id: "Radiology",
    title: "Radiology",
    images : [
      { src: '../assets/course-banners/course-slides/RL 1 (1).png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/RL 2 (1).png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/RL3.png', alt: 'Slide 3' },
    ],
    description: `Discover medical imaging modalities like X-rays and MRIs and learn diagnostic interpretation.<br>
<li>Duration: 5 chapters</li>
<li>Estimated Completion Time: 2 weeks</li>`

   
  },
  {
    id: "Pulmonary-and-Critical-Care-Medicine",
    title: "Pulmonary and Critical Care-Medicine (Xpress CME Certification Course)",
    images : [
      { src: '../assets/course-banners/course-slides/P1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/P2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/P3 (2).png', alt: 'Slide 3' },
    ],
    description: `Explore lung disorders and critical care strategies like mechanical ventilation and emergency treatments.<br>
<li>	Duration: 2 chapters</li>
<li>	Estimated Completion Time: 1 week</li>

`

   
  },
  {
    id: "Patient-Care",
    title: "Patient Care",
    images : [
      { src: '../assets/course-banners/course-slides/PC 1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/PC 2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/PC 3.png', alt: 'Slide 3' },
    ],
    description: `Learn ethical healthcare practices and navigate complex decision-making scenarios.
<li>	Duration: 5 chapters</li>
<li>	Estimated Completion Time: 2 weeks</li>


`

   
  },
  {
    id: "Outcomes-Ethics",
    title: "Outcomes Ethics",
    images : [
      { src: '../assets/course-banners/course-slides/OE 1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/OE 2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/OE 3 (1).png', alt: 'Slide 3' },
    ],
    description: `Learn about ethical dilemmas in healthcare, balancing patient needs with resource allocation.<br>
<li>	Duration: 5 chapters</li>
<li>	Estimated Completion Time: 2 weeks</li>




`

   
  },
  {
    id: "Intro-to-Obstetrics/Gynaecology",
    title: "Intro to Obstetrics / Gynaecology",
    images : [
      { src: '../assets/course-banners/course-slides/OB1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/OB2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/OB3.png', alt: 'Slide 3' },
    ],
    description: `Comprehensive women’s health covering prenatal care, labor, and gynecological conditions.<br>
<li>	Duration: 5 chapters</li>
<li>	Estimated Completion Time: 2 weeks</li>`


   
  },
  {
    id: "Neuroscience",
    title: "Neuroscience",
    images : [
      { src: '../assets/course-banners/course-slides/NS 1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/NS 2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/NS 3.png', alt: 'Slide 3' },
    ],
    description: `Explore the nervous system, its functions, and conditions like Alzheimer’s and epilepsy.<br>
<li>	Duration: 5 chapters </li>
<li>	Estimated Completion Time: 2 weeks</li>`

   
  },
  {
    id: "Medical-Knowledge",
    title: "Medical Knowledge",
    images : [
      { src: '../assets/course-banners/course-slides/MK 1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/MK 2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/MK 3.png', alt: 'Slide 3' },
    ],
    description: `Master anatomy, physiology, and pathology for foundational medical decision-making.<br>
    <li>	Duration: 5 chapters</li>
    <li>	Estimated Completion Time: 2 weeks</li>
`

   
  },
  {
    id: "Latin",
    title: "Latin",
    images : [
      { src: '../assets/course-banners/latin/latin-1.jpg', alt: 'Slide 1' },
      { src: '../assets/course-banners/latin/latin-2.jpg', alt: 'Slide 2' },
      { src: '../assets/course-banners/latin/latin-3.jpg', alt: 'Slide 3' },
    ],
    description: `Learn Latin and unlock the language of ancient Rome, the foundation of modern languages and Western civilization.<br>
    <li>Duration: 2 Chapters </li>
    <li>Estimated Completion Time: 1 week</li>
`

   
  },
  {
    id: "Issues-in-Practice",
    title: "Issues in Practice",
    images : [
      { src: '../assets/course-banners/course-slides/IP 1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/IP2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/IP 3.png', alt: 'Slide 3' },
    ],
    description: `Navigate real-world challenges in healthcare like confidentiality, informed consent, and disparities.<br>
<li>	Duration: 5 chapters</li>
<li>	Estimated Completion Time: 2 weeks</li>






`

   
  },
  {
    id: "Nephrology",
    title: "Nephrology (Xpress CME Certification Course)",
    images : [
      { src: '../assets/course-banners/course-slides/N1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/N2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/n3nephro.png', alt: 'Slide 3' },
    ],
    description: `Understand kidney health, diseases, and treatments like dialysis and transplantation.<br>
<li>	Duration: 2 chapters</li>
<li>Estimated Completion Time: 1 week</li>`

   
  },
  {
    id: "Intro-to-Surgery",
    title: "Intro to Surgery",
    images : [
      { src: '../assets/course-banners/course-slides/IS 1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/IS 2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/IS 3.png', alt: 'Slide 3' },
    ],
    description: `Explore surgical principles, including pre/post-operative care and sterile techniques.<br>
<li>Duration: 5 chapters</li>
<li>Estimated Completion Time: 2 weeks</li>`},
  {
    id: "Intro-to-Pediatrics",
    title: "Intro to Pediatrics",
    images : [
      { src: '../assets/course-banners/course-slides/pedia1.jpeg', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/pedia2.jpeg', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/pedia3.jpeg', alt: 'Slide 3' },
    ],
    description: `Gain insights into child healthcare, covering growth, immunizations, and acute/chronic conditions.<br>
    <li>	Duration: 5 chapters</li>
    <li>	Estimated Completion Time: 2 weeks</li>`

   
  },
  {
    id: "Intro-to-Psychiatry",
    title: "Intro to Psychiatry",
    images : [
      { src: '../assets/course-banners/course-slides/ps1.jpeg', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/ps2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/ps3.jpeg', alt: 'Slide 3' },
    ],
    description: `Learn about mental health disorders and therapies like psychotherapy and medication management.<br>
<li>	Duration: 5 chapters</li>
<li>	Estimated Completion Time: 2 weeks</li>`},






{
    id: "Neurology",
    title: "Neurology (Xpress CME Certification Course)",
    images : [
      { src: '../assets/course-banners/course-slides/IN 1.jpeg', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/IN 2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/IN 3.png', alt: 'Slide 3' },
    ],
    description: `Explore brain and nerve function, focusing on disorders like epilepsy and Parkinson’s.
<li>	Duration: 1 chapter</li>
<li>	Estimated Completion Time: 1 week</li>










`

   
  },
  {
    id: "Intro-to-Internal-Medicine",
    title: "Intro to Internal Medicine (Xpress CME Certification Course)",
    images : [
      { src: '../assets/course-banners/course-slides/IIM1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/IIM2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/IIM3.png', alt: 'Slide 3' },
    ],
    description: `Learn to diagnose and manage adult diseases like hypertension and diabetes with practical insights.<br>
    <li>Duration: 5 chapters</li>
    <li>Estimated Completion Time: 2 weeks.</li>`

   
  },{
    id: "Interview-Prep",
    title: "Interview Prep",
    images : [
      { src: '../assets/course-banners/course-slides/Interview Prep 1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/Interview Prep 2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/Interview Prep 3.png', alt: 'Slide 3' },
    ],
    description: `Prepare for interviews with practice, confidence-building, and strategies for all formats.<br>
<li>	Duration: 5 chapters</li>
<li>Estimated Completion Time: 2 weeks</li>
`

   
  },
  {
    id: "Infectious-Diseases",
    title: "Infectious Diseases (Xpress CME Certification Course)",
    images : [
      { src: '../assets/course-banners/course-slides/I1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/I2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/I3.png', alt: 'Slide 3' },
    ],
    description: `Study the mechanisms and management of diseases like HIV and tuberculosis.<br>
<li>Duration: 2 chapters</li>
<li>Estimated Completion Time: 1 week</li>`

   
  },
  //add
  {
    id: "Biology",
    title: "Biology",
    images : [
      { src: 'http://edyouwebsite.s3.us-west-2.amazonaws.com/course-slide/B1.jpeg', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/B2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/B3.png', alt: 'Slide 3' },
    ],
    description: `Explore cells, genetics, and ecosystems with engaging lessons and hands-on activities.<br>
<li>	Duration: 21 chapters</li>
<li> Estimated Completion Time: 7 weeks</li>`

   
  }, {
    id: "Bully-Help",
    title: "Bully Help",
    images : [
      { src: '../assets/course-banners/course-slides/Bully Help 1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/Bully Help 2 (1).png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/Bully Help 3.png', alt: 'Slide 3' },
    ],
    description: `Address bullying with strategies for resilience, safe spaces, and effective intervention.<br>
<li>	Duration: 5 chapters</li>
<li>	Estimated Completion Time: 2 weeks</li>


`

   
  }, {
    id: "Clinical-Neuro/Psych",
    title: "Clinical Neuro/Psych",
    images : [
      { src: '../assets/course-banners/course-slides/CNP 1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/CNP 2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/CNP 3.png', alt: 'Slide 3' },
    ],
    description: `Study the intersection of neurological and psychological conditions, focusing on diagnosis and treatment.<br>
<li>	Duration: 5 chapters</li>
<li> Estimated Completion Time: 2 weeks</li>










`

   
  }, {
    id: "Conflict-Resolution",
    title: "Conflict-Resolution",
    images : [
      { src: '../assets/course-banners/course-slides/CR 1 (1).png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/CR 2 (1).png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/CR 3 (1).png', alt: 'Slide 3' },
    ],
    description: `Learn strategies to handle disputes confidently and strengthen relationships.<br>
<li>	Duration: 5 chapters</li>
<li> Estimated Completion Time: 2 weeks</li>
`

   
  }, {
    id: "Intro-to-Family-Medicine",
    title: "Intro to Family Medicine",
    images : [
      { src: '../assets/course-banners/course-slides/F1.jpeg', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/F2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/F3.jpeg', alt: 'Slide 3' },
    ],
    description: `Holistic care training across all ages, focusing on chronic disease management and preventive care.<br>
<li>Duration: 5 chapters</li>
<li>Estimated Completion Time: 2 weeks </li>`

   
  }, {
    id: "Geriatric-Medicine",
    title: "Geriatric Medicine (Xpress CME Certification Course)",
    images : [
      { src: '../assets/course-banners/course-slides/G1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/G2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/G3.1.png', alt: 'Slide 3' },
    ],
    description: `Specialized care for the elderly addressing dementia, mobility issues, and chronic diseases.<br>
<li>Duration: 2 chapters</li>
<li>Estimated Completion Time: 1 week </li>`}, 
  {
    id: "Hematology-and-Oncology",
    title: "Hematology and Oncology",
    images : [
      { src: '../assets/course-banners/course-slides/H1.jpeg', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/H2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/H3.png', alt: 'Slide 3' },
    ],
    description: `Master conditions like anemia and cancer with cutting-edge diagnostics and treatment strategies.<br>
<li>	Duration: 5 chapters </li>
<li> Estimated Completion Time: 2 weeks</li>`

   
  }, 
  {
    id: "Heart-Failure",
    title: "Heart-Failure",
    images : [
      { src: '../assets/course-banners/course-slides/heart1.jpeg', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/heart2.jpeg', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/heart3.jpeg', alt: 'Slide 3' },
    ],
    description: `Understand causes, symptoms, and treatments for heart failure with practical care strategies.<br>
<li>	Duration: 5 chapters</li>
<li>	Estimated Completion Time: 2 weeks</li>
`

   
  }, 
  {
    id: "Behavioral/Social-Science",
    title: "Behavioral/Social-Science",
    images : [
      { src: '../assets/course-banners/course-slides/BSS1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/BSS2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/BSS3.png', alt: 'Slide 3' },
    ],
    description: `Understand human behavior and societal influences with applications in healthcare and social studies.<br>
<li>	Duration: 5 chapters</li>
<li>	Estimated Completion Time: 2 weeks</li>










`

   
  },  {
    id: "Accent-Reduction",
    title: "Accent Reduction",
    images : [
      { src: '../assets/course-banners/course-slides/AR 1.png', alt: 'Slide 1' },
      { src: '../assets/course-banners/course-slides/AR 2.png', alt: 'Slide 2' },
      { src: '../assets/course-banners/course-slides/AR3.png', alt: 'Slide 3' },
    ],
    description: `Refine pronunciation and clarity while preserving individuality through guided practice.<br>
<li>	Duration: 5 chapters</li>
<li>	Estimated Completion Time: 2 weeks</li>`

   
  }, 
  {
    id: "High-School-ACT",
    title: "High School ACT",
    images : [
      { src: '../assets/newb2cmedia/act1.png', alt: 'Slide 1' },
      { src: '../assets/newb2cmedia/act2.png', alt: 'Slide 2' },
      { src: '../assets/newb2cmedia/act3.png', alt: 'Slide 3' },
    ],
    description: `Comprehensive ACT prep covering English, Math, Reading, Science, and Writing with proven test strategies.
<li>	Duration: 48 chapters</li>
<li>	Estimated Completion Time: 8 weeks</li>
`

   
  }, 
  {
    id: "High-School-SAT",
    title: "High School SAT",
    images : [
      { src: '../assets/newb2cmedia/sat1.png', alt: 'Slide 1' },
      { src: '../assets/newb2cmedia/sat2.png', alt: 'Slide 2' },
      { src: '../assets/newb2cmedia/sat3.png', alt: 'Slide 3' },
    ],
    description: `Master SAT sections with focused lessons on Math, Reading, and Writing, plus proven test strategies.<br>
<li>	Duration: 20 chapters</li>
<li>	Estimated Completion Time: 4 weeks</li>
`

   
  }, 
  {
    id: "Exam-Anxiety",
    title: "Exam Anxiety Course",
    images : [
      { src: '../assets/newb2cmedia/EA1.png', alt: 'Slide 1' },
      { src: '../assets/newb2cmedia/EA2.png', alt: 'Slide 2' },
      { src: '../assets/newb2cmedia/EA3.png', alt: 'Slide 3' },
    ],
    description: `Overcome test anxiety with stress management techniques, confidence building, and relaxation methods.<br>
<li>	Duration: 5 chapters</li>
<li>	Estimated Completion Time: 2 weeks</li>
`

   
  }, 
]
