
import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import * as $ from 'jquery';  // Import jQuery
import { UserService } from '../service/user.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router, UrlTree } from '@angular/router';
import { validateConfirmPassword } from '../directives/customValidator.directive';
import { interval, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
declare var google: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  emailMain:string =''
  storedLastName:string=''
  showPopup: boolean = false;
  isLoading: boolean = false
  show: boolean = false;
  isOverlayActive: boolean = false
  signUpForm: boolean = false
  signInForm: boolean = true
  forgetInForm: boolean = false
  otpsuccessForm: boolean = false
  otpsuccessForminvalid: boolean = false
  isForgetSubmitTrue: boolean = false
  isLoading2: boolean = false;
  showMessage: any;
  //isSignUpFormSubmit
  isSignUpFormSubmit: boolean = false
  errorMessage: any = '';
  isForgetFormSubmit: boolean = false
  resetPasswordActive: boolean = false
  forgetLoader: boolean = false;
  resetLoader: boolean = false;
  loggedInDaTa: any;
  age: number = 0;
  showParent: boolean = false;
  showParentb2c: boolean = false;
  schoolname: string = "";
  subscribedata: any;
  isModalOpen: boolean = true;
  otreverify: boolean = false;
  verification: boolean = false
  signuperrormessage: any;
  signUpLoginPassword: any;
  isResendBtndisabled: boolean = false;
  countDown: number = 0;
  private timerSubscription!: Subscription;
  storeEmail: any;
  newLoader:boolean = false;
  loginClicked: boolean = false;
  //  urlGoogle: string = 'https://lnhk70rlqk.execute-api.us-west-2.amazonaws.com/Development/signupAPI/Google_SignUP'
  urlGoogle: string = 'https://lnhk70rlqk.execute-api.us-west-2.amazonaws.com/Production/signupAPI/Google_SignUP'
  private isBrowser: boolean = false;
  storedFirstName: any ='';
  constructor(@Inject(PLATFORM_ID) private platformId: Object,private userserice: UserService,private http: HttpClient, private formBuilder: FormBuilder, private router: Router) { }
  // signUpform!: FormGroup;
  ngOnInit(): void {
    // this.initializeGoogleSignin();
    console.log
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.userserice.popupState$.subscribe(state => {
      this.loginClicked = state.isLogin;
      this.showPopup = state.isOpen;
   
      //this.otpsuccessForm = true;
      if (this.showPopup == true) {
        $('#login').addClass('show'); // This will trigger the modal to open
        this.isOverlayActive = true
      }
      if( this.loginClicked == true){
        this.signUpForm = false
        this.signInForm = true


        this.toggleModal(true);
      }else{
       //handles scenario from course page
        this.signUpForm = true
        
        this.signInForm = false
        this.signUpFun()
        this.toggleModal(true);
      
      }
      // console.log('login', this.showPopup, this.loginClicked)
    });
    

    this.errorMessage = '';
    this.isSignUpFormSubmit = false;
    this.verification = true;
    //  this.otpsuccessForm = true
    //this.otpsuccessForm = true
   
  }
  ngAfterViewInit() {
    this.initializeGoogleSignin();
    this.changeGoogleSigninButtonText();
}
initializeGoogleSignin(): void {
  if(this.isBrowser){ 
  if (window.google && window.google.accounts && window.google.accounts.id) {
  
    // Initialize the Google Sign-In API
    window.google.accounts.id.initialize({
      client_id: '514614298809-kf2itpfg57seg0577dhut5tb409btu01.apps.googleusercontent.com',
      scope: 'profile email',
      auto_prompt: false, // Ensures no automatic prompt for One Tap
      auto_select: false, // Prevents automatic account selection
      callback: this.handleCredentialResponse.bind(this),
    });

    // Render the Sign-In button
    window.google.accounts.id.renderButton(
      document.querySelector('.g_id_signin'), // Ensure this selector matches your HTML
      {
        theme: 'outline', // Customize appearance
        size: 'large', // Size of the button
        text: 'continue_with', // Specify button text
      }
    );
    document
    .getElementById('customGoogleSignInButton')
    ?.addEventListener('click', () => {
      // Trigger Google Sign-In popup
      window.google.accounts.id.prompt();
    });

  
    // Optionally show the One Tap prompt
    // window.google.accounts.id.prompt(); // Uncomment to enable One Tap
  
  } else {
    console.error('Google API not loaded');
  }
}
}
triggerGooglePrompt(): void {

  if(this.isBrowser)
    window.google.accounts.id.prompt((notification: any) => {
      if (notification.isDismissedMoment()) {
        // Only show alert when the user explicitly dismisses or cancels the prompt
        // alert('Sign-in was cancelled by the user.');
      } else if (notification.isSkippedMoment()) {
        // Handle other scenarios if needed
        alert('Sign-in was cancelled by the user.');
      } else {
       
      }
    });
 
}
  changeGoogleSigninButtonText() {
    const buttonText = document.querySelector('.g_id_signin');
    if (buttonText) {
        const buttonSpan = buttonText.querySelector('span');
        if (buttonSpan) {
            buttonSpan.textContent = 'Google'; // Change the text to 'Google'
        }
    }
}
  
  handleCredentialResponse(response: any): void {
    
    const idToken = response.credential;

    // Send token to Lambda for verification
    this.userserice.googleApi ( idToken
      ).subscribe(
        (data: any) => {
          if (data.body.body?.email) {
            this.closePopup();
            this.newLoader = true;

            let loginPayload = {
              "email": data.body.body?.email,
              "password": data.body.body?.password,
            }
            // Redirect to Dashboard
            // this.router.navigate(['/'], { state: { user: data.body.body } });
            let p : {

            }
            this.userserice.signIn(loginPayload).subscribe(
              (data: any) => {
                if (data.statusCode == 200) {
                  this.isLoading = false
                  this.loggedInDaTa = data;
                  localStorage.setItem("url", JSON.stringify(data.url));
                  localStorage.setItem("user", JSON.stringify(data.body))
                  localStorage.setItem("user", JSON.stringify(data.body));
                  localStorage.setItem("LoginState", JSON.stringify(true));
                  localStorage.setItem("email", data.body.email);
                  localStorage.setItem("appurl", data.redirect_url);
                  localStorage.setItem("plan_type", data.b2c_plan_enable);
      
                  localStorage.setItem("plan_b2b", data.non_b2c_plan_enable);
                  //localStorage.setItem("subscription", JSON.stringify(data));
      
      
      
                  let selectedPlan = localStorage.getItem('PlanSelected');
                

                  if(selectedPlan)
                  // let  emailid = localStorage.getItem('email')
                    this.planAPI( data.body.email,selectedPlan)

      
      
                  let payload = {
      
                    "request": "get_customer_product",
                    "customer_id": data.body.cus_id
                  }
      
                  this.userserice.getSubscriptionDetail(payload).subscribe((res: any) => {
                    if (res.statusCode == 200) {
      
                      this.subscribedata = res.body
                      this.userserice.setSubscriptionData(this.subscribedata);
                      localStorage.setItem("subscription", JSON.stringify(this.subscribedata)); 
                      // Close the login modal and trigger the storage event
                      if(this.isBrowser){ 
                      window.dispatchEvent(new Event('storage'));
                      }
      
                    } else {
                      //localStorage.removeItem("subscription");
                      this.subscribedata = ''
                    
                      localStorage.removeItem("subscription");
                    }
                  })
      
                  //  localStorage.setItem("subscription", JSON.stringify(res.body));
                 
                  
      
                    
                    localStorage.setItem("Login_User", JSON.stringify('B2C'));
                    this.router.navigate(['/']);
                    
                  
      
      
                  //    localStorage.setItem("orderhistory", JSON.stringify(data.orderHistory));
      
      
                  //  localStorage.setItem("token", JSON.stringify(data.Token));
                  this.userserice.login();
                  this.newLoader = false;
                  // this.closePopup();
                }
                else if (data.statusCode == 401) {
                  this.isLoading = false
      
                  this.errorMessage = data.body
      
                  //   this.isSignUpFormSubmit = true;
      
                  // If there is an error with the request, display an error message
                  // this.statusCodeError(data)
                } else if (data.statusCode == 402) {
                  this.isLoading = false
      
                  this.errorMessage = data.body
                  setTimeout(() => {
                    this.errorMessage = ''
                  }, 3000)
                }
              })
          } else {
            alert('Failed to sign in');
          }
        },
        (err:any) => {
          console.error('Error during sign-in:', err);
          alert('Error during sign-in');
        }
      );
  }

  handleGoogleSignIn(): void {
   
    // Trigger the Google Sign-In flow manually if needed
    if(this.isBrowser){ 
    window.google.accounts.id.prompt();
    }
  }

  // form group
  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
    password: new FormControl('', Validators.required),
  })


  //signUp
  // form group
  signUpform = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
    //  email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),

    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    f_name: new FormControl(this.storedFirstName, Validators.required),
    parentEmail: new FormControl(''),
    policy: new FormControl(false, Validators.requiredTrue),
    policyage: new FormControl(true, Validators.requiredTrue),
    l_name: new FormControl('', Validators.required),
    // confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8), validateConfirmPassword('password', 'confirmPassword')
    // ]),
  //signupForm.get('f_name')?.setValue(this.storedFirstName);
  })
 

  // Set the value dynamically after initialization
  

  //signUp
  // form group
  forgetForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
  })

  //restPassword
  // form group
  // restPasswordForm = new FormGroup({
  //   newPassword: new FormControl('', [ Validators.required,Validators.minLength(8)]),
  //   confirmPassword: new FormControl('', [ Validators.required,Validators.minLength(8)]),
  // })


  restPasswordForm = this.formBuilder.group({
    newPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
  }, { validator: validateConfirmPassword('newPassword', 'confirmPassword') });



  //otp 
  otpForm = new FormGroup({
    otp1: new FormControl('', [Validators.required, Validators.maxLength(1)]),
    otp2: new FormControl('', [Validators.required, Validators.maxLength(1)]),
    otp3: new FormControl('', [Validators.required, Validators.maxLength(1)]),
    otp4: new FormControl('', [Validators.required, Validators.maxLength(1)]),
    otp5: new FormControl('', [Validators.required, Validators.maxLength(1)]),
    otp6: new FormControl('', [Validators.required, Validators.maxLength(1)]),
  });

  // validation  controls for form 
  get email() {
    return this.form.controls['email'];
  }

  get parentEmail() {
    return this.signUpform.get('parentEmail') as FormControl;
  }
  get password() {
    return this.form.controls['password'];
  }
  get policy() {
    return this.signUpform.get('policy') as FormControl;
  }
  get policyage() {
    return this.signUpform.get('policyage') as FormControl;
  }
  // sign up validation 

  // validation  controls for form 
  get emails() {
    return this.signUpform.controls['email'];
  }
  get emailsforget() {
    return this.forgetForm.controls['email'];
  }


  // validation  controls for form 
  get fname() {
    // this.signUpform.get('f_name')?.setValue(this.storedFirstName);
    return this.signUpform.controls['f_name'];
  }

  get lname() {
    return this.signUpform.controls['l_name'];
  }

  get date() {
    return this.signUpform.controls['date'];
  }

  // get school() {
  //   return this.signUpform.controls['school'];
  // }

  get passwordF() {
    return this.signUpform.controls['password'];
  }

  // reset password validation
  get newPassword() {
    return this.restPasswordForm.controls['newPassword'];
  }

  // validation  controls for form 
  get confirmPassword() {
    return this.restPasswordForm.controls['confirmPassword'];
  }



  closePopup() {
  
    const modalElement = document.getElementById('login');
    if (modalElement) {
      modalElement.classList.remove('show'); // Remove the show class
    }
    if(this.loginClicked == true){
      this.signUpForm = false
      this.signInForm = true
    }else{
      this.signInForm = false
     
      this.showParent = false
      this.signUpForm = true
     
      let userDetail = localStorage.getItem('tryuser');


      let parsedUserDetail = userDetail ? JSON.parse(userDetail) : null;
      
      this.emailMain =  parsedUserDetail?.email || '';
     // this.storedFirstName = parsedUserDetail?.name || '';
      const fullName = parsedUserDetail?.name || '';
      const lastSpaceIndex = fullName.lastIndexOf(' ');
      if (lastSpaceIndex !== -1) {
        // Split the name into first and last names
        this.storedFirstName = fullName.substring(0, lastSpaceIndex).trim();
      this.storedLastName = fullName.substring(lastSpaceIndex + 1).trim();
      }else{
       this.storedFirstName = fullName;
      }
     

      console.log(userDetail,this.storedFirstName,parsedUserDetail)

    }
    // this.userserice.hidePopup();
    // this.showParent = false
    this.signuperrormessage = ''
    this.isOverlayActive = false
//    this.signInForm = true
    this.forgetInForm = false
    this.otpsuccessForm = false
 //   this.signUpForm = false
    this.isForgetFormSubmit = false
    this.isForgetSubmitTrue = false
    this.resetPasswordActive = false
    this.otreverify = false;
    this.form.reset()
    this.signUpform.reset({
      policy: false,
      policyage: true,
      // Other fields with default values
    })
    this.forgetForm.reset()
    this.restPasswordForm.reset()
    this.otpForm.reset()
    this.errorMessage = ''
    this.otpsuccessForminvalid = false
    // this.initializeGoogleSignin();
  }

  openLoginModal(): void {
    $('#login').modal('show'); // This will trigger the modal to open
  }


  getTenantList() {
    console.log('hit fun=======================')
    let pay = {}
    this.userserice.getTenant(pay).subscribe(
      (data: any) => {
        if (data.statusCode == 200) {
          console.log(data)
        }
      })
  }


  /**
 * * This function verifies the user's credentials and grants access if they are valid.
 *  It may involve checking the username/password against a database or an authentication service.
 * Returns a success message or throws an error if authentication fails.
 */
  login() {

  
    // this.otpsuccessForm = true;

    if (this.form.valid) {
      let loginPayload = {
        "email": this.form.value.email,
        "password": this.form.value.password,
      }
      this.isLoading = true
      this.userserice.signIn(loginPayload).subscribe(
        (data: any) => {
          if (data.statusCode == 200) {
            this.isLoading = false
            this.loggedInDaTa = data;
            localStorage.setItem("url", JSON.stringify(data.url));
            localStorage.setItem("user", JSON.stringify(data.body))
            localStorage.setItem("user", JSON.stringify(data.body));
            localStorage.setItem("LoginState", JSON.stringify(true));
            localStorage.setItem("email", data.body.email);
            localStorage.setItem("appurl", data.redirect_url);
            localStorage.setItem("plan_type", data.b2c_plan_enable);
            localStorage.setItem("plan_b2b", data.non_b2c_plan_enable);
            //localStorage.setItem("subscription", JSON.stringify(data));






            let payload = {

              "request": "get_customer_product",
              "customer_id": data.body.cus_id
            }

            this.userserice.getSubscriptionDetail(payload).subscribe((res: any) => {
              if (res.statusCode == 200) {

                this.subscribedata = res.body
                this.userserice.setSubscriptionData(this.subscribedata);
                localStorage.setItem("subscription", JSON.stringify(this.subscribedata)); 
                // Close the login modal and trigger the storage event
                if(this.isBrowser){ 
                window.dispatchEvent(new Event('storage'));
                }

              } else {
                //localStorage.removeItem("subscription");
                this.subscribedata = ''
                console.log('no data')
                localStorage.removeItem("subscription");
              }
            })

            //  localStorage.setItem("subscription", JSON.stringify(res.body));
            console.log(this.loggedInDaTa)
            if (this.loggedInDaTa.url.includes('/sc')) {
              console.log('URL contains /sc', this.loggedInDaTa.url);
              this.router.navigate(['/']);
              localStorage.setItem("Login_User", JSON.stringify('SC'));
              // Perform any logic if needed
            } else {

              console.log('URL does not contain /sc');
              localStorage.setItem("Login_User", JSON.stringify('B2C'));
              this.router.navigate(['/']);
              
            }


            //    localStorage.setItem("orderhistory", JSON.stringify(data.orderHistory));


            //  localStorage.setItem("token", JSON.stringify(data.Token));
            this.userserice.login();

            this.closePopup()
          }
          else if (data.statusCode == 401) {
            this.isLoading = false

            this.errorMessage = data.body

            //   this.isSignUpFormSubmit = true;

            // If there is an error with the request, display an error message
            // this.statusCodeError(data)
          } else if (data.statusCode == 402) {
            this.isLoading = false

            this.errorMessage = data.body
            setTimeout(() => {
              this.errorMessage = ''
            }, 3000)
          }
        })
    } else {
      this.validateAllFormFields(this.form);
      localStorage.removeItem('email');
      localStorage.removeItem('user');
    }

  }


  signUp() {
    this.toggleModal(true);
    let userDetail = localStorage.getItem('tryuser');


      let parsedUserDetail = userDetail ? JSON.parse(userDetail) : null;
      
     
      this.storedFirstName = parsedUserDetail?.name || '';
      console.log(userDetail,this.storedFirstName)
     this.initializeGoogleSignin()
    console.log('signup', this.signUpForm.valueOf)
    console.log('this.schoolname', 'Form Submitted', this.signUpform.value, this.signUpform.controls, this.signUpform.valid, this.isFormValid())



    if (this.isFormValid()) {
      // console.log('this.schoolname','Form Submitted', this.signUpform.value)
      
      let loginPayload = {
        "email": this.signUpform.value.email,
        "f_name": this.signUpform.value.f_name,
        "l_name": this.signUpform.value.l_name,
        "password": this.signUpform.value.password,
        "tenantName": 'B2C',
        "policy": this.signUpform.value.policy,
        "purchase": 0,
        "grade": "",
        "policyage": this.showParent,
        "Parent_email": this.signUpform.value.parentEmail,
        "Parent_email_Status": this.showParent,
      }
      this.signUpLoginPassword = this.signUpform.value.password;
      this.isLoading2 = true
      this.userserice.signUp(loginPayload).subscribe(
        (data: any) => {
          if (data.statusCode == 200) {
            this.otpsuccessForm = true;
            this.isLoading2 = false
            let p = {
              "email": this.signUpform.value.email,
              "password": this.signUpform.value.password,
            }
            this.userserice.signIn(p).subscribe(
              (data: any) => {
                if (data.statusCode == 200) {
                  this.isLoading = false
                  this.loggedInDaTa = data;
                  localStorage.setItem("url", JSON.stringify(data.url));
                  localStorage.setItem("user", JSON.stringify(data.body))
                  localStorage.setItem("user", JSON.stringify(data.body));
                  localStorage.setItem("plan_type", JSON.stringify(data.b2c_plan_enable));
                  localStorage.setItem("plan_b2b", data.non_b2c_plan_enable);
                  localStorage.setItem("LoginState", JSON.stringify(true));
                  localStorage.setItem("email", data.body.email);

                  //localStorage.setItem("subscription", JSON.stringify(data));


                  let selectedPlan = localStorage.getItem('PlanSelected');
                  if(selectedPlan)
                    // this.email_id = localStorage.getItem('email')
                    this.planAPI(this.signUpform.value.email,selectedPlan)



                  let payload = {

                    "request": "get_customer_product",
                    "customer_id": data.body.cus_id
                  }

                  this.userserice.getSubscriptionDetail(payload).subscribe((res: any) => {
                    if (res.statusCode == 200) {

                      this.subscribedata = res.body
                      this.userserice.setSubscriptionData(this.subscribedata);
                      localStorage.setItem("subscription", JSON.stringify(this.subscribedata));
                    } else {
                      //localStorage.removeItem("subscription");
                      this.subscribedata = ''
                      console.log('no data')
                      localStorage.removeItem("subscription");
                    }
                  })

                  //  localStorage.setItem("subscription", JSON.stringify(res.body));
                  console.log(this.loggedInDaTa)
                 
                    this.router.navigate(['/']);
                  


                  //    localStorage.setItem("orderhistory", JSON.stringify(data.orderHistory));


                  //  localStorage.setItem("token", JSON.stringify(data.Token));
                  this.userserice.login();
                  setTimeout(() => {
                    this.closePopup()
                    //  this.signInFun()
                  }, 4000)

                }
                else if (data.statusCode == 401) {
                  this.isLoading = false

                  this.errorMessage = data.body

                  //   this.isSignUpFormSubmit = true;

                  // If there is an error with the request, display an error message
                  // this.statusCodeError(data)
                } else if (data.statusCode == 402) {
                  this.isLoading = false

                  this.errorMessage = data.body
                  setTimeout(() => {
                    this.errorMessage = ''
                  }, 3000)
                }
              })
            //  this.showMessage = data.body

            //  this.isSignUpFormSubmit = true

            // setTimeout(() => {
            //   this.showMessage = '' 
            // //  this.signInFun()
            // }, 4000)
          } else {
            this.signuperrormessage = data.body
            setTimeout(() => {
              this.isLoading2 = false
              this.signuperrormessage = ''
              //  this.signInFun()
            }, 2000)
          }

        })

    } else {
      this.validateAllFormFields(this.signUpform);
    }

  }


  otpVerify(value: any) {
    this.storeEmail = this.forgetForm.value.email
    let emailvalue
    let password
    if (value == true) {
      emailvalue = this.forgetForm.value.email
    } else {
      emailvalue = this.signUpform.value.email
      password = this.signUpform.value.password
    }
    if (this.otpForm.valid) {
      let payload = {
        "email": emailvalue,
        "forgot": value,
        // email: 'raitest@yopmail.com',
        "resend": false,
        "OTP": Object.values(this.otpForm.value).join('')
      }
      console.log(payload)
      this.isLoading2 = true
      this.userserice.verifyOTP(payload).subscribe((res: any) => {
        console.log(res.statusCode, 202, 'onverify')
        if (res.statusCode == 200) {
          // this.isSignUpFormSubmit = false
          // this.closePopup()
          this.verification = true;
          this.otpsuccessForm = true;
          // let   loginPayload: {
          //    "email": this.signUpform.value.email,
          //   "password": password,
          //    }
          let p = {
            "email": this.signUpform.value.email,
            "password": this.signUpform.value.password,
          }
          this.userserice.signIn(p).subscribe(
            (data: any) => {
              if (data.statusCode == 200) {
                this.isLoading = false
                this.loggedInDaTa = data;
                localStorage.setItem("url", JSON.stringify(data.url));
                localStorage.setItem("user", JSON.stringify(data.body))
                localStorage.setItem("user", JSON.stringify(data.body));
                localStorage.setItem("LoginState", JSON.stringify(true));
                localStorage.setItem("email", data.body.email);

                //localStorage.setItem("subscription", JSON.stringify(data));






                let payload = {

                  "request": "get_customer_product",
                  "customer_id": data.body.cus_id
                }

                this.userserice.getSubscriptionDetail(payload).subscribe((res: any) => {
                  if (res.statusCode == 200) {

                    this.subscribedata = res.body
                    this.userserice.setSubscriptionData(this.subscribedata);
                    localStorage.setItem("subscription", JSON.stringify(this.subscribedata));
                  } else {
                    //localStorage.removeItem("subscription");
                    this.subscribedata = ''
                    console.log('no data')
                    localStorage.removeItem("subscription");
                  }
                })

                //  localStorage.setItem("subscription", JSON.stringify(res.body));
                console.log(this.loggedInDaTa)
                if (this.loggedInDaTa.url.includes('/sc')) {
                  console.log('URL contains /sc', this.loggedInDaTa.url);
                  this.router.navigate(['/SC']);
                  localStorage.setItem("Login_User", JSON.stringify('SC'));
                  // Perform any logic if needed
                } else {
                  console.log('URL does not contain /sc');
                  localStorage.setItem("Login_User", JSON.stringify('B2C'));
                  this.router.navigate(['/']);
                }


                //    localStorage.setItem("orderhistory", JSON.stringify(data.orderHistory));


                //  localStorage.setItem("token", JSON.stringify(data.Token));
                this.userserice.login();

                this.closePopup()
              }
              else if (data.statusCode == 401) {
                this.isLoading = false

                this.errorMessage = data.body

                //   this.isSignUpFormSubmit = true;

                // If there is an error with the request, display an error message
                // this.statusCodeError(data)
              } else if (data.statusCode == 402) {
                this.isLoading = false

                this.errorMessage = data.body
                setTimeout(() => {
                  this.errorMessage = ''
                }, 3000)
              }
            })
          this.isLoading2 = false
          //  this.login()
          //  this.otpsuccessForm = true
          this.signUpform.reset()

          this.showMessage = res.message
          //  this.otpverify
          setTimeout(() => {
            this.showMessage = ''
            // localStorage.setItem("user", JSON.stringify(res.body));
            // localStorage.setItem("token", JSON.stringify(res.Token));
            //  this.userserice.login();


            //
            //this.closePopup()
          }, 3000)
        } else if (res.statusCode == 201) {
          // reset password
          this.otpsuccessForminvalid = true
          this.isLoading2 = false
          this.otpForm.reset()
          this.showMessage = res.message
          setTimeout(() => {
            this.showMessage = ''
            this.isForgetFormSubmit = false
            this.resetPasswordActive = true
            this.closePopup()
          }, 4000)

        } else if (res.statusCode == 202) {
          this.isLoading2 = false
          console.log("going inside")
          this.otpsuccessForminvalid = true

          this.errorMessage = res.message
          this.otpForm.reset()
          this.signUpform.reset()
          setTimeout(() => {
            this.errorMessage = ''
            //  this.signInFun()
            this.closePopup()
          }, 4000)
        } else {
          this.otpsuccessForminvalid = true
          this.errorMessage = "Some thing went wrong."
          setTimeout(() => {
            this.errorMessage = ''
            //  this.signInFun()
            this.closePopup()
          }, 4000)
        }

      })

    }


  }

  otpVerifyforget(value: any) {

    let emailvalue
    if (value == true) {
      emailvalue = this.forgetForm.value.email
    } else {
      emailvalue = this.signUpform.value.email
    }
    if (this.otpForm.valid) {
      let payload = {
        "email": emailvalue,
        "forgot": value,
        // email: 'raitest@yopmail.com',
        "resend": false,
        "OTP": Object.values(this.otpForm.value).join('')
      }
      console.log(payload)
      this.isLoading2 = true
      this.userserice.verifyOTP(payload).subscribe((res: any) => {
        if (res.statusCode == 200) {
          // this.isSignUpFormSubmit = false
          this.otpsuccessForm = true
          this.isLoading2 = false


          this.signUpform.reset()

          this.showMessage = res.message
          setTimeout(() => {
            this.showMessage = ''
            //  localStorage.setItem("user", JSON.stringify(res.body));
            //localStorage.setItem("token", JSON.stringify(res.Token));
            //this.userserice.login();



            this.closePopup()
          }, 3000)
        } else if (res.statusCode == 201) {
          // reset password
          this.isLoading2 = false
          this.otpForm.reset()
          this.showMessage = res.message
          setTimeout(() => {
            this.showMessage = ''
            this.isForgetFormSubmit = false
            this.resetPasswordActive = true
            //  this.closePopup()
          }, 2000)

        } else if (res.statusCode == 202) {
          this.isLoading2 = false
          this.otpForm.reset()
          this.signUpform.reset()
          this.errorMessage = res.message
          setTimeout(() => {
            this.errorMessage = ''
            //  this.signInFun()
          }, 4000)
        }

      })

    }


  }

  //
  resendOtp() {
    console.log('dddddd send hit')
    this.isResendBtndisabled = true
    this.countDown = 30; // Countdown for 30 seconds

      let payload = {
        "email":  this.storeEmail,
        "forgot": true,
        // email: 'raitest@yopmail.com',
        "resend": true,
        "OTP": ''
      }
      console.log(payload)
   
     
    this.userserice.verifyOTP(payload).subscribe({
      next: (response: any) => {
        console.log('OTP Resent Successfully:', response);
        this.startCountdown(); 

        if(response.statusCode == 201){
          this.showMessage = response.message
          setTimeout(() => {
            this.showMessage = ''
           
            //  this.closePopup()
          }, 4000)
        }
      },
      error: (error: any) => {
        console.error('Error in resending OTP:', error);
        // If API fails, enable the button again (optional)
        this.isResendBtndisabled = false
      }
    })

  

  }

  //
  startCountdown() {
    // Start an interval timer that emits values every second
    this.timerSubscription = interval(1000).subscribe(() => {
      this.countDown--;
      if (this.countDown === 0) {
        this.isResendBtndisabled = false
        this.timerSubscription.unsubscribe();
      }
    });
  }

  forgetSubmit() {
    if (this.forgetForm.invalid) {
      // Mark all fields as touched to trigger validation messages
      this.forgetForm.markAllAsTouched();
      return; // Exit if the form is invalid
    } 

    this.storeEmail = this.forgetForm.value.email 
    console.log('st', this.storeEmail)
    let payload = {
      "email": this.forgetForm.value.email
    }
    this.forgetLoader = true
    this.userserice.forgotAPI(payload).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.forgetLoader = false
        this.isForgetSubmitTrue = true
        this.isForgetFormSubmit = true
      } else
        if (res.statusCode == 401) {
          this.errorMessage = res.message
          this.forgetForm.reset()
          this.forgetLoader = false
          setTimeout(() => {
            this.errorMessage = ""
          }, 5000)
        }

        else {
          this.forgetLoader = false
        }

    })

  }


  resetPassword() {
    let payload = {
      "email": this.forgetForm.value.email,
      "password": this.restPasswordForm.value.newPassword,
      "confpassword": this.restPasswordForm.value.confirmPassword
    }

    this.resetLoader = true
    this.userserice.ResetPassword(payload).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.resetLoader = false
        this.restPasswordForm.reset()
        this.forgetForm.reset()
        this.showMessage = res.message
        this.otpsuccessForm = true

        setTimeout(() => {
          this.showMessage = ''
          this.isForgetFormSubmit = false
          this.resetPasswordActive = false
          this.closePopup()
          //  this.closePopup()
        }, 4000)
      } else {
        this.resetLoader = false
      }

    })

  }


  /**
  * This function iterates through all form controls in the specified FormGroup and marks them as touched to trigger validation.
  * @param {FormGroup} formGroup - The FormGroup containing form controls to be validated.
  * @returns {void}
  */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);

      // Skip 'parentEmail' field
      if (field === 'parentEmail') {
        return;
      }

      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  signUpFun() {
  
    
    this.signInForm = false
    this.signUpForm = true
    this.showParent = false
    let userDetail = localStorage.getItem('tryuser');


      let parsedUserDetail = userDetail ? JSON.parse(userDetail) : null;
      
     
      // this.storedFirstName = parsedUserDetail?.name || '';
      this.emailMain =  parsedUserDetail?.email || '';
      // this.storedFirstName = parsedUserDetail?.name || '';
       const fullName = parsedUserDetail?.name || '';
      //  const lastSpaceIndex = fullName.lastIndexOf(' ');
      
       let lastName = '';
     
       // Check if there is more than one word
       const lastSpaceIndex = fullName.lastIndexOf(' ');
       if (lastSpaceIndex !== -1) {
         // Split the name into first and last names
         this.storedFirstName = fullName.substring(0, lastSpaceIndex).trim();
       this.storedLastName = fullName.substring(lastSpaceIndex + 1).trim();
       }else{
        this.storedFirstName = fullName;
       }
     
      this.emailMain = parsedUserDetail?.email || '';
      console.log(userDetail,this.storedFirstName)
      this.signUpform.get('f_name')?.setValue(this.storedFirstName);
      this.signUpform.get('email')?.setValue(this.emailMain);
      this.signUpform.get('l_name')?.setValue(this.storedLastName);

    this.forgetInForm = false
    this.toggleModal(true)
    this.isSignUpFormSubmit = false
  }

  signInFun() {
    this.toggleModal(true)
    this.signInForm = true
    this.signUpForm = false
    this.forgetInForm = false
  }

  forgetFun() {
    this.signInForm = false
    this.signUpForm = false
    this.forgetInForm = true
  }


  // password show and hide function
  hideShow() {
    this.show = !this.show
  }

  moveFocus(event: KeyboardEvent, nextElement: string) {
    const target = event.target as HTMLInputElement;

    // Check if the key entered is a valid digit (0-9)
    if (target.value.length === 1 && /\d/.test(target.value)) {
      const nextInput = document.querySelector(`[formControlName="${nextElement}"]`) as HTMLInputElement;

      if (nextInput) {
        nextInput.focus(); // Move to the next input
      }
    }
  }

  isFormValid(): boolean {

   
    const emailValid = this.signUpform.get('email')?.valid || false;
    const passwordValid = this.signUpform.get('password')?.valid || false;
    const fNameValid = this.signUpform.get('f_name')?.valid || false;
    const lNameValid = this.signUpform.get('l_name')?.valid || false;
    const policycheck = this.signUpform.get('policy')?.valid || false;
    

    console.log(this.signUpform.get('email'), emailValid, passwordValid, fNameValid, lNameValid, 'validfffffffffffffff')
    // Consider form valid if all relevant fields are valid, ignoring parentEmail
    if(this.showParent == true){
      const parentcheck = this.signUpform.get('parentEmail')?.valid || false;
      return emailValid && passwordValid && fNameValid && lNameValid  && policycheck && parentcheck;
  }else{
    return emailValid && passwordValid && fNameValid && lNameValid  && policycheck 
  }
    }
    
  callapi() {
    this.otreverify = !this.otreverify;
    // this.signInForm = false;
    this.errorMessage = ''

  }
  otpReVerify(value: any) {
    this.errorMessage = ''
    let emailvalue
    if (value == true) {
      emailvalue = this.forgetForm.value.email
    } else {
      emailvalue = this.form.value.email
    }
    if (this.otpForm.valid) {
      let payload = {
        "email": emailvalue,
        "forgot": value,
        // email: 'raitest@yopmail.com',
        "resend": false,
        "OTP": Object.values(this.otpForm.value).join('')
      }
      console.log(payload)
      this.isLoading2 = true
      this.userserice.verifyOTP(payload).subscribe((res: any) => {
        if (res.statusCode == 200) {
          // this.isSignUpFormSubmit = false
          // this.isSignUpFormSubmit = false
          this.otpsuccessForm = true
          this.isLoading2 = false
          this.otreverify = false;

          this.signUpform.reset({
            policy: true,
            policyage: true,
            // Other fields with default values
          })

          this.showMessage = res.message
          setTimeout(() => {
            this.showMessage = ''
            //  localStorage.setItem("user", JSON.stringify(res.body));
            //localStorage.setItem("token", JSON.stringify(res.Token));
            //this.userserice.login();



            // this.closePopup()
          }, 4000)
        } else if (res.statusCode == 201) {
          // reset password
          //this.otreverify = false;
          this.isLoading2 = false
          this.otpForm.reset()
          this.otpsuccessForminvalid = true
          this.showMessage = res.message
          setTimeout(() => {
            this.showMessage = ''
            this.isForgetFormSubmit = false
            this.resetPasswordActive = true
            this.closePopup()
          }, 4000)

        } else if (res.statusCode == 202) {
          // this.otreverify = false;
          this.isLoading2 = false
          this.otpsuccessForminvalid = true
          this.otpForm.reset()
          this.signUpform.reset({
            policy: true,
            policyage: true,
            // Other fields with default values
          })

          this.errorMessage = res.message
          setTimeout(() => {
            this.errorMessage = ''
            this.closePopup()
            //  this.signInFun()
          }, 4000)
        } else {

          this.otreverify = false;
          this.otpsuccessForminvalid = true
          this.errorMessage = "There is a technical glitch"
          setTimeout(() => {
            this.errorMessage = ''
            this.closePopup()
            //  this.signInFun()
          }, 4000)
        }

      })

    }


  }
  closeModal() {


  }
  loginPopup() {
    this.resetPasswordActive = false

    this.otpsuccessForm = false
    this.signInForm = true
    this.signUpForm = false
    this.forgetInForm = false


    this.otreverify = false;
    // this.isForgetSubmitTrue = false
    // this.closePopup()
  }
  powerButtonpolicy13year(e: any){
    if (e.target.checked == true) {
      this.showParent = false;
      const parentEmailControl = this.signUpform.get('parentEmail');
      
      if (parentEmailControl) {
        parentEmailControl.clearValidators();
        parentEmailControl.updateValueAndValidity();
        parentEmailControl.setValue('');
      }
    }
    else if (e.target.checked == false) {
      this.showParent = true
      // this.showParent = false;
      const parentEmailControl = this.signUpform.get('parentEmail');
     
      if (parentEmailControl) {
        parentEmailControl.setValidators([
          Validators.required,
          Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        ]);
        parentEmailControl.updateValueAndValidity();
      }
    }
  }
  powerButton(e: any) {
    if (e.target.checked == true) {
     // this.showParent = false;
      localStorage.setItem('Termscondition', 'true')
    }
    else if (e.target.checked == false) {
      //this.showParent = true
      this.form.get('policy')?.setValue("");
      localStorage.setItem('Termscondition', 'false')
    }
  }
  planAPI(email: string,plan:string) {
    // this.isloading = true;
     this.newLoader = true
     let payload 
     //live test
     console.log('planapitrigger')
     payload ={
      "env":"live","product":plan
      //  "env":"test","product":plan
      }
     
    
    
  
    this.userserice.stripe(payload).subscribe((res: any) => {
       
      
       if (res.statusCode == 200) {
         let resvalue = res;
         let payload1
         console.log(resvalue)
         switch (plan) {
             case 'Silver':
               plan = 'Silver';
               payload1 ={ "email": email,
                 "prod_id": res.body.prod_id,
          
                 "plan": res.body.product,
                 "price_id": res.body.price_id,
                 "mode": "setup",
                "price": res.body.amount ,
                "price_amount": res.body.amount,
              
                 "belong_to" :"nonsc",
      
                 "type":"payment"
             }  
               break;
             default:
                   payload1 ={ "email": email,
                       "prod_id": res.body.prod_id,
                
                       "plan": res.body.product,
                       "price_id": res.body.price_id,
                       "mode": "setup",
                      "price": res.body.amount ,
                      "price_amount": res.body.amount,
                    
                       "belong_to" :"nonsc",
            
                       "type":"subscription"
                   }  
                  
                    break;
             }
      
       
        this.userserice.stripe_checkout(payload1).subscribe((res: any) => {
        // console.log(payload1)
         if (res.statusCode == 303) {
         this.newLoader = false
         if(this.isBrowser){ 
         window.location.href = res.headers.Location;
         }
         }else{
           return
         }
      
     //    this.closeForm()
        
         
      
        
        
       //  this.isloading =false;
       } )}else{
         return
       }
     })
 
   }
   toggleModal(show: boolean): void {
   // this.signInForm = show;

    if (show) {
      setTimeout(() => {
        this.initializeGoogleSignin();
      }, 0); // Ensure the DOM is updated before initializing
    }
  }
  gotoTermsaPage(){
    this.router.navigate(['/terms'])
  }

  termsclicked(){
    if (this.form.get('termsAccepted')?.value) {
     // this.allmendatoryfield = true;  
    } else {
   //   this.allmendatoryfield = false;
    }
  }


  over13year(){
    if (this.form.get('isOver13')?.value) {
    //  this.allmendatoryfield = true; 
      this.showParent = true
    } else {
      this.showParent = false;
     // this.hideparentemail = true
    } 
  }
}
