import { Component, OnInit ,ElementRef, PLATFORM_ID, Inject} from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../service/user.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-enterprise-solution',
  templateUrl: './enterprise-solution.component.html',
  styleUrls: ['./enterprise-solution.component.css']
})
export class EnterpriseSolutionComponent implements OnInit {

  constructor(@Inject(PLATFORM_ID) private platformId: Object,private router :Router,private el :ElementRef,private service :UserService) { }
  isSuccessVisible :boolean = false;
  private isBrowser: boolean =false;
  ngOnInit(): void {
    this.isBrowser = isPlatformBrowser(this.platformId);
    const videoElement = this.el.nativeElement.querySelector('#backgroundVideo') as HTMLVideoElement;
 
    if (videoElement ) {
      // Ensure the video meets autoplay policies
      videoElement.muted = true; // Required for autoplay in modern browsers
      videoElement.playsInline = true; // For mobile devices
      videoElement.autoplay = true;

      // Manually trigger playback
      videoElement
        .play()
        .then(() => {
          console.log('Video is playing.');
        })
        .catch((error) => {
          console.error('Video playback failed:', error);
        });
    }
  }
  
  navigateToAIAvatar(){
    this.router.navigate(['/AI_Tutor'])
  }

 
  navigateToEI(){
    this.router.navigate(['/educational-institute'])
  }
  showSuccessModal() {
    this.isSuccessVisible = true;
  }

  calendly() {
    if (this.isBrowser){
    window.open(this.service.salesLink());
    }
  }
}
