import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { UserService } from '../service/user.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {
  onsuccess:boolean = false
  newsletterForm !: FormGroup;
  isLoading:boolean = false
  
  constructor(private fb: FormBuilder,private router: Router,private route :ActivatedRoute,private service :UserService, @Inject(PLATFORM_ID) private platformId: Object){
    this.newsletterForm = this.fb.group({
      email: ['', [Validators.email, this.customEmailValidator]],
    });
  }

  ngOnInit(): void {
    
  }

  
  newslettersubmit(){
    this.isLoading = true
    if (this.newsletterForm.valid) {
      const pay = {
        name:"",
        email: this.newsletterForm.value.email,
      };// this.router.navigate([], { fragment: 'newsForm' });
    
      
      this.service.signupNewsletter(pay).subscribe((res:any)=>{
        console.log(res)
        if(res.statusCode == 200){
          this.isLoading = false
          this.newsletterForm.reset();
          this.onsuccess =true
          this.router.navigate(['/newsletter-success']);
          setTimeout(()=>{
            this.onsuccess = false
          },3000)
        }else{
          this.isLoading = false
        }
      })
      
    }else{
      this.isLoading = false
      console.log('form not valid')
      this.newsletterForm.get('email')?.markAsTouched();
    }
  }
  
  customEmailValidator(control: AbstractControl): ValidationErrors | null {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const valid = emailPattern.test(control.value);
    return valid ? null : { invalidEmail: true };
  }
  get email() {
    return this.newsletterForm.get('email');
  }

}