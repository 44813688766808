import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ColorChangeService } from '../service/color-change.service';
import { isPlatformBrowser } from '@angular/common';
import { filter } from 'rxjs';
import { UserService } from '../service/user.service';
import { ChangeDetectorRef } from '@angular/core';

declare var jQuery: any;
@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.css'],
  animations: [
    trigger('slideDownUp', [
      state('visible', style({
        height: '100%'
      })),
      transition(':enter', [
        style({
          height: '0'
        }),
        animate('0.3s ease')
      ]),
      transition(':leave', [
        animate('0.3s ease', style({
          height: '0'
        }))
      ])
    ])
  ]
})
export class NavigationBarComponent implements OnInit {
  showMenu: boolean = false;
  isbuttondisabled: boolean = false;
  userName: any;
  user: any;
  mobileNav: boolean = true;
  enableB2cPlan: boolean = true;
  isb2b: boolean = false;
  backgroundColor: any ;
  constructor(private cd: ChangeDetectorRef,
    private router: Router, private service: ColorChangeService, private userservice: UserService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { this.isBrowser = isPlatformBrowser(this.platformId); }
  scrollKey: any;
  private isBrowser: boolean;
  screenSize: string = 'large';
  isSafetyState = false;
  currentUrl: string = 'url';
  isScPage: boolean = false;
  sc: boolean = false;
  isLoggedIn: boolean = false;

  userOrderFull: any;
  userDetails: any;
  buttonName: any;
  updatedData: any

  scloggedin: boolean = false;
  b2cloggin: boolean = false;
  no_user_Loggedin: boolean = false;

  fordesktop: boolean = false;
  forMobile: boolean = false;
  forIpad: boolean = false;
  subscriptionDetailCustomer: any;
  subscriptionDetailinfo: any = [];

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      // Check if the current route is 'enterprise-solutions'
      if (this.router.url === '/enterprise-solutions') {
        this.isb2b = true;
      } else if (this.router.url === '/educational-institute') {
        this.isb2b = true;
      } else {
        this.isb2b = false;
      }
    });
    if(this.isBrowser){
    const width = window.innerWidth;
    if (width < 624) {
      this.screenSize = 'small'; // Mobile

    } else {
      this.screenSize = 'large'; // Desktop
    }

    }
  
    this.user = JSON.parse(localStorage.getItem('user') || '{}')
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Navigation is starting 
        if (event.url === '/' || event.url == '/educational-institute' || event.url == '/enterprise-solutions' ) {
          let bg = {
            background: 'linear-gradient(180deg, rgba(0, 3, 5, 0.7) 0%, rgba(34, 34, 34, 0.5) 100%)',
            boxShadow:' 0px 2px 5px 0px rgba(0, 0, 0, 0.16), 0px 2px 10px 0px rgba(0, 0, 0, 0.12)'
            // background: 'transparent',
            // boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.16), 0px 2px 10px 0px rgba(0, 0, 0, 0.12)',
          };
          this.backgroundColor = bg
        } else { 
          let bg = {
            background: 'linear-gradient(180deg, rgba(0, 3, 5, 0.7) 0%, rgba(34, 34, 34, 0.5) 100%)',
            boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.16), 0px 2px 10px 0px rgba(0, 0, 0, 0.12)',
          };
          this.backgroundColor = bg;

        }
        this.currentUrl = this.router.url;
        // console.log('Current URL before navigation starts:', this.currentUrl);
        this.hideNavigation()
      }

      if (event instanceof NavigationEnd) {
        // Navigation is starting
        this.currentUrl = this.router.url;

        // Check if the URL contains '/safety'
        if (this.router.url.includes('/safety') || this.router.url.includes('/terms') || this.router.url.includes('/privacy')) {
          this.isSafetyState = true;
          //  console.log('u')
        } else {
          this.isSafetyState = false;
        }
        if (this.router.url.includes('/SC') || this.router.url.includes('/sc')) {
          this.isScPage = true;
          this.forMobile = false
          if (this.screenSize == 'small') {
            this.sc = true;
          } else {
            this.sc = false;
          }
        } else {
          if (this.screenSize == 'small') {
            this.sc = false;
          }
          this.isScPage = false;
          this.forMobile = true
          this.sc = false;
        }

        // if(width< 601 && this.router.url.includes('/SC')||this.router.url.includes('/sc')){
        //   this.isScPage = true;
        //   this.forMobile = false
        // }else{
        //   this.isScPage = true;
        //   this.forMobile = true
        // }

        //   console.log('Current URL before navigation starts:', this.currentUrl,this.isSafetyState);
        this.hideNavigation()
      }
      // this.checkdevice()
    });


    // console.log(width)
    // if (width < 1024) {
    //   this.screenSize = 'small'; // Mobile
    // } else {
    //   this.screenSize = 'large'; // Desktop
    // }

    this.userservice.loggedIn$.subscribe(state => {
      this.checkUserLogin()
      const storedUserData = localStorage.getItem('user');
      if (storedUserData) {
        this.updatedData = JSON.parse(storedUserData);
        console.log(this.updatedData, this.updatedData, 'all user infor', this.updatedData.subscription)
        this.userName = this.updatedData.f_name + " " + this.updatedData.l_name;

      }
      
        //   this.openSuccessPopup = false
        // },2000)
     
        this.cd.detectChanges();
  })
  this.userservice.subscription$.subscribe(subscriptionData => {
    this.subscriptionDetailinfo = subscriptionData || [];
    // this.updatedData.subscription = 
    console.log( this.subscriptionDetailinfo,'navinformation')
    this.enableB2cPlan = localStorage.getItem("plan_type") === "true"
    if(this.enableB2cPlan){
    this.buttonName = this.subscriptionDetailinfo.subscription === false || this.subscriptionDetailinfo.length === 0 || this.subscriptionDetailinfo[0].is_valid == false ? "Startfree" : "Learn";
    }else{
      this.buttonName ='Learn'
    }
   this.cd.detectChanges(); // Update the UI accordingly
  });
   // this.getProfileDetail()
  }

  navigateTOAboutUs() {
    this.toggleMenu()
    this.router.navigate(['/about-us'])
  }

  navigateToCourse() {
    this.toggleMenu()
    this.router.navigate(['/course'])

  }

  navigateToNews() {
    this.toggleMenu()
    this.router.navigate(['/news'])

  }


  homePage() {
    this.router.navigate(['/'])
  }

  toggleMenu() {
    const menu: any = document.getElementById("menu");

    menu.classList.toggle("show");
  }

  hideNavigation() {
    if (this.isBrowser) {
      const targetDiv = document.getElementById('targetDiv');
      if (targetDiv) {
        targetDiv.style.minHeight = '0';
        targetDiv.style.maxHeight = '0';
      }
    }
  }
  // toggleMenu() {
  //   this.showMenu = !this.showMenu;
  // }

  openLoginPopup(event: any, isLogin: boolean): void {
    event.stopPropagation();
    this.userservice.showPopup(isLogin);
  }
  navigateTosubscription() {
    console.log('true')
   // this.router.navigate(['/course'])
    //this.shouldScrollToFragment = true; 
    this.router.navigate(['/course'], { fragment: 'pricing_section_id' }).then(() => {
      setTimeout(() => {
        const element = document.getElementById('pricing_section_id');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    });
  }

  logOut() {

    this.router.navigate(['/']);


    localStorage.removeItem('LoginState');
    localStorage.removeItem('email');
    localStorage.removeItem('user');
    localStorage.removeItem('url');
    localStorage.removeItem('Login_User');
    localStorage.removeItem('subscription');
    localStorage.removeItem("plan_type");
    localStorage.removeItem("PlanSelected");
    localStorage.removeItem("plan_b2b");
    localStorage.removeItem('tryuser');
    this.userservice.logout()
    this.isbuttondisabled = false
    // window.location.reload();


  }
  navigateToProfile() {
    this.menuToggle()
    // this.sc=true
    let url1
    url1 = localStorage.getItem('url');
    if (url1 && url1.includes('/sc')) {
      this.router.navigate(['/SC/profile']);
    } else {
      this.router.navigate(['/profile']);
    }


  }
  menuToggle(event?: MouseEvent) {
    this.enableB2cPlan = localStorage.getItem("plan_type") === "true"
    const toggleMenu: any = document.querySelector("#menuId");
    toggleMenu.classList.toggle("active");
    if (toggleMenu.classList.contains('active')) {
      // Add click event listener to the document to handle closing
      document.addEventListener('click', this.closeMenuOnClickOutside);
    } else {
      // If the menu is closed, remove the event listener
      document.removeEventListener('click', this.closeMenuOnClickOutside);
    }
    // console.log('hit menu')
  }
  closeMenuOnClickOutside = (event: MouseEvent) => {
    const toggleMenu: any = document.querySelector("#menuId");
    const profile: any = document.querySelector(".profile");

    // Check if the click is outside both the menu and profile
    if (!toggleMenu.contains(event.target as Node) && !profile.contains(event.target as Node)) {
      toggleMenu.classList.remove('active');

      // Remove the event listener once the menu is closed
      document.removeEventListener('click', this.closeMenuOnClickOutside);
    }
  }


  getProfileDetail() {
    let createToken = {
      // token: this.token,
      email: this.user.email,
    };
    this.userservice.getProfileAPI(createToken).subscribe((res: any) => {
      if (res.statusCode == 200) {

        this.userOrderFull = res;
        this.userDetails = res.data;


        console.log(" this.userDetails", this.userDetails)

      } else {

      }
    })
    this.cd.detectChanges();
  }
  linkToedyouUser() {
    let user = localStorage.getItem('user');
    console.log(user)
    if (user) {
      // Parse the string back into a JavaScript object
      let user1 = JSON.parse(user);

      // Now you can access the 'link' property
      console.log(user1.link, 'urltomove', this.user);
      // const userLink: string = user1.link;

      // If you want to navigate, uncomment this:
      if(this.isBrowser){
      window.location.href = user1.link;
      }
    } else {
      if(this.isBrowser){
      window.location.href = 'https://smdev.edyou.com/'
      }
    }



  }
  checkUserLogin() {
    const storedLoginState = localStorage.getItem('LoginState');

    // Parse the JSON string to a boolean value
    const isLoggedIn = storedLoginState ? JSON.parse(storedLoginState) : false;
    //   console.log(typeof(isLoggedIn), 'user check',isLoggedIn)
    // Check the login state and perform the corresponding action
    if (isLoggedIn == true) {
      this.getProfileDetail
      //  this.menuToggle()

      //  let  x :any = this.userservice.buttonName;
      //  console.log(x,'navitem')
      //  if(x.orderHistory.length>0){
      //   this.buttonName = "Learn"

      // }else {
      //     this.buttonName = "Startfree"
      // }
      //  this.userObj = res.user;
      // this.shared.SharedData(this.userObj);

      //  console.log(" this.userDetails", this.userDetails)

      // console.log('true state')
      this.isLoggedIn = true
      // if (this.user.f_name && this.user.l_name) {
      //   this.userName = this.user.f_name + " " + this.user.l_name
      // } 

    } else {
      this.isLoggedIn = false
      this.logOut
      this.user = JSON.parse(localStorage.getItem('user') || '{}')
      this.userservice.loggedIn$.subscribe(state => {

        this.isLoggedIn = state;
        //   console.log('else state ==',isLoggedIn)

        // console.log('state login', this.isLoggedIn)
        //  if (this.user.f_name && this.user.l_name) {
        //   this.userName = this.user.f_name + " " + this.user.l_name
        //  }
      });
    }


    let loggedin_User = localStorage.getItem('Login_User');
    // console.log(loggedin_User)
    if (isLoggedIn == true) {

      this.no_user_Loggedin = false
    }

    else {
      console.log('!logged in')


      this.no_user_Loggedin = true
    }
  }
  calendly() {
    if(this.isBrowser){
    window.open(this.userservice.salesLink());
    }
  }
}