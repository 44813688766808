import { Component, OnInit,HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-edyouai',
  templateUrl: './edyouai.component.html',
  styleUrls: ['./edyouai.component.css']
})
export class EdyouaiComponent implements OnInit {

  constructor(private router:Router,@Inject(PLATFORM_ID) private platformId: Object) { }
  isCourseRoute:boolean = true;
  isMobileScreen: boolean = false
  ngOnInit(): void {
    
   if(this.router.url === '/how-edyous-ai-is-reimagining-the-future-of-personalized-learning')
   {
    this.isCourseRoute = true
   }else{
    this.isCourseRoute = false
   }
   if (isPlatformBrowser(this.platformId)) {
    this.isMobileScreen = window.innerWidth <= 600;
  }
  }

  

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    
    this.isMobileScreen = event.target.innerWidth <= 600;
  }
  navigateToBlog(){
    this.router.navigate(['/news'], { fragment: "blog"}).then(() => {
      setTimeout(() => {
        const element = document.getElementById("blog");
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    });}
}
