<div class="news-maincontainer">
    <div class="panel-media">
        
        <div class="main-heading">
          <a> Media</a> 

        </div>
        <div class="card"   [ngClass]="{'mobile-bg': isMobileScreen}" [ngStyle]="{ 'background-size': 'cover',
        'background-repeat': 'no-repeat'}">

            
            <p class="content">Listen to the edYOU Podcast</p>
            <button class="content" (click)="gotopodcast()">edYOU Podcast</button>
           
        </div>

    </div>
    <div class="banner">
        <div class="div-container">
          <div class="scrolling-belt">
            <img class="" src="../../assets/newsscroll/image 70.png" alt="USA Today logo"/>
            <img class="-4" src="../../assets/newsscroll/image 71.png" alt="Los Angeles Times logo"/>
            <img class="-3" src="../../assets/newsscroll/image 73.png" alt="Associated Press logo"/>
            <img class="-6" src="../../assets/newsscroll/image 75.png" alt="Forbes logo"/>
            <img class="-7" src="../../assets/newsscroll/benzinga-logo.png" alt="Benzinga logo"/>
            <img class="-5" src="../../assets/newsscroll/image 77.png" alt="Tech Times logo"/>
    
            <!-- <img src="https://c.animaapp.com/fXulaZA9/img/image-54@2x.png" />
            <img src="https://c.animaapp.com/fXulaZA9/img/image-60@2x.png" />
            <img src="https://c.animaapp.com/fXulaZA9/img/image-58@2x.png" />
            <img src="https://c.animaapp.com/fXulaZA9/img/image-59@2x.png" />
            <img src="https://c.animaapp.com/fXulaZA9/img/image-61@2x.png" />
            <img src="https://c.animaapp.com/fXulaZA9/img/image-57@2x.png" /> -->
          </div>
        </div>
    </div>
    <div class="panel-press">
        <p>Press</p>
        <div class="main-container-press">
            <div class="newpreecard" *ngFor="let press of pressSection" [ngStyle]="{'background-image': 'url(' + press.backgroundimg + ')'}">
                <div class="newpresscontent">
                    <h6 class="newpresscontentText">{{ press.text }}</h6>
                    <a class="newpressbutton" [href]="press.link" target="_blank" title= {{press.title}} >Read Article</a>
                </div>
            </div>
            <!-- <div class="newpreecard">
                <div class="newpresscontent">
                    <h6 class="newpresscontentText">AI Educational Program making an impact</h6>
                    <a class="newpressbutton">Read Article</a>
                </div>
            </div> -->
        </div>
            
       
    </div>

    <div class="panel-press blogmergin" >
        <p>Blog</p>
        <div class="main-container-press">
            <div class="newpreecard" *ngFor="let press of blogsection" style="cursor: pointer;" [ngStyle]="{'background-image': 'url(' + press.backgroundimg + ')'}" (click)="gotonewblog(press.id)" [title]="press.titleText">
                <!-- <div class="newpresscontent"> -->
                    <!-- <h6 class="newpresscontentText">{{ press.text }}</h6> -->
                    <!-- <a class="newpressbutton" (click)="gotonewblog(press.id)">Read Article</a> -->
                <!-- </div> -->
            </div>
           
        </div>
    </div>

    <!-- <div class="panel-blog" id="blog">
        <p>Blog</p>
        <div class="main-container-blog">
           
        <div class="blog-card">
            <img   (click)="navigateToEdyousAi()" src="./assets/newb2cmedia/blog-1.png" alt="blog" />
          
        </div>
        <div class="blog-card">
            <img    (click)="navigateToRevolutionizingEducation()" src="./assets/newb2cmedia/blog-2.png" alt="blog" />
          
        </div>
    </div> -->
       
    <!-- </div> -->
    <div 
    class="panel-labs" 
    (click)="navigateToLab()" 
    [ngClass]="{'mobile-bg': isMobileScreen}"  
    [ngStyle]="{
      'background-image': isMobileScreen ? 'url(../assets/newb2cmedia/edu-lab-mobile.png)' : 'url(../assets/newb2cmedia/edYOU-Labs.png)',
 
      'background-repeat': 'no-repeat',
    
    'background-position': 'center center',
    
    'width': '100%'
   
    }">
    </div>

</div>