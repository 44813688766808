
<div class="container">
    <app-uneeqavatar *ngIf="displayuneeqcomponent" (pptclose)="closepptclicked()" (closeUneeq)="closeuneeqcomponent()" ></app-uneeqavatar>
    <!-- <app-uneeqavatar ></app-uneeqavatar> -->

    <div class="formcard" *ngIf="openForm" [ngStyle]="{ height: hideparentemail ? '415px' : '495px' }">
        <div class="closebutton">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" (click)="backtomainpage()"  height="24" viewBox="0 0 24 24" fill="none">
                <path d="M17 7L7 17M7 7L17 17" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <h1 class="formcardHeader" >Get Started</h1>
        <h4 class="formcardmassage">Please enter your info to get started</h4>
        <form [formGroup]="form" class="formcontainer" [ngClass]="{ 'formcontainer-error': hasError() }" >
            <!-- <div class="inputerrorconatiner" >
                <div class="input-container">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                        <path d="M13.2615 15.3667C13.2615 14.4048 13.2615 13.9239 13.1502 13.5325C12.8995 12.6514 12.2527 11.9618 11.4263 11.6946C11.0592 11.5758 10.6081 11.5758 9.70591 11.5758H6.47356C5.57137 11.5758 5.12027 11.5758 4.75321 11.6946C3.92676 11.9618 3.28002 12.6514 3.02932 13.5325C2.91797 13.9239 2.91797 14.4048 2.91797 15.3667M10.9989 6.0619C10.9989 7.77486 9.6964 9.16349 8.08974 9.16349C6.48308 9.16349 5.18062 7.77486 5.18062 6.0619C5.18062 4.34893 6.48308 2.9603 8.08974 2.9603C9.6964 2.9603 10.9989 4.34893 10.9989 6.0619Z" stroke="#2A7CC7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <input type="text"  formControlName="f_name" maxlength="100" placeholder="First Name"  class="input-box" />
                </div>
                <div *ngIf="form.get('f_name')?.touched && form.get('f_name')?.invalid" class="error-message">
                    <span *ngIf="form.get('f_name')?.errors?.['required']">First Name is required.</span>
                   
                </div>
            </div> -->


            
            
            <!-- <div class="input-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                    <path d="M13.2615 15.3667C13.2615 14.4048 13.2615 13.9239 13.1502 13.5325C12.8995 12.6514 12.2527 11.9618 11.4263 11.6946C11.0592 11.5758 10.6081 11.5758 9.70591 11.5758H6.47356C5.57137 11.5758 5.12027 11.5758 4.75321 11.6946C3.92676 11.9618 3.28002 12.6514 3.02932 13.5325C2.91797 13.9239 2.91797 14.4048 2.91797 15.3667M10.9989 6.0619C10.9989 7.77486 9.6964 9.16349 8.08974 9.16349C6.48308 9.16349 5.18062 7.77486 5.18062 6.0619C5.18062 4.34893 6.48308 2.9603 8.08974 2.9603C9.6964 2.9603 10.9989 4.34893 10.9989 6.0619Z" stroke="#2A7CC7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <input type="text"  placeholder="Last Name" formControlName="l_name" maxlength="100"  class="input-box" />
            </div> -->

            <div class="inputerrorconatiner" >
                <div class="input-container" [ngClass]="{'error-border': form.get('f_name')?.touched && form.get('f_name')?.invalid}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 18" fill="none">
                        <path d="M13.2615 15.3667C13.2615 14.4048 13.2615 13.9239 13.1502 13.5325C12.8995 12.6514 12.2527 11.9618 11.4263 11.6946C11.0592 11.5758 10.6081 11.5758 9.70591 11.5758H6.47356C5.57137 11.5758 5.12027 11.5758 4.75321 11.6946C3.92676 11.9618 3.28002 12.6514 3.02932 13.5325C2.91797 13.9239 2.91797 14.4048 2.91797 15.3667M10.9989 6.0619C10.9989 7.77486 9.6964 9.16349 8.08974 9.16349C6.48308 9.16349 5.18062 7.77486 5.18062 6.0619C5.18062 4.34893 6.48308 2.9603 8.08974 2.9603C9.6964 2.9603 10.9989 4.34893 10.9989 6.0619Z" stroke="#2A7CC7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <input type="text"  formControlName="f_name" maxlength="100" placeholder="Full Name*"  class="input-box" />
                </div>
                <div *ngIf="form.get('f_name')?.touched && form.get('f_name')?.invalid" class="error-message">
                    <span *ngIf="form.get('f_name')?.errors?.['required']">Full Name is required.</span>
                   
                </div>
            </div>
    
            <div class="inputerrorconatiner" >
                <div class="input-container" [ngClass]="{'error-border': form.get('email')?.touched && form.get('email')?.invalid}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 17" fill="none">
                        <g clip-path="url(#clip0_12260_9823)">
                          <path d="M1.69531 5.2749L6.9737 9.21422C7.40113 9.53322 7.61484 9.69272 7.8473 9.7545C8.05264 9.80907 8.26741 9.80907 8.47275 9.7545C8.70521 9.69272 8.91892 9.53322 9.34635 9.21423L14.6247 5.2749M4.79837 14.2351H11.5217C12.6078 14.2351 13.1509 14.2351 13.5658 14.0097C13.9307 13.8114 14.2274 13.4951 14.4134 13.1061C14.6247 12.6637 14.6247 12.0847 14.6247 10.9267V6.51553C14.6247 5.3575 14.6247 4.77848 14.4134 4.33617C14.2274 3.9471 13.9307 3.63078 13.5658 3.43254C13.1509 3.20717 12.6078 3.20717 11.5217 3.20717H4.79837C3.7122 3.20717 3.16911 3.20717 2.75425 3.43254C2.38933 3.63078 2.09263 3.9471 1.9067 4.33617C1.69531 4.77848 1.69531 5.3575 1.69531 6.51553V10.9267C1.69531 12.0847 1.69531 12.6637 1.9067 13.1061C2.09263 13.4951 2.38933 13.8114 2.75425 14.0097C3.16911 14.2351 3.7122 14.2351 4.79837 14.2351Z" stroke="#2A7CC7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_12260_9823">
                            <rect width="15.5153" height="16.5418" fill="white" transform="translate(0.402344 0.450195)"/>
                          </clipPath>
                        </defs>
                    </svg>
                    <input type="text" type="email" placeholder="Email*"  formControlName="email" name="email" class="input-box" />
    
    
                </div>
                <div *ngIf="form.get('email')?.touched && form.get('email')?.invalid" class="error-message">
                    <span *ngIf="form.get('email')?.errors?.['required']" style="position:fixed;" >E-mail is required.</span>
                    <span *ngIf="form.get('email')?.errors?.['email']"  style="position:fixed;">Enter a valid email address.</span>
                </div>
            </div>
            
            <div class="inputerrorconatiner" >
                <div class="input-container" [ngClass]="{'error-border': form.get('phone')?.touched && form.get('phone')?.invalid}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 12 17" fill="none">
                        <path d="M6.0878 12.4141H6.09426M2.85544 1.38623H9.32015C10.0342 1.38623 10.6131 2.0034 10.6131 2.76472V13.7926C10.6131 14.5539 10.0342 15.1711 9.32015 15.1711H2.85544C2.14137 15.1711 1.5625 14.5539 1.5625 13.7926V2.76472C1.5625 2.0034 2.14137 1.38623 2.85544 1.38623Z" stroke="#2A7CC7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <input type="tel" (keypress)="allowOnlyNumbers($event)" (input)="validatePhoneInput($event)" id="phone" name="phone"   placeholder="Number for SMS Alerts" formControlName="phone" maxlength="10"  class="input-box" />

                </div>
                <div *ngIf="form.get('phone')?.touched && form.get('phone')?.invalid" class="error-message">
                    <span *ngIf="form.get('phone')?.errors?.['required']" style="position:fixed;" >Phone number is required.</span>
                    <span *ngIf="form.get('phone')?.errors?.['pattern']" style="position:fixed;">Enter a valid phone number.</span>
                </div>
            </div>
            <!-- <div class="input-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 12 17" fill="none">
                    <path d="M6.0878 12.4141H6.09426M2.85544 1.38623H9.32015C10.0342 1.38623 10.6131 2.0034 10.6131 2.76472V13.7926C10.6131 14.5539 10.0342 15.1711 9.32015 15.1711H2.85544C2.14137 15.1711 1.5625 14.5539 1.5625 13.7926V2.76472C1.5625 2.0034 2.14137 1.38623 2.85544 1.38623Z" stroke="#2A7CC7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <input type="tel" id="phone" name="phone"  placeholder="Number for SMS Alerts" formControlName="phone" maxlength="100"  class="input-box" />
                
            </div> -->

            <!-- parent email -->

            <div class="inputerrorconatiner" *ngIf="!hideparentemail">
                <div class="input-container" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 17" fill="none">
                        <g clip-path="url(#clip0_12260_9823)">
                          <path d="M1.69531 5.2749L6.9737 9.21422C7.40113 9.53322 7.61484 9.69272 7.8473 9.7545C8.05264 9.80907 8.26741 9.80907 8.47275 9.7545C8.70521 9.69272 8.91892 9.53322 9.34635 9.21423L14.6247 5.2749M4.79837 14.2351H11.5217C12.6078 14.2351 13.1509 14.2351 13.5658 14.0097C13.9307 13.8114 14.2274 13.4951 14.4134 13.1061C14.6247 12.6637 14.6247 12.0847 14.6247 10.9267V6.51553C14.6247 5.3575 14.6247 4.77848 14.4134 4.33617C14.2274 3.9471 13.9307 3.63078 13.5658 3.43254C13.1509 3.20717 12.6078 3.20717 11.5217 3.20717H4.79837C3.7122 3.20717 3.16911 3.20717 2.75425 3.43254C2.38933 3.63078 2.09263 3.9471 1.9067 4.33617C1.69531 4.77848 1.69531 5.3575 1.69531 6.51553V10.9267C1.69531 12.0847 1.69531 12.6637 1.9067 13.1061C2.09263 13.4951 2.38933 13.8114 2.75425 14.0097C3.16911 14.2351 3.7122 14.2351 4.79837 14.2351Z" stroke="#2A7CC7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_12260_9823">
                            <rect width="15.5153" height="16.5418" fill="white" transform="translate(0.402344 0.450195)"/>
                          </clipPath>
                        </defs>
                    </svg>
                    <input type="text" type="email" placeholder="Parent’s Email*"  formControlName="P_email" name="email" class="input-box" />
    
    
                </div>
                <div class="error-message">
                    <span style="position:fixed;left: 14%;" ><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <g clip-path="url(#clip0_13916_9946)">
                          <path d="M6 8V6M6 4H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z" stroke="#E74040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_13916_9946">
                            <rect width="12" height="12" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg> A parent’s email is required if you are under 13 years old</span>
                    <span *ngIf="form.get('P_email')?.errors?.['P_email']"  style="position:fixed;left: 16%;">Enter a valid email address.</span>
                </div>
            </div>


            <div class="agechecker">
                <div class="checkbox-wrapper-40">
                    <label>
                        <input type="checkbox" (click)="over13year()" formControlName="isOver13"/>
                        <span class="checkbox"></span>
                    </label>
                </div>

                <h6 class="agecheckername">I confirm that I am 13 years old or over</h6>
            </div>

            <div class="agechecker">
                <div class="checkbox-wrapper-40" >
                    <label>
                        <input type="checkbox" (click)="termsclicked()" formControlName="termsAccepted"/>
                        <span class="checkbox"></span>
                    </label>
                </div>

                <h6 class="agecheckername">I accept and consent to the edYOU <b style="color: #2A7CC7;cursor: pointer;"  (click)="gotoTermsaPage()">Terms & Conditions</b></h6>
            </div>
            <button class="startbutton" (click)="startSession()"><img class="" style="width : 24px; color:white;" *ngIf="isLoading"
                src="../../assets/images/Rolling-1s-200px (2).gif">
            <span class="signIn" *ngIf="!isLoading">Start Now</span></button>
        </form>
        <div class="displayMsg" *ngIf="allmendatoryfield">
           
            Please fill in all mandatory fields
        </div>
    </div>
</div>

<div class="triedcard" *ngIf="triedcard ">
    <h4 class="triedCardHeader">You have completed your free demo.</h4>
    <h5 class="triedCardfooter">View our plans and get started.</h5>
    <button  *ngIf="!from" class="triedcardbutton" (click)="goforsubscribe()">Buy Now</button>
    <button *ngIf="from == 'b2b'" class="triedcardbutton" (click)="calendly()">Contact Sales</button>
</div>

<div class="viewallcard" *ngIf="closedpptbutton">
    <div class="closebutton">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" (click)="closeviewallcard()"  height="24" viewBox="0 0 24 24" fill="none">
            <path d="M17 7L7 17M7 7L17 17" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>
    <h5 *ngIf="!from" class="viewallcardfooter">View our plans and get started.</h5>
    <button *ngIf="!from" class="triedcardbutton" (click)="goforsubscribe()">Buy Now</button>
    <h5 class="viewallcardfooter" *ngIf="from == 'b2b'">Thank you for trying edYOU!</h5>
    <button *ngIf="from == 'b2b'" class="triedcardbutton" (click)="calendly()">Contact Sales</button>
</div>
