<div class="d-flex justify-content-center">

    <div class="boxPricing">
        <span><img src="../../assets/failedPayment.png" class="succesImg"></span><br><br>
        <span class="MainHeading ">Payment Failed</span> <br>
        <span class="subHeading">Your Payment is failed, please try again.</span>
        <br><br>
        
        <span class="d-flex justify-content-center">
            <button class="btnHome"><span class="textbtn" (click)="navigateTOPricing()">Go to Home</span></button>
        </span>

    </div>
</div>