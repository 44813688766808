<div style="zoom: initial; top: auto; padding-right: initial;"class="fixed-mic " id="movableCard-main" [style.bottom.px]="mainClassBottomsize">


  <!-- chat bubble button -->
  <div class="bottomright" id="settingId" style="z-index: 99999999;">
    <div class="commonstyle" id="tourSetting">
      <span class="setttingMain">
        <span class="ToolSetting">Chat on/off</span>

        <div class="setting" (click)="chatBubbleFun('normal')" (mouseenter)="toggleGif(true)"
          (mouseleave)="toggleGif(false)">
          <img class="setting"
            [src]="bubblewindowOnOff  ? '../../../../assets/bubbleCrossIcon.png' : '../../../../assets/newChatIcon.png'"
            alt="Dynamic Image">


        </div>
        <!-- <img class="setting" (click)="chatBubbleFun()"
          [src]="bubblewindowOnOff ? '../../../../assets/bubbleCrossIcon.png' : '../../../../assets/newChatIcon.png'"> -->
      </span>

    </div>
  </div>

  <!-- stop speaking -->
  <!-- [style.bottom.px]="stopIconBottomPosition"  [style.right.px]="stopIconRightPosition"  -->
  <div class="stopHandIcon" id="stopavatarId" style="z-index: 99999999;">
    <!-- [style.width.px]="stopIconWidthPosition"  [style.height.px]="stopIconHeightPosition" -->
    <img class="stopIConImg" id="stopSoundId" (click)="stopDigitalPerson()"
      src="../../../../assets/stopSpeakingRed.png">
      <span class="newmuteTooltip">Stop speaking</span>
  </div>

  <!-- mute voice -->


  <!-- mute voice -->
  <div class="muteVoiceIcon" id="muteVoiceId" style="z-index: 999999999" (click)="muteunmuteVoice()">
    <span class="muteButton">
      <span class="muteTooltip">Mute/Unmute</span>
      <img class="setting"
        [src]="muteVoiceAvatar ? '../../../../assets/redMute.png' : '../../../../assets/unmuteicon.png'">
    </span>
  </div>


  <!-- // avatar hide button feature  -->
  <div class="bottomChat " id="mobileAvatarButton">
    <div class="commonstyle" onkeyup="event.preventDefault()" (click)="hideMethod()">
      <div class="">
        <span class="tooltipAvatar">Hide Hannah</span>
        <span class="tooltipAvatar" *ngIf="mobileAvatarOnOff">Show Hannah</span>
        <span>
          <img id="avm" class="" *ngIf="!mobileAvatarOnOff" class="avatar_icon chat_icon"
            src="../../../../assets/hannahCloseIcon.png">

          <img class="" *ngIf="mobileAvatarOnOff" class="avatar_icon imgSet chat_icon"
            src="../../../../assets/HannahIcon.png">

        </span>
      </div>
    </div>
  </div>


  <!-- // chat input box  -->
  <div class="centerDiv" id="centerDiv">
    <div class="" id="chat-bar" style="z-index: 1100000;">
    </div>
  </div>

  <!-- <div class="speaking-lady   imgTest" id="movableCard" style="border-radius: 0px;"
    [style.background-color]="backgroundColor">
    
  </div> -->

  <div class="speaking-lady   imgTest" id="movableCard" style="border-radius: 0px;"
    [style.background-color]="backgroundColor">
    <div id="avatarLoaders">
      <div id="loader" style="display: flex;justify-content: center; font-size: 18px;">
        <img src="../../assets/images/Rolling.gif" alt="Rolling" style="width: 50px; height:50px ">
      </div>
      <p style="display: flex;justify-content: center; font-family: 'DM Sans'; font-size: 18px; color: white;text-align: center;">
        {{avatarName}} is on her way to meet with you.<br>  Please wait a
        moment.
      </p>
    </div>
    <ng-container *ngIf="!messageForQueueAvatar">
      <!-- speakingsss_large   // its use when the card open in full screen on open -->
      <div class="speakingsss " id="cross" style="position: absolute;" (click)="onLoadCard('sm-video')">
        <span id="maximizeAvatar" class="">
          <svg width="23" height="23" fill="#FFFFFF" stroke="#fafafa" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 3h6v6"></path>
            <path d="M9 21H3v-6"></path>
            <path d="m21 3-7 7"></path>
            <path d="m3 21 7-7"></path>
          </svg>
          <span style="font-style: normal;" class="max">Maximize</span>
        </span>


        <span style="margin-top: -10px;" style=" position: absolute;" class="" id="minimizeAvatar">
          <!-- <img src="../../../../assets/interaction/x-circle.svg" style="width: 29px; height: 29px;"> -->
          <img id="cross1" src='../../../../assets/interaction/arrow-left.svg' style="width: 30px; height: 30px;">
          <span class="min" style="font-style: normal;">Minimize</span>
        </span>
      
      </div>
      <div class="exitPresentBtn showI" id="exitpresentation" style="position: absolute;">
        <img src="../../../../assets/interaction/x.png" (click)="exitpresentationFun()"
          style="width: 30px; height: 30px;">
        <span class="exitppt" style="font-style: normal;">Exit Session</span>
      </div>
    
      <div class="exitPresentBtn showI" id="exitExam" style="position: absolute;">
        <img src="../../../../assets/interaction/arrow-left.svg" (click)="exitTestSeries()"
          style="width: 30px; height: 30px;">
      </div>

      <!-- <div class="speedspeech " id="speedspeech" style="position: absolute;">
        <div class="ForDropDown" #popup1>
      
          <input type="checkbox" id="dropdownToggle" class="dropdown-toggle" #dropdownToggle
            (click)="toggleDropdown($event)" style="cursor: pointer;border: 1px solid blak;">

        
          <label for="dropdownToggle" class="dropdown-button" style="cursor: pointer;">
           
            <svg xmlns="http://www.w3.org/2000/svg" style="margin-top: -5px;cursor:pointer;" width="30" height="30"
              viewBox="0 0 30 30" fill="none">
              <path
                d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
                fill="white" fill-opacity="0.2" />
              <path
                d="M15 17.5C16.3807 17.5 17.5 16.3807 17.5 15C17.5 13.6193 16.3807 12.5 15 12.5C13.6193 12.5 12.5 13.6193 12.5 15C12.5 16.3807 13.6193 17.5 15 17.5Z"
                fill="white" fill-opacity="0.2" />
              <path
                d="M15 2.5V5.625M15 2.5C8.09644 2.5 2.5 8.09644 2.5 15M15 2.5C21.9036 2.5 27.5 8.09644 27.5 15M15 24.375V27.5M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15M5.625 15H2.5M27.5 15H24.375M23.848 23.848L21.6309 21.6309M6.15204 23.848L8.37144 21.6286M6.15204 6.25L8.3226 8.42056M23.848 6.25L16.8749 13.125M17.5 15C17.5 16.3807 16.3807 17.5 15 17.5C13.6193 17.5 12.5 16.3807 12.5 15C12.5 13.6193 13.6193 12.5 15 12.5C16.3807 12.5 17.5 13.6193 17.5 15Z"
                stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
           
            <h6 class="dropdowntext_one" style="cursor: pointer;">Speed <span class="selectedSpeed">{{ selectedSpeed
                }}</span></h6>
          </label>

         
          <div class="dropdown-content_left" *ngIf="isDropdownOpen">
            
            <a *ngFor="let speed of speeds" (click)="selectSpeed(speed)">
              
              <span [ngClass]="{'selected-speed': selectedSpeed === speed.value}">
               
                <svg class="check-icon" [ngClass]="{'hidden': selectedSpeed !== speed.value}"
                  xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path d="M15 4.5L6.75 12.75L3 9" stroke="#2A7CC7" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
                {{ speed.label }}
              </span>
            </a>
          </div>
        </div>
        <span class="speedtool">Speech Speed</span>
      </div> -->

      <div class="count_timer" id="mintimer">
        {{ timer }}
        <span class="GuidedTime">Time Left</span>
      </div>
      <div class="tourDivCss " id="tourIcon" style="position: absolute;">
        <img src="../../../../assets/interaction/map-02.svg" (click)="StartTourGuideSetting()"
          style="width: 30px; height: 30px;">
        <span class="GuidedTour">Guided Tour</span>
      </div>

    </ng-container>

    <div id="iconShow" class="zz showI"
      style=" justify-content: left; margin-left: 18px; margin-top: 10px; cursor: pointer">
    </div>

    <!-- //  Mathematics button -->
    <div class="rightDiv zz">
      <div *ngIf="notSchool == 'school'">
        <div class="image-container" id="hambergerBar">
          <div class="toggle-switch" id="GuideMathametic" (click)="toggleOnOff()">
            <input class="toggle-input" id="GuideMathametic1" #mathToggleInput1 type="checkbox"
              [disabled]="mathButtonDisabled" [(ngModel)]="toggleValue" name="toggleValue">
            <label class="toggle-label" for="mathToggleInput1"></label>
          </div>

          <span class="mathematicsText">Mathematics <span *ngIf="toggleValue">ON</span> <span
              *ngIf="!toggleValue">OFF</span> </span>
        </div>
      </div>
      <!-- height: 30px; -->
      <div *ngIf="notSchool == 'notSchool'" id="hambergerBar" class="notSchool" style="height: 45px;"></div>

    </div>

    <!-- // hand stop avatar voice  -->
    <!-- <div class="div" id="stopTourGuide"> -->
    <!-- <div class="stopavatar showI" id="stopavatarId">
      <div class="bottomStopSpeaking pausebtn" id="stopspeakingID" style="z-index: 1400000;"
        [style.bottom]="stopBottomSize" [style.left]="stopLeftSize">
        <img src="../../../../assets/interaction/stopspeaking.svg" *ngIf="!unmuteMicrophone"
          onkeyup="event.preventDefault()" (click)="stopDigitalPerson()" class="stopIcon">
      </div>
    </div> -->

    <!-- // hand unmute avatar voice  -->
    <!-- <div class="stopavatar" *ngIf="unmuteMicrophone">
      <div class="bottomStopSpeaking pausebtn" style="z-index: 1400000;" [style.bottom]="stopBottomSize"
        [style.left]="stopLeftSize">
        <img src="../../../../assets/interaction/unmuteSpeaking.png" onkeyup="event.preventDefault()"
          (click)="muteDigitalPerson()" class="stopIcon mt-2" style="height: 32px !important;">
      </div>
    </div> -->


    <div *ngIf='messageForQueueAvatar' style="margin-top:50px   ;  height: 196px;">
      <div id="loader" style="display: flex;justify-content: center; font-size: 18px;">
        <img src="../../assets/images/Rolling.gif" style="width: 50px; height:50px ">
      </div>
      <p style="display: flex;justify-content: center; font-size: 16px; color: white;">
        Oops! Seems like we got disconnected.<br> Give me a moment I will be right back.
      </p>
    </div>

    <!-- // avatar video card -->
    <div class="userVideo">
      <div class="g" style="display: flex; justify-content: center;">
        <!-- <div class="uneeqAvatar" id="sm-video"> autoplay  [muted]="videoMuted" [autoplay]="autoplayTue"-->
        <div class="uneeqAvatar" id="sm-video">
          <video id="smVideo" width="100%" height="100%" autoplay playsinline></video>
        </div>
      </div>
    </div>

    <div class="peerVideo">
      <!-- 
 // text field -->
      <div class="QuestionCard fontss hideMessage" id="textDisplay" style="margin-top: 21px;">
        <div class="avatarspeak-s fontss" id="descpText">
        </div>
      </div>
      <!-- 
 // end text field -->

      <!-- Image display area -->
      <div class="playerOnePPT hideMessage" id="pptPageLoad">
        <div class="hideMessage" id="pdfDataSet" style="margin-top: 7px;">
          <!-- <app-pdf #childPdf [pdf]="pdfShow" (stopSubtitle)="stopSubtitleAnimation()"></app-pdf> -->
          <app-pdf #childPdf [pdf]="pdfShow" (callTimer)="callTheTimer()"
            (stopSubtitle)="stopSubtitleAnimation()"></app-pdf>
        </div>
      </div>


      <div class="playerOne  hideMessage fontss" id="ImageDisplay" style="margin-top: 21px;">
        <div class="" style="height:330px;">
          <img class="ImageAspectRatio Qimage" [src]="ImageData"
            style=" box-shadow: rgba(239, 221, 221, 0.35) 0px 5px 15px; ">
          <img class="ImageAspectRatio " src="../../../../assets/nb.jpg"
            style="height: 330px; max-width: 480px;  box-shadow: rgba(239, 221, 221, 0.35) 0px 5px 15px; ">
        </div>
      </div>

      <!-- -----testing---- -->
      <div class="playerOne  hideMessage fontss" id="MathameticsDisplay" style="margin-top: 10px;">
        <div class="">
          <div class="" style="display: flex; justify-content: center;">
            <!-- background: #e5f0ee; -->
            <div class="list-button  fontss" style=" width: 100%; cursor: default; padding:5px ; line-height: 25px;">
              <span style="display: flex; margin-left: 10px;font-size: 24px;">Question</span>
              <p id="user_questionD" class="userQuesClass"> &nbsp; </p>
            </div>
          </div>

        </div>
      </div>
      <!-- -----normal conversation---- -->

      <ng-container *ngIf="DescAnswer != ''">
        <!-- <div class="playerThree  hideMessage " id="message" style="margin-top: 38px; position: sticky"> -->
        <div class="playerThree hideMessage " [ngClass]="{
          'overlayBlurportion': blurEffect }" id="message" style="margin-top: 8px;">
          <div class="descptionContent" [ngClass]="{
          'overlayBlurportion': blurEffect }">
            <div class="avatarspeak-s scrollbar spaceInText" id="outputDesc">
            </div>
            <br>
            <!-- <div *ngIf="normalGPT" class="di" style="display: flex; justify-content: center;">
              <img src="../../../../assets/interaction/edyou-throbber-v2-100-white.gif" class="edyouLoaderWhite2">
            </div> -->
            <!-- <div *ngIf="descptionContentLoader == true" class="di" style="display: flex; justify-content: center;">
              <img src="../../assets/interaction/edyou-throbber-v2-100-blue.gif" class="edyouLoaderWhite">
            </div> -->
            <div *ngIf="runLoderGPT" class="di" style="display: flex; justify-content: center;">
              <img src="../../assets/interaction/edyou-throbber-v2-100-blue.gif" class="edyouLoaderWhite">
            </div>
          </div>

          <!-- ngIf="refreshBtnPPT" -->
          <div class="refreshbtnPPT" id="refreshTG" *ngIf="refreshBtnPPT" (click)="refreshSlidePPT()">
            <img class="" src="../../../../assets/refreshNew.svg" style="width: 20px;cursor: pointer;">
          </div>

          <div class="">
            <button *ngIf="isBackToStepsOn" class="text-button fontss	btn-ripple" id="backTour" (click)="backToSteps()"
              style="margin-left: 0px ; margin-bottom:5px ; width: 49% ; height: 35px; font-family: DM Sans;
            font-weight: 400;
            line-height: 19px; font-size: 12px;   z-index: 15000000;">
              <svg width="15" height="15" fill="none" stroke="#fcfcfc" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 12H5"></path>
                <path d="m12 19-7-7 7-7"></path>
              </svg>
              Back to steps
            </button>


            <button *ngIf="isPreviousStep" class="text-button fontss	btn-ripple mathematicBackBtn" id="backTour"
              (click)="previousStepFun()">
              <svg width="15" height="15" fill="none" stroke="#fcfcfc" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 12H5"></path>
                <path d="m12 19-7-7 7-7"></path>
              </svg>
              Previous step
            </button>

            <span style="margin-left: 6px ;" *ngIf="isPreviousStep"></span>
            <button *ngIf="isNextStep" class="text-button fontss	btn-ripple mathematicNextBtn" id="nextTour"
              (click)="nextStepFun()">Next step
              <svg width="15" height="15" fill="none" stroke="#fcfcfc" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 12h14"></path>
                <path d="m12 5 7 7-7 7"></path>
              </svg>
            </button>
          </div>

          <div class="hideMessage" id="mathApproach" style="margin-top: 4px;">
            <div class="row mt-2">
              <span class="methods">Methods</span>
              <ng-container *ngFor="let list of approachesList ; let i = index">
                <div class="apprachbutton btn-ripple" id="colorApproachOption{{i}}"
                  (click)="selectMathsListOption(list,i)">{{list}}
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- chat bubble start  -->
        <div class="chatbubbleBox   hideMessage" id="chatbubblemessage" style="margin-top: 8px;">
          <div #messageContainer class="chat-container">
            <div class="message-container" *ngFor="let chat of storedChatBubbleMessage; let i = index">
              <ng-container *ngIf="chat.source === 'user'">
                <span class="receiver-timer">{{chat.time}}</span>
                <div class="message   receiver-message">
                  <div class="receviedSpan scrollbarchatUser "> {{chat.text}} </div>
                </div>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" style="align-self: flex-end;
                z-index: 100;
                margin-right: 6px;
                margin-top: -25px;" width="17" height="18" viewBox="0 0 17 18" fill="none">
                  <g filter="url(#filter0_b_8074_5239)">
                    <path d="M8 0.5L17 17.5L0.5 12.5L8 0.5Z" fill="#1367B4" />
                  </g>
                  <defs>
                    <filter id="filter0_b_8074_5239" x="-14.5" y="-14.5" width="46.5" height="47"
                      filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
                      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_8074_5239" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_8074_5239" result="shape" />
                    </filter>
                  </defs>
                </svg> -->
                <svg xmlns="http://www.w3.org/2000/svg" style="align-self: flex-end;
                z-index: 100;
                margin-right: 4px;
                margin-top: -24px;" width="17" height="18" viewBox="0 0 17 18" fill="none">
                  <g filter="url(#filter0_b_8074_5239)">
                    <path d="M8 0.5L17 17.5L0.5 12.5L8 0.5Z" fill="#1367B4" />
                  </g>
                  <defs>
                    <filter id="filter0_b_8074_5239" x="-14.5" y="-14.5" width="46.5" height="47"
                      filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
                      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_8074_5239" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_8074_5239" result="shape" />
                    </filter>
                  </defs>
                </svg>
              </ng-container>



              <ng-container *ngIf="chat.source === 'persona'">
                <span class="sender-timer">{{chat.time}}</span>
                <div class="message   sender-message">

                  <div class="senderSpan scrollbarchat" [innerHTML]="chat.text"></div>


                </div>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" style="    z-index: 100;
        margin-top: -25px;
        margin-left: 6px;" width="17" height="18" viewBox="0 0 17 18" fill="none">
                  <path d="M9 0.5L0 17.5L16.5 12.5L9 0.5Z" fill="white" />
                </svg> -->
                <svg xmlns="http://www.w3.org/2000/svg" style="    z-index: 100;
                margin-top: -24px;
                margin-left: 4px;" width="17" height="18" viewBox="0 0 17 18" fill="none">
                  <path d="M9 0.5L0 17.5L16.5 12.5L9 0.5Z" fill="white" />
                </svg>
              </ng-container>
            </div>

            <ng-container *ngIf="showLoaderMess">
              <div class="loaders"> <img src="../../assets/threedot.gif" style="width: 50px;
        height: 50px;margin-left: 10px; border-radius: 15px;
        background-color: white;"></div>
              <svg xmlns="http://www.w3.org/2000/svg" style="     z-index: 100;
margin-top: -45px;
margin-left: 7px;" width="17" height="18" viewBox="0 0 17 18" fill="none">
                <path d="M9 0.5L0 17.5L16.5 12.5L9 0.5Z" fill="white" />
              </svg>
            </ng-container>
          </div>
        </div>

        <!-- // chat bubble end -->

      </ng-container>

      <!-- -->
      <!-- <div class="hideMessage" id="userCC">
        <div class="bottomboxSize">
          <div class="container">
            <div class=" d-flex justify-content-center">
              <div class="ccBoxNew " id="userText">

                <div class="avatarspeak-s" id="local-transcript">
                  You: {{userInputText}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- start testseries -->
      <div class="playerOnePPT testseries hideMessage " id="showTestPrepItem">
        <div class="group-parent">
          <div class="group-container">
            <!-- <div class="parent" id="parentQuestion" style="margin-left: -13px;"> -->
            <div class="parent" id="parentQuestion" style="margin-left: -7px;">
              <!-- <div class="question-5-of ">Question {{current_testseries_len}} of {{total_testseries_len}}</div> -->
              <div class="qname" id="question_AND_percent">
                <div class="question-5-of ">Q. {{current_testseries_len}}/{{total_testseries_len}}</div>
                <div class="q_progress">
                  <div class="progress rectangle2  ">
                    <div class="progress-bar  percent" style="background-color:#2A7CC7;"
                      [ngStyle]="{'width': test_progress_Percent}" role="progressbar" aria-valuenow="100"
                      aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <h6 class="progressPercent">{{test_Progress}}%</h6>
                </div>
              </div>
              <div class="questionTextTest QuestionBOx" id="testQuestion" style="width: 90%;">
                <p class="spaceInText panelss scrollbar" id="QQ">

                </p>
              </div>
            </div>

            <div class="row " id="testTour">
              <ng-container *ngIf="DescAnswer == ''">
                <!-- <ng-container > -->
                <div class="" id="optionMessage">
                  <button class="btnn  fontss " id="btnOptionCC" [disabled]="isClick" style="border: none !important;"
                    (click)="selectOptionForTest(i)" *ngFor="let option of optionList ; let i = index">
                    <div class="row" style="display: flex;flex-direction: row;flex-wrap: nowrap;">
                      <div class="firstBoxOption" id="startColor{{i}}">
                        <div class="button-1">{{ stringRef.fromCharCode('A'.charCodeAt(0)+i) }}</div>
                      </div>
                      <div class="secondBoxoption" id="colorbtnOption{{i}}">
                        <span id="colorOption{{i}}"></span>
                        <div class="button-12"></div>
                      </div>
                    </div>
                  </button>

                </div>
                <!-- for mobile option -->

                <div class="playerOneForMobile showI" id="optionMessageMobile">
                  <div class="accordionsectionMobile">
                    <button id="accodH1" (click)="accordQestionhit()" style="font-weight: 400;"
                      class="accordinColor  active">&nbsp;
                      <b style="color: white;">Answer options</b>
                      <i *ngIf="accod1 == false"
                        style="display:flex ; justify-content:right ; margin-top:-23px ; color: white;"
                        class="fa-solid fa-plus mr-3 mb-2"></i>
                      <i *ngIf="accod1 == true"
                        style="display:flex ; justify-content:right ; margin-top:-23px ; color:white"
                        class="fa-solid fa-minus mr-3 mb-2"></i>
                    </button>
                    <div class="panelBoxOption" id="accod1" style="display: block;">
                      <div class="panelsOption mobileFonts scrollbar" style="margin-left: -10px;">
                        <button class="btnn fontss" id="btnOptionCC" [disabled]="isClick"
                          (click)="selectOptionForTest(i)" *ngFor="let option of optionList ; let i = index">

                          <div class="row">
                            <div class="firstBoxOption" id="startColorMobile{{i}}">
                              <div class="button-1">{{ stringRef.fromCharCode('A'.charCodeAt(0)+i) }}</div>
                            </div>
                            <div class="secondBoxoption" id="colorbtnOptionMobile{{i}}">
                              <span id="colorOptionMobile{{i}}"></span>
                              <div class="button-12"></div>
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
                <!-- /// end mobile options -->
              </ng-container>
            </div>

            <div id="explainTour" class="rectangle-parent" *ngIf="CorrectAnswer != ''">
              <div class="rectangle-div"></div>
              <div class="explanation">Explanation</div>
              <div class="the-value-of mobileFonts panelss scrollbar" id="answerDescription">
                <p class="spaceInText" id="answerD">
                </p>
              </div>
              <div class="rectangle-wrapper">
                <div class="group-child1"></div>
              </div>
              <div class="rectangle-container">
                <div class="group-child2"></div>
              </div>
            </div>


            <div class="ExplainTestBox" id="testBTNTOur">
              <div class="row" *ngIf="nextButtonTestseries">
                <button *ngIf="nextButtonTestseries" class="text-button fontss	btn-ripple" id="backTour"
                  [disabled]="current_testseries_len == 1" (click)="previousQuestion()"
                  [ngClass]="{'fade-shade': current_testseries_len == 1}"
                  style="margin-left: 3px ; margin-bottom:5px ; width: 90px; height: 35px;    z-index: 15000000;">
                  <svg width="15" height="15" fill="none" stroke="#fcfcfc" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 12H5"></path>
                    <path d="m12 19-7-7 7-7"></path>
                  </svg>
                  Back
                </button>

                <button *ngIf="nextButtonTestseries" class="text-button fontss	btn-ripple" id="nextTour"
                  (click)="nextQuestion()"
                  style="margin-left: 6px ; margin-bottom:5px ; width: 90px;height: 35px;      background: #2A7CC7;   z-index: 15000000;">Next
                  <svg width="15" height="15" fill="none" stroke="#fcfcfc" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 12h14"></path>
                    <path d="m12 5 7 7-7 7"></path>
                  </svg>

                </button>
              </div>

              <button *ngIf="resumeButtonTestseries" class="text-button  fontss	btn-ripple" (click)="resumeQuestion()"
                style="margin-left: 3px ; width: 90px; height: 30px;">Resume
              </button>
            </div>

          </div>
        </div>
      </div>

      <!-- test summery -->

      <div class="testSummery hideMessage " id="newSummery">
        <h4>Test Summary</h4>
        <h6>Congrats! you have completed the test.</h6>
        <!-- <div class="timer">
            <h5>Time Taken</h5>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="black">
                <g clip-path="url(#clip0_9144_19176)">
                  <path d="M9.99935 18.3307C14.6017 18.3307 18.3327 14.5998 18.3327 9.9974C18.3327 5.39502 14.6017 1.66406 9.99935 1.66406C5.39698 1.66406 1.66602 5.39502 1.66602 9.9974C1.66602 14.5998 5.39698 18.3307 9.99935 18.3307Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10 5V10L13.3333 11.6667" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_9144_19176">
                    <rect width="20" height="20" fill="white"/>
                  </clipPath>
                </defs>
            </svg>
            <h5 >1:20</h5>
        </div> -->
        <div class="row option_Only">
          <div class="summery_button">
            <span class="button-121">Total Questions</span>
            <!-- <div class="button-121"></div> -->
          </div>
          <div class="summery_box">
            <div class="testloader" *ngIf="testLoade"></div>
            <div class="button-11" *ngIf="!testLoade">{{total_Question}}</div>
          </div>
        </div>
        <div class="row option_Only2">
          <div class="summery_button">
            <span class="button-121">Correct Answers</span>
            <!-- <div class="button-121"></div> -->
          </div>
          <div class="summery_box">
            <div class="button-11" style="color: #2DC071;" *ngIf="!testLoade">{{correct_Ans }}</div>
            <div class="testloader" *ngIf="testLoade"></div>
          </div>
        </div>
        <div class="row option_Only2">
          <div class="summery_button">
            <span class="button-121">Wrong Answers</span>
            <!-- <div class="button-121"></div> -->
          </div>
          <div class="summery_box">
            <div class="button-11" style="color: #FF6B6B;" *ngIf="!testLoade">{{wrong_Ans}}</div>
            <div class="testloader" *ngIf="testLoade"></div>
          </div>
        </div>
        <div class="summery_details">
          <div class="block1">
            <h5 class="block1h5" *ngIf="!testLoade">{{score_earned}}</h5>
            <div class="testloader" *ngIf="testLoade"></div>
            <h6 class="block1h6">Score Earned</h6>
          </div>
          <div class="border"></div>
          <div class="block1">
            <h5 class="block1h5" *ngIf="!testLoade">#{{totalRank}}</h5>
            <div class="testloader" *ngIf="testLoade"></div>
            <h6 class="block1h6">Total Rank</h6>
          </div>
          <div class="border"></div>
          <div class="block1">
            <h5 class="block1h5" *ngIf="!testLoade">#{{overall_Rank}}</h5>
            <div class="testloader" *ngIf="testLoade"></div>
            <h6 class="block1h6">Overall Rank</h6>
          </div>
        </div>
      </div>

      <!-- end test series -->



      <!-- -- chat box , mic ------ -->
      <div class="bottomboxSize baseChatBottom" id="bottomBar" [style.bottom.px]="bottomPosition"
        [style.height]="bottomPositionheight" [style.width]="bottomPositionWidth">
        <div class="container">
          <div class="d-flex justify-content-center centerDiv">
            <div class="box-1" id="boxForInput" style="z-index: 21000000">
              <ng-container>
                <div class="input-container">
                  <ng-container *ngIf="normalChatBar">
                    <!-- <input type="text" #input1 style="width: 60px;">
                <input type="text" #input2 style="width: 60px;"> 
                <button (click)="setVideo(input1.value, input2.value)">send</button>  -->
                    <!-- onkeydown="if (event.keyCode == 13) document.getElementById('sendId1').click()" -->
                    <input type="text" [style.width]="inputWidthSize" id="textTourBox" [style.height]="inputheightSize"
                      (focus)="onFocus('inputActive')" (blur)="onBlur()" class="group-item" [(ngModel)]="userText"
                      placeholder="Type here or push mic to talk"
                      style="font-size: medium !important; padding-left: 15px !important;">

                  </ng-container>

                  <!-- <ng-container *ngIf="mathsChatBar">
                    <div class="div" style="    width: 93%;" #customM id="eventTest" (click)="clickEvent($event)">
                      <math-field [smartMode]="true" style="display: block; " id="textTourBox"
                        (focus)="onFocus('inputActive')" (blur)="onBlur()" class="group-item math-field"
                        [style.width]="inputWidthMathSize" [style.height]="inputheightSize" (change)="mathsValue($event)"
                        [(ngModel)]="inputMathsValue"></math-field>
                    </div>

                  </ng-container> -->
                  <img class="chat-button" id="sendId1" style="width: 50px;height: 45px; background-color: white;"
                    (click)="sendTextToAvatar()"
                    [src]="focusInput == 'inputActive' ? '../../assets/newSend.svg' : '../../assets/send-03.svg'">

                </div>
              </ng-container>
            </div>


            <div class="box-2" [style.margin-left]="box2MarginLeft">
              <div class="di">
                <!-- <span>
                  
                  <img alt="" id="voiceId"
                       [ngClass]="{'hidden': isvoiceAnimationOn, 'visible': !isvoiceAnimationOn, 'disable-click': disableMicButton ,'active': isActive}"
                       (click)="disableMicButton ? null : onclickMic()"
                       [style.width.px]="isvoiceAnimationOn ? micWidthOnly : micWidth"
                       [style.height.px]="micHeight"
                       [style.margin-left]="micMarginLeft2"
                       
                       src="../../assets/micBlank.png" />
              
                  <img alt="" id="voiceId"
                       [ngClass]="{'hidden': !isvoiceAnimationOn, 'visible': isvoiceAnimationOn, 'disable-click': disableMicButton,'active': !isActive}"
                       (click)="stopOnClick()"
                       [style.width.px]="micWidthOnly"
                       [style.height.px]="micHeight"
                       [style.margin-left]="micMarginLeft2"
                      
                       src="../../../../assets/newMicGIff.gif" />
                </span> -->

                <span>
                  <img alt="" id="voiceId" *ngIf="isvoiceAnimationOn == false"
                    (click)="disableMicButton ? null : onclickMic()" [style.width.px]="micWidth"
                    [style.height.px]="micHeight" [style.margin-left]="micMarginLeft2"
                    src="../../assets/micBlank.png" />
                  <!-- <img alt="" *ngIf="isvoiceAnimationOn == true" src="../../assets/micOrange.png"
                    [style.width.px]="micWidthOnly" [style.margin-left]="micMarginLeft2" (click)="stopOnClick()"
                    [style.height.px]="micHeight" [style.margin-left.px]="micMarginleft"> -->
                  <img alt="" *ngIf="isvoiceAnimationOn == true" [class.active]="isvoiceAnimationOn"
                    src="../../../../assets/newMicGIff.gif" [style.width.px]="micWidthOnly"
                    [style.margin-left]="micMarginLeft2" (click)="stopOnClick()" [style.height.px]="micHeight"
                    [style.margin-left.px]="micMarginleft">
                </span>
              </div>
            </div>

            <img id="minimiseMathBTN" class="showI miniBTN" (click)="mathEventHit('')"
              src="../../../../assets/interaction/Minimize_icon1.svg" />

            <div class="box-3" id="box3Main" style="margin-left: 5px; cursor: default;">
              <img *ngIf="!dotIndicatorAnimation" style="    position: relative; width: 75px; 
            margin-left: 3px;
            margin-top: 15px;" src="../../assets/dotIndicator.svg">
              <div id="bars" style="margin-top: 15px;" *ngIf="dotIndicatorAnimation">
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
              </div>
            </div>
          </div>


          <div class="testtt showI" id="mobileDotAni" style="    position: absolute;
        right: 27%;
        width: 40px;
        margin-top: -145px;">
            <img *ngIf="!dotIndicatorAnimation" class="indicatorForMobile" src="../../assets/dotIndicator.svg">
            <div id="bars" class="animationIndictor" *ngIf="dotIndicatorAnimation">
              <div class="bar"></div>
              <div class="bar"></div>
              <div class="bar"></div>
              <div class="bar"></div>
              <div class="bar"></div>
              <div class="bar"></div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="overlayBlur" *ngIf="blurEffect"></div>
</div>
<!-- <ng-container *ngIf="isSwal">
  <app-swal [type]="type" [payload]="payload" [swalFireData]="swalFireData"
    (swalDataFun)="swalDataFun($event)"></app-swal>
</ng-container> -->