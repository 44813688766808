<div class="container" *ngIf="isBrowser">
  <div class="image-div"  >
    <!-- [stick-to-page]="true" -->
    <div class="loader" *ngIf="isLoaderActive"></div>
    <div *ngIf="isBrowser && pdfSrc" class="pdf-wrapper">
      <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false" [page]="page" 
        [fit-to-page]="false" [zoom]="1" [rotation]="0" [show-all]="false" [zoom-scale]="'page-width'"
        (error)="onError($event)" [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
        (after-load-complete)="afterLoadComplete($event)" class="pdf-viewer">
      </pdf-viewer>
    </div>
  

  </div>
  
  <!-- style="z-index: 1900000000000" -->
   <div class="button-new">
    <div class="right-side" style="    z-index: 190000;">
   
      <button [disabled]="pdfSrctype == 'youtube'"  class="  button btn btn-sm" title="Download" id="downloadTG" style="z-index: 100;">
             <a  (click)="downloadPDFUrl()">
          <svg [attr.width]="downloadIconSize" [attr.height]="downloadIconSize" fill="none" stroke="#f2f2f2"
            stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <path d="m7 10 5 5 5-5"></path>
            <path d="M12 15V3"></path>
          </svg>
        </a>
      </button>
  
       <button onkeyup="event.preventDefault()" class="btn btn-sm button " id="slidedropdown">
        <div class="dropdown show">
          <a style="    width: 51px" class="btn text-white border dropdown-toggle" role="button" id="dropdownMenuLink1" data-bs-toggle="dropdown" aria-expanded="false" [attr.aria-disabled]="isCommonDisableButton"
          [class.disabled]="isCommonDisableButton"
          [style.cursor]=" (isCommonDisableButton) ? 'none' : 'pointer'"
          [attr.disabled]="isCommonDisableButton ? true : null" >
            {{pvalue}}
          </a>
  
          <div class="dropdown-menu dropdownHeight" aria-labelledby="dropdownMenuLink1" >
            <a class="dropdown-item " (click)="getPaginationvalue(p)" *ngFor="let p of totalPagination">{{p}}</a>
  
          </div>
        </div>
  
      </button>
  
    </div>
  
    <div class="button-container" style="z-index: 19000;">
      <button (click)="prevPage($event)" title="Previous" onkeyup="event.preventDefault()" id="backTG"
        [disabled]="(page === 1) || (page === 0) || (isCommonDisableButton)" class="btn btnMobilesize btn-sm button "
        [style.cursor]="(page === 1) || (page === 0)|| (isCommonDisableButton) ? 'not-allowed' : 'pointer'">
      
        <img [attr.width]="iconSize" [attr.height]="iconSize" src="../../../../assets/interaction/chevron-left.svg">
      </button>
  
      <button (click)="refreshCurrentSlide()"  [disabled]="isCommonDisableButton" onkeyup="event.preventDefault()"
        title="Repeat" class=" ml-1 button btn btn-sm"
        [style.cursor]="isCommonDisableButton ? 'not-allowed' : 'pointer'">
      
        <p class="numberpagination">&nbsp;{{ page }} / {{ totalPages }}</p>
      </button>
  
      <button (click)="nextPage($event)" onkeyup="event.preventDefault()"    id="nextTG"
        [disabled]="(page === totalPages) || (isCommonDisableButton)" title="Next" class=" ml-1 button btn btn-sm" 
        [style.cursor]="(page === totalPages) || (isCommonDisableButton) ? 'not-allowed' : 'pointer'">
    
        <img [attr.width]="iconSize" [attr.height]="iconSize" src="../../../../assets/interaction/chevron-right.svg">
      </button>
  
    </div>
   </div>
  
  <!-- <div class="page-container">
    <p class="page-info ">&nbsp;{{ page }} / {{ totalPages }}</p>

  </div> -->

</div>
<br>
