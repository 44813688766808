<div class="main">
<div data-elementor-type="single-page" data-elementor-id="21477"
    class="elementor elementor-21477 elementor-location-single post-3 page type-page status-publish hentry"
    data-elementor-post-type="elementor_library">
    <div
        class="has_ae_slider elementor-element elementor-element-a98c644 light bottom-p e-flex e-con-boxed ae-bg-gallery-type-default e-con e-parent"
        data-id="a98c644" data-element_type="container"
        data-settings="{&quot;content_width&quot;:&quot;boxed&quot;}"
        data-core-v316-plus="true">
        <div class="e-con-inner">
            <div
                class="elementor-element elementor-element-6ff1770 elementor-widget elementor-widget-heading"
                data-id="6ff1770" data-element_type="widget"
                data-widget_type="heading.default">
                <div class="elementor-widget-container">
                    <style>
.elementor-heading-title{padding:0;margin:0;line-height:1}.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a{color:inherit;font-size:inherit;line-height:inherit}.elementor-widget-heading .elementor-heading-title.elementor-size-small{font-size:15px}.elementor-widget-heading .elementor-heading-title.elementor-size-medium{font-size:19px}.elementor-widget-heading .elementor-heading-title.elementor-size-large{font-size:29px}.elementor-widget-heading .elementor-heading-title.elementor-size-xl{font-size:39px}.elementor-widget-heading .elementor-heading-title.elementor-size-xxl{font-size:59px}</style><h1
                        class="elementor-heading-title elementor-size-default">Privacy
                        Policy</h1> </div>
            </div>
            <div
                class="elementor-element elementor-element-2984f07 elementor-widget elementor-widget-theme-post-content"
                data-id="2984f07" data-element_type="widget"
                data-widget_type="theme-post-content.default">
                <div class="elementor-widget-container">
                    <p>This policy describes how edYOU Technologies Inc. (“we,”
                        “our,” “us”) collects, uses, and shares your or your
                        child’s personal information (as defined under the
                        Children’s Online Privacy Protection Rule) or the
                        personal information of your child. “Kid” or “child”
                        means a user under the age of 18. “Parent” means a kid’s
                        parent or legal guardian, or an agent of the parent,
                        including the school that has contracted with us for the
                        services. “You” or “your” may mean you the user, or your
                        child using the services.</p>
                    <h3>Types Of Information We Collect</h3>
                    <p>The following provides examples of the type of
                        information that we collect from you and how we use that
                        information.</p>
                    <table class="pp-table">
                        <tbody>
                            <tr>
                                <th>Context</th>
                                <th>Types of Data</th>
                                <th>Primary Purpose for Collection and Use of
                                    Data</th>
                            </tr>
                            <tr>
                                <td>Account Registration</td>
                                <td>We collect your name, email address and
                                    contact information when you create an
                                    account with us to use our Services.</td>
                                <td>We have a legitimate interest in providing
                                    information to you to perform the
                                    Services.</td>
                            </tr>
                            <tr>
                                <td>Subscriber Information</td>
                                <td>We collect the name, email and contact
                                    information, of our subscribers and their
                                    Companies and possibly their employees with
                                    whom we may interact.</td>
                                <td>We have a legitimate interest in contacting
                                    our subscribers and communicating with them
                                    concerning normal administration.</td>
                            </tr>
                            <tr>
                                <td>Cookies and first party tracking</td>
                                <td>We use cookies and clear GIFs. “Cookies” are
                                    small pieces of information that a website
                                    sends to a computer’s hard drive while a web
                                    site is viewed.</td>
                                <td>We have a legitimate interest in making our
                                    website operate efficiently.</td>
                            </tr>
                            <tr>
                                <td>Email Interconnectivity</td>
                                <td>If you receive email from us, we use certain
                                    tools to capture data related to when you
                                    open our message, click on any links or
                                    banners.</td>
                                <td>We have a legitimate interest in
                                    understanding how you interact with our
                                    communications to you.</td>
                            </tr>
                            <tr>
                                <td>Feedback/Support</td>
                                <td>If you provide us feedback or contact us for
                                    support we will collect your name and e-mail
                                    address, as well as any other content that
                                    you send to us, in order to reply.</td>
                                <td>We have a legitimate interest in receiving,
                                    and acting upon, your feedback or
                                    issues.</td>
                            </tr>
                            <tr>
                                <td>Mailing List</td>
                                <td>When you sign up for one of our mailing
                                    lists we collect your email address or
                                    postal address.</td>
                                <td>We have a legitimate interest in sharing
                                    information about our organization.</td>
                            </tr>
                            <tr>
                                <td>Mobile Devices</td>
                                <td>We collect information from your mobile
                                    device such as unique identifying
                                    information broadcast from your device when
                                    visiting our website.</td>
                                <td>We have a legitimate interest in identifying
                                    unique visitors, and in understanding how
                                    users interact with us on their mobile
                                    devices.</td>
                            </tr>
                            <tr>
                                <td>Payments</td>
                                <td>We collect your name, billing address,
                                    shipping address, e-mail address, phone
                                    number, and credit card number when you
                                    subscribe to our Services.</td>
                                <td>We use your information to perform our
                                    contract to provide you with our
                                    Services.</td>
                            </tr>
                            <tr>
                                <td>Website Interactions</td>
                                <td>We use technology to monitor how you
                                    interact with our website. This may include
                                    which links you click on, or information
                                    that you type into our online forms. This
                                    may also include information about your
                                    device or browser.</td>
                                <td>We have a legitimate interest in
                                    understanding how you interact with our
                                    website to better improve it, and to
                                    understand your preferences and interests in
                                    order to select offerings that you might
                                    find most useful. We also have a legitimate
                                    interest in detecting and preventing
                                    fraud.</td>
                            </tr>
                            <tr>
                                <td>Web Logs</td>
                                <td>We collect information, including your
                                    browser type, operating system, Internet
                                    Protocol (IP) address (a number that is
                                    automatically assigned to a computer when
                                    the Internet is used), domain name, click-
                                    activity, referring website, and/or a
                                    date/time stamp for visitors.</td>
                                <td>We have a legitimate interest in monitoring
                                    our networks and the visitors to our
                                    website. Among other things, it helps us
                                    understand which of our services is the most
                                    popular.</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>In addition to the information that we collect from you
                        directly, we may also receive information about you from
                        other sources, including third parties, partners, our
                        affiliates, or publicly available sources.</p>
                    <h3>Use And Processing Of Information</h3>
                    <p>In addition to the purposes and uses described above, we
                        use information in the following ways:</p>
                    <ul>
                        <li>To identify you when you visit our website.</li>
                        <li>To provide our services to a child.</li>
                        <li>To improve our service offerings.</li>
                        <li>To streamline the transaction or payment
                            process.</li>
                        <li>To conduct analytics.</li>
                        <li>To send promotional materials, including information
                            relating to our services or promotions.</li>
                        <li>For internal administrative purposes, as well as
                            what’s necessary for business interests (such as
                            when we collect and use a child’s data in support of
                            internal operations, taking into account the impact
                            of the child’s privacy, or for security
                            purposes).</li>
                        <li>To comply with the law or legal process (such as
                            when we use a child’s personal information for
                            record-keeping to satisfy legal and compliance
                            obligations).</li>
                        <li>In accordance with valid consent (where a parent has
                            provided consent for their child).</li>
                    </ul>
                    <p style="margin-top: 1rem;">Although the sections above
                        describe our primary purpose in collecting your
                        information, in many situations we have more than one
                        purpose. For example, if you complete an online
                        transaction we will collect your information to perform
                        our contract with you, but we also collect your
                        information because we have a legitimate interest in
                        maintaining your information after your transaction is
                        complete so that we can quickly and easily respond to
                        any questions about your transaction. As a result, our
                        collection and processing of your information is based
                        in different contexts upon your consent, our need to
                        perform a contract, our obligations under law, and/or
                        our legitimate interest in conducting our business.</p>
                    <h3>Sharing Of Information</h3>
                    <p>In addition to the specific situations discussed
                        elsewhere in this policy, we disclose information in the
                        following situations:</p>
                    <ol>
                        <li><strong>Affiliates and Acquisitions.</strong> We may
                            share information with our affiliates (e.g., parent
                            organization, sister organization, joint ventures,
                            or other organizations under common control). If
                            another organization acquires, or plans to acquire,
                            our Company, operations, or our assets, we will also
                            share information with that organization, including
                            at the negotiation stage.</li>
                        <li><strong>Disclosures to Third Parties.</strong> We
                            may disclose aggregated and/or anonymized
                            information to unaffiliated third parties for solely
                            research purposes.</li>
                        <li><strong>Other Disclosures without Your
                                Consent.</strong> We may disclose information in
                            response to subpoenas, warrants, or court orders, or
                            in connection with any legal process, or to comply
                            with relevant laws. We may also share your
                            information in order to establish or exercise our
                            rights, to defend against a legal claim, to
                            investigate, prevent, or take action regarding
                            possible illegal activities, suspected fraud, safety
                            of person or property, or a violation of our
                            policies, or to comply with your request for the
                            shipment of products to or the provision of services
                            by a third party intermediary.</li>
                        <li><strong>Service Providers.</strong> We share your
                            information with service providers. Among other
                            things service providers help us to administer our
                            website, conduct surveys, provide technical support,
                            process payments, and assist in the fulfillment of
                            Services.</li>
                    </ol>
                    <h3>Your Choices</h3>
                    <p>You can make the following choices regarding your
                        personal information:</p>
                    <ol>
                        <li><strong>Access To Your Personal
                                Information.</strong> You may request access to
                            your personal information by contacting us at the
                            address described below. If required by law, upon
                            request, we will grant you reasonable access to the
                            personal information that we have about you. Note
                            that California residents may be entitled to ask us
                            for a notice describing what categories of personal
                            information (if any) we share with third parties or
                            affiliates for direct marketing.</li>
                        <li><strong>Changes To Your Personal
                                Information.</strong> We rely on you to update
                            and correct your personal information. Note that we
                            may keep historical information in our backup files
                            as permitted by law. If our website does not permit
                            you to update or correct certain information contact
                            us at the address described below.</li>
                        <li><strong>Deletion Of Your Personal
                                Information.</strong> Typically, we retain your
                            personal information for the period necessary to
                            fulfill the purposes outlined in this policy, unless
                            a longer retention period is required or permitted
                            by law. You may, however, request information about
                            how long we keep a specific type of information, or
                            request that we delete your personal information by
                            contacting us at the address described below. If
                            required by law we will grant a request to delete
                            information, but you should note that in many
                            situations we must keep your personal information to
                            comply with our legal obligations, resolve disputes,
                            enforce our agreements, or for another one of our
                            business purposes.Notwithstanding the foregoing, if
                            you are a student of the school contracting for our
                            services and (i) you withdraw from or otherwise
                            leave the care of the school or (ii) the school
                            terminates its agreement with us, your personal
                            information will be deleted promptly. Unless the
                            school renews the services, all your personal
                            information will be deleted within a year.Please
                            note, in some cases we may de-identify personal
                            information instead of deleting it.</li>
                        <li><strong>Objection to Certain Processing.</strong>
                            You may object to our use of your personal
                            information by contacting us at the address
                            described below.</li>
                        <li><strong>Online Tracking.</strong> We do not
                            currently recognize automated browser signals
                            regarding tracking mechanisms, which may include
                            &#8220;Do Not Track&#8221; instructions.</li>
                        <li><strong>Revocation Of Consent.</strong> If you
                            revoke your consent for the processing of personal
                            information then we may no longer be able to provide
                            you services. In some cases, we may limit or deny
                            your request to revoke consent if the law permits or
                            requires us to do so, or if we are unable to
                            adequately verify your identity. You may revoke
                            consent to processing (where such processing is
                            based upon consent) by contacting us at the address
                            described below.</li>
                        <li><strong>Parental Choices and Controls.</strong> If
                            you are parent of one of our child users, you can
                            ask us to:</li>
                    </ol>
                    <ul>
                        <li style="list-style-type: none;">
                            <ul style="list-style-type: lower-alpha;">
                                <li>Allow you to review your child’s personal
                                    information;</li>
                                <li>Deactivate your child’s account;</li>
                                <li>Revoke any consent you have given for your
                                    child;</li>
                                <li>Delete any of your child’s personal
                                    information your child has given us; an</li>
                                <li>Stop collecting personal information from
                                    your child.</li>
                            </ul>
                        </li>
                    </ul>
                    <p style="padding-left: 40px; margin-top: 1rem;">To make
                        these requests, or if you have any other questions about
                        the Site or our policies regarding the collection, use
                        or disclosure of your child’s personal information, you
                        may contact us at:</p>
                    <p style="padding-left: 40px;">edYOU Technologies,
                        Inc.<br />
                        11766 Wilshire Boulevard<br />
                        Suite 405<br />
                        Los Angeles, CA 90025</p>
                    <p style="padding-left: 40px;">privacy@investor.edyou.com</p>
                    <h3>How We Protect Personal Information</h3>
                    <p>No method of transmission over the Internet, or method of
                        electronic storage, is fully secure. While we use
                        reasonable efforts to protect your personal information
                        from unauthorized access, use, or disclosure, we cannot
                        guarantee the security of your personal information. In
                        the event that we are required by law to inform you of a
                        breach to your personal information we may notify you
                        electronically, in writing, or by telephone, if
                        permitted to do so by law.</p>
                    <p>Our website permits you to create an account. When you do
                        you will be prompted to create a password. You are
                        responsible for maintaining the confidentiality of your
                        password, and you are responsible for any access to or
                        use of your account by someone else that has obtained
                        your password, whether or not such access or use has
                        been authorized by you. You should notify us of any
                        unauthorized use of your password or account.</p>
                    <h3>California Privacy Act</h3>
                    <p>Under California law, residents of California are
                        permitted to request details on how their information is
                        shared with third parties for direct marketing
                        purposes.</p>
                    <p>Furthermore, a parent can request on their child’s behalf
                        that we:</p>
                    <ol>
                        <li>Provide the categories of Kids’ Personal Information
                            we have collected or disclosed about the child in
                            the last 12 months, the categories of sources of
                            such information, the business or commercial purpose
                            for collecting such information, and the categories
                            of third parties to which we disclosed such
                            information (most of which is already provided
                            above);</li>
                        <li>Provide access to and/or a copy of certain child’s
                            personal information;</li>
                        <li>Delete certain personal information of the child;
                            and</li>
                        <li>Correct inaccurate personal information of the
                            child.</li>
                    </ol>
                    <p style="margin-top: 1rem;">If you are a California
                        resident and would like to make a request, please
                        contact us at privacy@investor.edyou.com.</p>
                    <h3>Children’s  Privacy</h3>
                    <p>We do not intentionally collect any personal information
                        from children under the age of 13. If you are under the
                        age of 13, you can look at our websites, but you should
                        not make a purchase, register, or submit personal
                        information to us. If you feel that we have collected
                        data on a child, please reach out to us at
                        Privacy@investor.edyou.com so that we can take appropriate
                        action.</p>
                    <h3>Users Outside the United States</h3>
                    <p>If you are a non-U.S. user of the Site, by visiting the
                        Site and providing us with data, you understand and
                        agree that the data you provide to us may be processed
                        for the purposes listed in this Policy. You also
                        understand and agree that the data you provide to us may
                        be processed in the United States. U.S. laws regarding
                        the processing of data may be less stringent than the
                        laws of your country. By providing your data, you
                        consent to this processing.</p>
                    <p><strong> Residents of the European Economic
                            Area</strong></p>
                    <p>Upon request, residents of the EEA have the right to
                        access the information we process about you, and to
                        request that we correct, modify, or erase that
                        information. You also have the right to opt out of or
                        restrict certain types of processing subject to
                        applicable legal restrictions. If you have any questions
                        or you would like to exercise any of these rights,
                        please reach out to us at Privacy@investor.edyou.com. We may take
                        reasonable steps to verify your identity before granting
                        access or making corrections.</p>
                    <h3>Miscellaneous</h3>
                    <p>The following additional information relates to our
                        privacy practices:</p>
                    <ul>
                        <li><strong>Transmission Of Information To Other
                                Countries.</strong> As a Company located in the
                            United States, we process personal information in
                            the United States. If you provide your personal
                            information to us your information will be processed
                            in the United States where privacy laws may be less
                            stringent than the laws in your country. By
                            submitting your personal information to us you agree
                            to the transfer, storage and processing of your
                            information in a country other than your country of
                            residence including the United States.</li>
                        <li><strong>Third Party Applications/Websites.</strong>
                            We have no control over the privacy practices of
                            websites or applications that we do not own.</li>
                        <li><strong>Changes To This Privacy Policy.</strong> We
                            may change our privacy policy and practices from
                            time to time at our discretion. To the extent that
                            our policy changes in a material way, the policy
                            that was in place at the time that you submitted
                            personal information to us will generally govern
                            that information unless we receive your consent to
                            the new privacy policy.</li>
                    </ul>
                    <h3>Contact Information</h3>
                    <p>If you have any questions, comments, or complaints
                        concerning our privacy practices please contact us at
                        the appropriate address below. We will attempt to
                        respond to your requests and to provide you with
                        additional privacy-related information.
                        Privacy@investor.edyou.com Updated Date: November 1, 2023 Upon
                        request, residents of the EEA have the right to access
                        the information we process about you, and to request
                        that we correct, modify, or erase that information. You
                        also have the right to opt out of or restrict certain
                        types of processing subject to applicable legal
                        restrictions. If you have any questions or you would
                        like to exercise any of these rights, please reach out
                        to us at</p>
                    <p>privacy@investor.edyou.com</p>
                    <p>Updated Date: November 1, 2023</p>
                </div>
            </div>
        </div>
    </div>
</div>
</div>