import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
// import { UserService } from '../service/user.service';
import { ChangeDetectorRef } from '@angular/core';
import { UserService } from '../service/user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UneeqavatarComponent } from '../uneeqavatar/uneeqavatar.component';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit , AfterViewInit {
  videoLinks: string[] = [
    'https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/no-volume-ever-intro-vid.mp4',
    'https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/dr-emma-meet-ai-tutors.mp4',
    'https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/eddie-meet-ai-tutors.mp4',
  ];
  @ViewChild('backgroundVideo') backgroundVideo!: ElementRef<HTMLVideoElement>;
  isModalOpen: boolean = false;
  isloading: boolean = false;
  isLoading3: boolean = false;
  currentVideoIndex = 0;
  isLoading2: boolean = false;
  counter: any = 1;
  plan: string | null = null;
  private isBrowser: boolean =false;
  storageListener: any;
  @ViewChild('video', { static: true }) videoElem!: ElementRef<HTMLVideoElement>;
  @ViewChild('videoPlayer', { static: true }) videoPlayer:
    | ElementRef<HTMLVideoElement>
    | any;
  @ViewChild(UneeqavatarComponent) uneeqAvatar!: UneeqavatarComponent;
  b2bplan: boolean = false;
  
  rotatingTextIndex: number = 0;

  rotatingTexts = [
    'Personalized Learning',
    'Test Prep',
    'Professional Certifications',
    'Sports Learning'
  ];

  nextRotatingText(): void {
    this.rotatingTextIndex = (this.rotatingTextIndex + 1) % this.rotatingTexts.length;
  }

  constructor(@Inject(PLATFORM_ID) private platformId: Object,private router: Router, private service: UserService,private cd: ChangeDetectorRef,private el: ElementRef) {}

  ngOnInit(): void {
    this.isBrowser = isPlatformBrowser(this.platformId);
    const videoIds = ['#backgroundVideo', '#laptop', '#videoPlayer'];
    videoIds.forEach((id) => {
      const videoElement = this.el.nativeElement.querySelector(id) as HTMLVideoElement;
      if (videoElement) {
        videoElement.muted = true; // Required for autoplay in modern browsers
        videoElement.playsInline = true; // For mobile devices
        videoElement.autoplay = true;
    
        videoElement
          .play()
          .then(() => {
            console.log(`Video ${id} is playing.`);
          })
          .catch((error) => {
            console.error(`Video playback failed for ${id}:`, error);
          });
      } else {
        console.error(`Video element not found for selector: ${id}`);
      }
    });
  
    this.openForm = !this.openForm;
    setInterval(() => {
      this.nextRotatingText();
    }, 3500);
    // Initial fetch from local storage
   
    this.service.loggedIn$.subscribe(state => {
      // this.checkUserLogin()
      const storedUserData = localStorage.getItem('user');
      if (storedUserData) {
        this.plan = localStorage.getItem('subscription');
        // this.updatedData = JSON.parse(storedUserData);
        // console.log(this.updatedData, this.updatedData, 'all user infor', this.updatedData.subscription)
        // this.userName = this.updatedData.f_name + " " + this.updatedData.l_name;

      
      
        //   this.openSuccessPopup = false
        // },2000)
     
        this.cd.detectChanges();

    // Listen for changes in local storage
   
    this.storageListener = this.handleStorageEvent.bind(this);
    if(this.isBrowser){ 
    window.addEventListener('storage', this.storageListener);
    }

    let subscribed = JSON.parse(localStorage.getItem('subscription') || '{}');
    //  let subscribed = localStorage.getItem('subscription')
    let b2bpan = localStorage.getItem('plan_b2b') === 'true';
    this.b2bplan = b2bpan;
    if (b2bpan) {
      this.isModalOpen = true;
    } else {
      let subscribed = JSON.parse(localStorage.getItem('subscription') || '{}');
      console.log(subscribed, subscribed.length, 'susbcription');

      if (subscribed) {
        //  console.log('goinin')
        if (subscribed.length > 0) {
          //  this.subscribedbutton = true
          console.log('goinin');
          this.isModalOpen = true;
          // this.subscribedbutton  = truesub
        } else {
          // this.subscribedbutton = false
          this.isModalOpen = false;
          //this.subscribedbutton  = false
        }
      }
    }
   
    
  }
  })

 
  
  }

  onPause(event: Event): void {
    const video: HTMLVideoElement = this.videoElem.nativeElement;
  
    // Check if the video has not ended and is not in a playing state
    if (!video.ended && video.paused) {
      console.log('Video paused or stuck - resuming playback...');
      video.play().catch((err) => {
        console.error('Error resuming playback:', err);
      });
    }
  }
  
  // Handle potential cases where the video might get stuck
  onWaiting(event: Event): void {
    const video: HTMLVideoElement = this.videoElem.nativeElement;
    console.log('Video is buffering - trying to resume playback...');
    video.play().catch((err) => {
      console.error('Error resuming playback during buffering:', err);
    });
  }
  
  onError(event: Event): void {
    console.error('An error occurred with the video element:', event);
  }

  ngAfterViewInit() {
    // const videoElement:any = document.querySelector('.background-video');

    // videoElement.addEventListener('canplay', () => {
    //   videoElement.play().catch((err:any) => {
    //     console.error('Video playback failed:', err);
    //   });
    // });
    
    // // Optional: Restart video on `pause` if it wasn't intentional
    // videoElement.addEventListener('pause', () => {
    //   videoElement.play();
    // });

 
      const video:any = document.getElementById('backgroundVideo');
      if (video) {
        video.play();  // Force play the video
      }
 
  }
 
  
  
  handleStorageEvent() {
    let subscribed = JSON.parse(localStorage.getItem('subscription') || '{}');
    let b2bpan = localStorage.getItem('plan_b2b') === 'true';
    let  b2cpan =localStorage.getItem('plan_type')  === 'true';
    console.log('b2bpan', b2bpan);
    if (b2bpan ) {
      this.isModalOpen = true;
    } else {
      if (subscribed) {
        //  console.log('goinin')
        if (subscribed.length > 0) {
          //  this.subscribedbutton = true
          console.log('goinin');
          this.isModalOpen = true;
          // this.subscribedbutton  = truesub
        } else {
          // this.subscribedbutton = false
          this.isModalOpen = false;
          //this.subscribedbutton  = false
        }
      }
    }
    //  console.log(subscribed, 'ggddfffsfsfsf');

    // Parse JSON if it's not null
    let parsedSubscribed = subscribed ? JSON.parse(subscribed) : '';

    // if (parsedSubscribed && Array.isArray(parsedSubscribed) && parsedSubscribed.length > 0) {
    //   console.log('Going inside because array is not empty');
    //   // this.subscribedbutton = true;
    //   if(this.counter == 1){
    //     this.isModalOpen = true;
    //     }
    //     this.counter = 2;
    //  //this.isModalOpen = true;
    // } else {
    //   console.log('Either empty array or no subscription');
    //   // this.subscribedbutton = false;
    //   this.isModalOpen = false;
    // }
  }
  nextVideo() {
    if (this.currentVideoIndex < this.videoLinks.length - 1) {
      this.currentVideoIndex++;
    } else {
      this.currentVideoIndex = 0;
    }
    this.updateVideoSource();
  }

  previousVideo() {
    if (this.currentVideoIndex > 0) {
      this.currentVideoIndex--;
    } else {
      this.currentVideoIndex = this.videoLinks.length - 1;
    }
    this.updateVideoSource();
  }

  updateVideoSource() {
    const videoElement = this.videoPlayer.nativeElement;
    videoElement.src = this.videoLinks[this.currentVideoIndex];
    videoElement.load();
    videoElement.play();
  }

  navigateToAIAvatar() {
    this.router.navigate(['/AI_Tutor']);
  }

  navigateToEI() {
    this.router.navigate(['/educational-institute']);
  }
  navigateToES() {
    this.router.navigate(['/enterprise-solutions']);
  }
  isLoading: boolean = false;
  displayuneeqcomponent: boolean = false;
  displaymsg: any;
  openForm: boolean = false;
  triedcard: boolean = false;
  closedpptbutton: boolean = false;
  allmendatoryfield: boolean = false;
  hideparentemail: boolean = false;

  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    f_name: new FormControl('', [Validators.required]),
    // l_name: new FormControl(''),
    // phone: new FormControl(''),
    // P_email : new FormControl('',[Validators.email]),
    // isOver13: new FormControl(false),
    // termsAccepted: new FormControl(false, [Validators.requiredTrue])
  });

  // validation  controls for form
  get email() {
    return this.form.controls['email'];
  }

  get f_name() {
    return this.form.controls['f_name'];
  }

  get l_name() {
    return this.form.controls['l_name'];
  }
  get phone() {
    return this.form.controls['phone'];
  }
  get P_email() {
    return this.form.controls['P_email'];
  }
  get isOver13() {
    return this.form.controls['isOver13'];
  }

  get termsAccepted() {
    return this.form.controls['termsAccepted'];
  }

  validateAllFormFields(formGroup: FormGroup) {
    // Iterate over each field in the form group
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      // Check if the control is an individual form control
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        // Check if the control is a nested form group
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  hasError(): boolean {
    return (
      (this.form.get('f_name')?.touched && this.form.get('f_name')?.invalid) ||
      false ||
      (this.form.get('email')?.touched && this.form.get('email')?.invalid) ||
      false
    );
  }

  closebutton() {
    this.openForm = !this.openForm;
    this.form.reset();
  }
  backtomainpage() {
    this.router.navigate(['/']);
  }

  gotoTermsaPage() {
    this.router.navigate(['/terms']);
  }

  termsclicked() {
    if (this.form.get('termsAccepted')?.value) {
      this.allmendatoryfield = true;
    } else {
      this.allmendatoryfield = false;
    }
  }

  over13year() {
    if (this.form.get('isOver13')?.value) {
      this.allmendatoryfield = true;
      this.hideparentemail = false;
    } else {
      this.allmendatoryfield = false;
      this.hideparentemail = true;
    }
  }

  startSession() {
    // Retrieve the values of isOver13 and P_email controls
    const isOver13 = this.form.get('isOver13')?.value;
    const parentEmailControl = this.form.get('P_email');

    // Set or clear validators based on the isOver13 value
    if (!isOver13) {
      // Under 13: make P_email required
      parentEmailControl?.setValidators([
        Validators.required,
        Validators.email,
      ]);
    } else {
      // Over 13: clear validators
      parentEmailControl?.clearValidators();
      this.allmendatoryfield = false;
    }
    // Update validity status
    parentEmailControl?.updateValueAndValidity();

    if (this.form.valid) {
      this.allmendatoryfield = false;

      let payload = {
        name: this.form.value.f_name,
        email: this.form.value.email,
        // Phone:this.form.value.phone,
        // parentemail:this.form.value.P_email,
        // agelimit:this.form.value.isOver13,
        // termscondition:this.form.value.termsAccepted,
      };
      console.log(payload);
      this.isLoading = true;
      this.service
        .new_Avatar_demo_video_details(payload)
        .subscribe((res: any) => {
          if (res.statusCode == 200) {
            this.isLoading = false;
            localStorage.setItem('name', JSON.stringify(payload));
            localStorage.setItem('user', JSON.stringify(res.data));
            this.closebutton();
            this.displayuneeqcomponent = true;

            setTimeout(() => {
              this.uneeqAvatar.startAvatarFunction();
            }, 300);
          } else if (res.statusCode == 201) {
            this.isLoading = false;
            // this.displaymsg = res.body
            this.openForm = false;
            this.triedcard = true;
          } else {
            console.log('else');
            this.isLoading = false;
            this.displayuneeqcomponent = true;
          }
        });
    } else {
      this.validateAllFormFields(this.form);
      this.allmendatoryfield = true;
    }
  }

  closeuneeqcomponent() {
    this.displayuneeqcomponent = false;
    this.uneeqAvatar.uneeq.endSession();
    console.log('redirect to main page-----------------------------');
    this.openForm = false;
    this.triedcard = true;
  }

  closepptclicked() {
    console.log('playpptcalled');
    this.displayuneeqcomponent = false;
    this.uneeqAvatar.uneeq.endSession();
    this.closedpptbutton = true;
  }
  closeviewallcard() {
    this.closedpptbutton = false;
    // this.openForm = true
    this.router.navigate(['/']);
  }
  goforsubscribe() {
    this.router.navigate(['/course']);
    // this.router
    //   .navigate(['/course'], { fragment: 'course_page' })
    //   .then(() => {
    //     setTimeout(() => {
    //       const element = document.getElementById('course_page');
    //       if (element) {
    //         element.scrollIntoView({ behavior: 'smooth' });
    //       }
    //     }, 100);
    //   });
  }

  closeModal() {
    this.isModalOpen = false;
  }
  navigateToProfile() {
    //this.menuToggle()
    // this.sc=true
    let url1;
    url1 = localStorage.getItem('url');
    if (url1 && url1.includes('/sc')) {
      this.router.navigate(['/SC/profile']);
    } else {
      this.router.navigate(['/profile']);
    }
  }
  linkToedyouUser() {
    let user = localStorage.getItem('user');
    if (user) {
      // Parse the string back into a JavaScript object
      let user1 = JSON.parse(user);

      // Now you can access the 'link' property
      console.log(user1.link, 'urltomove');
      // const userLink: string = user1.link;

      // If you want to navigate, uncomment this:
      if(this.isBrowser){
      window.location.href = user1.link;
      }
    } else {
      if(this.isBrowser){
      window.location.href = 'https://smdev.edyou.com/';
      }
    }

    console.log(user);
    // if (user) {
    //   // Parse the string back into a JavaScript object
    //  let user1 = JSON.parse(user);

    // Now you can access the 'link' property
    //  console.log(user1.link, 'urltomove', this.user);
    // const userLink: string = user1.link;

    // If you want to navigate, uncomment this:

    //   this.service.getIpAdress().subscribe((f: any) => {
    //     console.log(f)
    //     this.IPv4 = f.IPv4
    //     localStorage.setItem('IPAdress', this.IPv4)
    //   })
    //   // this.actRouter.queryParams.subscribe((queryParams) => {
    //   //   this.newEmail = queryParams['email'] || null;
    //   //   var newPassword = queryParams['password'] || null;
    //   //   if (newPassword) {
    //   //     this.newPassword = newPassword.split("___")
    //   //   }
    //   // })

    //     // Collect user data
    // var browserName = this.getBrowserName();
    // var deviceType = this.getDeviceType();
    // let DeviceInfo = {
    //   browser: browserName,
    //   device: deviceType
    // };
    // let loginPayload = {
    //   "email":  user1.email,
    //   "password": user1.password,
    //   "policy": true,
    //   "Ipaddress": this.IPv4,
    //   "device_info":DeviceInfo,
    //   "avatar":"NOVA"
    // }
    // this.isLoading2 = true
    // let url  = localStorage.getItem('appurl');
    // this.service.appLogin(loginPayload).subscribe(
    //   (data :any) =>  { if (data.statusCode == 200) {
    //     this.isLoading2 = false
    //     if(url){
    //     window.location.href = url;
    //     }else{
    //       alert('there is some issue')
    //     }
    // }else {
    //   this.isLoading2 = false
    //   alert(data.body)
    //   this.closeModal()
    // }})
    // }else{
    //   window.location.href= 'https://smdev.edyou.com/'
    // }
  }
  togglePlay(videoElement: HTMLVideoElement): void {
    if (videoElement.paused) {
      videoElement.play();
    } else {
      videoElement.pause();
    }
  }

  ensureMuted(videoPlayer: HTMLVideoElement) {
    videoPlayer.muted = true; 
  }
}
