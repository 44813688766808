<div data-elementor-type="single-page" data-elementor-id="21477"
    class="maintermscontainer "
    data-elementor-post-type="elementor_library">
    <div
        class="has_ae_slider elementor-element elementor-element-a98c644 light bottom-p e-flex e-con-boxed ae-bg-gallery-type-default e-con e-parent"
        data-id="a98c644" data-element_type="container"
        data-settings="{&quot;content_width&quot;:&quot;boxed&quot;}"
        data-core-v316-plus="true">
        <div class="e-con-inner">
            <div
                class="elementor-element elementor-element-6ff1770 elementor-widget elementor-widget-heading"
                data-id="6ff1770" data-element_type="widget"
                data-widget_type="heading.default">
                <div class="elementor-widget-container">
                    <style>/*! elementor - v3.19.0 - 07-02-2024 */
                    .elementor-heading-title{padding:0;margin:0;line-height:1}.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a{color:inherit;font-size:inherit;line-height:inherit}.elementor-widget-heading .elementor-heading-title.elementor-size-small{font-size:15px}.elementor-widget-heading .elementor-heading-title.elementor-size-medium{font-size:19px}.elementor-widget-heading .elementor-heading-title.elementor-size-large{font-size:29px}.elementor-widget-heading .elementor-heading-title.elementor-size-xl{font-size:39px}.elementor-widget-heading .elementor-heading-title.elementor-size-xxl{font-size:59px}</style><h1
                        class="elementor-heading-title elementor-size-default">Terms
                        of Use</h1> </div>
            </div>
            <div
                class="elementor-element elementor-element-2984f07 elementor-widget elementor-widget-theme-post-content"
                data-id="2984f07" data-element_type="widget"
                data-widget_type="theme-post-content.default">
                <div class="elementor-widget-container">
                    <p>Welcome to the website of edYOU Technologies Inc. PLEASE
                        READ THESE TERMS OF USE CAREFULLY BEFORE ACCESSING OR
                        USING THIS WEBSITE.</p>
                    <p>EdYOU Technologies Inc., a Delaware corporation doing
                        business as “edYOU” (“edYOU Technologies”, “edYOU,” “us”
                        or “we”) owns and operates this website and any other
                        web services or products offered by us now or in the
                        future. By accessing, using, downloading, viewing this
                        website through any of the URLs including, investor.edyou.com, or
                        any of its services, or Content (defined below)
                        (collectively, the “Site”), you hereby consent and agree
                        to these terms and conditions (“Terms of Use”). The
                        Terms of Use and Privacy Policy (collectively, the
                        “Terms”) govern your use of the Site, use and access of
                        other free materials and resources provided by the Site,
                        and any other services or Products we make available on
                        this Site (collectively, the “Services”). These Terms
                        constitute a legally binding agreement made by and
                        between EdYOU and the user of this Site (personally and,
                        if applicable, on behalf of the entity for whom you are
                        using the Site; collectively, “you”).</p>
                    <p>BY ACCESSING OR USING ANY PART OF THE SITE OR SERVICES,
                        YOU AGREE THAT YOU HAVE READ, UNDERSTOOD AND AGREED TO
                        BE BOUND BY THESE TERMS, WHICH CONTAIN A TERMS OF SALE
                        AGREEMENT, AN ARBITRATION AGREEMENT, A WAIVER OF
                        CLASS-ACTION RIGHTS, AND LIABILITY LIMITATIONS.</p>
                    <p>If you do not agree to these Terms and to follow all
                        applicable laws, then please cease access or use of the
                        Site and Services immediately.</p>
                    <p>If you have any questions about these Terms, please
                        contact us by email at privacy@investor.edyou.com.</p>
                    <p>If you access any EdYOU Site on a social media network
                        (such as, without limitation, Facebook, Twitter,
                        Instagram, or Pinterest), you also consent to and agree
                        to abide by the terms and conditions of that social
                        media network.</p>
                    <h3>1. Content on Our Site</h3>
                    <p><strong>Intellectual Property Rights.</strong> You agree
                        that the Site itself, as well as all content,
                        photographs, sound or videos, media, images, formulas,
                        graphics, webinars, training materials, products,
                        services and/or other information and materials, and
                        selection and arrangements thereof is copyrighted work
                        under the United States and other copyright laws, and is
                        the property of or licensed by EdYOU, made available on
                        the Site or any information, materials, and content
                        transferred via a downloadable file or link by us or
                        other third parties (collectively the “Content”). All
                        trademarks, service marks, and trade names
                        (collectively, the “Marks”) are trademarks or registered
                        trademarks of and are proprietary to EdYOU or other
                        respective owners that have granted EdYOU the right to
                        use such Marks. Subject to your compliance with these
                        Terms, we hereby grant you a limited, non-exclusive,
                        non-transferable, and non-sublicensable, and revocable
                        right to access, view, and use the Site solely for your
                        personal purposes and non-commercial use, and as we
                        otherwise intend. EdYOU reserves the right to monitor
                        the Services and Content for the purpose of determining
                        that your usage complies with these Terms.</p>
                    <p>You may not modify, publish, transmit, participate in the
                        transfer or sale of, reproduce, create derivative works
                        of, distribute, publicly perform, publicly display, or
                        in any way exploit any of the materials or Content on
                        our Site in whole or in part, other that as necessary
                        for your own personal non-commercial use, without our
                        written consent. Modification of the Content or use of
                        the Content for any other purpose is a violation of the
                        copyright and other proprietary rights of EdYOU, as well
                        as other authors who created the materials, and may be
                        subject to monetary damages and penalties.</p>
                    <p><strong>Third-Party Content.</strong> Our Site contains
                        Content that we create as may also include Content
                        provided by third parties. We do not monitor, we do not
                        endorse, and we are not liable for any third-party
                        content. There may be some inadvertent accuracies or
                        errors in the content and we do not guarantee the
                        accuracy, integrity, completeness or quality of the
                        content on our Site or located at third party URLs that
                        may be posted on our Site. EdYOU is not responsible or
                        the content on any linked site or any link contained in
                        a linked site. We do not endorse or accept
                        responsibility for the content of such third-party
                        sites.</p>
                    <p><strong>Third-Party Services.</strong> Third parties may
                        offer their services directly to you through the Site.
                        In such case, you may be required to agree to the third
                        party’s terms of service and/or privacy policy to use
                        the service. EdYOU will not be liable in any way for the
                        acts or omissions of such third party, the terms of
                        service or privacy policy or its failure to adhere to
                        its terms of services or privacy policy, or any loss,
                        damages, liability or expenses (including attorneys’
                        fees) that you may incur arising from or related to such
                        third party’s services or products.</p>
                    <h3>2. Your Conduct on Our Site</h3>
                    <p><strong>Eligibility.</strong> To use the Site, you must
                        be, and represent and warrant that you are, of legal age
                        (18 years of age or older) and competence. By using the
                        Site on behalf of any third party, you are representing
                        to us that you are an authorized representative of that
                        third party and that your use of the Site constitutes
                        that third party’s acceptance of these Terms. In
                        addition, if you have been previously prohibited from
                        accessing the Site or the website of any of our
                        affiliates, you are not permitted to access the
                        Site.</p>
                    <p><strong>Account Information.</strong> If you create an
                        account, any information that you choose to provide us
                        must be true, accurate, current, and complete. You are
                        entirely responsible for maintaining the confidentiality
                        of your password and account. You are entirely
                        responsible for any and all activities that occur under
                        your account. Your account is non-transferrable. You
                        cannot sell, combine, or otherwise share it with any
                        other person. Any violation of these Terms, including,
                        without limitation, failure to maintain updated and
                        correct information about your account or cause your
                        account to fall out of good standing and we may cancel
                        your account in our sole discretion. When you register
                        with EdYOU and/or this Site, you expressly consent to
                        receive any notices, announcements, agreements,
                        disclosures, reports, documents, communications
                        concerning new products or services, or other records or
                        correspondence from EdYOU.</p>
                    <p><strong>Feedback.</strong> If you send comments or
                        suggestions about the Site to EdYOU, including, but not
                        limited to, notes, text, drawings, images, designs or
                        computer programs, such submissions shall become, and
                        shall remain, the sole property of EdYOU. No submission
                        shall be subject to any obligation of confidence on the
                        part of EdYOU. EdYOU shall exclusively own all rights to
                        (including intellectual property rights thereto), and
                        shall be entitled to unrestricted use, publication, and
                        dissemination as to all such submissions for any
                        purpose, commercial or otherwise without any
                        acknowledgment or compensation to you.</p>
                    <p><strong>Prohibited Conduct.</strong> You agree not to
                        copy or imitate the appearance, design or style of our
                        Site or any Content. The technology and software
                        underlying our Site and the Services are the property of
                        EdYOU, our affiliates and/or our business partners. You
                        agree that you will not use our Site or its Content to
                        take any of the following actions:</p>
                    <ul>
                        <li>Defame, abuse, harass, stalk, threaten, or otherwise
                            violate the legal rights of EdYOU or any other
                            person or entity;</li>
                        <li>Use the Service or Site commercially;</li>
                        <li>Reverse engineer, decompile, tamper with or
                            disassemble the technology used to provide the
                            Services or Site (except as and only to the extent
                            any foregoing restriction is prohibited by a
                            non-waivable provision of applicable law);</li>
                        <li>Interfere with or damage the Services, Site, or
                            underlying any technology;</li>
                        <li>Impersonate or misrepresent your identity or
                            affiliation;</li>
                        <li>Attempt to obtain unauthorized access to the
                            Services or Site;</li>
                        <li>Violate, misappropriate or infringe a third party’s
                            intellectual property or other right, or any
                            social<br />
                            media platform terms;</li>
                        <li>Violate any law, rule, or regulation;</li>
                        <li>Transmit executable programming or corrupted files
                            of any kind, including viruses, spyware,
                            trojan<br />
                            horses, Easter eggs or any other similar software or
                            programs that may damage or adversely affect the
                            operation of another person&#8217;s computer, our
                            Site, software or hardware, third party websites or
                            telecommunications equipment;</li>
                        <li>Falsify or delete any author attributions, legal or
                            other proper notices or proprietary designations or
                            labels of the origin or the source of software
                            posted or contributed content or other material;
                            or</li>
                        <li>Engage in any illegal activities.</li>
                    </ul>
                    <p>Use of our Site is subject to existing laws and legal
                        process. Nothing contained in these Terms will limit our
                        right to comply with governmental, court, and
                        law-enforcement requests or requirements relating to
                        your use of our Site.</p>
                    <h3>3. Privacy Policy</h3>
                    <p>All of the information that we collect from you, such as
                        your e-mail address and related personal information and
                        credit card information, is subject to our privacy
                        policy. EdYOU’s privacy policy (which describes how we
                        collect, use and disclose your data and your consent to
                        such collection, use, and disclosure) is incorporated
                        into and is a part of these Terms.</p>
                    <h3>4. DCMA Copyright Infringement Takedown Policy</h3>
                    <p><strong>Infringement Notification.</strong> EdYOU
                        respects the rights of others and we expect users of our
                        Site and Services to do the same. These Terms prohibit
                        the infringement of the copyrights of others, and it is
                        also our policy that we may remove, suspend, terminate
                        access, or take other appropriate action against repeat
                        offenders. We may also remove content that in our sole
                        discretion appears to infringe the intellectual property
                        rights of others.</p>
                    <p><strong>How to File an Infringement
                            Notification.</strong> If you have evidence, know,
                        or have a good faith belief that content residing on or
                        accessible through our Site infringes a copyright which
                        you own or for which you are a designated agent, please
                        send a notice of infringement by email to EdYOU:</p>
                    <p><strong>EdYOU Technologies</strong><br />
                        Email Address: privacy@investor.edyou.com.</p>
                    <p>Send such notice with the information that sets forth the
                        items specified below:</p>
                    <ul>
                        <li>Identify the copyrighted work claimed to have been
                            infringed. If multiple copyrighted works are covered
                            by a single notification, provide a representative
                            list of such works.</li>
                        <li>Identify the material that is claimed to be
                            infringing or to be the subject of infringing
                            activity. Include information reasonably sufficient
                            to permit EdYOU to locate the material. Please
                            provide a URL and screenshots for each item. Include
                            the specific asset(s) or page(s) that you claim to
                            be infringing. Say<br />
                            “entire work” ONLY if all assets/pages in a
                            collection/document are infringing.</li>
                        <li>Include details of your claim to the material, or
                            your relationship to the material’s copyright
                            holder.</li>
                        <li>Provide your full name, address, and telephone
                            number should we need to clarify your claim.</li>
                        <li>Provide a working email address where we can contact
                            you to confirm your claim.</li>
                        <li>If true, include the following statement: “I have a
                            good faith belief that use of the copyrighted
                            materials described above as the allegedly
                            infringing web pages is not authorized by the
                            copyright owner, its agent, or the law.”</li>
                        <li>If true, include the following statement: “I swear,
                            under penalty of perjury, that the information in
                            the notification is accurate and that I am the
                            copyright owner or am authorized to act on behalf of
                            the copyright owner to make this complaint.”</li>
                        <li>Sign the document, physically or
                            electronically.</li>
                    </ul>
                    <p>ANY NOTICE THAT DOES NOT COMPLY WITH THE REQUIREMENTS OF
                        TITLE 17, UNITED STATES CODE, SECTION 512(c)(3) WILL NOT
                        RECEIVE A RESPONSE. NOTHING IN THIS POLICY IS INTENDED
                        TO EXPAND OR SUPPLEMENT THE LEGAL RIGHTS, PROCEDURES AND
                        REMEDIES AUTHORIZED AND GRANTED UNDER THE DMCA. Please
                        note that you may be liable for damages, including but
                        not limited to costs and attorneys’ fees, under the DMCA
                        if you knowingly materially misrepresent: (a) that
                        material on the Site infringes upon your copyright; or
                        (b) that material on the Site was removed or disabled by
                        mistake or misidentification. If a user is found to be
                        an infringer of the copyright rights of others, EdYOU
                        may terminate access to the user’s account.</p>
                    <h3>A. TERMS OF SALE FOR PRODUCTS</h3>
                    <p>The following terms apply to your purchase of the
                        products offered on the Site (“Products”).</p>
                    <p><strong>Product Descriptions.</strong> We try to make the
                        Site thorough, accurate, and helpful to our customers.
                        Nonetheless, there may be times when certain information
                        contained on the Site may be incorrect, incomplete,
                        inaccurate, or appear inaccurate because of the browser,
                        hardware, or other technology that you use. We apologize
                        in advance for any such errors that may result in an
                        incorrect price, inaccurate description, item
                        unavailability or otherwise affect your order. We
                        reserve the right to correct errors (whether by changing
                        information on the Site or by informing you of the error
                        and giving you an opportunity to cancel your order) or
                        to update Product information at any time without
                        notice.</p>
                    <p><strong>Availability and Pricing.</strong> EdYOU reserves
                        the right to change the prices and available Products at
                        any time. Quantities of some Products may be limited and
                        stock cannot always be guaranteed. Products offered for
                        sale on this Site are for sale only in the United States
                        and all prices are quoted in U.S. dollars. We may
                        occasionally make errors in the stated prices on this
                        Site. If a product’s correct price is higher than the
                        listed price, we will, in our discretion, either confirm
                        the correct price with you or cancel your order and
                        notify you of such cancellation.<br />
                        Purchasing Products. The display of Products on the Site
                        invites you to make us an offer to buy the Products.
                        Your order is an offer to buy the Products, which we
                        accept only by shipping the Products ordered. Any
                        confirmation that you receive after placing an order
                        does not constitute an acceptance of your offer and is
                        subject to correction before shipment in the event of
                        inaccuracies, errors, Product unavailability, or for any
                        other reason.</p>
                    <p><strong>Orders.</strong> We have the right to refuse or
                        limit any orders and limit quantities. We will not be
                        liable if a Product is unavailable. All orders are
                        non-cancelable; we may grant or deny cancellation
                        requests in our sole and absolute discretion. We reserve
                        the right to provide substantially similar products to
                        fulfill your order.</p>
                    <p><strong>Returns.</strong> We do not allow returns or
                        substitutions of Products. All sales are final.</p>
                    <p><strong>Product Issues.</strong> If you are otherwise
                        unhappy with any Product, please e-mail
                        privacy@investor.edyou.com.) Any are made solely in our
                        discretion.</p>
                    <p><strong>Payment.</strong> You authorize us (and any
                        payment processor) to charge your payment card for all
                        purchases you make. We accept the forms of payment
                        stated on the Site and, for credit card payments, charge
                        your credit card when your order is processed. The bank
                        issuing your credit card may control when to release
                        funds in the case of an order cancellation or refund. We
                        reserve the right to use the payment information you
                        provide us in connection with this payment to provide
                        better service to you should you wish to use our service
                        again in the future and to protect us from fraud and
                        other losses. Completion of a payment transaction is
                        contingent upon: (a) you providing complete personal,
                        account, transaction and any other information needed,
                        (b) authorization of the payment by your credit or debit
                        card company, and (c) acceptance of your payment. You
                        may cancel your payment prior to your final submission
                        of it to us. We may, in our sole discretion, cancel your
                        payment at any time by providing notice to you through
                        your contact information or by a notice when you attempt
                        to make a payment. We may cancel a payment or prevent
                        you from initiating future payments for any reason,
                        including, without limitation, the following: (i) if you
                        attempt to use the Services in breach of any applicable
                        law or regulation, including the card network rules or
                        regulations; (ii) if you use the Services in breach of
                        these Terms; (iii) if we suspect fraudulent, unlawful or
                        improper activity regarding a payment; (iv) if we
                        detect, in our sole discretion, that your payments have
                        excessive disputes, high reversal rates or present a
                        relatively high risk of losses; or (v) failure to
                        cooperate in an investigation or provide additional
                        information when requested.</p>
                    <p><strong>Taxes.</strong> Stated prices do not include any
                        customs duties, sales, use, value-added, excise,
                        federal, state, local or other taxes. You are solely
                        responsible for the payment of such taxes related to
                        your purchase. We have the right to charge you for any
                        taxes that we believe we are required to pay or collect
                        related to your purchase.</p>
                    <p><strong>Personal Use Only.</strong> Products are for your
                        personal use only. You agree not sell or resell any
                        products you purchase. Except where prohibited by law,
                        we may limit the quantity of Products available for
                        purchase. We reserve the right, with or without notice,
                        to cancel or reduce the quantity of any order to be
                        filled or products to be provided to you that may result
                        in a violation of these Terms, as we determine in our
                        sole discretion.</p>
                    <p>Questions? If you have questions, please contact us
                        at:</p>
                    <p>EdYOU Technologies, Inc.<br />
                        11766 Wilshire Boulevard<br />
                        Suite 405<br />
                        Los Angeles, CA 90025</p>
                    <p>privacy@investor.edyou.com</p>
                    <h3>5. Disputes</h3>
                    <p>Subject to applicable law, you and EdYOU agree that any
                        dispute that has arisen or may arise between us relating
                        in any way to your use of or access to the Services or
                        Site, any breach, enforcement, or termination of these
                        Terms, or otherwise relating to EdYOU in any way will be
                        resolved in accordance with the provisions set forth in
                        this Section.</p>
                    <p><strong>Informal Resolution.</strong> If you have any
                        dispute with us, you agree that before taking any formal
                        action, you will contact us at privacy@investor.edyou.com,
                        provide a brief, written description of the dispute and
                        your contact information (including your username, if
                        your dispute relates to an account) and allow sixty (60)
                        days to pass, during which we will attempt to reach an
                        amicable resolution of any issue.</p>
                    <p><strong>Arbitration.</strong> These Terms and each of its
                        parts evidence a transaction involving interstate
                        commerce, and the Federal Arbitration Act applies in all
                        cases and governs the interpretation and enforcement of
                        the arbitration rules and arbitration proceedings. Any
                        Covered Matters must be asserted individually in binding
                        arbitration administered by the American Arbitration
                        Association (“AAA”) in accordance with its Commercial
                        Arbitration Terms and the AAA Supplementary Procedures
                        for Consumer-Related Disputes (including, without
                        limitation, utilizing desk, phone or video conference
                        proceedings where appropriate and permitted to mitigate
                        costs of travel). The arbitrator shall not conduct any
                        form of class or collective arbitration nor join or
                        consolidate claims by or for individuals. The
                        arbitrator, and not any federal, state, or local court
                        or agency, shall have exclusive authority to resolve any
                        dispute relating to the interpretation, applicability,
                        enforceability or formation of these Terms, including,
                        but not limited to, any claim that all or any part of
                        these Terms is void or voidable or a particular claim is
                        subject to arbitration. Judgment on the award rendered
                        by the arbitrator may be entered in any court of
                        competent jurisdiction.</p>
                    <p><strong>Award.</strong> For matters where the relief
                        sought is over $5,000, the arbitrator’s decision will
                        include the essential findings and conclusions upon
                        which the arbitrator based the award. The arbitrator
                        will decide the substance of all claims in accordance
                        with applicable law, including recognized principles of
                        equity, and will honor all claims of privilege
                        recognized by law. The arbitrator shall not be bound by
                        rulings in prior arbitrations involving different users
                        but is bound by rulings in prior arbitrations involving
                        the same EdYOU user to the extent required by applicable
                        law. The arbitrator’s award shall be final and binding
                        and judgment on the award rendered by the arbitrator may
                        be entered in any court having jurisdiction thereof. THE
                        ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY,
                        INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE
                        INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT
                        NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY’S
                        INDIVIDUAL CLAIM(S). ANY RELIEF AWARDED CANNOT AFFECT
                        OTHER USERS.</p>
                    <p><strong>Exceptions.</strong> There are only two
                        exceptions to this arbitration agreement:</p>
                    <p>First, if a party reasonably believes that the other
                        party has in any manner infringed or threatened to
                        infringe the intellectual property rights of the other
                        party, the party who owns the intellectual property
                        rights may seek injunctive or other appropriate interim
                        relief in any court of competent jurisdiction.</p>
                    <p>Second, any claim of $500 or less may, at the option of
                        the claiming party, be resolved in small claims court
                        within the United States, if the claim and the parties
                        are within the jurisdiction of the small claims court
                        and so long as the matter remains in such court and
                        advances only on an individual (non-class, non-
                        representative) basis.</p>
                    <p><strong>Costs of Arbitration.</strong> Payment of all
                        filing, administration, and arbitrator fees will be
                        governed by the AAA’s rules, unless otherwise stated in
                        this agreement to arbitrate. If the value of the relief
                        sought is $5,000 or less, at your request, EdYOU will
                        reimburse you for all filing, administration, and
                        arbitrator fees associated with the arbitration
                        following the earlier of the arbitrator’s decision or
                        settlement. In the event the arbitrator determines the
                        claim(s) you assert in the arbitration to be frivolous,
                        EdYOU is relieved of its obligation to reimburse you for
                        any fees associated with the arbitration.</p>
                    <p><strong>Future Amendments to the Agreement to
                            Arbitrate.</strong> Notwithstanding any provision in
                        the Terms to the contrary, you and we agree that if we
                        make any amendment to this agreement to arbitrate in the
                        future, that amendment shall not apply to any claim that
                        was filed in a legal proceeding against EdYOU prior to
                        the effective date of the amendment. The amendment shall
                        apply to all other disputes or claims governed by the
                        agreement to arbitrate that have arisen or may arise
                        between you and EdYOU. If you do not agree to these
                        amended terms, you may close your account within thirty
                        (30) days of the posting or notification and you will
                        not be bound by the amended terms.</p>
                    <p><strong>Judicial Forum for Legal Disputes.</strong>
                        Unless you and EdYOU agree otherwise, in the event that
                        the agreement to arbitrate above is found not to apply
                        to you or to a particular claim or dispute, either as a
                        result of your decision to opt out of the agreement to
                        arbitrate, as a result of a decision by the arbitrator
                        or a court order or because you have chosen to file an
                        eligible lawsuit in small claims court, you agree that
                        any claim or dispute that has arisen or may arise
                        between you and EdYOU must be resolved exclusively by a
                        state or federal court located in California. You and
                        EdYOU agree to submit to the personal jurisdiction of
                        the courts located within California for the purpose of
                        litigating all such claims or disputes.</p>
                    <p><strong>OPT-OUT.</strong> IF YOU ARE A NEW EDYOU USER,
                        YOU CAN CHOOSE TO REJECT THE AGREEMENT TO ARBITRATE
                        PROVISION (&#8220;OPT-OUT&#8221;) BY EMAILING US AN
                        OPT-OUT NOTICE TO [EMAIL ADDRESS] (&#8220;OPT-OUT
                        NOTICE&#8221;): EDYOU. THE OPT-OUT NOTICE MUST BE
                        RECEIVED NO LATER THAN THIRTY (30) DAYS AFTER THE DATE
                        YOU ACCEPT THE TERMS OF SERVICE FOR THE FIRST TIME. IF
                        YOU ARE NOT A NEW EDYOU USER, YOU HAVE UNTIL THIRTY (30)
                        DAYS AFTER THE POSTING OF THE NEW TERMS OF SERVICE TO
                        SUBMIT AN ARBITRATION OPT-OUT NOTICE.</p>
                    <p><strong>Procedure.</strong> In order to opt-out, you must
                        email your name, address (including street address,
                        city, state, and zip code), and email address(es)
                        associated with your Account(s) to which the opt-out
                        applies and an unaltered digital image of a valid
                        driver’s license which matches the name on your account
                        to: privacy@investor.edyou.com.</p>
                    <p>This procedure is the only way you can opt out of the
                        agreement to arbitrate. If you opt out of the agreement
                        to arbitrate, all other parts of the agreement and its
                        Disputes Section will continue to apply to you. Opting
                        out of this agreement to arbitrate has no effect on any
                        previous, other, or future arbitration agreements that
                        you may have with us.</p>
                    <p><strong>WAIVER.</strong> BY AGREEING TO THESE TERMS, YOU
                        HEREBY IRREVOCABLY WAIVE ANY RIGHT YOU MAY HAVE TO A
                        COURT TRIAL (OTHER THAN SMALL CLAIMS COURT AS PROVIDED
                        BELOW) OR TO SERVE AS A REPRESENTATIVE, AS A PRIVATE
                        ATTORNEY GENERAL, OR IN ANY OTHER REPRESENTATIVE
                        CAPACITY, OR TO PARTICIPATE AS A MEMBER OF A CLASS OF
                        CLAIMANTS, IN ANY LAWSUIT, ARBITRATION OR OTHER
                        PROCEEDING FILED AGAINST US AND/OR RELATED THIRD
                        PARTIES.</p>
                    <h3>6. Indemnification</h3>
                    <p>You hereby agree to indemnify, defend and hold EdYOU and
                        all of our officers, directors, mangers, members,
                        employees, agents, information providers, affiliates,
                        partners, and licensors (“EdYOU Party,” or collectively,
                        the “EdYOU Parties”) harmless from and against any and
                        all liability, claims, damages, losses, costs, and
                        expenses, including attorneys&#8217; fees, incurred by
                        any EdYOU Party arising from, related to, or in
                        connection with (a) a violation of any provision of
                        these Terms by you; or (b) arising from, related to, or
                        connected with your violation of the rights of EdYOU or
                        any other person or entity. We may, in our sole and
                        absolute discretion, control the disposition of any such
                        claim at your sole cost and expense. You may not settle
                        any such claim without our express written consent. This
                        defense and indemnification obligation is intended to
                        extend to the fullest extent permitted by law and will
                        survive these Terms and your use of the Site.</p>
                    <h3>7. Warranties and Disclaimers</h3>
                    <p><strong>Service Outages and Force Majeure.</strong>
                        Unless you have greater rights in a separate signed
                        agreement with us, we disclaim to the fullest extent
                        permitted by law any service outages that are caused by
                        our maintenance on the servers or the technology that
                        underlies our Site, failures of our service providers
                        (including telecommunications, hosting, and power
                        providers), computer viruses, natural disasters or other
                        destruction or damage of our facilities, acts of nature,
                        war, civil disturbance, or any other cause beyond our
                        reasonable control. Under no circumstances shall EdYOU
                        or its licensor or service providers be held liable for
                        any delay or failure in performance resulting directly
                        or indirectly from an event beyond its reasonable
                        control. This provision is not intended to disclaim
                        liability that EdYOU may not disclaim under law.</p>
                    <p>USE OF SITE AND CONTENT IS AT YOUR OWN RISK. WE DO NOT
                        WARRANT THAT OUR SITE WILL BE UNINTERRUPTED OR ERROR
                        FREE. IN ADDITION, WE DO NOT MAKE ANY WARRANTY AS TO THE
                        CONTENT ON OUR SITE. OUR SITE AND CONTENT ARE
                        DISTRIBUTED ON AN &#8220;AS IS, AS AVAILABLE&#8221;
                        BASIS TO THE FULLEST EXTENT PERMITTED BY LAW. ANY
                        MATERIAL THAT YOU DOWNLOAD OR OTHERWISE OBTAIN THROUGH
                        OUR SITE IS DONE AT YOUR OWN DISCRETION AND RISK, AND
                        YOU WILL BE SOLELY RESPONSIBLE FOR ANY POTENTIAL DAMAGES
                        TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS
                        FROM YOUR DOWNLOAD OF ANY SUCH MATERIAL. NEITHER WE NOR
                        ANY OF OUR AFFILIATES OR BUSINESS PARTNERS MAKES ANY
                        WARRANTY THAT (i) OUR SITE, AND SERVICES WILL MEET YOUR
                        REQUIREMENTS OR EXPECTATIONS, (ii) OUR SITE WILL BE
                        UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE, (iii) THE
                        RESULTS THAT MAY BE OBTAINED FROM THE USE OF OUR SITE
                        WILL BE ACCURATE OR RELIABLE, AND (iv) ANY ERRORS WILL
                        BE CORRECTED. NEITHER WE NOR ANY OF OUR AFFILIATES OR
                        BUSINESS PARTNERS MAKE ANY WARRANTIES OF ANY KIND,
                        EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT
                        LIMITATION, WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF
                        NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A
                        PARTICULAR PURPOSE, WITH RESPECT TO OUR SITE, ANY
                        CONTENT, OR ANY OF OUR SERVICES, TOOLS, PRODUCTS, OR
                        PROPERTIES. YOU EXPRESSLY AGREE THAT YOU WILL ASSUME THE
                        ENTIRE RISK AS TO THE QUALITY AND THE PERFORMANCE OF OUR
                        SITE AND THE ACCURACY OR COMPLETENESS OF ITS CONTENT.
                        EDYOU ASSUMES NO RESPONSIBILITY FOR AND DISCLAIMS ALL
                        LIABILITY TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
                        LAW FOR ANY SUCH INACCURACIES, ERRORS OR OMISSIONS.
                        NEITHER WE NOR OUR AFFILIATES OR BUSINESS PARTNERS WILL
                        BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
                        OR CONSEQUENTIAL DAMAGES ARISING OUT OF THE USE OF OR
                        INABILITY TO USE OUR SITE, EVEN IF WE HAVE BEEN ADVISED
                        OF THE POSSIBILITY OF SUCH DAMAGES. THIS PROVISION IS
                        NOT INTENDED TO DISCLAIM LIABILITY THAT EDYOU MAY NOT
                        DISCLAIM UNDER APPLICABLE LAW.</p>
                    <p>NO RESPONSIBILITY FOR THIRD-PARTY SERVICES AND MATERIALS.
                        EDYOU DOES NOT WARRANT, ENDORSE, GUARANTEE OR ASSUME
                        RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
                        OFFERED BY A THIRD PARTY THROUGH THE SITE OR ANY WEBSITE
                        FEATURED OR LINKED TO THROUGH THE SITE, AND EDYOU WILL
                        NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR
                        MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY
                        PROVIDERS OF PRODUCTS OR SERVICES. EDYOU WILL NOT BE
                        LIABLE FOR THE OFFENSIVE OR ILLEGAL CONDUCT OF ANY THIRD
                        PARTY. YOU VOLUNTARILY ASSUME THE RISK OF HARM OR DAMAGE
                        FROM THE FOREGOING. THE FOREGOING LIMITATIONS WILL APPLY
                        TO THE FULLEST EXTENT PERMITTED BY LAW, EVEN IF A REMEDY
                        FAILS OF ITS ESSENTIAL PURPOSE.</p>
                    <p>NEW JERSEY RESIDENTS. TO NEW JERSEY RESIDENTS, THE
                        PROVISIONS ABOVE ARE INTENDED TO BE AS BROAD AND
                        INCLUSIVE AS PERMITTED BY THE LAW OF THE STATE OF NEW
                        JERSEY ONLY.</p>
                    <h3>8. Limitation of Liability</h3>
                    <p><strong>NO CONSEQUENTIAL DAMAGES.</strong> IN NO EVENT,
                        AS PERMITTED BY THE FULLEST EXTENT OF APPLICABLE LAW,
                        WILL EDYOU, AND EDYOU PARTIES BE LIABLE FOR ANY
                        INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE
                        DAMAGES RESULTING FROM THE PERFORMANCE, USE OF OR THE
                        INABILITY TO USE THE SITE, SERVICES, CONTENT OR
                        PRODUCTS, EVEN IF EDYOU HAS BEEN ADVISED OF THE
                        POSSIBILITY OF SUCH DAMAGES, WHETHER IN AN ACTION IN
                        CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY,
                        VIOLATION OF STATUTE OR OTHERWISE. THIS PROVISION IS NOT
                        INTENDED TO EXCLUDE LIABILITY THAT EDYOU MAY NOT EXCLUDE
                        UNDER APPLICABLE LAW.</p>
                    <p><strong>OUR LIABILITY IS LIMITED.</strong> IN ANY EVENT,
                        OUR AGGREGATE LIABILITY WILL NOT EXCEED THE AMOUNT PAID
                        FOR THE PRODUCTS TO WHICH THE CLAIM RELATES OR, IF THE
                        CLAIM DOES NOT RELATE TO A PRODUCT, $100. THIS PROVISION
                        IS NOT INTENDED TO EXCLUDE LIABILITY THAT EDYOU MAY NOT
                        EXCLUDE UNDER APPLICABLE LAW.</p>
                    <p><strong>NEW JERSEY RESIDENTS.</strong> TO NEW JERSEY
                        RESIDENTS, THE PROVISIONS OF THIS PARAGRAPH 9 ARE
                        INTENDED TO BE AS BROAD AND INCLUSIVE AS PERMITTED BY
                        THE LAW OF THE STATE OF NEW JERSEY ONLY.</p>
                    <h3>9. Termination; Survival</h3>
                    <p><strong>Term.</strong> These Terms are effective unless
                        and until terminated by you or us. We may, in our sole
                        and absolute discretion and without any liability,
                        modify, suspend or discontinue any aspect of the Site,
                        temporarily or permanently, at any time and without
                        prior notice.</p>
                    <p><strong>Suspension and Termination.</strong> We may deny
                        you access to all or part of the Site at any time for
                        any reason (including if you violate these Terms, as
                        determined in our sole and absolute discretion) or no
                        reason at all.</p>
                    <p><strong>Survival.</strong> If we terminate your right to
                        access the Site, these Terms will terminate and all
                        rights you have to access the Site will immediately
                        terminate. The following provisions will survive
                        termination: Intellectual Property, Indemnification,
                        Payment Obligations, Warranties and Disclaimers,
                        Limitations of Liability, Dispute and any and all others
                        that by their sense and context are intended to survive
                        the termination or expiration of the Agreement shall
                        survive</p>
                    <h3>10. General Terms</h3>
                    <p><strong>No Waiver; Severability.</strong> Our failure to
                        exercise or enforce any right or provision of these
                        Terms will not constitute a waiver of such right or
                        provision, and our failure to exercise or enforce any
                        right or remedy in these Terms does not waive that right
                        or remedy. The provisions of these terms are intended to
                        extend to the fullest extent permitted by law. No waiver
                        of any term of these Terms will be binding unless in
                        writing.</p>
                    <p><strong>Statute of Limitations.</strong> You agree that
                        regardless of any statute or law to the contrary, any
                        claim or cause of action arising out of or related to
                        use of our Site or these Terms must be filed within one
                        (1) year after such claim or cause of action arose or be
                        forever barred.</p>
                    <p><strong>Applicable Law.</strong> These Terms will be
                        construed in accordance with the laws of the United
                        States of America and (to the extent not inconsistent
                        with or preempted by federal law) the State of Delaware,
                        and the parties irrevocably consent to bring any action
                        to enforce these Terms before an arbitration panel or
                        before a court of competent jurisdiction in Delaware if
                        seeking interim or preliminary relief or enforcement of
                        an arbitration award.</p>
                    <p>If any part of these Terms is determined to be invalid or
                        unenforceable pursuant to applicable law, then the
                        invalid or unenforceable provision will be deemed
                        superseded by a valid, enforceable provision that most
                        closely matches the intent of the original provision,
                        and the remainder of the Terms shall continue in
                        effect.<br />
                        Headings. The provision titles in these Terms are for
                        convenience only and have no legal or contractual
                        effect. These Terms will not be construed against the
                        drafter.<br />
                        Notice. You hereby consent to receiving and transacting
                        with us by electronic means. We may deliver notice to
                        you by e-mail, posting a notice on the Site or any other
                        method we choose and such notice will be effective on
                        dispatch. If you give notice to us, it will be effective
                        when received and you must use the following email
                        address:</p>
                    <p>compliance@investor.edyou.com</p>
                    <p><strong>Entire Agreement.</strong> These Terms (and all
                        terms and conditions incorporated herein) constitute the
                        entire agreement between you and EdYOU and govern your
                        use of the Site and Services and supersede any prior
                        agreements between you and EdYOU on the subject matter.
                        You may also be subject to additional terms when you use
                        certain EdYOU third party software, content, links, or
                        websites. These Terms, and any rights or licenses
                        granted hereunder, may not be assigned or delegated by
                        you. These Terms, and any rights or licenses granted
                        hereunder, may be assigned or delegated by EdYOU without
                        restriction. These Terms bind and inure to the benefit
                        of each party and the party’s successors and permitted
                        assigns. These Terms may not be modified by an oral
                        statement by a representative of EdYOU. No agency,
                        partnership, joint venture or employee-employer
                        relationship is intended or created by these Terms. You
                        agree to comply with all applicable laws in your use of
                        the Site and Services. You agree that any agreements
                        made by and between you and us in electronic form are as
                        legally binding as if made in physical written form.</p>
                    <p><strong>Notice to California Users.</strong> Under
                        California Civil Code Section 1789.3, California website
                        users are entitled to the following specific consumer
                        rights notice: The Complaint Assistance Unit of the
                        Division of Consumer Services of the California
                        Department of Consumer Affairs may be contacted in
                        writing at 1625 N. Market Blvd., Suite S-202,
                        Sacramento, California 95834, or by telephone at (800)
                        952-5210.</p>
                    <p><strong>Notice to Users Outside the United States of
                            America.</strong> The Site is controlled and offered
                        by EdYOU from the United States of America. EdYOU makes
                        no representations that the Site is appropriate for use
                        in other locations. Those who access or use the Site
                        from other locations do so at their own risk and are
                        responsible for compliance with local law. You consent
                        to the processing in the United States of America of
                        information you provide to us.</p>
                </div>
            </div>
        </div>
    </div>
</div>