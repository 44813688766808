import { Component, ElementRef,Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router, UrlTree } from '@angular/router';
import { courses } from '../courses-data';
import { UserService } from '../service/user.service';


@Component({
  selector: 'course-template',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CoursePageComponent implements OnInit ,OnDestroy {
  scrollKey: any;
  private isBrowser: boolean;
  openForm: boolean = false;
  storedLogin: string | null = "s";
  isloading: boolean = false;
  selectedButton: string = 'button-month';
  
  @ViewChild('pricingSection') pricingSection!: ElementRef;
  cards = [
    {
      id: 'Academic',
      title: 'Academic',
      img: 'assets/newb2cmedia/Maskgroup2.png',
      icon: 'assets/newb2cmedia/Academic_new.png',
      showOverlay: false,
      overlayContent: [
        {
          category: 'STEM',
          items: [
            { id: 'Biology', text: 'Biology' },
            { id: 'computer-science', text: 'Computer Science' },
            { id: 'Word-Problems', text: 'Word Problems' },
            { id: 'Pre-Algebra', text: 'Pre-Algebra' },
            { id: 'algebra', text: 'Algebra' }
           
          ]
        },
        {
          category: 'Social Science',
          items: [
            { id: 'Politics', text: 'Politics' },
            { id: 'Economics', text: 'Economics *' },
            { id: 'Psychology', text: 'Psychology *' },
            { id: 'Sociology', text: 'Sociology *' },
          ]
        },
        {
          category: 'Literary Studies',
          items: [
            { id: 'english', text: 'English' },
            { id: 'Biology', text: 'Drama *' },
            { id: 'computer-science', text: 'Fiction *' },
            { id: 'Writing', text: 'Writing' },
            
            { id: 'Poetry', text: 'Poetry' },
          ]},
            {
              category: 'Humanities',
              items: [
            { id: 'Latin', text: 'Latin' },
            { id: 'us-history', text: 'US History' },
            { id: 'World History', text: 'World History *' },
            { id: 'Philosophy', text: 'Philosophy *' },
      
          
            { id: 'Literature', text: 'Literature *' }
          ]
        },
      ]
    },
    {
      id: 'CME-Certification',
      title: 'CME Certification',
      img: 'assets/newb2cmedia/Maskgroup1.png',
      icon: 'assets/newb2cmedia/tp2icon.png',
      showOverlay: false,
      overlayContent: [
        {
          category: 'CME Credits and Certification',
          items: [
            { id: 'Intro-to-Internal-Medicine', text: 'Intro to Internal Medicine' },
            { id: 'Intro-to-Family-Medicine', text: 'Intro to Family Medicine' },
            { id: 'Intro-to-Psychiatry', text: 'Intro to Psychiatry' },
            { id: 'Intro-to-Surgery', text: 'Intro to Surgery' },
            { id: 'Intro-to-Pediatrics', text: 'Intro to Pediatrics' },
            { id: 'Intro-to-Obstetrics/Gynaecology', text: 'Intro to Obstetrics / Gynaecologic'},
            { id: 'Radiology', text: 'Intro to Radiology'},
          ]
        },
        
        {
          category: 'Ethics',
          items: [
            { id: 'Issues-in-Practice', text: 'Issues in Practice' },
            { id: 'Outcomes-Ethics', text: 'Outcomes Ethics' },
            { id: 'Medical-Knowledge', text: 'Medical Knowledge' },
            { id: 'Behavioral/Social-Science', text: 'Behavioral/Social Science' },
            
          ]
        
        },
        {
          category: 'Specialities',
          items: [
            { id: 'Rheumatology', text: 'Rheumatology' },
            { id: 'Cardiology', text: 'Cardiology' },
            { id: 'Heart-Failure', text: 'Heart Failure' },
            { id: 'Endocrinology', text: 'Endocrinology' },
            { id: 'Gastroenterology', text: 'Gastroenterology' },
            { id: 'Nephrology', text: 'Nephrology' },
            { id: 'Pulmonary-and-Critical-Care-Medicine', text: 'Pulmonary & Critical Care Medicine' },
            { id: 'Patient-Care', text: 'Patient Care' },
            { id: 'Hematology-and-Oncology', text: 'Hematology and Oncology' },
            { id: 'Toxicology', text: 'Toxicology' },
            { id: 'Neurology', text: 'Neurology' },
            { id: 'Clinical-Neuro/Psych', text: 'Clinical Neuro/Psych' },
            { id: 'Neuroscience', text: 'Neuroscience' },
            { id: 'Geriatric-Medicine', text: 'Geriatric Medicine' },
            { id: 'Infectious-Diseases', text: 'Infectious Diseases' },
            { id: 'Sexually-Transmitted-Diseases', text: 'Sexually Transmitted Diseases' },
        
           
           
           
          ]
        },
      ]
      
    },
    {
      id: 'Test-Prep',
      title: 'Test Prep',
      img: 'assets/newb2cmedia/test-prep1.png',
      icon: 'assets/newb2cmedia/tp1icon.png',
      showOverlay: false,
      overlayContent: [{
        category: 'Test Preparation',
        items: [
          { id: 'Exam-Anxiety', text: 'Exam Anxiety Help' },
          { id: 'Exam-Time-Management', text: 'Exam Time Management *' },
        ]
        },
       {
        category: ' High School/College',
        items: [
        /* High School/College */
        { id: 'High-School-ACT', text: 'ACT (American College Testing)' },
        { id: 'High-School-SAT', text: 'SAT' },
        { id: 'GRE', text: 'GRE (Graduate Records Examination) *' },
        ]},
        /* United States Medical Licensing Examination */
        {
          category: 'United States Medical Licensing Examination',
          items: [
        { id:'USMLE-Step-1',text:'USMLE Step 1 *'},
        { id: 'USMLE-Step-2', text: 'USMLE Step 2 *' },
        { id: 'USMLE-Step-3', text: 'USMLE Step 3 *' },
          ]},
          {
            category: 'Medical Residents (In-Training Examination)',
            items: [
        /* Medical Residents (In-Training Examination) */
        { id: 'Internal-Medicine-ITE', text: 'Internal Medicine (ITE) *' },
       
        { id: 'Psychiatry-PRITE-Inspired', text: 'Psychiatry (PRITE Inspired)*' },
        { id: 'Surgery-ABSITE', text: 'Surgery (ABSITE) *' },
            ]},
        /* Law */
        {
          category: 'Law',
          items: [
        { id: 'LSAT', text: 'LSAT (Law School Admission Test)*' },
      // { id: 'Coming', text: 'Coming Soon*' },
          ]}
    ]
    },{
          id: 'Skills-For-Life',
          title: 'Skills For Life',
          img: 'assets/newb2cmedia/Maskgroup.png',
          icon: 'assets/newb2cmedia/tp4icon.png',
          showOverlay: false,
          overlayContent: [
            {
              category: '',
              items: [
            { id: 'Interview-Prep', text: 'Interview Prep' },
            { id: 'communications', text: 'Communications' },
            { id: 'Accent-Reduction', text: 'Accent Reduction' },
            { id: 'Time-Management', text: 'Time Management' },
            { id: 'project-management', text: 'Project Management' },
            { id: 'leadership', text: 'Leadership' },
            { id: 'entrepreneurship', text: 'Entrepreneurship' },
            { id: 'Self-Care', text: 'Self-Care' },
            { id: 'Bully-Help', text: 'Bully Help' },
            { id: 'Conflict-Resolution', text: 'Conflict Resolution' },
            ]},
          ]
        }
    // Other cards follow the same format
  ];
  
  
  @ViewChild('carouselWrapper') carouselWrapper!: ElementRef;
  courseId: string;
  currentCourse: any;
  email_id:any;
  prodDetail: any[] = [];
  enableB2cPlan: boolean = true;
  isLoggedIn: any;
  annualAmt: any;
  isMobile: boolean =false;
  constructor(private service: UserService,private router: Router, @Inject(PLATFORM_ID) private platformId: Object,
  private titleService: Title, private metaService: Meta,private el: ElementRef) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.courseId = this.router.url.slice(1)
    this.currentCourse = courses.find((course) => course.id === this.courseId)
    console.log(this.currentCourse)
  }
  

  ngOnInit(): void {
    this.isMobile = window.innerWidth <= 768;
    const videoElement = this.el.nativeElement.querySelector('#bgVideo') as HTMLVideoElement;
    if (videoElement) {
      // Ensure the video meets autoplay policies
      videoElement.muted = true; // Required for autoplay in modern browsers
      videoElement.playsInline = true; // For mobile devices
      videoElement.autoplay = true;

      // Manually trigger playback
      videoElement
        .play()
        .then(() => {
          console.log('Video is playing.');
        })
        .catch((error) => {
          console.error('Video playback failed:', error);
        });
    }
    this.enableB2cPlan = localStorage.getItem("plan_type") === "true"
    const storedLoginState = localStorage.getItem('LoginState');
    this.isLoggedIn = storedLoginState ? JSON.parse(storedLoginState) : false;
  // Check the login state and perform the corresponding action
  if (this.isLoggedIn == true) {}
    console.log( this.enableB2cPlan,'pls',localStorage.getItem("plan_type"))
    let payload = {

        "env": "live"
        // "env": "test"
    }
    this.service.stripe(payload).subscribe((res: any) => {
      this.prodDetail = res.body
      console.log(res, 'proddetail')
      this.calculateDiscount(this.prodDetail[0]?.Actual_amount,this.prodDetail[0]?.Discount)
    })
    this.titleService.setTitle('edYOU - Courses');
    this.metaService.addTags([
      {name: 'description', content: "Unlock limitless learning with edYOU's diverse course offerings. Explore academic subjects, CME certifications, test prep solutions, and essential life skills, all available through flexible subscription plans. From mastering algebra and USMLE Step preparation to leadership training and conflict resolution, edYOU empowers learners at every stage of their journey. With testimonials from educators, parents, and professionals, edYOU is recognized as the future of personalized education. Choose from Silver, Gold, or Platinum plans to access interactive courses, quizzes, and wellness tools designed to elevate learning outcomes and inspire growth."},
      {name: 'keywords', content: 'edYOU courses, online learning platform, academic courses, CME certification, test prep, USMLE courses, life skills training, personalized learning, subscription plans, algebra tutoring, SAT prep, GRE prep, leadership skills, conflict resolution, healthcare education, professional certifications, educational technology, wellness tools, flexible learning plans, interactive quizzes, step-by-step learning, testimonials, future of education'},
    ])
  }

  ngOnDestroy(): void {
    if (this.isBrowser) {
    this.service.saveScrollPosition(this.scrollKey, window.scrollY);
    }
  }
  choosedPlan: any;
  silverloader: boolean = false;
  goldloader: boolean = false;
  platinamloader: boolean = false;

  preEnroll(data: any, event: Event) {
 //dev 
  //  window.open('https://smdev.edyou.com/signup', '_blank');
    window.open('https://ever-app.edyou.com/signup','_blank');
    // localStorage.setItem("PlanSelected", data);
    // this.storedLogin = localStorage.getItem('user');
    // this.email_id = localStorage.getItem('email');
    // console.log('state', this.storedLogin, this.email_id)
    // if(this.selectedButton === 'button-month'){
    //  this.choosedPlan = 'Monthly'
    // }else{
    //   this.choosedPlan = 'Annually'
    // }
    

    // if (this.choosedPlan == 'Silver') {
    //   this.silverloader = true;
    // }
    // if (this.choosedPlan == 'Gold') {
    //   this.goldloader = true;
    // }
   
    // if (this.choosedPlan == 'Yearly') {
    //   this.platinamloader = true;
    // }
    // if (this.storedLogin) {
    //   //   this.buyPackage();

    //   this.closeForm
    //   this.planAPI(this.email_id, this.choosedPlan)
    //   //this.planAPI(this.email_id)



    // } else {
    //   this.openLoginPopup(event,false)
    
    // }
    
  }
  // openLoginPopup(event: any, isLogin: boolean): void {
   
  //   this.silverloader = false;
  //       this.goldloader = false
  //       this.platinamloader = false
  //   event.stopPropagation();
  //   this.service.showPopup(isLogin);
  // }
  // Buying plan implementation
  planAPI(email: string, plan: string) {
 
    let payload
   
  // Use same api for Production Only payload changes
    payload = {
      
    // "env": "test", "product": plan
      "env": "live", "product": plan
    }
   

    this.service.stripe(payload).subscribe((res: any) => {


      if (res.statusCode == 200) {
        this.silverloader = false;
        this.goldloader = false
        this.platinamloader = false
        let resvalue = res;
        let payload1
        console.log(resvalue)
        switch (plan) {
          case 'Silver':
            
            plan = 'Silver';
            payload1 = {
              "email": email,
              "prod_id": res.body.prod_id,

              "plan": res.body.product,
              "price_id": res.body.price_id,
              "mode": "setup",
              "price": res.body.amount,
              "price_amount": res.body.amount,

              "belong_to": "nonsc",

              "type": "payment"
            }
            break;
          default:
            payload1 = {
              "email": email,
              "prod_id": res.body.prod_id,

              "plan": res.body.product,
              "price_id": res.body.price_id,
              "mode": "setup",
              "price": res.body.amount,
              "price_amount": res.body.amount,

              "belong_to": "nonsc",

              "type": "subscription"
            }

            break;
        }


        this.service.stripe_checkout(payload1).subscribe((res: any) => {
          // console.log(payload1)
          if (res.statusCode == 303) {
           // this.newLoader = false
           if (this.isBrowser){
            window.location.href = res.headers.Location;
           }
          } else {
            return
          }

         
        })
      } else {
        return
      }
    })

  }

  toggleOverlay(card: any, event?: MouseEvent): void {
    if (event) {
      event.stopPropagation(); // Prevent the click from bubbling up to the parent element
    }
    console.log(card,'whatevent')
    if (card.id === 'CME-Certification') {
     this.redirectToCME()
    }else{
      card.showOverlay = !card.showOverlay;
    }
    
    // card.showOverlay = !card.showOverlay;
  }
  
  navigateToCoursePage(url: string | UrlTree) {
    console.log(url)
    
    this.router.navigateByUrl(url);
    
  
  }
  navigateToPricing() {
    this.pricingSection.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
  closeForm() {
    this.openForm = false
    
    this.isloading = false
  }
  isComingSoon(text: string): boolean {
    return text.includes('*');
  }
  navigateToInstitutePage(){
    
    this.router.navigateByUrl('/educational-institute');
  }
  navigateToEnterprisePage(){
    
    this.router.navigateByUrl('/enterprise-solutions');
  }
 

  selectButton(button: string): void {
    this.selectedButton = button;

  }
  // to calculate discount according to backend
  calculateDiscount(amount: number, discountPercent: number) {
  if (!amount || isNaN(amount) || !discountPercent || isNaN(discountPercent)) {
    console.error('Invalid amount or discount percentage provided');
    return 0;
  }
  let discount = +(amount * (discountPercent / 100)).toFixed(2)
 // Ensures precision to two decimal places
// Discount rounded to 2 decimal places
 this.annualAmt = +(amount - discount).toFixed(2); // Ensures
 return +this.annualAmt;
}
redirectToCME(){
 
    window.open('https://cme.edyou.com/', '_blank');
  
  
}
}