<div class="main-course">

<div class="course-panel">
  
<div class="carousel-container">
  <div class="course-title">
    {{currentCourse.title}}
  </div>
    <div class="carousel-wrapper" #carouselWrapper  >
      <div class="carousel-slide" *ngFor="let image of currentCourse.images;  let i = index"  [ngClass]="{'active': i === currentIndex}">
        <img [src]="image.src" [alt]="image.alt" class="carousel-image" >
      </div>
    </div>
    <button class="carousel-button prev" (click)="prevSlide()">&#10094;</button>
    <button class="carousel-button next"  (click)="nextSlide()">&#10095;</button>
    <div class="carousel-dots">
        <span 
          *ngFor="let image of currentCourse.images; let i = index"
          class="dot"
          [ngClass]="{'active': i === currentIndex}"
          (click)="goToSlide(i)"
        ></span>
      </div>
  </div>
  <div class="description-box">
    <div class="small-lappy ">
        <div [innerHTML]="currentCourse.description"></div>   
    </div>
    <div class="subscribe-button-container">
        <button class="main-button" (click)="goTo_mainPage()">Go to course page</button>
      <button class="subscribe-button" (click)="gotoPreenroll('pricing_section_id')">Buy Now</button>
      <!-- <button class="subscribe-button">Pre-Enroll Now</button> -->
    </div>
  </div>
</div>
</div>