import { Component, ElementRef,Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ColorChangeService } from '../service/color-change.service';
import { isPlatformBrowser } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router, UrlTree } from '@angular/router';
import { courses } from '../courses-data';
import { UserService } from '../service/user.service';


@Component({
  selector: 'course-template',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CoursePageComponent implements OnInit ,OnDestroy {
  scrollKey: any;
  private isBrowser: boolean;
  openForm: boolean = false;
  storedLogin: string | null = "s";
  isloading: boolean = false;
  @ViewChild('pricingSection') pricingSection!: ElementRef;
  cards = [
    {
      id: 'Academic',
      title: 'Academic',
      img: 'assets/newb2cmedia/Maskgroup2.png',
      icon: 'assets/newb2cmedia/Academic.png',
      showOverlay: false,
      overlayContent: [
        { id: 'us-history', text: 'US History' },
        { id: 'Poetry', text: 'Poetry' },
        { id: 'algebra', text: 'Algebra' },
        { id: 'Writing', text: 'Writing' },
        { id: 'english', text: 'English' },
        { id: 'Word-Problems', text: 'Word Problems' },
        { id: 'computer-science', text: 'Computer Science' },
        { id: 'Pre-Algebra', text: 'Pre-Algebra' },
        { id: 'Biology', text: 'Biology' },
        { id: 'Politics', text: 'Politics' }
      ]
    },
   
    {
      id: 'CME-Certification',
      title: 'CME Certification',
      img: 'assets/newb2cmedia/Maskgroup1.png',
      icon: 'assets/newb2cmedia/tp2icon.png',
      showOverlay: false,
      overlayContent: [
        { id: 'Intro-to-Internal-Medicine', text: 'Intro to Internal Medicine' },
        { id: 'Rheumatology', text: 'Rheumatology' },
        { id: 'Intro-to-Family-Medicine', text: 'Intro to Family Medicine' },
        { id: 'Geriatric-Medicine', text: 'Geriatric Medicine' },
        { id: 'Intro-to-Psychiatry', text: 'Intro to Psychiatry' },
        { id: 'Hematology-and-Oncology', text: 'Hematology and Oncology' },
        { id: 'Intro-to-Surgery', text: 'Intro to Surgery' },
        { id: 'Infectious-Diseases', text: 'Infectious Diseases' },
        { id: 'Intro-to-Obstetrics/Gynaecology', text: 'Intro to Obstetrics / Gynaecologic' },
        { id: 'Nephrology', text: 'Nephrology' },
        { id: 'Intro-to-Pediatrics', text: 'Intro to Pediatrics' },
        { id: 'Toxicology', text: 'Toxicology' },
        { id: 'Cardiology', text: 'Cardiology' },
        { id: 'Neurology', text: 'Neurology' },
        { id: 'Endocrinology', text: 'Endocrinology' },
        { id: 'Sexually-Transmitted-Diseases', text: 'Sexually Transmitted Diseases' },
        { id: 'Gastroenterology', text: 'Gastroenterology' },
        { id: 'Heart-Failure', text: 'Heart Failure' },
        { id: 'Pulmonary-and-Critical-Care-Medicine', text: 'Pulmonary & Critical Care Medicine' },
        { id: 'Patient-Care', text: 'Patient Care' },
        { id: 'Medical-Knowledge', text: 'Medical Knowledge' },
        { id: 'Radiology', text: 'Radiology' },
        { id: 'Neuroscience', text: 'Neuroscience' },
        { id: 'Outcomes-Ethics', text: 'Outcomes Ethics' },
        { id: 'Clinical-Neuro/Psych', text: 'Clinical Neuro/Psych' },
        { id: 'Behavioral/Social-Science', text: 'Behavioral/Social Science' },
        { id: 'Issues-in-Practice', text: 'Issues in Practice' },
      ]
    },
    {
      id: 'Test-Prep',
      title: 'Test Prep',
      img: 'assets/newb2cmedia/test-prep1.png',
      icon: 'assets/newb2cmedia/tp1icon.png',
      showOverlay: false,
      overlayContent: [
        { id: 'Test-prep', text: 'Test Prep' },
        { id: 'High-School-ACT', text: 'High School ACT' },
        { id: 'High-School-SAT', text: 'High School SAT' },
        { id: 'Exam-Anxiety', text: 'Exam Anxiety Course' },
        { id: 'GRE', text: 'GRE (Coming Soon)' },
        { id: 'USMLE-Step-3', text: 'USMLE Step 3 (Coming Soon)' },
        { id: 'LSAT', text: 'LSAT (Coming Soon)' },
       
        { id: 'Surgery-ABSITE', text: 'Surgery (ABSITE) (Coming Soon)' },
        { id: 'Psychiatry-PRITE-Inspired', text: 'Psychiatry (PRITE Inspired) (Coming Soon)' },
        
        
        { id:'USMLE-Step-1',text:'USMLE Step 1 (Coming Soon)'},
        { id: 'USMLE-Step-2', text: 'USMLE Step 2 (Coming Soon)' },
      ]
    },
   
    {
      id: 'Skills-For-Life',
      title: 'Skills For Life',
      img: 'assets/newb2cmedia/Maskgroup.png',
      icon: 'assets/newb2cmedia/tp4icon.png',
      showOverlay: false,
      overlayContent: [
        { id: 'leadership', text: 'Leadership' },
        { id: 'Accent-Reduction', text: 'Accent Reduction' },
        { id: 'communications', text: 'Communications' },
        { id: 'Conflict-Resolution', text: 'Conflict Resolution' },
        { id: 'entrepreneurship', text: 'Entrepreneurship' },
        { id: 'Time-Management', text: 'Time Management' },
        { id: 'project-management', text: 'Project Management' },
        { id: 'Self-Care', text: 'Self-Care' },
        { id: 'Interview-Prep', text: 'Interview Prep' },
        
        { id: 'Bully-Help', text: 'Bully Help' },
        
      ]
    }
  ];
  
  @ViewChild('carouselWrapper') carouselWrapper!: ElementRef;
  courseId: string;
  currentCourse: any;
  email_id:any;
  prodDetail: any[] = [];
  enableB2cPlan: boolean = true;
  isLoggedIn: any;
  constructor(private service: UserService,private router: Router, @Inject(PLATFORM_ID) private platformId: Object,
  private titleService: Title, private metaService: Meta,private el: ElementRef) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.courseId = this.router.url.slice(1)
    this.currentCourse = courses.find((course) => course.id === this.courseId)
    console.log(this.currentCourse)
  }
  

  ngOnInit(): void {
    const videoElement = this.el.nativeElement.querySelector('#bgVideo') as HTMLVideoElement;
    if (videoElement) {
      // Ensure the video meets autoplay policies
      videoElement.muted = true; // Required for autoplay in modern browsers
      videoElement.playsInline = true; // For mobile devices
      videoElement.autoplay = true;

      // Manually trigger playback
      videoElement
        .play()
        .then(() => {
          console.log('Video is playing.');
        })
        .catch((error) => {
          console.error('Video playback failed:', error);
        });
    }
    this.enableB2cPlan = localStorage.getItem("plan_type") === "true"
    const storedLoginState = localStorage.getItem('LoginState');
    this.isLoggedIn = storedLoginState ? JSON.parse(storedLoginState) : false;
//   console.log(typeof(isLoggedIn), 'user check',isLoggedIn)
  // Check the login state and perform the corresponding action
  if (this.isLoggedIn == true) {}
    console.log( this.enableB2cPlan,'pls',localStorage.getItem("plan_type"))
    let payload = {

     "env": "live"
        // "env": "test"
    }
    this.service.stripe(payload).subscribe((res: any) => {
      this.prodDetail = res.body
      console.log(res, 'proddetail')

    })
  }

  ngOnDestroy(): void {
    if (this.isBrowser) {
    this.service.saveScrollPosition(this.scrollKey, window.scrollY);
    }
  }
  choosedPlan: any;
  silverloader: boolean = false;
  goldloader: boolean = false;
  platinamloader: boolean = false;

  preEnroll(data: any, event: Event) {
    // window.open('https://buy.stripe.com/test_5kAdSpdiJcr8awEcMM', '_blank');
    // window.location.href = 'https://buy.stripe.com/test_5kAdSpdiJcr8awEcMM';
    localStorage.setItem("PlanSelected", data);
    this.storedLogin = localStorage.getItem('user');
    this.email_id = localStorage.getItem('email');
    console.log('state', this.storedLogin, this.email_id)
    this.choosedPlan = data

    if (this.choosedPlan == 'Silver') {
      this.silverloader = true;
    }
    if (this.choosedPlan == 'Gold') {
      this.goldloader = true;
    }
    if (this.choosedPlan == 'Platinum') {
      this.platinamloader = true;
    }
    if (this.storedLogin) {
      //   this.buyPackage();

      this.closeForm
      this.planAPI(this.email_id, this.choosedPlan)
      //this.planAPI(this.email_id)



    } else {
      this.openLoginPopup(event,false)
      //this.openForm = !this.openForm
    }
    // this.openForm = !this.openForm

    // console.log(data)
  }
  openLoginPopup(event: any, isLogin: boolean): void {
    console.log(event,isLogin)
    this.silverloader = false;
        this.goldloader = false
        this.platinamloader = false
    event.stopPropagation();
    this.service.showPopup(isLogin);
  }
  planAPI(email: string, plan: string) {
  //  this.isloading = true;
   // this.newLoader = true
    let payload
    //live test

    payload = {
    "env": "live", "product": plan
      // "env": "test", "product": plan
    }
    // console.log(payload)
    // switch (plan) {
    //   case 'Silver':
    //     plan = 'Silver';
    //      payload = {
    //       "email": email,
    //      // silver - prod_QvPYwWXIKFLUpK -price_1Q3YjZALy7MM11rqpdgvJvyM 
    //       "prod_id": "prod_QvPYwWXIKFLUpK",
    //     //  "prod_id": "prod_QmrFV8irjoWH9E",
    //       // "prod_id": "prod_Qkv0CvSnYSA23r",
    //       "plan": plan,
    //       "price_id": "price_1Q3YjZALy7MM11rqpdgvJvyM",
    //      // "price_id": "price_1PxoBRALy7MM11rqkjD1f8DB",
    //       // "price_id": "price_1PxjA1ALy7MM11rqNsEDK3ke",
    //       "mode": "setup",
    //       "price": "price_1Q3YjZALy7MM11rqpdgvJvyM",
    //       // "price": "price_1PxjA1ALy7MM11rqNsEDK3ke",
    //       "price_amount": "19.99",
    //       // "price_amount": "39",
    //       "belong_to" :"nonsc",

    //       "type":"payment"
    //     }
    //     break;
    //   case 'Gold':
    //     plan = 'Gold';
    //    payload = {
    //       "email": email,
    //     //  gold - prod_QvPc1yzCUHcSCv - price_1Q3YmqALy7MM11rq84K4saZ2
    //       "prod_id": "prod_QvPc1yzCUHcSCv",
    //      // "prod_id": "prod_QmrFLWOmU2oDzr",
    //       // "prod_id": "prod_Qkv5HTR2zZJ9Dd",
    //       "plan": plan,
    //       "price_id": "price_1Q3YmqALy7MM11rq84K4saZ2",
    //      // "price_id": "price_1Pxo9IALy7MM11rqfEWyUv4i",
    //       // "price_id": "price_1Pxj7tALy7MM11rqzdYpQN8y",
    //       "mode": "setup",
    //       "price": "price_1Q3YmqALy7MM11rq84K4saZ2",
    //       // "price": "price_1Pxj7tALy7MM11rqzdYpQN8y",
    //       "price_amount": "74.99",
    //       "belong_to" :"nonsc",

    //       "type":"subscription"

    //     }
    //     break;
    //     //package updated 27sep2024
    //     //platinum - prod_QvPcLe2x2J5JXO - price_1Q3YncALy7MM11rqdQIA8mTi
    //   //gold - prod_QvPc1yzCUHcSCv - price_1Q3YmqALy7MM11rq84K4saZ2
    //   //silver - prod_QvPYwWXIKFLUpK -price_1Q3YjZALy7MM11rqpdgvJvyM
    //   case 'Platinum':
    //     plan = 'Platinum';
    //     payload = {
    //      // platinum - prod_QvPcLe2x2J5JXO - price_1Q3YncALy7MM11rqdQIA8mTi
    //       "email": email,
    //       "prod_id": "prod_QvPcLe2x2J5JXO",
    //      // "prod_id": "prod_QmrFZUGlrjAclG",
    //       // "prod_id": "prod_Qkv3dLCQOXIq0z",
    //       "plan": plan,
    //        "price_id": "price_1Q3YncALy7MM11rqdQIA8mTi",
    //      // "price_id": "price_1PxoB0ALy7MM11rqYdsqSzJN",
    //       // "price_id": "price_1PxjHqALy7MM11rqGyaxNJY1",
    //       "mode": "setup",
    //       "price": "price_1Q3YncALy7MM11rqdQIA8mTi",
    //       // "price": "price_1PxjHqALy7MM11rqGyaxNJY1",
    //       "price_amount": "199.99",
    //       // price_amount": "199",
    //       "belong_to" :"nonsc",

    //       "type":"subscription"
    //     }
    //     break;
    //     default:
    //     {}

    //     break;
    // }

    this.service.stripe(payload).subscribe((res: any) => {


      if (res.statusCode == 200) {
        this.silverloader = false;
        this.goldloader = false
        this.platinamloader = false
        let resvalue = res;
        let payload1
        console.log(resvalue)
        switch (plan) {
          case 'Silver':
            
            plan = 'Silver';
            payload1 = {
              "email": email,
              "prod_id": res.body.prod_id,

              "plan": res.body.product,
              "price_id": res.body.price_id,
              "mode": "setup",
              "price": res.body.amount,
              "price_amount": res.body.amount,

              "belong_to": "nonsc",

              "type": "payment"
            }
            break;
          default:
            payload1 = {
              "email": email,
              "prod_id": res.body.prod_id,

              "plan": res.body.product,
              "price_id": res.body.price_id,
              "mode": "setup",
              "price": res.body.amount,
              "price_amount": res.body.amount,

              "belong_to": "nonsc",

              "type": "subscription"
            }

            break;
        }


        this.service.stripe_checkout(payload1).subscribe((res: any) => {
          // console.log(payload1)
          if (res.statusCode == 303) {
           // this.newLoader = false
           if (this.isBrowser){
            window.location.href = res.headers.Location;
           }
          } else {
            return
          }

          //this.closeForm()





         // this.isloading = false;
        })
      } else {
        return
      }
    })

  }

  toggleOverlay(card: any, event?: MouseEvent): void {
    if (event) {
      event.stopPropagation(); // Prevent the click from bubbling up to the parent element
    }
    card.showOverlay = !card.showOverlay;
  }
  
  navigateToCoursePage(url: string | UrlTree) {
    console.log(url)
    
    this.router.navigateByUrl(url);
    
    // this.router.navigate(['/us-history']);
  }
  navigateToPricing() {
    this.pricingSection.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
  closeForm() {
    this.openForm = false
    
    this.isloading = false
  }
  isComingSoon(text: string): boolean {
    return text.includes('(Coming Soon)');
  }
  navigateToInstitutePage(){
    
    this.router.navigateByUrl('/educational-institute');
  }
  navigateToEnterprisePage(){
    
    this.router.navigateByUrl('/enterprise-solutions');
  }
}