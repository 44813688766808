import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { UserService } from '../service/user.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UneeqavatarComponent } from '../uneeqavatar/uneeqavatar.component';
import { isPlatformBrowser } from '@angular/common';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit , AfterViewInit {
  videoLinks: string[] = [
    'https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/no-volume-ever-intro-vid.mp4',
    'https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/dr-emma-meet-ai-tutors.mp4',
    'https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/eddie-meet-ai-tutors.mp4',
  ];
  @ViewChild('backgroundVideo') backgroundVideo!: ElementRef<HTMLVideoElement>;
  isModalOpen: boolean = false;
  isloading: boolean = false;
  isLoading3: boolean = false;
  currentVideoIndex = 0;
  isLoading2: boolean = false;
  counter: any = 1;
  plan: string | null = null;
  private isBrowser: boolean =false;
  storageListener: any;
  @ViewChild('video', { static: true }) videoElem!: ElementRef<HTMLVideoElement>;
  @ViewChild('videoPlayer', { static: true }) videoPlayer:
    | ElementRef<HTMLVideoElement>
    | any;
  @ViewChild(UneeqavatarComponent) uneeqAvatar!: UneeqavatarComponent;
  b2bplan: boolean = false;
  
  rotatingTextIndex: number = 0;

  rotatingTexts = [
    'Personalized Learning',
    'Test Prep',
  ];

  nextRotatingText(): void {
    this.rotatingTextIndex = (this.rotatingTextIndex + 1) % this.rotatingTexts.length;
  }

  constructor(@Inject(PLATFORM_ID) private platformId: Object,private titleService: Title,private router: Router, private service: UserService,private cd: ChangeDetectorRef,private el: ElementRef) {}

  ngOnInit(): void {
    this.titleService.setTitle('edYOU - Empower Yourself');
    this.isBrowser = isPlatformBrowser(this.platformId);
    const videoIds = ['#backgroundVideo', '#laptop', '#videoPlayer'];
    //multiple video to play 
    videoIds.forEach((id) => {
      const videoElement = this.el.nativeElement.querySelector(id) as HTMLVideoElement;
      if (videoElement) {
        videoElement.muted = true; // Required for autoplay in modern browsers
        videoElement.playsInline = true; // For mobile devices
        videoElement.autoplay = true;
    
        videoElement
          .play()
          .then(() => {
            console.log(`Video ${id} is playing.`);
          })
          .catch((error) => {
            console.error(`Video playback failed for ${id}:`, error);
          });
      } else {
        console.error(`Video element not found for selector: ${id}`);
      }
    });
  
    this.openForm = !this.openForm;
    setInterval(() => {
      this.nextRotatingText();
    }, 3500);

    // Initial fetch from local storage checking user is logged in or not
   
    this.service.loggedIn$.subscribe(state => {
      
      const storedUserData = localStorage.getItem('user');
      if (storedUserData) {
        this.plan = localStorage.getItem('subscription');
        this.cd.detectChanges();
   
        this.storageListener = this.handleStorageEvent.bind(this);
    if(this.isBrowser){ 
    window.addEventListener('storage', this.storageListener);
    }
    
    //checking for subscription to show pop-up of start learning 
    
    let b2bpan = localStorage.getItem('plan_b2b') === 'true';
    this.b2bplan = b2bpan;
    if (b2bpan) {
      this.isModalOpen = true;
    } else {
      let subscribed = JSON.parse(localStorage.getItem('subscription') || '{}');
      console.log(subscribed, subscribed.length, 'susbcription');

      if (subscribed) {
       
        if (subscribed.length > 0) {
          
          this.isModalOpen = true;
          
        } else {
         
          this.isModalOpen = false;
          
        }
      }
    }
   
    
  }
  })

 
  
  }


  onPause(event: Event): void {
    const video: HTMLVideoElement = this.videoElem.nativeElement;
  
    // Check if the video has not ended and is not in a playing state
    if (!video.ended && video.paused) {
    
      video.play().catch((err) => {
        
        console.error('Error resuming playback:', err);
      });
    }
  }
  
  // Handle potential cases where the video might get stuck
  onWaiting(event: Event): void {
    const video: HTMLVideoElement = this.videoElem.nativeElement;
    video.play().catch((err) => {
      console.error('Error resuming playback during buffering:', err);
    });
  }
  
  onError(event: Event): void {
    console.error('An error occurred with the video element:', event);
  }

  ngAfterViewInit() {
    

 
      const video = document.getElementById('backgroundVideo') as HTMLVideoElement;
      if (video) {
        video.muted = true;  // Ensure it's muted
        video.play().catch(error => console.error("Autoplay blocked:", error));
      }
  }
 
  
  //to check user has subscription if yes start learning 
  handleStorageEvent() {
    let subscribed = JSON.parse(localStorage.getItem('subscription') || '{}');
    let b2bpan = localStorage.getItem('plan_b2b') === 'true';
    
   
    if (b2bpan ) {
      this.isModalOpen = true;
    } else {
      if (subscribed) {
        
        if (subscribed.length > 0) {
          
          this.isModalOpen = true;
          
        } else {
      
          this.isModalOpen = false;
        
        }
      }
    }
    

    // Parse JSON if it's not null
    let parsedSubscribed = subscribed ? JSON.parse(subscribed) : '';

    
  }
  nextVideo() {
    if (this.currentVideoIndex < this.videoLinks.length - 1) {
      this.currentVideoIndex++;
    } else {
      this.currentVideoIndex = 0;
    }
    this.updateVideoSource();
  }

  previousVideo() {
    if (this.currentVideoIndex > 0) {
      this.currentVideoIndex--;
    } else {
      this.currentVideoIndex = this.videoLinks.length - 1;
    }
    this.updateVideoSource();
  }

  updateVideoSource() {
    const videoElement = this.videoPlayer.nativeElement;
    videoElement.src = this.videoLinks[this.currentVideoIndex];
    videoElement.load();
    videoElement.play();
  }

  navigateToAIAvatar() {
    this.router.navigate(['/AI_Tutor']);
  }

  navigateToEI() {
    this.router.navigate(['/educational-institute']);
  }
  navigateToES() {
    this.router.navigate(['/enterprise-solutions']);
  }
  isLoading: boolean = false;
  displayuneeqcomponent: boolean = false;
  displaymsg: any;
  openForm: boolean = false;
  triedcard: boolean = false;
  closedpptbutton: boolean = false;
  allmendatoryfield: boolean = false;
  hideparentemail: boolean = false;

  form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    f_name: new UntypedFormControl('', [Validators.required]),
    
  });

  // validation  controls for form
  get email() {
    return this.form.controls['email'];
  }

  get f_name() {
    return this.form.controls['f_name'];
  }

  get l_name() {
    return this.form.controls['l_name'];
  }
  get phone() {
    return this.form.controls['phone'];
  }
  get P_email() {
    return this.form.controls['P_email'];
  }
  get isOver13() {
    return this.form.controls['isOver13'];
  }

  get termsAccepted() {
    return this.form.controls['termsAccepted'];
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    // Iterate over each field in the form group
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      // Check if the control is an individual form control
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
        // Check if the control is a nested form group
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  hasError(): boolean {
    return (
      (this.form.get('f_name')?.touched && this.form.get('f_name')?.invalid) ||
      false ||
      (this.form.get('email')?.touched && this.form.get('email')?.invalid) ||
      false
    );
  }

  closebutton() {
    this.openForm = !this.openForm;
    this.form.reset();
  }
  backtomainpage() {
    this.router.navigate(['/']);
  }

  gotoTermsaPage() {
    this.router.navigate(['/terms']);
  }

  termsclicked() {
    if (this.form.get('termsAccepted')?.value) {
      this.allmendatoryfield = true;
    } else {
      this.allmendatoryfield = false;
    }
  }
 
  goforsubscribe() {
    this.router.navigate(['/course']);
    
  }

  closeModal() {
    this.isModalOpen = false;
  }
  navigateToProfile() {
      this.router.navigate(['/profile']);
    }
  //App link to start learning 
 
  togglePlay(videoElement: HTMLVideoElement): void {
    if (videoElement.paused) {
      videoElement.play();
    } else {
      videoElement.pause();
    }
  }
// using it to trigger  login popup
openLoginPopup(event: any, isLogin: boolean): void {
  event.stopPropagation();
  this.service.showPopup(isLogin);
}
  ensureMuted(videoPlayer: HTMLVideoElement) {
    videoPlayer.muted = true; 
  }
  linkToedyouUser() {
   
   
    if(this.isBrowser){
    // window.location.href = 
    // window.open('https://smdev.edyou.com/signup', '_blank');
    window.open('https://ever-app.edyou.com/signup','_blank');
    }
  
  }
}
