
<div class="mainnew">
  <div class="hero-section">
    <!-- Video Background -->
    <video id="backgroundVideo"#video muted autoPlay loop playsInline  (pause)="onPause($event)" src="/assets/video/LandingPageVideo_4.mp4"  class="background-video">
      <!-- <source  src="https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/Landing+Page+Video_4.mp4" > -->
   
         <!-- <source autoplay loop muted  src="https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/Compressed/LandingPageVideo_4.mp4" > -->
      Your browser does not support the video tag.
    </video>
    <!-- <img class="background-video"
      src="https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/LandingPageVideo_4-ezgif.com-video-to-gif.gif"> -->
    <!-- Overlay and Content -->
    <div class="overlay"></div>
    <div class="hero-content">
      <h1><span style="     font-weight: 500;">24/7 </span> AI Tutors for </h1>
      <div class="heightSet">
        <h2 class="hero-rotating-text">{{ rotatingTexts[rotatingTextIndex] }}</h2>
        <!-- <div class="scrolling-content">
              <h1>Personalized Learning</h1>
              <h1>Test Prep</h1>
              <h1>Professional Certification</h1>
              <h1>Sports Coaching</h1>
          </div> -->
      </div>

      <div class="mobilebtn-h">
        <button class="cta-button" (click)="navigateToAIAvatar()"><span class="innerTextbtn"> Try Free Now
          </span></button>
      </div>
      <div class="mobilebtn-t">
        <p class="small-text">*No credit card required</p>

      </div>
    </div>
  </div>

  <div class="banner">
    <div class="div-container">
      <div class="scrolling-belt">

        <img class="-5" src="https://c.animaapp.com/fXulaZA9/img/image-57@2x.png" /> <!-- Oxford -->
        <img class="cornell-logo" src="./assets/logos-institutions/cornell-logo.png" />
        <img class="harvard-logo" src="./assets/logos-institutions/harvard-logo-white.png" />
        <img class="-6" src="https://c.animaapp.com/fXulaZA9/img/image-59@2x.png" /> <!-- Columbia -->
        <img class="stanford-logo" src="./assets/logos-institutions/stanford-white.png" />
        <img class="-7" src="https://c.animaapp.com/fXulaZA9/img/image-61@2x.png" /> <!-- Dartmouth -->
        <!-- <img class="-4" src="https://c.animaapp.com/fXulaZA9/img/image-60@2x.png" /> 
        <img class="" src="https://c.animaapp.com/fXulaZA9/img/image-54@2x.png" />  -->

      </div>
    </div>
   
  </div>
 
  
  <!-- avatar card -->

  <!-- <div class="avatarCard">
    <div class="centeravatarCard">
      <button class="avatarButton">click here</button>
    </div>
  </div> -->
  <!-- institution card -->
  <div class="institution-container">
    <div class="card-institution">
      <div class="image-institute">
        <img src="../../assets/newDesignImage/IndividualIcon.png" alt="">
      </div>
      <div class="heading-institute"><a>Personal</a></div>
      <div class="content-institute"><a>Solutions for Individuals</a></div>
      <button (click)="goforsubscribe()">Click Here</button>
    </div>
    <div class="card-institution">
      <div class="image-institute">
        <img src="../../assets/newb2cmedia/inst2.1.png" alt="">
      </div>
      <div class="heading-institute"><a>Educational Institutions</a></div>
      <div class="content-institute"><a>Solutions for Schools</a></div>
      <button (click)="navigateToEI()">Click Here</button>
    </div>
    <div class="card-institution">
      <div class="image-institute">
        <img src="../../assets/newb2cmedia/inst2.2.png" alt="">
      </div>
      <div class="heading-institute"><a>Enterprise Solutions</a></div>
      <div class="content-institute"><a>Solutions for Businesses</a></div>
      <button (click)="navigateToES()">Click Here</button>
    </div>
  </div>

   <!-- edyou intro  -->
   <section class="edyouwork">
    <div class="container">
      <div class="content">
        <h1>How edYOU Works</h1>
        <ul class="features-list">
          <li>Choose your customized courses.</li>
          <li>Engage with personalized AI tutors 24/7.</li>
          <li>Test your knowledge with infinite test prep.</li>
          <li>Monitor progress with real-time analytics.</li>
          <li>Improve grades & earn certification.</li>
        </ul>
      </div>

      <div class="image-container">
        <img src="../../assets/newDesignImage/Macbook.png" alt="Laptop showing platform demo" class="laptop-mockup">
        <video  id="laptop" class="overlay-video" autoPlay loop muted playsInline  >
          <!-- <source muted  src="https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/no_zoom_emma.mp4"  type="video/mp4"> -->
          <source muted  src="../../assets/video/no_zoom_emma.mp4"  >
          Your browser does not support the video tag.
        </video>
        <!-- <img class="overlay-video"
          src="https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/no_zoom_emma-laptop-to-gif-converter.gif"> -->
      </div>

    </div>
  </section>
  <!--  -->

   <!-- trynow avatarppt -->
 <div class="avmain">
  <div class="avcontainer">

    <button class="cta-button" (click)="navigateToAIAvatar()"><span class="innerTextbtn"> Try Free Now
      </span></button>





  </div>
</div>

<!-- meet our AI -->
<section class="course-tutors">

  <div class="tutors-section">
    <div class="contentt">
      <h2>Meet our AI Tutors</h2>

      <p>
        edYOU is a safe, reliable platform where humans and AI companions collaborate to achieve more together.
        Our adaptable AI Conversational Beings enhance human capabilities in learning, wellness, and business
        training.
        At edYOU, we empower you to grow, explore, and reach new heights.
      </p>
    </div>
    <div class="ai-image">
      <div class="video-container">
        <video muted autoplay loop controls playsinline class="portrait-video"
          (loadedmetadata)="ensureMuted(videoPlayer)" #videoPlayer (click)="togglePlay(videoPlayer)" id="videoPlayer">
          <source [src]="videoLinks[currentVideoIndex]" type="video/mp4">
        </video>
      </div>

      <div class="slider-navigation">
        <span style="cursor: pointer;"><img src="../../assets/newDesignImage/Previous.png" style="margin-right: 5px;
  margin-bottom: 2px;" (click)="previousVideo()"></span>
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>


        <span style="cursor: pointer;"><img src="../../assets/newDesignImage/Next.png" style="margin-left: 5px;
  margin-bottom: 2px;" (click)="nextVideo()"></span>
      </div>
    </div>
  </div>
  <!-- <div class="button-container">
    <button class="explore-button">Explore Courses</button>
  </div> -->

</section>

<!-- gallery -->

<div class="outer-card">
  <div class="section-title">edYOU is For</div>
  <div class="contentCenter" style="display: flex; justify-content: center;">
  <div class="content-section "> 
   
    <!-- Students - Content Left, Image Right -->
    <div class="col-md-12 mb-4  content-sectionbox">
      <div class="card galleryrow">
        <div class="card-content rightPadding ">

          <h5>
            <div class="title-blue"></div> Students
          </h5>
          <ul>
            <li>Ace your tests with Infinite Test Prep</li>
            <li>AI-guided test prep for SAT, GRE, USMLE, LSAT, ACT, and more.</li>
            <li>Improve your grades with curriculum-aligned courses.</li>
            <li>Personalized, adaptive learning with our 24/7 conversational AI tutor.</li>
          </ul>
        </div>
        <img class="rightimage" src="../../assets/newDesignImage/galery1.png" alt="Students Image">
      </div>
    </div>

    <!-- Parents - Image Left, Content Right -->
    <div class="col-md-12 mb-4 content-sectionbox">
      <div class="card galleryrowreverse">
        <div class="card-content reverse-padding">
          <h5>
            <div class="title-yellow"></div> Parents
          </h5>
          <ul>
            <li>Improved grades and test scores for your children.</li>
            <li>Real-time progress reports delivered to you daily.</li>
            <li>Cost savings compared to hiring traditional tutors.</li>
            <li>Safe, secure study assistance platform.</li>
            <li>Improve university/college acceptances.</li>
          </ul>
        </div>
        <img class="reverse-img" src="../../assets/newDesignImage/galery2.png" alt="Parents Image">
      </div>
    </div>

    <!-- Professionals - Content Left, Image Right -->
    <div class="col-md-12 mb-4 content-sectionbox">
      <div class="card galleryrow">
        <div class="card-content rightPadding">
          <h5>
            <div class="title-green"></div>Professional Certifications
          </h5>
          <ul>
            <li>Continued Medical Education certification (CME).</li>
            <li>Vocational education made engaging, interactive, and 24/7 accessible.</li>
          </ul>
        </div>
        <img class="rightimage" src="../../assets/newDesignImage/galery3.png" alt="Professionals Image">
      </div>
    </div>

    <!-- Athletes - Image Left, Content Right -->
    <div class="col-md-12 mb-4 content-sectionbox">
      <div class="card galleryrowreverse">
        <div class="card-content reverse-padding ">
          <h5>
            <div class="title-darkblue"></div> Athletes (Player’s Pal)
          </h5>
          <ul>
            <li>Our AI coaches simplify sports learning.</li>
            <li>Upload, review, and test playbook content.</li>
            <li>24/7 tutor for academic support.</li>
            <li>Sports psychology wellness companion.</li>
          </ul>
        </div>
        <img class="reverse-img" src="../../assets/newDesignImage/galery4.png" alt="Athletes Image">
      </div>
    </div>
    </div>
  </div>
</div>

<!--  -->

 <!-- testimonial -->

 <app-testimonial-card></app-testimonial-card>

 <!--  -->

 <!-- review -->
 <section class="testimonila-review">
   <br>
   <hr>
   <div class="review-section">
     <div class="review-content">
       <h2> <span style="color: yellow;">★ ★ ★ ★ ★</span></h2>

       <p>
         “It's made learning easier for me. I wish I knew how about this when I was taking the STEP exams. It helps me
         retain information, so I don't really have to study a lot. It's made learning fun for me.”
         <br><br>
         <b> Dr. Shakirat </b> <br>
         edYOU Student & <br>
         Internal Medicine Resident Doctor
       </p>
     </div>
     <div class="ai-image">
       <div class="video-container">
         <video muted loop controls playsInline webkit-playsinline class="portrait-video">
           <!-- <source muted mute loop src="https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/Dr.+Shakiratnew.MP4" -->
           <source muted mute loop src="../../assets/video/Dr. Shakiratnew.mp4"
             >
         </video>
       </div>


     </div>
   </div>

   <br>

   <hr>
 </section>

 <!--  -->



 <!-- abc of edyou -->
 <div class="abc-edyou">

   <div class="center-heading mt-4">
     <span>The ABC’s of edYOU</span>
   </div>

   <div class="icon-row">
     <div class="icon-container">
       <img src="../../assets/newDesignImage/A-letter.png" alt="Icon A" class="icon">
       <span class="icon-text">Ace <br>your tests</span>
     </div>
     <div class="icon-container">
       <img src="../../assets/newDesignImage/B-letter.png" alt="Icon B" class="icon">
       <span class="icon-text">Better <br>your grades</span>
     </div>
     <div class="icon-container foripadmargintop">
       <img src="../../assets/newDesignImage/C-letter.png" alt="Icon C" style="width: 71px;" class="icon">
       <span class="icon-text">Certifications <br>for professionals</span>
     </div>
   </div>
 </div>
 </div>


<div class="overlay" *ngIf="isModalOpen ">
  <div class="modal-overlay">
    <div class="modal-content">
      <!-- <span class="close-btn" (click)="closeModal()">&times;</span> -->
      <div class="iconclose" (click)="closeModal()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M17 7L7 17M7 7L17 17" stroke="black" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </div>
      <div class="modal-heading">Welcome to the world of edYOU</div>
      <p *ngIf="b2bplan === true" class="modal-p">Get started on a lesson now. </p>
      <p *ngIf="b2bplan === false" class="modal-p">Get started on a lesson now, or head to your profile for an overview
      </p>
      <div class="row">
        <button *ngIf="b2bplan === false" class='buttonprofile' style="background: white;
    color: #2A7CC7;" (click)="navigateToProfile()">Go To Profile</button>
        <button style="  background-color: #144c84; cursor: pointer;" class='buttonprofile'
          (click)="linkToedyouUser()"><img class="" style="width : 24px;  color:white;" *ngIf="isLoading2"
            src="../../assets/images/Rolling-1s-200px (2).gif"> <span class="btnSubmit" *ngIf="!isLoading2"> Start
            Learning </span></button>
      </div>
    </div>
  </div>
</div>
