import { Component, OnInit,HostListener , PLATFORM_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-news-page',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.css']
})
export class NewsPageComponent implements OnInit {

  constructor(@Inject(PLATFORM_ID) private platformId: Object,private router:Router) { }
  isMobileScreen: boolean = false
  pressSection: any = [
    { backgroundimg: "../../assets/newpress/newpress1.png", text: "Residency Success: Key Tools and Strategies Residents Medical Provides for Aspiring Doctors Facing OSCE and Beyond.", link: "https://www.medicaldaily.com/residency-success-key-tools-strategies-residents-medical-provides-aspiring-doctors-facing-osce-472521" },

    { backgroundimg: "../../assets/newpress/newpress2.png", text: "edYOU Launches 3 Subscription Tiers to the Public", link: "https://worldreporter.com/edyou-launches-3-subscription-tiers-to-the-public/" },

    { backgroundimg: "../../assets/newpress/newpress3.png", text: "Challenging The Myth Of Social Awkwardness In Homeschooling: How edYOU's AI Experiences Support Social Development.", link: "https://www.benzinga.com/partner/general/24/10/41378898/challenging-the-myth-of-social-awkwardness-in-homeschooling-how-edyous-ai-experiences-support-soc?utm_campaign=edYOU%20Social%20Media%20Campaign&utm_content=313777717&utm_medium=social&utm_source=twitter&hss_channel=tw-1661850749587038208" },

    { backgroundimg: "../../assets/newpress/newpress4.png", text: "edYOU Launches Direct-To-Consumer Platform", link: "https://lawire.com/edyou-launches-direct-to-consumer-platform/" },

    { backgroundimg: "../../assets/newpress/newpress5.png", text: "edYOU is the Future of Learning and Wellness.", link: "https://localnews7.medium.com/edyou-is-the-future-of-learning-and-wellness-3f2f8bdcd9d9" },

    { backgroundimg: "../../assets/newpress/newpress6.png", text: "Exploring Affordable Options for Quality Learning: How Dr. Michael Everest's edYOU Offers Flexible Plans for Every Budget.", link: "https://www.techtimes.com/articles/307705/20241002/exploring-affordable-options-quality-learning-how-dr-michael-everests-edyou-offers-flexible.htm" },
    // { backgroundimg: "../../assets/newpress/newpress6.png", text: "", link: "" },
    { backgroundimg: "../../assets/newpress/newpress5.png", text: "Back to school: Ensuring a smooth return with edYOU's support", link: "https://napavalleyregister.com/life-entertainment/back-to-school-ensuring-a-smooth-return-with-edyous-support/article_3879b046-7831-5bfe-9392-826e0f783030.html" },

    { backgroundimg: "../../assets/newpress/newpress7.png", text: "edYOU Goes Public with Three Subscription Tiers: A Plan for Every Learner", link: "https://www.usatoday.com/story/special/contributor-content/2024/09/12/edyou-goes-public-with-three-subscription-tiers-a-plan-for-every-learner/75190118007/" },

    { backgroundimg: "../../assets/newpress/newpress4.png", text: "Learning on the Go: How edYOU’s AI Tools Help You Make the Most of Your Busy Schedule", link: "https://aijourn.com/learning-on-the-go-how-edyous-ai-tools-help-you-make-the-most-of-your-busy-schedule-2/" },

    { backgroundimg: "../../assets/newpress/newpress9.png", text: "AI Educational Program making an impact.", link: "https://fox5sandiego.com/thelocalistsd/sponsored-content-ai-educational-program-making-an-impact/" },

    { backgroundimg: "../../assets/newpress/newpress10.png", text: "Discover how edYOU uses AI to empower students with disabilities through specialized tutoring programs", link: "https://venturebeat.com/business/discover-how-edyou-uses-ai-to-empower-students-with-disabilities-through-specialized-tutoring-programs/" },

    { backgroundimg: "../../assets/newpress/newpress11.png", text: "AI in education: how it’s already being used and what’s going to happen next", link: "https://www.techfinitive.com/features/ai-in-education-how-its-already-being-used-and-whats-going-to-happen-next/" },
    { backgroundimg: "../../assets/newpress/newpress12.png", text: "Will AI hurt or help workers? It's complicated", link: "https://www.zdnet.com/article/will-ai-hurt-or-help-workers-its-complicated/" },
    { backgroundimg: "../../assets/newpress/newpress13.png", text: "Exploring How edYOU Empowers Students with Disabilities via Tailored Tutoring Platforms", link: "https://businessmondays.co.uk/exploring-how-edyou-empowers-students-with-disabilities-via-tailored-tutoring-platforms/" },
    // { backgroundimg: "../../assets/newpress/newpress12.png", text: "", link: "" }

  ];


  blogsection:any = [
    { backgroundimg: "./assets/newb2cmedia/blog-1.png", id: "1", },
    { backgroundimg: "./assets/newb2cmedia/blog-2.png",  id: "2" },
    // { backgroundimg: "", text: "", id: "2" },
  ]
  private isBrowser: boolean =false;
  ngOnInit(): void {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (isPlatformBrowser(this.platformId)) {
      this.isMobileScreen = window.innerWidth <= 600;
    }
     
  }

 


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobileScreen = event.target.innerWidth <= 600;
  }
  navigateToLab(){
        this.router.navigate(['/labs'])
  
    }
  
    // navigateToEdyousAi(){
    //   this.router.navigate(['/how-edyous-ai-is-reimagining-the-future-of-personalized-learning']);
    // }
    // navigateToRevolutionizingEducation(){
    //   this.router.navigate(['/revolutionizing-education-with-edyou-bridging-the-post-pandemic-learning-gap']);
    // }

  gotonewblog(data:any){
    console.log(data)
    switch (data) {
      case "1":
        this.router.navigate(['/how-edyous-ai-is-reimagining-the-future-of-personalized-learning']);
        break;
      case "2":
        this.router.navigate(['/revolutionizing-education-with-edyou-bridging-the-post-pandemic-learning-gap']);
        break;
      default:
        console.error("Invalid ID:", data);
        break;
    }
  }

  gotopodcast(){
    const url = 'https://rss.com/podcasts/wearables-meet-ai-a-new-era-of-health-insights/';
    if(this.isBrowser){
    window.open(url, '_blank');
    }
  }
}
