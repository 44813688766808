import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Inject, NgZone, OnInit, Output, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { Persona, Scene } from '@soulmachines/smwebsdk'
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import { SpeechRecognizer } from 'microsoft-cognitiveservices-speech-sdk';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';

declare var $: any;

import { SwalService } from 'src/app/service/swal.service';
import { environment } from 'src/environments/environment';
import { API } from 'src/app/service/restapi';
import Swal from 'sweetalert2';
import { isPlatformBrowser } from '@angular/common';
//import { tour } from'../../assets/js/index';
//declare var Persona: any; // Declare Persona class if not automatically available

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-uneeqavatar',
  templateUrl: './uneeqavatar.component.html',
  styleUrls: ['./uneeqavatar.component.css']
})
export class UneeqavatarComponent implements OnInit, AfterViewInit {
  stringRef = String;
  // Set the timeout duration in milliseconds (28 seconds)
  //  optionListTestprep: any = ['hello', 'test', 'slove the eauationtest', 'end this text'];
  //  timeoutDuration = 29000;
  isActive: boolean = false;
  isHovered: boolean = false;
  fullScreen: boolean = false;
  userText: any
  connectionFailureCount = 0;
  elseConditionCount = 0;
  isSpinner: boolean = true
  iconShow: boolean = false
  // contentCard: any;
  unmuteMicrophone: boolean = false
  user: any = [];
  isMobileNormalTrue: boolean = false
  uneeq: any
  showMic: boolean = false
  token: any = '';
  //msgDisplay: any;
  //avatarTextActive: any;
  isMobileTrue: boolean = false
  // counting: number = 0
  // QuestionListData: any;
  @Output("pptclose") pptclose: EventEmitter<any> = new EventEmitter();

  ccOnOff: boolean = true;
  videoMuted: boolean = false
  styleWidthSize: any;
  stleHeightSize: any
  ischatBoxOpen: boolean = false
  idleTimeout: any;
  messageForQueueAvatar: boolean = false;
  mobileAvatarOnOff: any = false;
  showImage: boolean = false;
  smallSizeImage: boolean = false
  bigSizeImage: boolean = true
  avatarHideOnOff: boolean = false
  stopAvatarOnClick: boolean = false;
  mic: any
  isvoiceAnimationOn: boolean = false;
  UserccOnOff: boolean = true;
  questionList: any;
  optionList: any = [
    // 'A Give blood', 'B Look fff','C dgdgdgdgd' ,'D sssssssss'
  ];
  DescAnswer: any;
  QuestionccOnOff: boolean = true;
  hideHelpSetting: boolean = true
  checkFullScreenB: any = false
  accod1: boolean = true;
  accod2: boolean = false;
  CorrectAnswer: any;
  ImageData: any;
  linkDisData: any;
  dashboardTour: any
  followup: any;
  followName: any;
  feedback: boolean = false;
  //openFeedbackForm: boolean = false;
  isClick: boolean = false;
  checkTestSeriespage: any;
  expandOn: boolean = false

  mediaStream: any;
  audioTrack: any;
  pdfShow: any
  //= "https://pollydemo2022.s3.us-west-2.amazonaws.com/Presentation/49b4c467f429f846989cde5dbe9da95ffc.pdf";
  runLoderGPT: boolean = false;
  @ViewChild('childPdf') childPdf: any;
  @ViewChild('child') childMenu: any;
  userSpeakValue: any;
  pageReload: boolean = true;
  recognition: any;
  isListening = false;
  voiceActiveSectionDisabled: boolean = true;
  voiceActiveSectionError: boolean = false;
  voiceActiveSectionSuccess: boolean = false;
  voiceActiveSectionListening: boolean = false;
  voiceText: any
  showGraph = true;
  tourGuideValueCheck: any;
  micToggle: boolean = true;
  microphone: MediaStreamAudioSourceNode | any
  //
  recognizer: SpeechRecognizer | any;
  subscriptionKey = '9a9e0a9d5d7e4cebb5deee50ed7aa3db';
  serviceRegion = 'eastus';
  language = 'en-US'; // e.g. 'en-US'
  recognizer2: any
  speechRecognizer: any;
  spaceBarActive: boolean = false;
  isMicButtonActive: boolean = false;
  lastRecognized: any;
  recognizing: boolean = false;
  speechConfig: any;
  //
  timeoutID: any;
  isSubtitleAnimationRunning: boolean = false;
  isSubtitleON: boolean = true;
  SaveNewContData: any;
  isManualScrolling: any;
  nextButtonTestseries: boolean = false;
  hideOptionTempraryFormobile: boolean = true;
  checkOptionColor: any;
  avatarName: string = 'Hannah'
  total_testseries_len: any;
  current_testseries_len: any;
  test_Progress: any;
  test_progress_Percent: any;

  approachesList: any = [1, 2, 3];
  User_Question: any;
  isgraphLoaded: boolean = false;
  normalGPT: boolean = false
  resumeButtonTestseries: boolean = false;
  disableMicButton: boolean = false;
  UserQuestion_Display: any;
  mathematicsEnabled: boolean = false;
  avatarAnswerContent: any;
  inputMathsValue: any = "";
  scene: any;
  isMicrophoneOn: boolean = false;
  _personaId: any;
  persona: any;
  testPrepList: boolean = false;
  userInputText: any;
  handlingMessgeForMaths: any;
  normalChatBar: boolean = true
  mathsChatBar: boolean = false
  inputMathsValue2: any = "";
  mathInputClear: boolean = false;
  toggleValue: boolean = false;
  isDropDownSetting: boolean = false;
  bottomPosition: any;
  borderRadius: any;
  bottomPositionWidth: any;
  inputWidthSize: any;
  inputmarginLeft: any;
  inputheightSize: any;
  bottomPositionheight: any
  mathsQuestion: any;
  refreshBtnPPT: boolean = false
  stopLeftSize: any
  stopBottomSize: any
  mathButtonDisabled: boolean = false
  ccBox: boolean = true;
  micWidth: any;
  micHeight: any;
  backgroundColor: any;
  micWidthOnly: any;
  micMarginleft: any
  deviceInfo: any
  micMarginLeft: any;
  micMarginLeft2: any;
  inputMarginLeft: any;
  notSchool: any;
  hasDisconnected = false;
  isKeyboardOn: boolean = false;
  mathsMininmizeBtn: boolean = false;
  prepSubject: any;
  isNextStep: any;
  isPreviousStep: any;
  contentType: any;
  linkPDF: any;
  pdfType: any;
  isBackToStepsOn: boolean = false
  browserList: any = [];
  ipadColor: any;
  winCrome: any;
  winEdge: any;
  iosChromeColor: any;
  macOSEdgeColor: any;
  windowsChromeColor: any;
  macOSSafariColor: any;
  windowsEdgeColor: any;
  androidColor: any;
  windowsFirefoxColor: any;
  macOSChromeColor: any;
  iOSsafariColor: any;
  windowUniversal: any;
  storedChatBubbleMessage: any = [];
  showLoaderMess: boolean = false;
  baseUrl = "https://v6w3mrkkya.execute-api.us-west-2.amazonaws.com"
  @Output() storedErrorCode = new EventEmitter<any>();
  //<!-- ------------------------------------- Anand's for gamification----------------------------------------------------- -->
  @Output() exitTestClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() exitCourseClicked: EventEmitter<any> = new EventEmitter<any>();
  APIKey: any;
  dotIndicatorAnimation: boolean = false;
  muteVoiceAvatar: boolean = false;
  testSeris_Music: boolean = false;
  box2MarginLeft: any;
  blurEffect: boolean = false;
  // stopIconRightPosition: any;
  //stopIconBottomPosition: any;
  bubblewindowOnOff: boolean = false;
  stopIconWidthPosition: any;
  stopIconHeightPosition: any;
  learningContent: any;
  focusInput: any;
  mainClassBottomsize: any;
  //<!-- ------------------------------------- Anand's for gamification----------------------------------------------------- -->
  swalFireData: any;
  type: any;
  payload: any;
  isSwal: boolean = false;
  enterKeyPress: any;
  correct_Ans: any;
  total_Question: any;
  wrong_Ans: any;
  score_earned: any;
  totalRank: any;
  overall_Rank: any;
  testLoade: boolean = false
  descptionContentLoader:boolean = false
  private videoElement: HTMLVideoElement | null = null;
  inputWidthMathSize: any;
  async loadSdk() {
    if (isPlatformBrowser(this.platformId)) {
      // Dynamically import the @soulmachines/smwebsdk modules only in the browser
      const { Persona, Scene } = await import('@soulmachines/smwebsdk');
      console.log('smwebsdk loaded:', Persona, Scene);

      // Now you can use Persona and Scene
      // For example, initialize a Persona object
   
      // Initialize as needed
    }
  }

  // speechspeed ==================
  @ViewChild('popup1') popup1!: ElementRef;
  @ViewChild('dropdownToggle') dropdownToggle!: ElementRef;
  isDropdownOpen: boolean = false;
  isBrowser: boolean = false;
  

  constructor(  @Inject(PLATFORM_ID) private platformId: Object,private router: Router, private ngZone: NgZone, private _location: Location, private elementRef: ElementRef, private renderer: Renderer2,
    private ser: UserService, 
    private _swalService: SwalService) {

  }


  ngAfterViewInit() {
    // MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    // Wait for the DOM elements to be dynamically added
    const smVideoDiv = document.getElementById('sm-video');

    if (smVideoDiv) {
      // Observe when new elements are added to the 'sm-video' div
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node: any) => {
            if (node.tagName === 'VIDEO') {
              this.videoElement = node as HTMLVideoElement;
              this.videoElement.muted = false // Set initial muted state

              // Add a 'loadedmetadata' event listener to ensure the video is loaded
              this.videoElement.addEventListener('loadedmetadata', () => {
                console.log('Video loaded, attaching mute functionality.');
              });
            }
          });
        });
      });

      observer.observe(smVideoDiv, { childList: true, subtree: true });
    }
  }


  ngOnInit(): void {
    this.isBrowser = isPlatformBrowser(this.platformId); 
    // this.startTimer()
    this.fullScreen = true
    this.isSpinner = true
    this.avatarName = this.ser.avatar
    this.onLoadCard('')

    this.browserList = JSON.parse(localStorage.getItem('browserList') || '[]')
    this.user = JSON.parse(localStorage.getItem('tryuser') || '[]')
    this.token = localStorage.getItem('token')
    let selectedSpeed = localStorage.getItem('speechSpeed')
    if (selectedSpeed == null || selectedSpeed == undefined) {
      localStorage.setItem('speechSpeed','1x')
      this.selectedSpeed = localStorage.getItem('speechSpeed')
    } else {
      this.selectedSpeed = localStorage.getItem('speechSpeed')
    }
    //  this.findColor()
    // this.fullScreen = true
    if (this.user.Presentation_View == "PPT with avatar") {
      //  this.startAvatarFunction()
    }
    if (this.user.Firstlogin == false) {
      // setTimeout(() => {
      //   this.startTours()
      // }, 5000)

      //  fresh account first login
      this.fullScreen = true
      this.onLoadCard('id')
      $('#music_Off').addClass('showI');

    } else if (this.user.Firstlogin == true) {

      //  not first login
      this.fullScreen = false
      this.onLoadCard('id')
    }
    if(this.isBrowser){
    // this.checkDeviceAndColor()
 
    window.addEventListener('resize', this.resizeFun);
    if (window.innerWidth < 480) {
      //hide card
      if (this.user.Firstlogin == false) {

      } else {
        // $('#movableCard').addClass('showI')
        // this.mobileAvatarOnOff = true
      }
    }


    // remove item on page refresh 
    window.onbeforeunload = function () {
      localStorage.removeItem('Avatar');
      localStorage.removeItem('screen');
      localStorage.removeItem('learningId');
      localStorage.removeItem('mathtoggle');
    };


    // this.avatarFunction()
    // this.startAvatarFunction()

    this.ser.checkMathsInput.subscribe((value: any) => {
      // console.log('value of obj', value)
      if (value !== null) {
        if (value.MathLive == false || value.MathLive == 'false') {
          console.log('run when false')
          this.mathsChatBar = false
          this.normalChatBar = true
        } else if (value.MathLive == true || value.MathLive == 'true') {
          console.log('run when true')
          this.normalChatBar = false
          this.mathsChatBar = true
        }
      }


      if (value !== null) {
        console.log('run when data ')
        this.refreshBtnPPT = value.refreshBtnPPT
        this.mainClassBottomsize = value.mainClassBottomsizes
        this.bottomPosition = value.bottomPosition
        this.borderRadius = value.borderRadius
        this.micMarginLeft2 = value.micMarginLeft2,
          this.inputMarginLeft = value.inputMarginLeft,
          this.bottomPositionWidth = value.bottomPositionWidth
        this.inputWidthSize = value.inputWidthSize
        this.inputmarginLeft = value.inputmarginLeft
        this.inputheightSize = value.inputheightSize
        this.bottomPositionheight = value.bottomPositionheight,
          this.stopLeftSize = value.stopLeftSize,
          this.stopBottomSize = value.stopBottomSize
        this.mathButtonDisabled = value.mathButtonDisabled
        this.toggleValue = value.mathtoggleValue
        this.micWidth = value.micWidth
        this.micHeight = value.micHeight
        this.micWidthOnly = value.micWidthOnly
        this.isMobileTrue = value.isMobileTrue
        this.prepSubject = value.subject
        this.bubblewindowOnOff = value.bubbleOnOff

        //this.chatBubbleFun()
        if (value.hideMathsToggle == 'true' || value.hideMathsToggle == true) {
          this.notSchool = 'notSchool'
        }

        let toggle: any = JSON.parse(localStorage.getItem('mathtoggle') || 'false')
        if (toggle == true) {
          if (value.mathtoggleValue == false) {
            this.userCCOnOf()
            $('#mathApproach').removeClass('showMessage')
          }

        } else { }
      }
    });

    if (this.user.industryName == "School") {
      this.notSchool = "school"
    } else {
      this.notSchool = 'notSchool'
    }

    this.addResizeListener()
  }

  }


  startTours() {
   
  }


  isToggleOrAncestor(element: HTMLElement | null, toggleClassName: string): boolean {
    if (!element) return false;
    if (element.classList.contains(toggleClassName)) return true;
    return this.isToggleOrAncestor(element.parentElement, toggleClassName);
  }


  clickEvent(event: any) {
    //    const target = event.target as HTMLElement;
    // Get the target element by its id
    const target = document.getElementById('eventTest');
    if (target) {
      const rect = target.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      // Define the clickable range (50px to 60px from the right edge of the element)
      const clickableRangeStart = rect.width - 60; // 60px from the right edge
      const clickableRangeEnd = rect.width - 20;   // 50px from the right edge
      const targetE = event.target as HTMLElement;

      if (innerWidth >= 1367 && innerWidth < 1600) {
        console.log('Clicked within 1367 to 1600');
        if (x >= clickableRangeStart && x <= clickableRangeEnd) {
          console.log('Clicked within the clickable range');
          this.mathEventHit("")
        } else {
          // console.log('Clicked without the clickable range');
        }
      } else if (innerWidth >= 1601 && innerWidth < 3300) {
        console.log('Clicked within 1600 to 3300');
        if (x >= clickableRangeStart && x <= clickableRangeEnd) {
          console.log('Clicked within the clickable range');
          this.mathEventHit("")
        } else {
          // console.log('Clicked without the clickable range');
        }
      } else {
        this.mathEventHit("")
      }



    }


  }

  clickCount = 0;
  mathEventHit(event: any) {
    console.log('focus---------------on------------------------')
    this.clickCount++;


    if (this.isKeyboardOn === true) {
      console.log('yes')
      $('.box-1').css('margin-top', '');
      $('#textTourBox').css('background', '');
      $('.box-2').css('margin-top', '');
      // $('#sendId1').css('background', '');
      $('#minimiseMathBTN').addClass('showI');
      this.mathsMininmizeBtn = false
      $('#minimiseMathBTN').css('margin-top', '');
      $('#stopspeakingID').css('z-index', '1400000');
    } else {

      // Trigger a click event on the CSS element
      //var mathFieldPart:any = document.querySelector(".math-field::part(virtual-keyboard-toggle)");
      // mf.mathVirtualKeyboardPolicy = "manual";
      // mf.addEventListener("focusin", () =>  mathVirtualKeyboard.show());



      if (window.innerWidth < 480) {
        $('.box-1').css('margin-top', '-430px');
        $('.box-2').css('margin-top', '-430px');
        this.mathsMininmizeBtn = true
        $('#minimiseMathBTN').removeClass('showI');
        $('#minimiseMathBTN').css('margin-top', '-480px');
        // $('#sendId1').css('background', '#4E576D');
        $('#stopspeakingID').css('z-index', '1400');
        // $('#textTourBox').css('background', '#4E576D');
        console.log('no')

      } else if (innerWidth >= 600 && innerWidth <= 1024) {


        if ((innerHeight == 810 || innerHeight == 740) && (innerWidth == 1080)) {
          $('.box-1').css('margin-top', '-650px');
          $('.box-2').css('margin-top', '-650px');
          this.mathsMininmizeBtn = true
          $('#minimiseMathBTN').removeClass('showI');
          $('#minimiseMathBTN').css('margin-top', '-700px');
          // $('#sendId1').css('background', '#4E576D');
          // $('#textTourBox').css('background', '#4E576D');
          console.log('no')
        } else {
          $('.box-1').css('margin-top', '-750px');
          $('.box-2').css('margin-top', '-750px');
          this.mathsMininmizeBtn = true
          $('#minimiseMathBTN').removeClass('showI');
          $('#minimiseMathBTN').css('margin-top', '-800px');
          // $('#sendId1').css('background', '#4E576D');
          // $('#textTourBox').css('background', '#4E576D');
          console.log('no')
        }

      } else if (innerWidth >= 1025 && innerWidth < 1399) {
        console.log('range 1025 to 1399')
        $('#minimiseMathBTN').removeClass('showI');
        if ((innerHeight == 950 || innerHeight == 905 || innerHeight == 1024) && (innerWidth == 1366)) { // ipad pro
          $('.box-1').css('margin-top', '-720px');
          $('.box-2').css('margin-top', '-720px');
          // $('#sendId1').css('background', '#4E576D');
          $('#minimiseMathBTN').removeClass('showI');
          $('#minimiseMathBTN').css('margin-top', '-770px');
          //$('#textTourBox').css('background', '#4E576D');
          console.log('range 1366 to 1024')
        } else if ((innerHeight == 746 || innerHeight == 820) && (innerWidth == 1180)) { // ipad 10
          $('.box-1').css('margin-top', '-600px');
          $('.box-2').css('margin-top', '-600px');
          // $('#sendId1').css('background', '#4E576D');
          $('#minimiseMathBTN').removeClass('showI');
          $('#minimiseMathBTN').css('margin-top', '-650px');
          // $('#textTourBox').css('background', '#4E576D');
          console.log('no')

        } else if ((innerHeight == 746 || innerHeight == 834 || innerHeight == 760 || innerHeight == 727) && (innerWidth == 1194)) { // ipad prp 11
          $('.box-1').css('margin-top', '-650px');
          $('.box-2').css('margin-top', '-650px');
          // $('#sendId1').css('background', '#4E576D');
          $('#minimiseMathBTN').removeClass('showI');
          $('#minimiseMathBTN').css('margin-top', '-700px');
          //  $('#textTourBox').css('background', '#4E576D');
          console.log('no')
        }
        else {
          $('.box-1').css('margin-top', '-700px');
          $('.box-2').css('margin-top', '-700px');
          this.mathsMininmizeBtn = true
          $('#minimiseMathBTN').removeClass('showI');
          $('#minimiseMathBTN').css('margin-top', '-750px');
          $('#stopspeakingID').css('z-index', '1400');
          //  $('#sendId1').css('background', '#4E576D');
          //  $('#textTourBox').css('background', '#4E576D');
          console.log(' else 1025 to 1366')
        }
      }
      else if (innerWidth >= 1400 && innerWidth < 1600) {

        this.mathsMininmizeBtn = true
        if (innerWidth == 1536) {
          $('.box-1').css('margin-top', '-500px');
          $('.box-2').css('margin-top', '-500px');
          $('#minimiseMathBTN').removeClass('showI');
          $('#minimiseMathBTN').css('margin-top', '-550px');
          // $('.box-1').css('margin-top', '-500px');
          // $('.box-2').css('margin-top', '-500px');
          $('#stopspeakingID').css('z-index', '1400');
          // $('#sendId1').css('background', '#4E576D');
          // $('#textTourBox').css('background', '#4E576D');
          console.log('no')
        } else if (innerWidth == 1440) {
          $('.box-1').css('margin-top', '-500px');
          $('.box-2').css('margin-top', '-500px');
          $('#minimiseMathBTN').removeClass('showI');

          $('#minimiseMathBTN').css('margin-top', '-550px');
          // $('.box-1').css('margin-top', '-500px');
          // $('.box-2').css('margin-top', '-500px');
          $('#stopspeakingID').css('z-index', '1400');
          // $('#sendId1').css('background', '#4E576D');
          // $('#textTourBox').css('background', '#4E576D');
        }
        else {
          $('.box-1').css('margin-top', '-600px');
          $('.box-2').css('margin-top', '-600px');
          $('#minimiseMathBTN').removeClass('showI');
          $('#minimiseMathBTN').css('margin-top', '-650px');
          // $('.box-1').css('margin-top', '-500px');
          // $('.box-2').css('margin-top', '-500px');
          $('#stopspeakingID').css('z-index', '1400');
          // $('#sendId1').css('background', '#4E576D');
          //$('#textTourBox').css('background', '#4E576D');
          console.log('no')
        }
      } else if (innerWidth >= 1601) {
        this.mathsMininmizeBtn = true
        $('.box-1').css('margin-top', '-700px');
        $('.box-2').css('margin-top', '-700px');
        $('#minimiseMathBTN').removeClass('showI');
        $('#minimiseMathBTN').css('margin-top', '-750px');
        $('#stopspeakingID').css('z-index', '1400');
        //  $('#sendId1').css('background', '#4E576D');
        //   $('#textTourBox').css('background', '#4E576D');
        console.log('no')
      }

    }
    this.isKeyboardOn = !this.isKeyboardOn


  }





  StartTourGuideSetting() {
    if (isPlatformBrowser(this.platformId)) {
      // Dynamically import 'tour' only in the browser
      import('../../assets/js/index').then((module) => {
        const { tour } = module;  // Extract the 'tour' function from the module
        tour();  // Call the tour function after it is loaded
        console.log('tour---------------');
      }).catch((err) => {
        console.error('Error loading tour:', err);
      });
    } else {
      console.warn('Tour guide is not available during SSR');
    }
  
  }


  naviagtionTOHelp() {
    this.oncrossTest();
    // if (this.UserccOnOff == true) {
    //   const userText = this.elementRef.nativeElement.querySelector('#userText');
    //   this.renderer.addClass(userText, 'showI');
    //   console.log('inner help navigate run')
    // }
    this.persona.stopSpeaking()
    this.router.navigate(['/user/help'])

  }




  cleanupSessionStorage() {
    sessionStorage.clear();
  }


  // uneeq function start

  startAvatarFunction() {
    if (isPlatformBrowser(this.platformId)) {
      // Only load the library in the browser
      import('uneeq-js')
        .then((module) => {
          const Uneeq = module.Uneeq;

          // Get the video element
          const v1: any = document.getElementById('sm-video');
          let conversationId: any = 'fc31d7e4-a40b-4257-898f-8a88a8fc1fcb';

          let options = {
            url: 'https://api.us.uneeq.io',
            conversationId: 'fc31d7e4-a40b-4257-898f-8a88a8fc1fcb',
            avatarVideoContainerElement: v1,
            localVideoContainerElement: v1,
            messageHandler: (msg: any) => this.messageHandler(msg),
            sendLocalVideo: false,
            enableMicrophone: true,
            speechToTextLocales: 'AvaMultilingualNeural',
            micActivityMessages: true,
            playWelcome: true,
            enableTransparentBackground: true,
            voiceInputMode: 'PUSH_TO_TALK',
            sendLocalAudio: true
          };

          // Initialize Uneeq
          this.uneeq = new Uneeq(options);

          let pay = {
            email: this.user.email,
            personaId: conversationId
          };

          // API call to initialize token
          this.ser.newInitToken(pay).subscribe((res: any) => {
            if (res.statusCode === 200) {
              this.ser.updateSharedData(this.uneeq);
              this.tourGuideValueCheck = res.loginCheck;
              this.uneeq.initWithToken(res.body.token);
            } else if (res.statusCode == 400) {
              var error = res.statusCode
              this.storedErrorCode.emit(error);
            } else {
              var error = res.statusCode
              this.storedErrorCode.emit(error);
            }
          });
        })
        .catch((err) => {
          console.error('Error loading Uneeq:', err);
        });
    } else {
      console.warn('Uneeq is not available during SSR');
    }
  }


  // startAvatarFunction() { console.log(this.user.email)
  //   //this.msgDisplay = document.getElementById('msg');
  //   const v1: any = document.getElementById('sm-video');
  //   let options = {
  //     url: 'https://api.us.uneeq.io',

  //     // hannah local
  //     conversationId: '9fd11d0e-b63c-4ef0-8de3-2432abc37e2b',
  //     //  hannah prod
  //   // conversationId: 'a520bcec-d557-4c2b-aab1-d9ed4d2e1e9d',
  //     // nova prod

  //     avatarVideoContainerElement: v1,
  //     localVideoContainerElement: v1,
  //     messageHandler: (msg: any) => this.messageHandler(msg),
  //     sendLocalVideo: false,
  //     enableMicrophone: true,
  //     speechToTextLocales: 'en-US:ja-JP:de-DE',
  //     micActivityMessages: true,
  //     playWelcome: true,
  //     //logging:true,
  //     enableTransparentBackground: true,
  //     voiceInputMode: "PUSH_TO_TALK",
  //     // voiceInputMode: "VOICE_ACTIVITY",
  //     sendLocalAudio: true
  //   }

  //   this.uneeq = new Uneeq(options);
  //   let uneeqPayload = {
  //     // token: this.token,
  //     // name: this.user.name,
  //     email: this.user.email,
  //     // prompt: this.user.prompt,
  //     // lastlogin: this.user.lastlogin,
  //   }
  //   this.ser.uneeqAvatar(uneeqPayload).subscribe((res: any) => {
  //     if (res.statusCode == 200) {
  //       this.ser.updateSharedData(this.uneeq);
  //       //
  //       this.tourGuideValueCheck = res.loginCheck
  //       this.uneeq.initWithToken(res.body.token);
  //       // $('#avatarLoaders').css('display', 'none')
  //       // 30 sec prompt to
  //       //  document.body.addEventListener('click', () => this.reset());
  //       //  document.body.addEventListener('mouseover', () => this.reset());
  //       //  document.body.addEventListener('keypress', () => this.reset());
  //     } else if (res.statusCode == 400) {
  //       var error = res.statusCode
  //       this.storedErrorCode.emit(error);
  //     } else {
  //       var error = res.statusCode
  //       this.storedErrorCode.emit(error);
  //     }
  //   })
  // }

  sessionLiveForAvatar() {
    //this.uneeq.uneeqSetCustomChatMetadata(JSON.stringify({name:'mohit rai'}))
    // $('#avatarLoaders').css('display', 'none')
    $('#chat-widget-minimized').css('display', 'none');
    $('#chat-widget-container').css('height', '4px');
    this.isSpinner = false
    setTimeout(()=>{
      this.newpresentationapi()
    },5000)
    //  this.checkConnectionSpeed()
    // this.avatarsizechangeonCall()

    // Add key listeners on spacebar for start and stop recording

    this.addSpacebarEventListeners()
    localStorage.setItem('sessionId', this.uneeq.sessionId)
    let zoomPayload = {
      "sessionID": this.uneeq.sessionId,
      "email": this.user.email,
     
    }
    //  this.ser.zoomSetting(zoomPayload).subscribe((res: any) => {
    //  })
  }



  messageHandler(msg: any) {
    // console.log('all uneeq message =>', msg)
    
    switch (msg.uneeqMessageType) {

      // SessionLive: Everything has loaded and the digital human is ready for interaction
      case 'SessionLive':
        this.sessionLiveForAvatar()
        break;
      case 'StartedSpeaking':
        this.runLoderGPT = false
        this.dotIndicatorAnimation = true
        $('#stopavatarId').removeClass('showI')

        break;

      case 'FinishedSpeaking':
        this.dotIndicatorAnimation = false
        $('#stopavatarId').addClass('showI')

        break;

      case 'AvatarQuestionText':
        this.userAskQuestionDisplayText(msg.question)
        //
        if ((localStorage.getItem('screen') === "TestSeries")) {
          if (msg.question == 'yes' || msg.question == 'Yes.' || msg.question == 'Yes' || msg.question == 'repeat' || msg.question == 'Repeat' || msg.question == 'repeat.') {
            this.showOptionOnlyFOrMobile()
          }
        }
        break;
      // The digital human has an answer to the question
      case 'AvatarAnswer':
        var data = msg.answerAvatar
        var finalData = JSON.parse(data)
        console.log('after parse', finalData)



        this.DescAnswer = finalData.instructions.customData?.display_message
        this.learningContent = finalData.instructions.customData?.Presntation_content
        this.User_Question = finalData.instructions.customData?.User_Question
        this.approachesList = finalData.instructions.customData?.approaches
        this.mathsQuestion = finalData.instructions.customData?.math_question
        this.linkPDF = finalData.instructions.customData?.link
        this.isNextStep = finalData.instructions.customData?.next_step
        this.isPreviousStep = finalData.instructions.customData?.prev_step
        this.isBackToStepsOn = finalData.instructions.customData?.back_to_step
        this.handlingMessgeForMaths = finalData.instructions.customData?.handlingMessge
        this.pdfType = finalData.instructions.customData?.type

        if (this.DescAnswer == "Hi, I'm Hannah. You can talk to me at any time and I'll answer. Push the mic button or type. Let's learn together! This is an example of your course content. You have 10 minutes to interact with me.") {
          
          $('#avatarLoaders').css('display', 'none')
          setTimeout(()=>{
            // tour()
            if (isPlatformBrowser(this.platformId)) {
              // Dynamically import 'tour' only in the browser
              import('../../assets/js/index').then((module) => {
                const { tour } = module;  // Extract the 'tour' function from the module
                tour();  // Call the tour function after it is loaded
                console.log('tour---------------');
              }).catch((err) => {
                console.error('Error loading tour:', err);
              });
            } else {
              console.warn('Tour guide is not available during SSR');
            }
          
          },5000)
        }
        // console.log(this.DescAnswer)
        const screen = localStorage.getItem('screen');
        // test prep screen function
        if (screen == "TestSeries") {
          this.testPrepMessgeHandler(finalData)
          $('#speedspeech').removeClass('showI');
        }
        //
        this.avatarAnswerMessageHandler()

        // const screen = localStorage.getItem('screen');
        if (screen == "LearningScreen") {
          this.descptionContentLoader = true
          console.log('loader-------',this.descptionContentLoader)
          this.learningModulesFun()
          
          $('#speedspeech').removeClass('showI');
        }

        if (this.DescAnswer) {
          this.showLoaderMess = false
        }

        if (screen !== "TestSeries" && this.toggleValue == false) {
          const noprintMessage = [
            "Exiting the Mathematics Zone.",
            "closing the learning module",
            "Great job! You have successfully completed this module.",
            "don't hide the chat bubble",
            "Let me think!",
            "",
            " "
          ];

          let time = this.getCurrentTime()
          if (noprintMessage.includes(this.DescAnswer)) {
            // No action needed for exit messages
          } else {
            //        console.log('message handler run-- bubble --', this.DescAnswer);
            this.chatBubbleMessage('persona', this.DescAnswer, time);
          }


          // verbal command function
          if (localStorage.getItem('screen') === "LearningScreen") {
            this.verbalCommandNavigation(this.learningContent)
          } else {
            this.verbalCommandNavigation(this.DescAnswer)
          }
          // 

          // -------- ppt --------
          if (this.linkPDF) {
            const length = finalData.instructions.customData?.total_length
            const position = finalData.instructions.customData?.position
            const link = this.linkPDF
            let obj = {
              'type': this.pdfType,
              "link": link,
              "length": length,
              "position": position
            }
            this.ser.updateMultimediaPDF(obj);
          }

      

          // ----------
          // mathametic
          if (this.handlingMessgeForMaths == "continue loading" || this.handlingMessgeForMaths == 'continue loading') {
            var m: any = document.getElementById('outputDesc')
            if (m) m.innerHTML = '.';
            this.runLoderGPT = true
            setTimeout(() => {
              this.uneeq.sendTranscript('get response')
              //  this.persona.conversationSend('get response', {}, {});
            }, 10000)

          } else if (this.handlingMessgeForMaths == "") {
            this.runLoderGPT = false
          }


          if (finalData.instructions.customData?.error == 'If issue persists, please contact livechat support from Help & Support page bottom left button.') {
            this._swalService.error('There seems to be an error', '', 7000, `<b> Please refresh the website and try again</b>.<br>If issue persists, please contact livechat support from Help & Support page bottom left button.`)
          }
        }

        // openAI maths
        this.OpenAIMathematicsSoulMachine(finalData)
        //
        this.callPieAI(this.DescAnswer)

        break;
      default:
        // console.log('uneeq-js:message \'' + msg.uneeqMessageType + '\'', msg);
        this.avatarConnectionStatus(msg.uneeqMessageType)
        break;

    }
  }


  /**
 * Function to handle the connection status for the avatar
 * @param {any} value - The value representing the connection status
 */
  avatarConnectionStatus(value: any) {
    //console.warn('connecttion=>', value)
    const handleSwal = (title: string, text: string, confirmButtonText: string, callback: () => void) => {
      Swal.fire({
        title: title,
        text: text,
        confirmButtonText: confirmButtonText,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          callback();
        }
      });
    };


    switch (value) {
      case 'ConnectionLost':
        handleSwal('Connection lost!', 'Sorry the connection is lost, please click the button below to refresh.', 'Refresh', () => {
          window.location.reload();
        });
        break;

      case 'SessionEnded':
        if (localStorage.hasOwnProperty('sessionId')) {
          //   handleSwal('Session expired!', 'Oops! It seems like your session has expired, please log in again..', 'OK', () => {
          //    this.router.navigate(['/']);
          // });
        }
        break;

      case 'SessionError':
        // handleSwal('Session Error!', 'Oops! A session error has occurred, please retry', 'OK', () => {
        // this.router.navigate(['/']);
        //  });
        break;

      case 'AvatarUnavailable':
        this.messageForQueueAvatar = true;
        const IP = localStorage.getItem('IPAdress');
        const payloadC = {
          email: this.user.email,
          name: this.user.name,
          ip_address: IP,
          status: 'Unavailable',
        };
        //  this.ser.avatarCounter(payloadC).subscribe(() => { });
        this.isSpinner = false;
        this.renderer.setStyle(document.getElementById('avatarLoaders'), 'display', 'none');

        break;

      case 'AvatarAvailable':

        if (this.tourGuideValueCheck == true) {
          if (this.user.Firstlogin == false) {
            setTimeout(() => {
              this.startTours()
            }, 6000)

          }
        }

        if (this.fullScreen === true) {
          // card minimize
          setTimeout(() => {
            $('#minimizeAvatar').addClass('showI');
            $('#maximizeAvatar').removeClass('showI');
          }, 1000)
        }

        this.messageForQueueAvatar = false
        var IPP = localStorage.getItem('IPAdress')
        let payloadAvaible = {
          "email": this.user.email,
          "name": this.user.name,
          "ip_address": IPP,
          "status": 'Available'
        }

        //  this.ser.avatarCounter(payloadAvaible).subscribe(res => { })

        break;

      case 'Instructions':
        const message = 'Hi test test, welcome to the world of e-dee-YOU. I am ' + this.avatarName + '. How can I help you?';
        const sentences = message.split('.');
        setTimeout(() => {
          localStorage.setItem('Avatar', value);
        }, 2000);
        break;

      case 'DeviceError':
        console.log('----device error---')
        // this.storedErrorCode.emit('DeviceError');
        // Swal.fire("Please allow microphone access");
        Swal.fire({
          text: "Please allow microphone access and click 'ok' button to continue",
          confirmButtonColor: '#579BDB;',
        }).then((result) => {
          if (result.isConfirmed) {
            this.startAvatarFunction()
          }
        })
        break;
    }

  }



  // pie API function call old
  callPieAI(value: any) {
    if (value === 'Let me think!') {
      this.runLoderGPT = true;
      this.showLoaderMess = true;
      // var m: any = document.getElementById('outputDesc')
      // if (m) m.innerHTML = '';

      var session = localStorage.getItem('sessionId')
      let payloadData = {
        "avatar": "Hannah",
        "question": this.userSpeakValue,
        // "gptPrompt": this.user.gptPrompt,
        "sessionId": session,
        "email": this.user.email
        // "time": this.user.lastlogin,

      }
      if (localStorage.hasOwnProperty("learningId")) {
        $('.avatarspeak-s').scrollTop(0);
      }

      this.disableMicButton = true
      console.warn('after failed condition catch let me thing ', payloadData)
      this.ser.pieAPI(payloadData).subscribe({
        next: (v: any) => {
          this.normalGPT = false;
          this.disableMicButton = false
          this.showLoaderMess = false
        },
        error: (e: any) => {
          console.error(e);
          this.normalGPT = false;
          this.disableMicButton = false
          this.showLoaderMess = false
        }

      })
    } else {
      this.runLoderGPT = false;
      this.showLoaderMess = false
    }
  }



  // uneeq function end 



  /***
   * soul machine test start -------
   */

  async avatarFunction() {
    this.cleanupSessionStorage();
    // for dev
    const apiKey = 'eyJzb3VsSWQiOiJkZG5hLWVkeW91LXRlY2hub2xvZ2llcy0tZWR5b3UiLCJhdXRoU2VydmVyIjoiaHR0cHM6Ly9kaC5zb3VsbWFjaGluZXMuY2xvdWQvYXBpL2p3dCIsImF1dGhUb2tlbiI6ImFwaWtleV92MV81MzdkMDkwNS05MDc1LTQ5YzgtYWIxOC1kZTJmMzVhYmM4NjEifQ=='

    // local test
    // const apiKey = 'eyJzb3VsSWQiOiJkZG5hLWVkeW91LXRlY2hub2xvZ2llcy0tZWR5b3UiLCJhdXRoU2VydmVyIjoiaHR0cHM6Ly9kaC5zb3VsbWFjaGluZXMuY2xvdWQvYXBpL2p3dCIsImF1dGhUb2tlbiI6ImFwaWtleV92MV9lZDdhODEyNy05MmEyLTRhMmEtYmRmYy05YzBhZmY0MThlM2IifQ=='

    console.log('fun start')
    const videoEl: any = document.getElementById('smVideo');
    // create a new scene object
    this.APIKey = apiKey
    this.scene = new Scene({
      apiKey: apiKey,
      videoElement: videoEl,
      requestedMediaDevices: { microphone: false, camera: false },
      requiredMediaDevices: { microphone: false, camera: false },
    });

    // this.scene.connectionState.onConnectionStateUpdated.addListener(
    //   (connectionStateData: any) => {
    //     // display connectionState updates to the user
    //     console.warn(connectionStateData)
    //   }
    // );



    await this.scene
      .connect()
      .then((sessionId: any) => this.onConnectionSuccess(sessionId))
      .catch((error: any) => this.onConnectionError(error));




  }


  onConnectionSuccess(sessionId: any) {
    setTimeout(() => {
      $('#avatarLoaders').css('display', 'none')
    }, 1000)

    if (this.messageForQueueAvatar == true) {
      // this.onLoadCard('')
      //  console.log(this.fullScreen,'onLoad--------')
      if (this.fullScreen == true) {
        //   console.log(this.fullScreen,'onLoad ------ inner--------')
        setTimeout(() => {
          $('#tourIcon').addClass('showI');
          // $('#speedspeech').addClass('showI');
          $('#minimizeAvatar').addClass('showI');
          $('#maximizeAvatar').removeClass('showI');
        }, 0)

      } else {
        $('#tourIcon').removeClass('showI');
        // $('#speedspeech').addClass('showI');
        $('#minimizeAvatar').removeClass('showI');
        $('#maximizeAvatar').addClass('showI');
      }
    }

    this.messageForQueueAvatar = false

    this._personaId = sessionId
    localStorage.setItem('sessionId', sessionId)

    this.persona = new Persona(this.scene, "1");
    console.info('success! session id:', sessionId);

    const videoE: any = document.getElementById('smVideo');
    this.scene.startVideo().then((videoState: any) => {
      //  videoE.play()
      //   .then((videoState: any) => {
      console.info('started video with state:', videoState)
      // Can play with audio
      // videoE.muted = false;


      if (videoState && videoState.audio == false) {
        //  this.unmuteMicrophone = false
        const videoE: any = document.getElementById('smVideo');
        console.log('inner vide state ++++++')
        this.muteFun()
        let p = {
          name: 'Avatar start in mute state',

        }

        this.customLogger(p)
      }
      this.ser.updateSharedData(this.persona);
      this.ser.updateSoulMachineData(this.scene);
      //  console.log(this.scene)
      this.isvoiceAnimationOn = false
      

      if (this.scene.connectionState._connectionState['name'] == 'Connected') {
        // this.customWelcomeMessge()
        if (this.user.Firstlogin == true) {
          this.setVideo(500, 300)
          console.log('small card pixel')
        } else if (this.user.Firstlogin == false) {
          if (window.innerWidth < 480) {
            this.setVideo(800, 900)
          }
        }
      }

      this.customLogger('')

      this.scene.onDisconnectedEvent.addListener((error: any) => {
        let states = this.scene.connectionState._connectionState['name']
        console.log('scene disconnected:', states);

        if (states == "Disconnected") {

          this.reconnectAvatar()

        }



      });

    }
    ).catch((error: any) => {
      // Can't play with audio, so mute it
      videoE.muted = true;
      console.warn('could not start video:===>', error)
      console.warn('could check ========>', error)
      let p = {
        name: error.name,
        message: error.message
      }

      this.customLogger(p)

    });
  }


  muteFun() {
    // this.isSwal = true;
    // this.type = 'welcome';
    // this.swalFireData = {title: "Welcome",text: 'Please click the button below to get started.',confirmButtonText: "Start ",showConfirmButton: true,confirmButtonColor: '#579BDB',allowOutsideClick: false};
    // this.payload = '' 

    console.log('mute function call state ++++++')

    Swal.fire({
      title: "Welcome",
      text: 'Please click the button below to get started.',
      confirmButtonText: "Start ",
      showConfirmButton: true,
      confirmButtonColor: '#579BDB',
      allowOutsideClick: false,
    }).then((result) => {
      let p = {
        name: 'Avatar start speaking.',
      }
      this.customLogger(p)
      this.muteDigitalPerson()
    });
  }

  // handleSwal = (title: string, text: string, confirmButtonText: string, callback: () => void) => {
  //   Swal.fire({
  //     title: title,
  //     text: text,
  //     confirmButtonText: confirmButtonText,
  //     confirmButtonColor: '#579BDB',
  //     allowOutsideClick: false,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       callback();
  //     }
  //   });
  // };

  customLogger(error: any) {
    let ipAddress = localStorage.getItem('IPAdress');
    let p = {
      state: this.scene.connectionState._connectionState['name'],
      session: this._personaId,
      email: this.user.email,
      name: this.user.name,
      apiKey: this.APIKey,
      IpAdress: '',
      error: error
    };

    if (ipAddress) {
      p.IpAdress = ipAddress;
    }
    // this.ser.postAPI(`${this.baseUrl}${API.apiLogService}`, p).subscribe((res: any) => {
    //   console.log(res);
    // });
  }


  onConnectionError(error: any) {
    let p = {
      name: error.name,
      message: error.message
    }
    this.customLogger(p)
    switch (error.name) {
      case 'mediaStreamFailed':
        console.warn('mediaStreamFailed  test');
        break;
      case 'noUserMedia':
        console.warn('user blocked device access');
        break;
      case 'noScene':
      case 'serverConnectionFailed':
        console.log(this.scene)
        console.error('DEBUG:', error.name, error.message)
        // console.log(this.scene.connectionResult['message'])
        //  console.warn('noScene serverConnectionFailed failed', 'testing');
        if (this.connectionFailureCount < 5) {
          this.avatarFunction();
          console.log(this.connectionFailureCount, 'counter')
          this.connectionFailureCount++;
        } else if (this.elseConditionCount === 0) {

          console.error('Maximum connection failure attempts reached.');

          Swal.fire({
            title: 'Server Connection Failed!',
            text: ' Please try after sometime or connect with edYOU support if issue persists.',
            confirmButtonText: 'Ok',
            showConfirmButton: true,
            confirmButtonColor: '#579BDB',
            allowOutsideClick: false,
          }).then((result) => {
            this.router.navigate(['/'])
            setTimeout(() => {
              if(this.isBrowser)
              window.location.reload();
            }, 0)
          });
          this.elseConditionCount++;
        }
        break;
      case 'noSessionToResume':
        console.warn('noSessionToResume failed', 'testing');
        console.log(this.scene.connectionResult['message'])

        this.avatarFunction()
        console.log(this.scene)

        break;
      case 'sessionTimeout':
        //  console.log(this.scene)
        this.avatarFunction()
        console.warn('Session erroro');
        break;
      case 'noSession':
        console.warn('Session fail=====');
        break;
      case 'onDisconnected':
        this.scene.disconnect()
        this.avatarFunction
        console.warn('dissconnect =====');
        break;
      default:
        console.warn('unhandled error: ==>', error);
    }
  }


  getCurrentTime(): string {
    const now = new Date();
    let hours = now.getHours();
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const hoursStr = hours.toString()
    return hoursStr + ':' + minutes + ' ' + ampm;

  }



  scrollToBottom() {
    var messageContainer = document.getElementById('chatbubblemessage');
    if (messageContainer) {
      messageContainer.scrollTo({
        top: messageContainer.scrollHeight,
        behavior: 'smooth'
      });
    }
  }

  chatBubbleMessage(source: any, text: any, time: any) {
    if (this.userInputText !== 'Welcome') {
      this.storedChatBubbleMessage.push({ source, text, time });
      setTimeout(() => {
        this.scrollToBottom();
      }, 1000);
    }
  }


  OpenAIMathematicsSoulMachine(body: any) {
    if (this.User_Question != '') {
      $('#MathameticsDisplay').addClass('showMessage')
      //  this.UserQuestion_Display = body.output['context']['public-context']?.User_Question
    }

    //  console.log('after maths question ask')

    if (this.DescAnswer == 'Let me think a bit.') {
      console.warn('inner run on let me ')
      var m: any = document.getElementById('outputDesc')
      if (m) m.innerHTML = '';
      let payload = {
        "data": this.userInputText,
        "gptPrompt": "Everything",
        // "email": this.user.email,
        "time": this.user.lastlogin
      }
      this.disableMicButton = true
      this.runLoderGPT = true;

      this.ser.postAPI(`${environment.baseUrl}${API.OpenAIMathematicsSoulMachine}`, payload).subscribe(
        (res: any) => {
          // Successful response
          if (res.statusCode == 200) {
            this.disableMicButton = false
            console.log('running 200 verified')
            this.uneeq.sendTranscript('get response')
            // this.persona.conversationSend('get response', {}, {});
            this.runLoderGPT = false;
          }
        },
        (error: any) => {
          // Handle error here
          this.disableMicButton = false
          console.error('API call failed:======>', error);
          this.runLoderGPT = false;
          this.uneeq.sendTranscript('get response')
         // this.persona.conversationSend('get response', {}, {});
          // You can add additional error handling logic here if needed
        }
      );
    }

    if (this.isBackToStepsOn == true) {
      this.runLoderGPT = false
    }


    if (this.approachesList == undefined || this.approachesList == '[]') {
      $('#mathApproach').removeClass('showMessage')
    } else if (this.approachesList.length > 0) {
      // if(this.User_Question !== undefined){
      //   this.UserQuestion_Display = det.instructions.customData?.User_Question
      // }
      this.UserQuestion_Display = body.instructions.customData?.User_Question
      let value: any = document.getElementById('user_questionD')
      if (value) value.innerHTML = this.UserQuestion_Display;

      // this.User_Question = det.instructions.customData?.User_Question
      // console.log('appraches work and has value')
      setTimeout(() => {
        $('#mathApproach').addClass('showMessage')
      }, 2500)

      // color apprach apply method
      this.checkOptionColor = body.instructions.customData?.color
      setTimeout(() => {
        if (this.checkOptionColor !== 'none') {
          this.addColorToApproach(this.checkOptionColor)
        }
      }, 1200)

    } else {
      if (this.User_Question != '') {
        this.UserQuestion_Display = body.instructions.customData?.User_Question
        let value: any = document.getElementById('user_questionD')
        if (value) value.innerHTML = this.UserQuestion_Display;
      }

    }
  }


  // allganize normal conversation OpenAI
  allganizeOpenAI() {

    if (this.DescAnswer === 'Let me think') {
      this.runLoderGPT = true;
      var m: any = document.getElementById('outputDesc')
      if (m) m.innerHTML = '';
      let payload = {
        "question": this.userInputText,
        // "email": this.user.email,
      }

      this.disableMicButton = true
      this.ser.postAPI(`${environment.baseUrl}${API.allganizeAPI}`, payload).subscribe(
        (res: any) => {
          if (res.statusCode == 200) {
            this.disableMicButton = false
            this.persona.conversationSend('getting response', {}, {});
            this.runLoderGPT = false;
          } else if (res.errorType) {
            this.persona.conversationSend('getting error', {}, {});
            this.isSpinner = false;
            // Swal.fire({
            //   icon: "error",
            //   title: 'Some error occured',
            //   text: 'Please try again',
            //   showDenyButton: false,
            //   showCancelButton: false,
            //   showConfirmButton: false,
            //   allowOutsideClick: false,
            //   timer: 4000
            // })
            this._swalService.error('Some error occured', 'Please try again')
          }
        },
        (error: any) => {
          this.isSpinner = false;
          this.disableMicButton = false
          this.persona.conversationSend('getting error', {}, {});
          console.log('API fail ---------', error)
        }
      );
    }

  }


  /**
   * avatar message display on screen logic 
   */
  avatarAnswerMessageHandler() {
    this.disableMicButton = false
    if (this.DescAnswer != '') {
      setTimeout(() => {
        if (this.ccOnOff == true) {
          var item: any = localStorage.getItem('AvatResCC')
          this.checkFullScreenB = item
          if (this.checkFullScreenB == 'false') {
            // $('#message').addClass('showMessage')


          }
        }
        var d: any = document.getElementById('outputDesc')
        //  $('.avatarspeak-s').scrollTop(0);
        if (this.DescAnswer === 'Let me think a bit.' || this.DescAnswer === 'Let me think') {
          //  if (d) d.innerHTML = ''
        }
        else {
          const screen = localStorage.getItem('screen');
          if (screen == "LearningScreen") {
          } else {
            if (d) d.innerHTML = this.DescAnswer;
          }


          this.isSubtitleAnimationRunning = true
          //   this.subtitleAnimationRun(this.DescAnswer)
          if (localStorage.hasOwnProperty("learningId")) {

            // //   var checkValueText = det.instructions.customData.PPT
            // var checkValueText = "NEW"
            // if (checkValueText == "NEW") {
            //   if (this.DescAnswer === "Moving to the next slide" || this.DescAnswer === "Moving to the previous slide" ||
            //     this.DescAnswer === "Sure, repeating the slide") {
            //   } else {
            //     this.isManualScrolling = false
            //     if (this.DescAnswer.length >= 140) {
            //       this.startScrolling();
            //     }
            //   }
            // } else if (checkValueText == "continue") {
            //   // no need to run 
            // }
          } else {

            if (this.DescAnswer.length >= 195) {
              this.isManualScrolling = false
              this.startScrolling();

            }

          }
        }

      }, 1000)

    }


  }

  learningModulesFun() {
    
    // console.log('----------new learning function--------------------------', this.learningContent)
    if (this.learningContent != '') {
      this.DescAnswer = " "
      var d: any = document.getElementById('outputDesc')
      this.descptionContentLoader = false
      if (d) d.innerHTML = this.learningContent;
     
      if (localStorage.hasOwnProperty("learningId")) {


        // if (this.DescAnswer === "Moving to the next slide" || this.DescAnswer === "Moving to the previous slide" ||
        //   this.DescAnswer === "Sure, repeating the slide") {
        // } else {
        //   this.isManualScrolling = false
        //   if (this.learningContent.length >= 140) {
        //     this.startScrolling();
        //   }
        // }

        if (this.DescAnswer === "Moving to the next slide") {

        } else if (this.DescAnswer === "Moving to the previous slide") {

        } else if (
          this.DescAnswer === "Sure, repeating the slide") {

        } else {
          this.isManualScrolling = false
          if (this.learningContent.length >= 140) {
            this.startScrolling();
          }
        }

      }

    }


  }

  reconnectAvatar() {
    if (localStorage.getItem('screen') === "TestSeries" || localStorage.getItem('screen') === "LearningScreen") {
      this.isSwal = true;
      this.type = 'livechatError';
      this.swalFireData = { icon: "error", title: 'There seems to be an error', html: `<b> Please refresh the website and try again</b>.<br>If issue persists, please contact livechat support from Help & Support page bottom left button.`, showDenyButton: false, confirmButtonColor: '#579BDB', confirmButtonText: 'Refresh', showCancelButton: false, showConfirmButton: true, allowOutsideClick: false };
      this.payload = ''
      //       Swal.fire({
      //         icon: "error",
      //         title: 'There seems to be an error',
      //         html: `
      //  <b> Please refresh the website and try again</b>.<br>If issue persists, please contact livechat support from Help & Support page bottom left button.
      // `,
      //         // text: 
      //         showDenyButton: false,
      //         confirmButtonColor: '#579BDB',
      //         confirmButtonText: 'Refresh',
      //         showCancelButton: false,
      //         showConfirmButton: true,
      //         allowOutsideClick: false,
      //         // timer: 7000
      //       }).then((result) => {
      //         if (result.isConfirmed) {
      //           window.location.reload();
      //           // this.router.navigate(['/'])
      //           // localStorage.removeItem("sessionId");
      //           // localStorage.removeItem("user");
      //           // localStorage.removeItem("token");
      //           // localStorage.removeItem("Avatar");
      //           // localStorage.removeItem("sessionId");
      //         }
      //       })
    } else {
      this.messageForQueueAvatar = true
      console.warn('reconnect ++++++++++++++++++++++++++++++++++++++++')
      this.avatarFunction()
    }
  }

  mathsForm = new UntypedFormGroup({

    mathLive: new UntypedFormControl('', Validators.required),
  })


  /**
   * test prep logic to display and hide question and options and answer
   * 
   * @param body 
   */
  testPrepMessgeHandler(body: any) {
    this.questionList = body.instructions.customData?.Question
    this.optionList = body.instructions.customData?.options
    this.CorrectAnswer = body.instructions.customData?.test_prep_response
    let nextButton = body.instructions.customData?.next_button
    this.isClick = body.instructions.customData?.click
    this.total_testseries_len = body.instructions.customData?.total_testPrep_length
    this.current_testseries_len = body.instructions.customData?.current_testPrep_length
    this.test_Progress = body.instructions.customData?.Percentage
    this.test_progress_Percent = body.instructions.customData?.Percentage + '%'
    let resumeButton = body.instructions.customData?.resume
    localStorage.setItem('Progress', this.test_Progress)
    console.log(this.DescAnswer, 'rec')
    $('#showTestPrepItem').removeClass('showI')
    if (this.current_testseries_len == '' && this.total_testseries_len == '' && this.test_Progress == '') {
      $('#question_AND_percent').addClass('showI')
      $('#testQuestion').addClass('showI')
    } else {
      $('#question_AND_percent').removeClass('showI')
      $('#testQuestion').removeClass('showI')
    }
    // if(this.total_testseries_len ==  this.current_testseries_len) {
    //   $('#parentQuestion').addClass('showI')
    // }else{
    //   $('#parentQuestion').removeClass('showI')
    // }
    if (this.total_testseries_len == this.current_testseries_len) {
      // Check if the first part of the split text starts with "You have completed the test"
      const splitText = this.DescAnswer.split(". ");
      // console.warn('dd',splitText)
      if (splitText[0].startsWith("You have completed the test") || splitText[0].startsWith("<p>You have completed the test")) {
        //   console.log('run command')
        // setTimeout(() => {
        //   $('#message').addClass('showMessage')
        // }, 0)
        // this.testPrepsummery()
        setTimeout(() => {
          $('#newSummery').addClass('showMessage')
        }, 0)

        $('#parentQuestion').addClass('showI')
      } else {

        if (this.DescAnswer == "closing the Test module") {

        } else {
          //   console.log('not run  command')
          $('#parentQuestion').removeClass('showI')
        }

      }

    } else {
      console.log('================ else condirion --------------------------------')

      $('#parentQuestion').removeClass('showI')

    }


    if (localStorage.getItem('screen') === "TestSeries") {
      console.warn('------ set time out for descAnswer ----------------')
      setTimeout(() => {
        if (window.innerWidth < 500) {
          console.warn('------ for mobile test series condition run  ----------------')
          $('#optionMessageMobile').removeClass('showI')
          $('#optionMessage').addClass('showI')


        } else {
          console.warn('------ for big screen  test series condition run  ----------------')
          $('#optionMessageMobile').addClass('showI')

          $('#optionMessage').removeClass('showI')
        }
      }, 0)

    }


    // check TestSereis screen is on
    if (localStorage.getItem('screen') === "TestSeries") {
      this.checkOptionColor = body.instructions.customData?.color
      const position = body.instructions.customData?.position
      const correctColor = body.instructions.customData?.correctColor

      console.warn('selected option are == >', position, this.checkOptionColor)

      const element = document.getElementById('progressbar_status_block_' + (this.current_testseries_len - 1));

      if (this.checkOptionColor == 'green') {
        console.log(this.testSeris_Music, 'test series music-----------------------')
        // if (this.testSeris_Music == false) {
        //   this.ser.onSound('https://gamificationedyou.s3.us-west-2.amazonaws.com/music/success%2BtestPrep.aac');
        // }
        if (element) {
          // console.log('{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}} element is present', element);
          element.style.background = 'green';
        }
      } else if (this.checkOptionColor == 'red') {
        // if (this.testSeris_Music == false) {
        //   this.ser.onSound('https://gamificationedyou.s3.us-west-2.amazonaws.com/music/failure.mp3');
        // }

        if (element) {
          // console.log('{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}} element is present', element);
          element.style.background = 'green';
        }
      } else {
        // document.getElementById('progressbar_status_block' + this.current_testseries_len)!.style.backgroundColor = 'yellow !important';
      }
      setTimeout(() => {
        if (this.checkOptionColor !== 'none') {
          this.addColorOnOption(this.checkOptionColor, position)

          // run when wrong answer click only 
          if (correctColor) {
            const correctPosition = body.instructions.customData?.correctPosition
            this.addColorOnOption(correctColor, correctPosition)
          }
        }
      }, 1200)
    }

    if (this.questionList != '') {
      this.accod1 = true
      $('#accod1').css('display', 'block')
      $('#accodH1').addClass('active')
      var aa: any = document.getElementById('QQ')
      if (aa) aa.innerHTML = this.questionList;
      $('#questionDescription').scrollTop(0);
      //   let checkValueText = det.instructions.customData.PPT
      //   if (checkValueText == "NEW") {
      if (this.questionList.length >= 150) {
        //  console.log('auto scroll start ')
        //if (backButton == false) {
        this.isManualScrolling = false
        this.startScrollingForQuestionTestSeries()
        //    }
      }
      //  } else if (checkValueText == "continue") {
      ////
      //   }
    }

    if (this.optionList.length == 0) {
      $('#accod1').css('display', 'none')
      $('#accodH1').removeClass('active')


    }

    if (this.optionList != '') {



      this.testPrepList = true
      setTimeout(() => {
        var items = this.optionList
        items.forEach((item: any, i: any) => {
          let value: any = document.getElementById('colorOption' + i)
          let valueMobile: any = document.getElementById('colorOptionMobile' + i)
          if(this.isBrowser)
          if (window.innerWidth < 500) {
            valueMobile.innerHTML = item;
            // if (this.optionList[0].length < 50) {
            //   $(value).css('font-size', '14px')
            //  // value.nativeElement.style.fontSize = '15px';
            // }else{
            //  // value.nativeElement.style.fontSize = '11px';
            //  $(value).css('font-size', '11px')
            // }
            if (this.prepSubject == "Mathematics" || this.prepSubject == "Pre-Algebra") {
              $(valueMobile).css('font-size', '12px')
            } else {
              if (this.optionList[0].length < 65) {
                $(valueMobile).css('font-size', '12px')
                // value.nativeElement.style.fontSize = '15px';
              } else {
                // value.nativeElement.style.fontSize = '11px';
                $(valueMobile).css('font-size', '11px')
              }
            }

          } else {

            if (value) value.innerHTML = item;
            console.log('oprions===', this.optionList[0].length)

            console.log('subject----------', this.prepSubject)
            if (this.prepSubject == "Mathematics" || this.prepSubject == "Pre-Algebra") {
              $(value).css('font-size', '14px')
            } else {
              if (this.optionList[0].length < 65) {
                $(value).css('font-size', '14px')
                // value.nativeElement.style.fontSize = '15px';
              } else {
                // value.nativeElement.style.fontSize = '11px';
                $(value).css('font-size', '11px')
              }
            }
          }
        });
      }, 0)
    } else {
      this.testPrepList = false
    }

    //  var option = det.instructions.customData.options
    if (this.CorrectAnswer != '') {
      setTimeout(() => {
        this.accod1 = false
        // $('#accod2').css('display', 'block')
        // $('#accodH2').addClass('active')
        $('#accod1').css('display', 'none')
        $('#accodH1').removeClass('active')
        var answer: any = document.getElementById('answerD')
        if (answer) answer.innerHTML = this.CorrectAnswer;

        $('.answerDescription').scrollTop(0);
        //  let checkValueText = det.instructions.customData.PPT
        //  if (checkValueText == "NEW") {
        //    this.isManualScrolling = false
        if (this.CorrectAnswer.length >= 150) {
          //  console.log('auto scroll start ')
          //     if (backButton == false) {
          this.isManualScrolling = false
          this.startScrollingForANswerTestSeries()
          //   console.log('answer next scrol work')
          //     }
        }
        //  } else if (checkValueText == "continue") {

        //  }

      }, 1400)
    }

    if (nextButton === true) {
      this.nextButtonTestseries = nextButton
    } else {
      this.nextButtonTestseries = nextButton
    }

    if (resumeButton == true) {

      this.resumeButtonTestseries = true
    } else if (resumeButton == false) {

      this.resumeButtonTestseries = false
    }

  }



  nextStepFun() {
    if (this.voiceText) {
      this.voiceText = ""
      this.stopSubtitleAnimation()
    }
    this.uneeq.stopSpeaking()
    // this.persona.stopSpeaking()

    var backQ = "next response"
    this.uneeq.sendTranscript(backQ)
    // this.persona.conversationSend(backQ, {}, {});
  }


  previousStepFun() {
    if (this.voiceText) {
      this.voiceText = ""
      this.stopSubtitleAnimation()
    }
    this.uneeq.stopSpeaking()
    //  this.persona.stopSpeaking()

    var backQ = "previous response"
    this.uneeq.sendTranscript(backQ)
    //.persona.conversationSend(backQ, {}, {});
  }


  backToSteps() {
    if (this.voiceText) {
      this.voiceText = ""
      this.stopSubtitleAnimation()
    }
    // this.persona.stopSpeaking()
    this.uneeq.stopSpeaking()
    var backQ = "back to step"
    this.uneeq.sendTranscript(backQ)
    // this.persona.conversationSend(backQ, {}, {});
  }

  toggleUserMicrophone() {



    if (this.scene) {
      const active = this.scene.isMicrophoneActive();
      this.scene.setMediaDeviceActive({
        microphone: !active,
      });
      console.log('off')
    } else {

    }

    this.isMicrophoneOn = !this.isMicrophoneOn

    if (this.isMicrophoneOn == true) {
      this.isvoiceAnimationOn = false
    } else {
      this.isvoiceAnimationOn = true
    }


    this.checkMicPosition()

    // this.clearAvatarContentBox()
    // this.toggleUserMicrophone()

  }

  stopDigitalPerson() {
    this.dotIndicatorAnimation = false
    this.isManualScrolling = true
    // this.childPdf.resetHighlighting()
    this.stopAvatarOnClick = !this.stopAvatarOnClick
    // this.persona.stopSpeaking()
    this.uneeq.stopSpeaking()
    this.stopSubtitleAnimation()

  }





  muteDigitalPerson() {
    this.unmuteMicrophone = false

    const videoEl: any = document.getElementById('smVideo');
    if (videoEl) videoEl.muted = false;

  }

  music_On_Off() {
    // this.testSeris_Music = !this.testSeris_Music
    // console.log(this.testSeris_Music, '------------------------------------------')
    // if (this.testSeris_Music == true) {
    //   this.ser.offMusic();
    // } else {
    //   this.ser.onMusic('https://gamificationedyou.s3.us-west-2.amazonaws.com/music/testprep-background-music.aac');
    // }
  }

  muteunmuteVoice() {
    this.muteVoiceAvatar = !this.muteVoiceAvatar

    //  if (videoVoice) {

    //     videoVoice.muted = !videoVoice.muted

    //     console.log('muted', videoVoice.muted)
    // }
    //const videoEl: any = document.querySelector('#sm-video video')
    //const videoEl:any = this.videoElement.nativeElement;

    //  if (videoEl) videoEl.muted = this.muteVoiceAvatar
    if (this.videoElement) {
      // this.isMuted = !this.isMuted;
      this.videoElement.muted = this.muteVoiceAvatar;
    }
    // else {
    //     // your code
    //     videoVoice.muted = false
    //     $("video").prop('muted', false);
    //     console.log('unMuted', videoVoice.muted)
    // };

    // videoVoice.muted = !videoVoice.muted;



    // this.muteVoiceAvatar = !this.muteVoiceAvatar
    // const videoVoice: any = document.getElementById('smVideo');
    // if (videoVoice) {
    //   videoVoice.muted = this.muteVoiceAvatar;
    //   console.log('mute work', videoVoice.muted);
    // } else {
    //   console.log('Video element not found');
    // }
    // this.muteVoiceAvatar = !this.muteVoiceAvatar;
    // const videoVoice = document.getElementById('smVideo');
    // if (videoVoice) {
    //   if (this.muteVoiceAvatar) {
    //     videoVoice.setAttribute('muted','true');
    //   } else {
    //     videoVoice.removeAttribute('muted');
    //   }
    //  // console.log('Mute status:', videoVoice.muted);
    // } else {
    //   console.log('Video element not found');
    // }
  }

  customWelcomeMessge() {
    if (this.scene.connectionState._connectionState['name'] == 'Disconnected') {

    } else {

      this.setVariable()
      const personaInstance = this.persona
      let text = 'Welcome to edYOU'
      personaInstance.conversationSend(text, {}, { /* optionalArgs */ });
      if (this.user.Firstlogin == false) {
        setTimeout(() => {
          $('#chatbubblemessage').addClass('showMessage')
        }, 4000)
      }
    }
  }


  clearAvatarContentBox() {
    this.runLoderGPT = true
    if ((localStorage.getItem('screen') === "TestSeries")) {

    } else {
      var m: any = document.getElementById('outputDesc')
      if (m) m.innerHTML = '';

    }

  }


  onEnter() {
    console.log('fffff')
  }

  sendTextToAvatar() {
    this.uneeq.stopSpeaking()

    // this.persona.stopSpeaking()
    const personaInstance = this.persona
    console.log('value of maths===', this.inputMathsValue)

    this.stop();

    var completeText
    if (this.inputMathsValue !== "") {
      this.clearAvatarContentBox()
      this.mathInputClear = true
      this.mathsValue(this.inputMathsValue2)

      if (this.enterKeyPress == "Enter") {
        console.warn('send with enter')
        this.enterKeyPress = ""
      } else {
        console.warn('send without enter')
        this.mathEventHit("")
        this.enterKeyPress = ""
      }

      completeText = this.inputMathsValue
      //  var textSet = 'User: ' + completeText
      var textSet = completeText
      this.userInputText = textSet
      console.log('MathsValue after hit', this.inputMathsValue)
      if (completeText.length > 0) {
        // const result = personaInstance.conversationSend(completeText, {}, { /* optionalArgs */ });;
        this.uneeq.sendTranscript(completeText)
      }
      this.inputMathsValue = ""
      this.userText = ""
      this.mathInputClear = false
    } else {
      if (this.userText) {
        const screen = localStorage.getItem('screen');
        if (screen == "LearningScreen") {
        } else {
          this.clearAvatarContentBox()
        }

        completeText = this.userText.trim();
        console.log(completeText, 'else condition')
        // var  textSet = 'User: ' + completeText
        var textSet = completeText
        this.userInputText = textSet
        console.log('user', this.userInputText)
        this.showLoaderMess = true
        //  let time = this.getCurrentTime()

        // if (screen !== "TestSeries" && this.toggleValue == false) {
        //   if(completeText == 'next' || completeText == 'Next.' || completeText == 'Next' || completeText == 'Previous.' || completeText == 'previous' || completeText == "Previous"){

        //   }else{
        //     this.chatBubbleMessage('user', completeText, time)
        //   }

        // }
        this.userSendChatbubble(completeText)
        this.stopSubtitleAnimation()
        if (completeText.length > 0) {
          this.uneeq.sendTranscript(completeText)
          //  this.setVariable()
          // const result = personaInstance.conversationSend(completeText, {}, { /* optionalArgs */ });;
        }
        this.funToOpenChatBubble(completeText)
        this.inputMathsValue = ""
        this.userText = ""
        this.mathInputClear = false
      }

      let value: any = document.getElementById('user_questionD')
      if (value) value.innerHTML = ''

    }


  }



  funToOpenChatBubble(value: any) {
    console.warn('open chat meaage ', value)
    const screen = localStorage.getItem('screen');
    if (screen == "LearningScreen") {
      const Message = [
        "next→",
        "←previous",
        "repeat↻",
        "next",
        "Next",
        "Next.",
        "Previous",
        "Previous.",
        "Repeat.",
        "repeat",
        "previous"
      ];

      if (Message.includes(value)) {
        // No action needed for exit messages
      } else {
        setTimeout(() => {
          this.bubblewindowOnOff = false
          // this.chatBubbleFun('normal')
        }, 2000)
      }
    }

  }


  setVariable() {
    console.log('----- set variable call =--------------')
    let payload = {
      "email": this.user.email,

      'error': "",
      "display_message": "",
      "id": "",
      "Question": "",
      "options": "",
      "test_prep_response": "",
      "click": "",
      "next_button": "",
      "back_button": "",
      "color": "",
      "position": "",
      "current_testPrep_length": "",
      "autoScroll": "",
      "total_testPrep_length": "",
      "resume": false,
      "handlingMessge": "",
      "approaches": [],
      "User_Question": "",
      "math_Question": ""

    }

    console.log(payload)
    this.uneeq.uneeqSetCustomChatMetadata(JSON.stringify({ name: 'ankit rai' }))
    // this.persona.conversationSetVariables(payload)
  }




 


  // Add push to talk spacebar key listeners
  addSpacebarEventListeners() {
    // When the user presses down on space bar, tell the digital human to start recording (start listening)
    document.addEventListener('keydown', (e: any) => {
      const isSpaceBar = e.code === 'Space' && !e.repeat;
      const isNotTextInput = e.target.type !== 'text';
      const isNotMathField = !e.target.classList.contains('math-field'); // Adjust the class name if needed

      const detailInput = !e.target.classList.contains('detailInput'); // Adjust the class name if needed

      if (isSpaceBar && isNotTextInput && isNotMathField && detailInput) {
        if (this.disableMicButton == true) {

        } else {
          this.onclickMic()
          this.uneeq.stopSpeaking()
          //this.persona.stopSpeaking()
          this.stopSubtitleAnimation()
        }

      }




    });
  }



  /*****************************************************************************************
 ************************************************* soul machine test  end -------
 */

  //  setVideoDimensions





  // form group
  form = new UntypedFormGroup({

    mathValues: new UntypedFormControl('', Validators.required),

  })



  mathsValue(event: any) {
    //console.log('mmmm',event)
    //  console.log('inputMathsValue ',this.inputMathsValue)
    //     // Clear the input value
    //  this.inputMathsValue = '';
    if (this.mathInputClear == true) {
      console.log('true state call', event)
      event.target.value = ''
    } else if (this.mathInputClear == false) {
      console.log('math', event)
      console.log('math==', event.type)
      this.inputMathsValue2 = event
      this.inputMathsValue = event.target.value
      this.inputMathsValue = this.inputMathsValue.replace(/\\text\{(.*?)\}/g, '$1');
    }


  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      // Handle submission here, e.g., call a method to send the data
      console.log('enter', event)
    }
  }


  runMathToggleFromParent() {
    this.childMenu.checkMethodSidebarOpen();
  }


  toggleOnOff() {
    if ((localStorage.getItem('screen') === "TestSeries") || (localStorage.getItem('screen') === "LearningScreen")) {
      // Swal.fire({
      //   title: 'Alert',
      //   text: 'Please exit the current module to access Mathematics module',
      //   timer: 5000,
      //   showConfirmButton: false
      // })
      this._swalService.default({ title: 'Alert', text: 'Please exit the current module to access Mathematics module', timer: 5000, showConfirmButton: false })
    } else {

      console.log(this.toggleValue, 'k')
      if (this.toggleValue == false) {
        this.MathameticsToggleONOFF(true)

        $('#chatbubblemessage').removeClass('showMessage')
        $('#message').addClass('showMessage')
        $('#settingId').addClass('showI')

        var m: any = document.getElementById('outputDesc')
        if (m) m.innerHTML = '';

        this.userCCOnOf()
      } else if (this.toggleValue == true) {
        this.MathameticsToggleONOFF(false)
        this.chatBubbleFun('keyboard')
        $('#chatbubblemessage').addClass('showMessage')
        $('#message').removeClass('showMessage')
        $('#settingId').removeClass('showI')

        this.userCCOnOf()
      }
      this.toggleValue = !this.toggleValue

      this.bubblewindowOnOff = !this.toggleValue
    }


  }

  onFocus(inputId: string) {
    // console.log('focus-------------------------------')
    this.focusInput = inputId;
  }

  onBlur() {
    this.focusInput = '';
  }


  /**
   * maths button on avatar screen logic 
   * disbaled button on test series and learning module 
   * @param isChecked 
   * @returns 
   */
  MathameticsToggleONOFF(isChecked: any) {
    console.log(this.toggleValue, 'k')

    if ((localStorage.getItem('screen') === "TestSeries") || (localStorage.getItem('screen') === "LearningScreen")) {
      // Swal.fire({
      //   title: 'Alert',
      //   text: 'Please exit the current module to access Mathematics module',
      //   timer: 5000,
      //   showConfirmButton: false
      // })
      this._swalService.default({ title: 'Alert', text: 'Please exit the current module to access Mathematics module', timer: 5000, showConfirmButton: false })
    } else {
      // If the function is already running, don't execute it again
      if (this.mathematicsEnabled) {
        console.log('already running')
        return;
      }
      // Set the flag to indicate that the function is running
      this.mathematicsEnabled = true;
      var session = localStorage.getItem('sessionId')
      let payload = {
        // "email": this.user.email,
        "currentStatus": isChecked,
        "sessionId": session,
      }
      //  this.uneeq.stopSpeaking()
      this.ser.postAPI(`${environment.baseUrl}${API.mathsONOF}`, payload).subscribe({
        next: (res: any) => {
          if (res.statusCode == 200) {
            this.mathematicsEnabled = false
            localStorage.setItem("mathtoggle", isChecked);
            if (isChecked == true) {
              this.normalChatBar = false
              this.mathsChatBar = true
              //  this.persona.conversationSend('Turn on mathematics module', {}, {});;
              this.uneeq.sendTranscript('Turn on mathematics module')
              $('#mathsIndicator').addClass('showI')
              $('#mathsIndicatorONN').removeClass('showI')
              // setTimeout(()=>{
              //   $('.ML__virtual-keyboard-toggle').css('background-color','white')
              // },3000)

              // this.childMenu.addConditiontoToggle(true)
            } else if (isChecked == false) {
              this.mathsChatBar = false
              this.normalChatBar = true
              // this.persona.conversationSend('Turn off mathematics module', {}, {});
              this.uneeq.sendTranscript('Turn off mathematics module')
              // this.childMenu.addConditiontoToggle(false)
              $('#MathameticsDisplay').removeClass('showMessage')
              $('#mathsIndicator').removeClass('showI')
              $('#mathsIndicatorONN').addClass('showI')
              $('#mathApproach').removeClass('showMessage')
            }
            // var m: any = document.getElementById('snackbarText')
            // m.innerHTML = res.body
            // Get the snackbar DIV
            // var x: any = document.getElementById("snackbar");
            // x.className = "show";
            //  setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
          } else {
            this.mathematicsEnabled = false
          }
        },
        error: (e) => {
          this.mathematicsEnabled = false
        }
      })
    }



  }


  /**
   * Function for handling accordion question  on off button 
   */
  accordQestionhit() {
    if (this.accod1 === true) {
      // If accod1 is true, collapse the accordion panel
      $('#accod1').css('display', 'none')
      $('#accodH1').removeClass('active')
    } else if (this.accod1 === false) {
      $('#accod1').css('display', 'block')
      $('#accodH1').addClass('active')
    }
    // Toggle the value of accod1
    this.accod1 = !this.accod1
  }

  /**
 * Function for handling accordion response  on off button 
 */
  accordAnswerHit() {
    if (this.accod2 === true) {
      // If accod1 is true, collapse the accordion panel
      $('#accod2').css('display', 'none')
      $('#accodH2').removeClass('active')
    } else if (this.accod2 === false) {
      $('#accod2').css('display', 'block')
      $('#accodH2').addClass('active')
    }
    // Toggle the value of accod1
    this.accod2 = !this.accod2
  }


  recognizerSetup() {
  }


  checkMicPosition() {



    setTimeout(() => {
      if (this.fullScreen === true) {
        if ((localStorage.getItem('screen') === "TestSeries") || (localStorage.getItem('screen') === "LearningScreen")) {
          $('#zoomMic').removeClass('microphoneMobile');
          $('#zoomMic').addClass('microphoneOn');
        } else {
          $('#zoomMic').addClass('microphoneMobile');
          $('#zoomMic').removeClass('microphoneOn');
        }


      } else {

        $('#zoomMic').removeClass('microphoneMobile');
        $('#zoomMic').addClass('microphoneOn');
      }
    }, 0)


  }


  /**
   * use azure STT 
   * Mic speak to text function
   * getting text and sending to uneeq function 
   */
  // onclickMic() {
  //   this.checkMicPosition()
  //   if (this.isMicButtonActive === true) {
  //     this.stop();
  //     this.isMicButtonActive = false;
  //     this.isSubtitleAnimationRunning = false;
  //   }
  //   else {
  //     this.recognizerSetup()
  //     if (this.voiceText) {
  //       this.voiceText = ""
  //       // this.uneeq.stopSpeaking()
  //       this.persona.stopSpeaking()
  //       this.stopSubtitleAnimation()
  //     }

  //     this.showMic = true
  //     this.persona.stopSpeaking()
  //     this.isvoiceAnimationOn = true


  //     this.isMicButtonActive = true;

  //     const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
  //     const speechConfig = sdk.SpeechConfig.fromSubscription(this.subscriptionKey, this.serviceRegion);
  //     speechConfig.speechRecognitionLanguage = this.language;
  //     this.recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig)

  //     //  console.log(this.recognizer)
  //     this.recognizer.recognizing = (s: any, e: any) => {
  //       console.log(`RECOGNIZING: Text=${e.result.text}`);
  //       this.userInputText = e.result.text
  //     };


  //     this.recognizer.recognized = (s: any, e: any) => {
  //       if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
  //         //  console.log(`RECOGNIZED: Text=${e.result.text}`);
  //         console.log('final', e.result.text);
  //         this.voiceText = e.result.text;
  //         this.userInputText = this.voiceText
  //         this.checkButton()
  //         this.UserQuestion_Display = ""
  //         //  this.uneeq.sendTranscript(this.voiceText)
  //         this.clearAvatarContentBox()
  //         this.persona.conversationSend(this.voiceText, {}, {});
  //         this.disableMicButton = true
  //         this.hideOptionOnlyFOrMobile()
  //         // this.recognizer.close();
  //         //this.recognizer.AudioConfig.turnOff()
  //         if (this.voiceText) {
  //           this.stop()

  //         }
  //       } else if (e.result.reason === sdk.ResultReason.NoMatch) {

  //         const noMatchDetail = sdk.NoMatchDetails.fromResult(e.result);
  //         console.log("No speech recognized." + " | NoMatchReason: " + sdk.NoMatchReason[noMatchDetail.reason]);
  //         // this.recognizer.AudioConfig.turnOff()
  //         // this.recognizer.close();
  //         this.isMicButtonActive = false;
  //         this.showMic = false
  //         this.isvoiceAnimationOn = false
  //         //  this.recognizer.stopContinuousRecognitionAsync(() => {
  //         //   this.recognizer.close();
  //         // })
  //         // Perform actions when no speech is recognized.
  //       } else {
  //         console.log(`ERROR: ${e.errorDetails}`);
  //       }
  //     };
  //     this.recognizer.canceled = (s: any, e: any) => {
  //       console.log(`CANCELED: Reason=${e.reason}`);
  //       if (e.reason == sdk.CancellationReason.Error) {
  //         console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
  //         console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
  //       }

  //       this.recognizer.stopContinuousRecognitionAsync();
  //     };

  //     this.recognizer.speechEndDetected = (s: any, e: any) => {
  //       //   console.log(`(speechEndDetected) SessionId: ${e.sessionId}`);
  //       this.recognizer.close();
  //       this.recognizer = undefined;
  //     };
  //     this.recognizer.sessionStopped = (s: any, e: any) => {
  //       //  console.log("\n    Session stopped event.");
  //       this.recognizer.stopContinuousRecognitionAsync();
  //       // Perform actions when speech ends, such as stopping the recognition or handling the final result.
  //     };
  //     this.recognizer.startContinuousRecognitionAsync();
  //     // }
  //   }
  // }

  userSendChatbubble(text: any) {
    let time = this.getCurrentTime()
    const screen = localStorage.getItem('screen');

    const noprintMessage = [
      "next",
      "Next.",
      "Repeat",
      "repeat",
      "Repeat.",
      "repeat.",
      "Next",
      "Previous.",
      "previous",
      "Previous",
      "Let me think!",
      "",
      " "
    ];
    if (screen !== "TestSeries" && this.toggleValue == false) {

      if (noprintMessage.includes(text)) {
        this.showLoaderMess = false
        // No action needed for exit messages
      } else {
        this.chatBubbleMessage('user', text, time)
      }


    }
  }

  async onclickMic() {
    //kanx
    isActive: true;
    this.checkMicPosition();
    if (localStorage.getItem('screen') !== "TestSeries" && this.toggleValue !== true) {
      // this.persona.conversationSend('listening', {}, {});
    }
    if (this.isMicButtonActive === true) {
      this.stop();
      this.isMicButtonActive = false;
      this.isSubtitleAnimationRunning = false;
    } else {
      this.recognizerSetup();
      if (this.voiceText) {
        this.voiceText = "";
        this.uneeq.stopSpeaking()
        // this.persona.stopSpeaking();
        this.stopSubtitleAnimation();
      }

      this.showMic = true;
      this.uneeq.stopSpeaking()
      //  this.persona.stopSpeaking();
      this.isvoiceAnimationOn = true;
      this.dotIndicatorAnimation = true
      this.isMicButtonActive = true;

      const getAudioConfig = async () => {
        try {
          // Request permission to access the microphone
          await navigator.mediaDevices.getUserMedia({ audio: true });
          console.log('Microphone access granted.');

          // Enumerate audio input devices
          const devices = await navigator.mediaDevices.enumerateDevices();
          const audioInputDevices = devices.filter(device => device.kind === 'audioinput');
          let selectedDeviceId = null;

          // Find AirPods or another specific device if desired
          audioInputDevices.forEach((device: MediaDeviceInfo) => {
            console.log("Device name: ${device.label}, id: ${device.deviceId}");
            if (device.label.includes("AirPods")) { // Adjust condition as necessary
              selectedDeviceId = device.deviceId;
            }
          });

          if (selectedDeviceId) {
            console.log("Selected device: ${selectedDeviceId}");
            return sdk.AudioConfig.fromMicrophoneInput(selectedDeviceId);
          } else {
            console.log('Using default microphone.');
            return sdk.AudioConfig.fromDefaultMicrophoneInput();
          }
        } catch (err) {
          console.error('Microphone access denied or error:', err);
          throw err; // Ensure the error is propagated
        }
      };

      const audioConfig = await getAudioConfig();
      const speechConfig = sdk.SpeechConfig.fromSubscription(this.subscriptionKey, this.serviceRegion);
      speechConfig.speechRecognitionLanguage = this.language;
      this.recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);

      this.recognizer.recognizing = (s: any, e: any) => {
        this.userInputText = e.result.text;
        this.userText = e.result.text
      };

      this.recognizer.recognized = (s: any, e: any) => {
        if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
          console.log('final', e.result.text);
          this.voiceText = e.result.text;

          this.userInputText = this.voiceText;
          //  let time = this.getCurrentTime()
          this.showLoaderMess = true
          this.stopSubtitleAnimation()
          const screen = localStorage.getItem('screen');
          // if (screen !== "TestSeries" && this.toggleValue == false) {

          //   if(this.voiceText == 'next' || this.voiceText == 'Next.' || this.voiceText == 'Next' ||
          //     this.voiceText == 'Previous.' || this.voiceText == 'previous' || this.voiceText == "Previous"){ 

          //   }else{
          //     this.chatBubbleMessage('user', this.voiceText, time)
          //   }

          // }
          this.userSendChatbubble(this.voiceText)
          this.UserQuestion_Display = "";

          if (screen == "LearningScreen") {

          } else {
            this.clearAvatarContentBox();
          }
          this.uneeq.sendTranscript(this.voiceText)
          //  this.persona.conversationSend(this.voiceText, {}, {});
          this.funToOpenChatBubble(this.voiceText)
          this.userText = ""

          this.disableMicButton = true;
          this.hideOptionOnlyFOrMobile();
          if (this.voiceText) {
            this.stop();
          }
        } else if (e.result.reason === sdk.ResultReason.NoMatch) {
          const noMatchDetail = sdk.NoMatchDetails.fromResult(e.result);
          console.log("No speech recognized." + " | NoMatchReason: " + sdk.NoMatchReason[noMatchDetail.reason]);
          this.isMicButtonActive = false;
          this.showMic = false;
          this.isvoiceAnimationOn = false;
        } else {
          // console.log(ERROR: ${e.errorDetails});
        }
      };

      this.recognizer.canceled = (s: any, e: any) => {
        console.log("CANCELED: Reason=${e.reason}");
        if (e.reason === sdk.CancellationReason.Error) {
          console.log("CANCELED: ErrorCode=${e.errorCode}")
          // console.log("CANCELED: ErrorDetails=${e.errorDetails});
        }
        this.recognizer.stopContinuousRecognitionAsync();
      };

      this.recognizer.speechEndDetected = (s: any, e: any) => {
        this.recognizer.close();
        this.recognizer = undefined;
      };

      this.recognizer.sessionStopped = (s: any, e: any) => {
        this.recognizer.stopContinuousRecognitionAsync();
      };

      this.recognizer.startContinuousRecognitionAsync();
    }
  }


  stopOnClick() {
    this.stop()
  }

  /**
   * stop mic function 
   * 
   */
  stopOnclickMic() {
    this.recognizer.stopContinuousRecognitionAsync(
      () => {
        //    console.log("Speech recognition stopped.");
      },
      (error: any) => {
        //   console.log(`Error stopping recognition: ${error}`);
      }
    );
    this.showMic = false
    this.isvoiceAnimationOn = false
    this.dotIndicatorAnimation = false
    this.isMicButtonActive = false
  }

  stop() {
    this.showMic = false
    this.isvoiceAnimationOn = false
    this.recognizing = false;
    this.dotIndicatorAnimation = false
    this.isMicButtonActive = false;
    if (this.recognizer) {
      this.recognizer.stopContinuousRecognitionAsync(
        this.stopRecognizer.bind(this),
      )
    } else {
      console.error('Recognizer is undefined');
    }
  }


  stopRecognizer() {
    this.recognizer.close()
    this.recognizer = undefined
    console.log('stopped')
  }


  /**
 * Manage chat bar functionality.
 * This function is responsible for handling interactions and functionality
 * related to the chat bar.
 * Note: Implement the specific chat-related actions within the function's logic.
 */
  chatbar() {
    const chatB = this.elementRef.nativeElement.querySelector('#chat-bar');
    if (this.ischatBoxOpen === true) {
      chatB.style.display = 'none';
    } else if (this.ischatBoxOpen === false) {
      chatB.style.display = 'block';
    }
    this.ischatBoxOpen = !this.ischatBoxOpen
  }


  /**
   * Toggle user's Closed Captions (CC) setting.
   * This function is responsible for toggling the user's Closed Captions setting
   * on or off, providing control over the display of captions for content.
   */
  userCCOnOf() {

    // console.log('else consition ')
    // const userText = this.elementRef.nativeElement.querySelector('#userText');
    // if (this.UserccOnOff == true) {
    //   this.renderer.addClass(userText, 'showI');
    // } else if (this.UserccOnOff == false) {
    //   this.renderer.removeClass(userText, 'showI');
    // }
    // this.UserccOnOff = !this.UserccOnOff
    // console.log('cc==', this.UserccOnOff)


  }


  /**
   * Toggle test prep Question card Closed Captions (CC) setting.
   * This function is responsible for toggling the question card Closed Captions setting
   * on or off, providing control over the display of captions for content.
   */
  QuestionCCOnOf() {
    const QuestionDisplay = this.elementRef.nativeElement.querySelector('#QuestionDisplay');
    if (this.QuestionccOnOff == true) {
      this.renderer.addClass(QuestionDisplay, 'hideMessage');
    } else if (this.QuestionccOnOff == false) {
      this.renderer.removeClass(QuestionDisplay, 'hideMessage');
    }
    this.QuestionccOnOff = !this.QuestionccOnOff
  }



  /**
   * Toggle Closed Captions (CC) for the avatar.
   * This function is responsible for toggling the Closed Captions setting
   * for the avatar, controlling the display of captions associated with
   * avatar interactions or messages.
   */
  avatarCCOnOf() {
    // const message22 = this.elementRef.nativeElement.querySelector('#message');
    const message22 = this.elementRef.nativeElement.querySelector('#chatbubblemessage');
    if (this.ccOnOff == true) {
      this.renderer.removeClass(message22, 'showMessage');
    } else if (this.ccOnOff == false) {
      this.renderer.addClass(message22, 'showMessage');
    }
    this.ccOnOff = !this.ccOnOff
    localStorage.setItem('cc', JSON.stringify(this.ccOnOff))
  }

  /**
 * Toggle chat bubble  for the avatar.
 */
  chatBubbleFun(x: any) {
    console.log('c')
    if (x = 'normal') { }
    const message22 = this.elementRef.nativeElement.querySelector('#chatbubblemessage');
    const normalMessage = this.elementRef.nativeElement.querySelector('#message');
    const screen = localStorage.getItem('screen');


    if (screen == "TestSeries" || screen == "LearningScreen") {

      if (this.bubblewindowOnOff == true) {
        console.log('----if learning  ------', ' if', this.bubblewindowOnOff)
        this.renderer.removeClass(message22, 'showMessage');
        $('#chatbubblemessage').css('margin-top', '8px')
        $('#chatbubblemessage').removeClass('learningchatBOx');
        this.blurEffect = false
      } else if (this.bubblewindowOnOff == false) {
        console.log('----if learning  ------', 'else if', this.bubblewindowOnOff)
        this.renderer.addClass(message22, 'showMessage');
        $('#chatbubblemessage').addClass('learningchatBOx');
        this.blurEffect = true
      }

    }
    else {
      console.log('----else ------')
      if (this.bubblewindowOnOff == true) {
        console.log('----if learning  ------', ' if', this.bubblewindowOnOff)
        this.renderer.removeClass(message22, 'showMessage');
      } else if (this.bubblewindowOnOff == false) {
        console.log('----if learning  ------', 'else if', this.bubblewindowOnOff)
        this.renderer.addClass(message22, 'showMessage');
      }
      this.blurEffect = false
    }
    console.log(this.bubblewindowOnOff, 'fffbb')
    // this.ccOnOff = !this.ccOnOff
    if (x === 'normal') {
      this.bubblewindowOnOff = !this.bubblewindowOnOff
    }
    console.log(x, 'all')
    console.log(this.bubblewindowOnOff, 'fffbb')
    localStorage.setItem('cc', JSON.stringify(this.ccOnOff))
  }



  checkHideCondition() {
    const div1 = this.elementRef.nativeElement.querySelector('#movableCard');
    const div2 = this.elementRef.nativeElement.querySelector('#centerDiv');

    this.renderer.removeClass(div1, 'showI');
    this.renderer.removeClass(div2, 'showI');
    this.mobileAvatarOnOff = false
  }


  hideCardForMobile() {

  }

  /**
 * Hide the avatar card on the user interface.
 * This function is responsible for hiding the avatar card, making it
 * invisible or removing it from the UI display.
 */
  hideMethod() {

    // if (window.screen.width < 480) {
    //   const div1 = this.elementRef.nativeElement.querySelector('#movableCard');
    //   this.renderer.removeClass(div1, 'showI');
    //   this.fullScreen = true
    //   this.onLoadCard('')
    // } else {
    const div1 = this.elementRef.nativeElement.querySelector('#movableCard');
    const div2 = this.elementRef.nativeElement.querySelector('#centerDiv');
    if (this.mobileAvatarOnOff === true) {
      this.renderer.removeClass(div1, 'showI');
      this.renderer.removeClass(div2, 'showI');
    } else if (this.mobileAvatarOnOff === false) {
      this.renderer.addClass(div1, 'showI');
      this.renderer.addClass(div2, 'showI');
    }
    this.mobileAvatarOnOff = !this.mobileAvatarOnOff
    // }
    console.log('=========', this.mobileAvatarOnOff)

  }



  // uneeq mic stop function
  uneeqStopSpeak() {

  }

  // function for reset idle prompt when user active
  reset() {
    clearTimeout(this.idleTimeout);
    this.idleTimeout = setTimeout(() => {
      this.idleAvatarMessgePrompt()
      //  }, 10000)
    }, 5 * 60 * 1000)
  }


  /**
 * Prompt a message from the idle user.
 * This function is responsible for triggering a message or prompt from the avatar
 * when the user is in an idle state.
 */
  idleAvatarMessgePrompt() {
    var session = localStorage.getItem('sessionId')
    this.hideOptionTempraryFormobile = true
    let data = {
      "sessionId": session,
      "message": "Are you there?",
      // "email": this.user.email
    }
    this.ser.postAPI(`${environment.baseUrl}${API.uneeqPromptMessege}`, data).subscribe(res => { })
  }






  /**
 * Function to make a call normal  OpenAI API
 */
  OpenAICall() {
    if (this.DescAnswer == "Let me ponder regarding this." ||
      this.DescAnswer == "Let me think a bit." ||
      this.DescAnswer == "Getting the answer from other data sources." ||
      this.DescAnswer == "Allow me a moment to think." ||
      this.DescAnswer == "I'll need a moment to access the data." ||
      this.DescAnswer == "Give me few seconds to gather my thoughts."
    ) {
      this.normalGPT = true
      if (localStorage.getItem('screen') === "TestSeries") {
        setTimeout(() => {
          var m: any = document.getElementById('outputDesc')
          if (m) m.innerHTML = '';
        }, 0)
      } else {
        var d: any = document.getElementById('outputDesc')
        if (d) d.innerHTML = '';
      }
      this.normalGPT = true
      this.callOpenAI()
    } else {
      this.runLoderGPT = false;
    }
  }

  /**
* Function to make a call mathematics  OpenAI API
*/
  OpenAIMathematics() {
    if (this.DescAnswer === 'Let me think') {
      this.runLoderGPT = true;
      var m: any = document.getElementById('outputDesc')
      if (m) m.innerHTML = '';
      this.callOpenAIMathematicsAPI()
      // this.OpenAIMathematicsSoulMAchine()
    } else {
      this.runLoderGPT = false;
    }
  }

  /**
 * Function to add color to an option at a specific position
 * @param {any} color - The color to be added to the option
 * @param {number} position - The position of the option to which the color should be added
 */
  addColorOnOption(color: any, position: number) {
    let buttonId: any
    let buttonId2: any
    if (window.innerWidth < 500) {
      buttonId = `#colorbtnOptionMobile` + position;
      buttonId2 = `#startColorMobile` + position;
    } else {
      buttonId = `#colorbtnOption` + position;
      buttonId2 = `#startColor` + position;
    }
    //  const buttonId = `#colorbtnOption` + position;
    // const buttonId2 = `#startColor` + position;
    const colorbtnOption: any = this.elementRef.nativeElement.querySelector(buttonId);
    const colorbtnOption2: any = this.elementRef.nativeElement.querySelector(buttonId2);

    if (colorbtnOption && colorbtnOption2) {
      if (color == 'green') {
        this.renderer.setStyle(colorbtnOption, 'background', 'green');
        this.renderer.setStyle(colorbtnOption, 'color', '#fff');
        this.renderer.setStyle(colorbtnOption, 'border', '2px solid green');
        this.renderer.setStyle(colorbtnOption2, 'background', 'green');
        this.renderer.setStyle(colorbtnOption2, 'color', '#fff');
        this.renderer.setStyle(colorbtnOption2, 'border', '2px solid green');
      } else {
        this.renderer.setStyle(colorbtnOption, 'background', '#d31010');
        this.renderer.setStyle(colorbtnOption, 'color', '#fff');
        this.renderer.setStyle(colorbtnOption, 'border', '2px solid #d31010');
        this.renderer.setStyle(colorbtnOption2, 'background', '#d31010');
        this.renderer.setStyle(colorbtnOption2, 'color', '#fff');
        this.renderer.setStyle(colorbtnOption2, 'border', '2px solid #d31010');
      }
    }


  }

  /**
* Function to add color to an mathematices appraoch  option at a specific position
* @param {number} index - The position of the option to which the color should be added
*/
  addColorToApproach(index: any) {
    const DivId = `#colorApproachOption` + index;
    const colorOption = this.elementRef.nativeElement.querySelector(DivId);
    if (colorOption) {
      this.renderer.setStyle(colorOption, 'background', '#104D85');
      this.renderer.setStyle(colorOption, 'color', 'white');
      this.renderer.setStyle(colorOption, 'border', '1px solid rgba(255, 255, 255, 0.75)');
    }
  }





  /**
 * Function to display user question text.
 * 
 * @param {any} msg - The message containing the user's question or input.
 * @returns {void} - This function does not return any value.
 */
  userAskQuestionDisplayText(msg: any) {  // user question show by mic 
    if (this.UserccOnOff == true) {
      $('#userText').removeClass('showI')
    }
    const userQues: any = document.getElementById('local-transcript')
    if (userQues) userQues.innerHTML = 'User: ' + msg;
    this.userSpeakValue = msg;
  }





  startScrolling() {  /// start auto scrolling animation for learning modules
    const lineHeight = 150;
    const scrollSpeed = 23000; // Total time for the scrolling animation (in milliseconds)
    //const scrollSpeed = 20000; // Total time for the scrolling animation (in milliseconds)
    const totalMovements = 30; // Number of times to move down by 20%
    //let isManualScrolling = false; // Flag to indicate manual scrolling
    let animationInProgress = false;
    let scroll = $('#outputDesc');
    let isEventHandled = false;
    scroll.on('click mouseenter touchstart touchend', () => {
      if (!isEventHandled) {
        this.stopAutoscrollFun();
        // Set the flag to true to indicate that the event has been handled
        isEventHandled = true;
        console.log('++++++++++++++++++ scroll manual detect ++++++++++++++++++++++++++')

      }
    });

    const scrollToPosition = (position: any) => {

      if (isEventHandled == true) {
        console.log('stopped', 'Manual scrolling detected touched detect ');
        return
      }

      if (!scroll.length) {
        // console.log('Scroll element with id "outputDesc" not found. Cannot start auto-scrolling.');
        return; // Exit the function if the scroll element is not found
      }

      if (this.isManualScrolling) {
        console.log('Manual scrolling detected. Stopping the automatic scrolling.');
        this.isManualScrolling = false
        return;
      }

      const newScrollTop = position * lineHeight;
      animationInProgress = true;
      scroll.animate({ scrollTop: newScrollTop }, scrollSpeed, function () {
        // Wait for the specified delay before triggering the next movement
        if (position < totalMovements && scroll.scrollTop() + scroll.innerHeight() < scroll[0].scrollHeight) {
          scrollToPosition(position + 1); // Move down by another 20%
          //  console.warn('========>', position + 1)
        } else {
          // Scrolling reached the bottom or the total number of movements, stop the animation
          //   console.warn('========> Scrolling animation stopped');
          scroll.stop()
          // scroll.off('scroll'); // Remove the scroll event listener when the animation stops
          animationInProgress = false;
        }
        // }, delayBetweenMovements);
      });
    }
    // if (!animationStarted) {
    //   animationStarted = true;
    setTimeout(function () {
      const t = 1
      scrollToPosition(t); // Start scrolling from the second position (20%)
    }, 5000);
    // }, 5000);
    // }
  }


  startScrollingForQuestionTestSeries() {  /// start auto scrolling animation for test series Question 
    const lineHeight = 150;
    const scrollSpeed = 23000; // Total time for the scrolling animation (in milliseconds)
    const delayBetweenMovements = 0; // Time to wait between each 20% movement (in milliseconds)
    const totalMovements = 30; // Number of times to move down by 20%
    let animationInProgress = false;
    var scroll = $('#questionDescription');

    let isEventHandled = false;
    scroll.on('click mouseenter touchstart touchend', () => {
      if (!isEventHandled) {
        //  console.log('detect scroll inside function running')
        // this.isManualScrolling = true
        this.stopAutoscrollFun()

        // Set the flag to true to indicate that the event has been handled
        isEventHandled = true;
        console.log('++++++++++++++++++ scroll manual detect ++++++++++++++++++++++++++')
      }
    });


    const scrollToPositionQues = (position: any) => {
      if (isEventHandled == true) {
        console.log('Manual scrolling detected. Stopping the automatic scrolling.');
        return
      }

      if (!scroll.length) {
        //   console.log('Scroll element with id "outputDesc" not found. Cannot start auto-scrolling.');
        return; // Exit the function if the scroll element is not found
      }
      // if (this.isManualScrolling) {
      //   console.log('Manual scrolling detected. Stopping the automatic scrolling.');
      //   // If manual scrolling is detected, stop the automatic scrolling
      //   return;
      // }

      const newScrollTop = position * lineHeight;
      animationInProgress = true;
      scroll.animate({ scrollTop: newScrollTop }, scrollSpeed, function () {
        // Wait for the specified delay before triggering the next movement
        if (position < totalMovements && scroll.scrollTop() + scroll.innerHeight() < scroll[0].scrollHeight) {
          scrollToPositionQues(position + 1); // Move down by another 20%
          //   console.warn('========>', position + 1)
        } else {
          // Scrolling reached the bottom or the total number of movements, stop the animation
          //   console.warn('========> Scrolling animation stopped');
          scroll.stop()
          // scroll.off('scroll'); // Remove the scroll event listener when the animation stops
          animationInProgress = false;
        }
        // }, delayBetweenMovements);
      });
    }
    setTimeout(function () {
      const t = 1
      scrollToPositionQues(t); // Start scrolling from the second position (20%)
      // console.log('run for Questions')
    }, 5000);

  }

  startScrollingForANswerTestSeries() {  /// start auto scrolling animation for test series response 

    const lineHeight = 150;
    const scrollSpeed = 23000; // Total time for the scrolling animation (in milliseconds)
    const delayBetweenMovements = 0; // Time to wait between each 20% movement (in milliseconds)
    const totalMovements = 30; // Number of times to move down by 20%
    let animationInProgress = false;

    var scroll = $('#answerDescription');

    let isEventHandled = false;
    scroll.on('click mouseenter touchstart touchend', () => {
      if (!isEventHandled) {
        //  console.log('detect scroll inside function running')
        // this.isManualScrolling = true
        this.stopAutoscrollFun()

        // Set the flag to true to indicate that the event has been handled
        isEventHandled = true;
        console.log('++++++++++++++++++ scroll manual detect ++++++++++++++++++++++++++')

      }
    });

    const scrollToPositionQues = (position: any) => {
      if (isEventHandled == true) {
        console.log('Manual scrolling detected. Stopping the automatic scrolling.');
        return
      }

      if (!scroll.length) {
        //  console.log('Scroll element with id "outputDesc" not found. Cannot start auto-scrolling.');
        return; // Exit the function if the scroll element is not found
      }

      if (this.isManualScrolling) {
        // console.log('Manual scrolling detected. Stopping the automatic scrolling.');
        // If manual scrolling is detected, stop the automatic scrolling
        return;
      }

      const newScrollTop = position * lineHeight;
      animationInProgress = true;
      scroll.animate({ scrollTop: newScrollTop }, scrollSpeed, function () {
        // Wait for the specified delay before triggering the next movement

        if (position < totalMovements && scroll.scrollTop() + scroll.innerHeight() < scroll[0].scrollHeight) {
          scrollToPositionQues(position + 1); // Move down by another 20%
          //   console.warn('========>', position + 1)
        } else {
          // Scrolling reached the bottom or the total number of movements, stop the animation
          //     console.warn('========> Scrolling animation stopped');
          scroll.stop()
          // scroll.off('scroll'); // Remove the scroll event listener when the animation stops
          animationInProgress = false;
        }
        // }, delayBetweenMovements);
      });
    }
    setTimeout(function () {
      const t = 1
      scrollToPositionQues(t); // Start scrolling from the second position (20%)
      // console.log('run for Questions')
    }, 5000);

  }



  subtitleAnimationRun(result: any) {
    //  if(this.UserccOnOff == true){  // check subtitle cc off 
    var splitResult = result.split(' ');
    var finalStr: any = [];
    for (var i = 0; i < splitResult.length; i += 6) {
      finalStr.push(splitResult.slice(i, i + 6).join(' '));
    }
    //console.log(finalStr);
    this.displaySubtitles(finalStr);
    // }

  }

  displaySubtitles(final: any) {
    var container: any = document.getElementById('local-transcript')
    let delay = 0;
    let index = 0;
    const displaySubtitle = (data: any) => {
      container.innerHTML = this.avatarName + ':' + data;
    };

    const animateSubtitles = () => {
      if (!this.isSubtitleAnimationRunning) return; // Stop if flag is set to false

      if (index < final.length) {
        displaySubtitle(final[index]);

        index++;
        setTimeout(animateSubtitles, delay); // Delay in milliseconds between each subtitle
        delay += 1800;
        delay = Math.min(delay, 2300); // Limit the maximum delay to 1800 milliseconds
        //  console.log(delay)
        // 1700 and 2200
      }
    };
    animateSubtitles();
  }


  // Function to stop the subtitle animation
  stopSubtitleAnimation() {
    this.isSubtitleAnimationRunning = false;
    this.stopAutoscrollFun()
  }


  stopAutoscrollFun() {
    if (localStorage.hasOwnProperty("learningId")) {
      this.isManualScrolling = true
      var scroll = $('#outputDesc');
      scroll.stop();
      $('.avatarspeak-s').scrollTop(0);
    } else {
      var scroll = $('#outputDesc');
      scroll.stop();
      $('.avatarspeak-s').scrollTop(0);
    }

    if (localStorage.hasOwnProperty("screen")) {
      //this.isManualScrolling = true
      var scroll = $('#questionDescription');
      scroll.stop();
      $('#questionDescription').scrollTop(0);

      var scroll = $('#answerDescription');
      scroll.stop();
      $('#answerDescription').scrollTop(0);
    }
  }

  /**
 * Function to handle navigation based on verbal commands
 * @param {any} msg - The verbal command message
 */
  verbalCommandNavigation(msg: any) {

    switch (msg) {
      case 'Closing the quiz.':
        setTimeout(() => {
          //  this.router.navigate(['/user/testseries']);
          this._location.back();
          this.oncrossTest();

        }, 2000);
        break;

      case 'Sure, hiding myself.':
        this.oncrossTest();
        this.mobileAvatarOnOff = false;
        this.hideMethod();
        break;

      case 'Here I am.':
        this.mobileAvatarOnOff = true;
        this.hideMethod();
        break;

      case 'Turning to full screen mode.':
        this.fullScreen = false;
        this.fullScreen = true;
        this.onLoadCard('');
        break;

      case 'Changing to smaller view.':
        this.fullScreen = true;
        this.fullScreen = false;
        this.onLoadCard('');
        break;

      case 'Opening the dashboard.':
        this.router.navigate(['/user/dashboard']);
        this.oncrossTest();
        break;

      case 'Opening the home page.':
        this.router.navigate(['/user/dashboard']);
        this.oncrossTest();
        break;


      case 'Sure, opening the reading section.':
        this.router.navigate(['/user/reading']);
        this.oncrossTest();
        break;

      case 'Ok, going to the profile page.':
        this.profile();
        this.oncrossTest();
        break;

      case 'Sure, opening the test series.':
        this.router.navigate(['/user/testseries']);
        this.oncrossTest();
        break;
      case 'Sure, opening the Test Prep.':

        this.router.navigate(['/user/testseries']);
        this.oncrossTest();
        break;

      case 'Sure, turning on your subtitles.':
        this.UserccOnOff = false;
        this.userCCOnOf();
        break;

      case 'Sure, turning off your subtitles.':
        this.UserccOnOff = true;
        this.userCCOnOf();
        break;

      case 'Sure, turning on my subtitles.':
        this.ccOnOff = false;
        this.avatarCCOnOf();
        break;

      case 'Sure, turning off my subtitles.':
        this.ccOnOff = true;
        this.avatarCCOnOf();
        break;

      case 'Showing the question.':
        // this.QuestionccOnOff = false;
        // this.QuestionCCOnOf();
        break;

      case 'Hiding the question.':
        // if (localStorage.getItem('screen') !== 'LearningScreen' ) {
        // this.QuestionccOnOff = true;
        // this.QuestionCCOnOf();
        // }
        break;

      case 'Opening the learning module':

        this.router.navigate(['/user/course']);
        this.oncrossTest();

        break;

      case 'Moving to the next slide':
        setTimeout(() => {
          this.childPdf.nextPage();
          console.log('next command hit')
          var scroll = $('#outputDesc');
          scroll.stop();

          $('.avatarspeak-s').scrollTop(0);
          // }, 2200)
        }, 3000)
        break;

      case 'Moving to the previous slide':
        setTimeout(() => {
          this.childPdf.prevPage()
          var scroll = $('#outputDesc');
          scroll.stop();

          $('.avatarspeak-s').scrollTop(0);
          // }, 2200)
        }, 3000)
        break;

      case 'Sure, repeating the slide':
        setTimeout(() => {
          this.childPdf.refreshCurrentSlide()
          console.log('rrrrrkrkrkrkrkrk')
        }, 2900)

        break;

      case 'Closing the module':
        setTimeout(() => {
          this.exitpresentationFun()
        }, 1200)
        break;

      case 'Sure, turning on the mathematics module.':
        // this.onLoadCard("id")
        // this.runMathToggleFromParent()

        break;

      case 'Logging out, see you soon.':
        setTimeout(() => {
          this.router.navigate(['/'])
          this.logOut()
          this.oncrossTest()
        }, 3000);
        break;
 
      case "Opening text bubbles.":
        setTimeout(() => {
          console.log(this.bubblewindowOnOff,'chat-----')
          if(this.fullScreen == false){
            
            
            if(this.bubblewindowOnOff = true){
              this.bubblewindowOnOff = false
              this.chatBubbleFun('normal')
            }else{
              this.chatBubbleFun('normal')
            }
          }else{
            this.fullScreen = true
            this.onLoadCard('');
            if(this.bubblewindowOnOff = true){
              this.bubblewindowOnOff = false
              this.chatBubbleFun('normal')
            }
          }
          
        }, 800);
      break;
      case "Opening chat bubbles.":
          setTimeout(() => {
            console.log(this.bubblewindowOnOff,'chat-----')
            if(this.fullScreen == false){
              
              if(this.bubblewindowOnOff = true){
                this.bubblewindowOnOff = false
                this.chatBubbleFun('normal')
              }else{
                this.chatBubbleFun('normal')
              }
            }else{
              
              this.fullScreen = true
              this.onLoadCard('');
              if(this.bubblewindowOnOff = true){
                this.bubblewindowOnOff = false
                this.chatBubbleFun('normal')
              }
              
              // this.bubblewindowOnOff = true
            }
            
          }, 800);
          const screen = localStorage.getItem('screen');
          console.log(screen)
          if(screen == "LearningScreen"){
            console.log('LearningScreen true----')
          }
      break;  
    }

  }

  // navigate to profile page
  profile() {
    this.router.navigate(['user/profile'])
  }

  // uneeq end senssion
  endSession() {
    this.uneeq.endSession();
  }

  //stop hand button function
  stopSpeak() {
    this.uneeq.uneeqStopSpeaking()

  }


  // Add push to talk spacebar key listeners
  addPTTKeyListeners() {
    // When the user presses down on space bar, tell the digital human to start recording (start listening)
    document.addEventListener('keydown', (e: any) => {
      if (e.code === 'Space' && !e.repeat && e.target.type !== 'text') {
        this.uneeq.stopSpeaking()
        this.onclickMic()
        this.stopSubtitleAnimation()
      }
    });
  }




  //Function to handle resizing operations
  resizeFun() {
    var avatarCanvas: any = document.querySelector('#sm-video canvas');
    const windowWidth = window.innerWidth;
    //this.minimizeBoxCardUI()
    // this.changeAvatarSize()
    // window.screen.width < 480
    if (windowWidth < 480) {



      $('#mainData').addClass('main-content_large')
      $('#sidebar').addClass('sidebar_small')
      $('#movableCard').addClass('mobile_speaking')
      $('#sidebar').addClass('showI')    // this is for mobile responsive
      this.showImage = false
      if (localStorage.getItem('AvatResCC') === "false") {
        // console.warn('its avatar component and resize ')
        avatarCanvas.style.height = '490px'
        avatarCanvas.style.width = '100%'
      }

      if (localStorage.getItem('screen') === "TestSeries") {
        avatarCanvas.style.height = '230px'
        avatarCanvas.style.width = '100%'

        setTimeout(() => {
          $('#optionMessage').css('margin-top', '2px')
          $('#QuestionDisplay').css('margin-top', '6px')
        }, 4000)
      }

    } else {

      $('#mainData').removeClass('main-content_large')
      $('#sidebar').removeClass('sidebar_small')
      $('#movableCard').removeClass('mobile_speaking')
      $('#sidebar').removeClass('showI')    // this is for mobile responsive
      this.showImage = true
    }
    // window.screen.height
    const windowHeight = window.innerHeight;
    if (windowHeight < 575) {
      if (localStorage.getItem('AvatResCC') === "false") {
        //   console.log('its landscape mode normal one' , )
        //  console.log('its landscape ==>  its on full screen')
        avatarCanvas.style.height = '220px'
        $('#message').css('width', '350px')
      }
    }


    // this.minimizeBoxCardUI()
    // this.smallcardSetPosition()

  }

  /**
   * Function to handle the selection of an option in the Maths List
   * @param {any} event - The event associated with the selection
   * @param {any} index - The index of the selected option
   */
  selectMathsListOption(event: any, index: any) {
    if (this.voiceText) {
      this.voiceText = ""

      this.stopSubtitleAnimation()
    }
    this.uneeq.stopSpeaking()
    // this.runLoderGPT = true
    //this.persona.stopSpeaking()
    this.userInputText = ""
    var textData = this.mathsQuestion + " by " + event
    this.userInputText = textData
    this.uneeq.sendTranscript(textData)
    setTimeout(()=>{
      this.runLoderGPT = true

    },1000)
    // this.persona.conversationSend(textData, {}, {});
  }

  // loadGraphDataForMaths() {
  //   this.isgraphLoaded = true
  //   $('#graphButton').removeClass('showMessage')
  // }




  // Function to navigate to the next question
  nextQuestion() {
    if (this.voiceText) {
      this.voiceText = ""
      //  this.uneeq.stopSpeaking()
      this.stopSubtitleAnimation()
    }
    this.optionList = []
    this.uneeq.stopSpeaking()
    // this.persona.stopSpeaking()
    this.showOptionOnlyFOrMobile()
    var nextQ = "next"
    // this.persona.conversationSend(nextQ, { 'Percentage': this.test_Progress }, {});
    this.uneeq.sendTranscript(nextQ)

  }

  // Function to navigate to the same question
  resumeQuestion() {
    if (this.voiceText) {
      this.voiceText = ""
      // this.uneeq.stopSpeaking()
      this.stopSubtitleAnimation()
    }
    this.uneeq.stopSpeaking()
    //this.persona.stopSpeaking()
    this.showOptionOnlyFOrMobile()
    var resumeQ = "next"
    // this.persona.conversationSend('next', {}, {});
    this.uneeq.sendTranscript(resumeQ)
  }

  // Function to navigate to the previous question
  previousQuestion() {
    if (this.voiceText) {
      this.voiceText = ""
      //  this.uneeq.stopSpeaking()
      this.stopSubtitleAnimation()
    }
    // this.persona.stopSpeaking()
    this.uneeq.stopSpeaking()
    this.showOptionOnlyFOrMobile()
    var backQ = "back"
    //this.persona.conversationSend(backQ, { 'Percentage': this.test_Progress }, {});
    this.uneeq.sendTranscript(backQ)
    localStorage.getItem('Progress')
  }


  showOptionOnlyFOrMobile() {  // show only for on mibile screen
    if (window.screen.width < 480) {
      setTimeout(() => {
        this.hideOptionTempraryFormobile = true
      }, 1000)
    }
  }

  hideOptionOnlyFOrMobile() { // hide option for mobile screen
    if (window.screen.width < 480) {
      this.hideOptionTempraryFormobile = false
    }
  }


  // stop speaking uneeq function
  stopSpeaking() {
    this.isManualScrolling = true
    this.stopAvatarOnClick = !this.stopAvatarOnClick
    this.uneeq.stopSpeaking()
    this.stopSubtitleAnimation()

  }



  minimizeBoxCardUI() {
    const windowWidth = window.innerWidth;
    if (windowWidth < 480) {
      this.isMobileNormalTrue = true
      this.bottomPositionWidth = '81.5%'
      // this.inputWidthSize = '210px';
      this.inputWidthSize = '150px';
      this.bottomPosition = 0
      // this.borderRadius = '0px 0px 20px 20px'
      this.borderRadius = '0px 0px 3px 3px'
      $('#settingId').addClass('showI');
      // this.inputmarginLeft = '35px'
      this.inputheightSize = '48px'
      this.bottomPositionheight = '60px'
      // this.stopBottomSize = '70%'
      this.inputMarginLeft = '-2px'
      // this.stopLeftSize = '8%'
      this.stopBottomSize = '75%'
      this.stopLeftSize = '10%'
      this.micWidth = '48'
      this.micWidthOnly = '48'
      this.micMarginleft = '0'
      this.micHeight = '48'
      this.micMarginLeft2 = '2px'
      this.box2MarginLeft = '4px'
      this.mainClassBottomsize = 2
    } else {
      this.isMobileNormalTrue = false
      // this.bottomPosition = 67
      this.bottomPosition = 10
      //  this.borderRadius = '0px 0px 20px 20px'
      this.borderRadius = '0px 0px 9px 9px'
      // this.bottomPositionWidth = '90%'
      this.bottomPositionWidth = '77.5%'
      // this.inputWidthSize = '201px';
      // this.inputWidthSize = '220px';
      this.inputWidthSize = '210px';
      $('#settingId').addClass('showI');
      this.inputheightSize = '48px'
      this.inputMarginLeft = '3px'
      this.bottomPositionheight = '60px'
      this.stopBottomSize = '75%'
      this.stopLeftSize = '10%'
      this.micWidth = '48'
      this.micWidthOnly = '48'
      this.micMarginleft = '0'
      this.micHeight = '48'
      this.micMarginLeft2 = '0px'
      this.box2MarginLeft = '6px'
      // stopicon
      //this.stopIconRightPosition = '116'
      // this.stopIconBottomPosition = '95'
      this.mainClassBottomsize = 2
      this.stopIconWidthPosition = '50'
      this.stopIconHeightPosition = '50'
    }




  }

  maximizeBoxCardUI() {
    if (window.screen.width < 480) {

      this.bottomPosition = 7
      this.borderRadius = '0px 0px 0px 0px'
      this.bottomPositionWidth = '100%'
      this.inputWidthSize = '85%';
      this.inputWidthMathSize = '100%';
      this.inputmarginLeft = '0px'
      this.inputheightSize = '50px'
      this.inputMarginLeft = '-2px'
      this.bottomPositionheight = '55px'
      this.stopBottomSize = '20%'
      this.stopLeftSize = '5%'
      this.micWidth = '50'
      this.micWidthOnly = '50'
      this.micMarginleft = '0'
      this.micHeight = '50'
      this.micMarginLeft2 = '0px'
      this.box2MarginLeft = '3px'
      this.stopIconWidthPosition = '40'
      this.stopIconHeightPosition = '40'
      this.mainClassBottomsize = 0
    }


    else if (innerHeight == 1152 && innerWidth == 2048) {
      this.stopBottomSize = '30%'
      this.stopLeftSize = '65%'
      this.bottomPosition = 0
      this.borderRadius = '0px 0px 0px 0px'
      this.bottomPositionWidth = '100%'
      this.inputWidthSize = '92%'
      this.inputWidthMathSize = '100%';
      this.inputmarginLeft = '0px'
      this.inputheightSize = '60px'
      this.bottomPositionheight = '68px'

      this.micWidth = '60'
      this.micWidthOnly = '57'
      this.micMarginleft = '0'
      this.micHeight = '60'

    } else {
      this.bottomPosition = 15
      this.borderRadius = '0px 0px 0px 0px'
      this.bottomPositionWidth = '100%'
      this.inputWidthSize = '92%';
      this.inputWidthMathSize = '100%';
      this.inputmarginLeft = '0px'
      this.inputheightSize = '55px'

      this.bottomPositionheight = '58px'
      this.stopBottomSize = '35%'
      this.stopLeftSize = '31%'
      this.micWidth = '55'
      this.micWidthOnly = '55'
      this.micMarginleft = '0'
      this.micHeight = '55'
      this.micMarginLeft2 = '0px'
      this.box2MarginLeft = '9px'
      // this.stopIconRightPosition = '30'
      // this.stopIconBottomPosition = '125'
      this.mainClassBottomsize = 0
      this.stopIconWidthPosition = '60'
      this.stopIconHeightPosition = '60'
    }


  }

  hideAvatarForMobile() {
    const div1 = this.elementRef.nativeElement.querySelector('#movableCard');
    this.renderer.addClass(div1, 'showI');
  }

  // card expand button function  
  onLoadCard(id: any) {

    setTimeout(() => {
      if (this.fullScreen === true) {
        // condition for making small screen avatar card
        this.checkFullScreenB = true
        this.expandOn = true


        // if (window.screen.width < 480) {
        // this.hideAvatarForMobile()
        $('#chat-widget-minimized').css('display', 'block');
        $('#chat-widget-container').css('z-index', '11000');
        $('#chat-widget-container').css('height', '84px');
        // }

        if (this.toggleValue == true) {
          this.normalChatBar = true
          this.mathsChatBar = false
        }

        // $('#bottomShadowBox').addClass('bottomboxSizewithSmallBox');
        // $('#bottomShadowBox').removeClass('bottomboxSize');
        console.log('small')
        this.minimizeBoxCardUI()
        $('#box3Main').addClass('showI');
        $('#muteVoiceId').addClass('showI');
        $('#music_Off').addClass('showI');
        // $('#stopavatarId').addClass('showI');
        //
        $('#stopavatarId').addClass('stopHandIconSmallScreen');
        $('#stopavatarId').removeClass('stopHandIcon');
        $('#stopSoundId').addClass('stopIConsmallscreenImg');
        $('#stopSoundId').removeClass('stopIConImg');
        // mic icons
        $('#zoomMic').addClass('microphoneMobile');
        $('#zoomMic').removeClass('microphoneOn');


        // $('#voiceId').removeClass('newIconSize');
        // $('#voiceId').addClass('newIconSizeMobile');
        // end mic icons
        // card minimize
        $('#tourIcon').addClass('showI');
        $('#speedspeech').addClass('showI');
        $('#minimizeAvatar').addClass('showI');
        $('#maximizeAvatar').removeClass('showI');
        //
        $('#hambergerBar').addClass('showI');
        $('#sidebarAvatar').css('display', 'none');
        $('#MathameticsDisplay').removeClass('showMessage')
        if (window.screen.width < 480) {

          $('#movableCard').addClass('mobile_speaking')
          $('#mobileDotAni').addClass('showI');

        }
        //  this.openFeedbackForm = false
        localStorage.setItem('AvatResCC', this.checkFullScreenB)
        $('#iconShow').addClass('showI')
        $('#sm-video').removeClass('uneeqAv')
        $('#sm-video').addClass('uneeqAvatar')
        $('#message').removeClass('showMessage')
        $('#chatbubblemessage').removeClass('showMessage')
        $('#ImageDisplay').removeClass('showMessage')
        $('#textDisplay').removeClass('showMessage')
        $('#userCC').removeClass('showMessage')
        $('#movableCard').removeClass('full_screen')

        // $('#movableCard').css('border-radius', '30px')
        $('#movableCard').css('border-radius', '8px')

        $('#movableCard-main').removeClass('full_screen')
        $('#stopIcon').removeClass('bottomleft-large')
        $('#stopIcon').addClass('bottomleft')
        $('#isvideo').removeClass('rightDiv-large')
        $('#isvideo').addClass('rightDIv')
        $('#ccSubtitle').addClass('showI')
        $('#chatbarOnly').addClass('showI')
        $('#chat-bar').css('display', 'block')
        $('#mobileAvatarButton').removeClass('showI')
        $('#cross').removeClass('speakingsss_large')
        $('#ruleSeries').addClass('showI')
        $('#feedback').addClass('showI')
        $('#audioMicBut').css('background-color', '#d9e9fd')

        $('#messDescription').addClass('hideMessage')
        this.changeAvatarSize()
        this.ischatBoxOpen = false
        // this.ischatBoxOpen === true
        this.iconShow = false


        var t: any = document.querySelector('#sm-video canvas')
        $(t).css('margin-left', '0px')


      } else if (this.fullScreen === false) {
        // condition for making big screen avatar card
        this.checkFullScreenB = false
        this.expandOn = false
        // card minimize
        this.maximizeBoxCardUI()
        $('#speedspeech').removeClass('showI');
        $('#box3Main').removeClass('showI');
        $('#muteVoiceId').removeClass('showI');
        // $('#music_Off').removeClass('showI');
        if (window.screen.width < 480) {
          $('#box3Main').addClass('showI');
          $('#bottomBar').removeClass('baseChatBottom');
          $('#mobileDotAni').removeClass('showI');
        }
        if (this.toggleValue == true) {
          this.normalChatBar = false
          this.mathsChatBar = true
        }
        // $('#stopavatarId').removeClass('showI');
        if (localStorage.hasOwnProperty("mathtoggle")) {
          let toggle = JSON.parse(localStorage.getItem('mathtoggle') || 'undefined')
          if (toggle == true) {
            $('#settingId').addClass('showI');
          } else {
            $('#settingId').removeClass('showI');
          }
        } else {
          $('#settingId').removeClass('showI');
        }
        //$('#settingId').removeClass('showI');
        //  this.setVideo(window.screen.width, window.screen.height)
        // mic icons
        $('#zoomMic').addClass('microphoneOn');
        $('#zoomMic').removeClass('microphoneMobile');
        // $('#sendId').addClass('newIconSize');
        // $('#sendId').removeClass('newIconSizeMobile');
        //
        $('#stopavatarId').removeClass('stopHandIconSmallScreen');
        $('#stopavatarId').addClass('stopHandIcon');
        $('#stopSoundId').removeClass('stopIConsmallscreenImg');
        $('#stopSoundId').addClass('stopIConImg');
        // $('#voiceId').addClass('newIconSize');
        // $('#voiceId').removeClass('newIconSizeMobile');
        // end mic icons

        // for mobile
        //  this.setVideo(745, 845)
        $('#tourIcon').removeClass('showI');
        // $('#speedspeech').addClass('showI');
        $('#minimizeAvatar').removeClass('showI');
        $('#maximizeAvatar').addClass('showI');
        //
        $('#hambergerBar').removeClass('showI');
        $('#sidebarAvatar').css('display', 'none');
        localStorage.setItem('AvatResCC', this.checkFullScreenB)
        //  setTimeout(()=>{
        // $("iframe").contents().find("#chat-widget-minimized").css('display','none');

        $('#chat-widget-container').css('z-index', '11000');
        // $('#chat-widget-minimized').css('display', 'none');
        $('#chat-widget-container').css('height', '4px');


        //  $('#chat-widget-minimized').css('z-index', '100');
        // },2000)
        $('#iconShow').removeClass('showI')
        $('#sm-video').removeClass('uneeqAvatar')
        //  $('#message').addClass('showMessage') 
        $('#userCC').addClass('showMessage')
        $('#sm-video').addClass('uneeqAv')
        $('#stopIcon').addClass('bottomleft-large')
        $('#stopIcon').removeClass('bottomleft')
        $('#movableCard').addClass('full_screen')

        $('#movableCard-main').addClass('full_screen')
        $('#isvideo').addClass('rightDiv-large')
        $('#isvideo').removeClass('rightDIv')

        $('#movableCard').css('border-radius', '0px')

        $('#ccSubtitle').removeClass('showI')
        $('#chatbarOnly').removeClass('showI')
        $('#chat-bar').css('display', 'none')
        $('#mobileAvatarButton').addClass('showI')
        $('#ruleSeries').removeClass('showI')
        $('#feedback').removeClass('showI')
        $('#audioMicBut').css('background-color', '#7393c4')

        $('#messDescription').removeClass('hideMessage')
        $('#cross').addClass('speakingsss_large')
        //this.setvideoWiths()
        this.iconShow = true
        this.changeAvatarSize()
        if (this.ccOnOff == true) {
          // $('#message').addClass('showMessage')
          //$('#chatbubblemessage').addClass('showMessage')
          console.log('cc check bubble ', this.bubblewindowOnOff)
          this.checkBubbleOpen('normal')
        }
      }
    }, 0)


    if (localStorage.hasOwnProperty("mathsSidebarClick")) {
      this.fullScreen = true
      localStorage.removeItem('mathsSidebarClick');
      //console.log('mathsSidebarClick', 'making it true ', this.fullScreen)
    } else {
      this.fullScreen = !this.fullScreen
      //  console.log('normal condition', this.fullScreen)
    }


    // this.fullScreen = !this.fullScreen
    // console.log('normal condition', this.fullScreen)
  }



  /**
 * Function to change the size of the avatar
 */
 


  smallcardSetPosition() {
    console.log('run when in small card view')
    // var t: any = document.querySelector('#sm-video')
    // var videoTag: any = document.querySelector('#smVideo')
    var t: any = document.querySelector('#sm-video canvas')
    // this.setVideo(330, 180)
    // this.setVideo(500, 300)
    if (window.innerWidth < 480) {
      if (t !== null) {
        t.style.width = '80%'
        // t.style.height = '100%'
        $(t).css('margin-left', '-60px')
        $(t).css('width', '100%')
        $(t).css('height', '100%')

        //    $(videoTag).css('margin-left', '0px')
        // t.style.width = '50%'
        //  t.style.height = '50%'
      }

    } else {
      if (t !== null) {
        // t.style.width = '90%'
        t.style.width = '70%'
        $(t).css('margin-left', '-80px')
        // $(t).css('margin-left', '-39px')
        //   $(videoTag).css('margin-left', '0px')
        //   videoTag.style.width = '100%'
        //  videoTag.style.height = '100%'
        //   $(t).css('width', '100%')
        // $(t).css('height', '100%')

      }
    }
  }



  // select from the option 
  selectOptionForTest(answer: any) {

    let list = [
      { 0: "a" },
      { 1: "b" },
      { 2: "c" },
      { 3: "d" },
      { 4: "e" },
    ];
    this.isClick = true
    this.hideOptionOnlyFOrMobile()
    this.uneeq.stopSpeaking()
    //  this.persona.stopSpeaking()
    this.stopSubtitleAnimation()
    let answerAsString = answer.toString();
    var getOrderList: any = list[answerAsString]
    var final = getOrderList[answerAsString]
    // this.persona.conversationSend(final, { 'Percentage': this.test_Progress }, {});
    this.uneeq.sendTranscript(final)
    let buttonId
    let buttonId2
    if (window.innerWidth < 480) {
      buttonId = `#colorbtnOptionMobile` + answer;
      buttonId2 = `#startColorMobile` + answer;
    } else {
      buttonId = `#colorbtnOption` + answer;
      buttonId2 = `#startColor` + answer;
    }
    //  this.uneeq.sendTranscript(final)
    const colorbtnOption: any = this.elementRef.nativeElement.querySelector(buttonId);
    const colorbtnOption2: any = this.elementRef.nativeElement.querySelector(buttonId2);
    if (colorbtnOption && colorbtnOption2) {
      this.renderer.setStyle(colorbtnOption, 'background', 'white');
      this.renderer.setStyle(colorbtnOption, 'color', 'black');
      this.renderer.setStyle(colorbtnOption2, 'background', 'white');
      this.renderer.setStyle(colorbtnOption2, 'color', 'black');
    }
  }




  // pie API function call old
  callOpenAI() {
    var session = localStorage.getItem('sessionId')
    let payloadData = {
      "data": this.userSpeakValue,
      "gptPrompt": this.user.gptPrompt,
      "sessionID": session,
      // "email": this.user.email,
      "time": this.user.lastlogin,
      "instance_pvt_ip": this.user.instance_pvt_ip
    }
    if (localStorage.hasOwnProperty("learningId")) {
      // var scroll = $('#outputDesc');
      // scroll.stop();
      $('.avatarspeak-s').scrollTop(0);
    }

    this.disableMicButton = true
    this.ser.postAPI(`${environment.baseUrl}${API.openAI}`, payloadData).subscribe({
      next: (v) => {
        this.normalGPT = false;
        this.disableMicButton = false
      },
      error: (e) => {
        console.error(e);
        this.normalGPT = false;
        this.disableMicButton = false
      }

    })
  }


  checkButton() {
    setTimeout(() => {
      if (this.fullScreen == true) {
        console.log('adding color when true')
        $('.bcolorMic').css('background-color', '#d9e9fd')
        if (localStorage.getItem('screen') === "TestSeries" || (localStorage.getItem('screen') === "LearningScreen")) {
          $('.bcolorMic').css('background-color', '#7393c4')
        }

      } else if (this.fullScreen == false) {
        console.log('adding color when false')
        $('.bcolorMic').css('background-color', '#7393c4')
      }
    }, 1)
  }

  //openAI API function call old
  callOpenAIMathematicsAPI() {
    var session = localStorage.getItem('sessionId')
    let payloadData = {
      "data": this.userSpeakValue,
      "gptPrompt": this.user.gptPrompt,
      "sessionID": session,
      // "email": this.user.email,
      "time": this.user.lastlogin,
      "instance_pvt_ip": this.user.instance_pvt_ip
    }
    if (localStorage.hasOwnProperty("learningId")) {
      $('.avatarspeak-s').scrollTop(0);
    }

    this.disableMicButton = true
    this.ser.postAPI(`${environment.baseUrl}${API.OpenAIMathematics}`, payloadData)
      .subscribe({
        next: (v) => {

          this.runLoderGPT = false
          this.disableMicButton = false
        },
        error: (e) => {
          console.error(e)
          this.runLoderGPT = false
          this.disableMicButton = false
        }
      })

  }


  /**
  * Logs out the current user.
  * This function performs the necessary actions to logout the user out of the application.
  * @returns {void}
  */
  logOut() {
    // let payload = {
    //   "time": this.user.lastlogin,
    // }
    // this.service.logOut(payload).subscribe(res => {
    //   localStorage.removeItem("user");
    //   localStorage.removeItem("token");
    // })
    // this.router.navigate(['/'])
  }


  exitTestSeriesClicked: boolean = false;
  // exit test series page button function
  exitTestSeries() {
    
  }


  /**
  * Function to handle the exit of a presentation
  * This function is called when the user wants to exit the presentation.
  */
  exitLearningClicked: boolean = false
  exitpresentationFun() {
    this.pptclose.emit()
    // // this.onLoadCard('')
    // if (this.exitLearningClicked) {
    //   //  this.breadcrumbService.setBreadcrumb('a','/user/dashboard')
    //   return; // Exit the function if already clicked
    // }
    // this.exitLearningClicked = true;
    // var learningid = localStorage.getItem('learningId')
    // let exitpayload = {
    //   "learning_status": false,
    //   "time": this.user.lastlogin,
    //   // "email": this.user.email,
    //   "id": learningid,

    // }

    // //this.persona.stopSpeaking()
    // this.uneeq.stopSpeaking();
    // this.childPdf.clearPdfViewer()
    // this.ser.IndexLearningNo = 1
    // this.ser.postAPI(`${environment.baseUrl}${API.startPresentation}`, exitpayload)
    //   .subscribe((res: any): any => {
    //     //this.onLoadCard('')
    //     localStorage.removeItem("learningId");
    //     if (res.statusCode == 200) {
    //       this.exitLearningClicked = false
    //       if (this.user.industryName == "School") {
    //         this.notSchool = "school"
    //       } setTimeout(() => {
    //         $('#hambergerBar').addClass('showI');
    //       }, 0)
    //       // -----------------------------------------------------sanju----------------------------------------
    //       // if (res.CoursesPopup != "") {
    //       this.exitCourseClicked.emit(res);

    //       this.oncrossTest()
    //       this._location.back();
    //       // this.router.navigate(['user/learning'])
    //     } else {
    //       this.oncrossTest()
    //       this._location.back();
    //       // this.router.navigate(['user/learning'])
    //     }
    //   })


    // this.childPdf.sendData(0, 'stop')
    // //  this.persona.conversationSend('stop', {}, {})
  }


  checkBubbleOpen(x: any) {
    if (x === 'normal') {
      if (localStorage.hasOwnProperty("mathtoggle")) {
        let toggle = JSON.parse(localStorage.getItem('mathtoggle') || 'undefined')
        if (toggle == true) {
          $('#message').addClass('showMessage')
          $('#chatbubblemessage').removeClass('showMessage')
        } else {
          if (this.bubblewindowOnOff == false) {

          } else {
            $('#chatbubblemessage').addClass('showMessage')
          }
        }
      } else {
        if (this.bubblewindowOnOff == false) {

        } else {
          $('#chatbubblemessage').addClass('showMessage')
        }
      }
    }
  }

  /**
   * Check if the maths indicator is active
   * This function is responsible for determining whether the maths indicator is currently active.
   */
  checkMathsIndicatorActive() {
    if (localStorage.hasOwnProperty("mathtoggle")) {
      let toggle = JSON.parse(localStorage.getItem('mathtoggle') || 'undefined')
      if (toggle == true) {
        // this.mathsChatBar = true
        // this.normalChatBar = false

        if (this.toggleValue == true) {
          this.normalChatBar = true
          this.mathsChatBar = false
        }
        this.mathButtonDisabled = false
        this.toggleValue = true
        this.UserccOnOff = true
        this.userCCOnOf()

        // $('#mathApproach').addClass('showMessage')
        $('#mathsIndicator').addClass('showI')
        $('#mathsIndicatorONN').removeClass('showI')
        $('#mathsIndicator').css('cursor', 'pointer')
        $('#mathsIndicator').addClass('mathsOffClass')
      } else {
        //  $('#chatbubblemessage').addClass('showMessage')
        this.mathsChatBar = false

        this.normalChatBar = true
        this.mathButtonDisabled = true
        this.toggleValue = false
        // this.userCCOnOf()
        $('#mathApproach').removeClass('showMessage')
        $('#mathsIndicator').removeClass('showI')
        $('#mathsIndicatorONN').addClass('showI')
        $('#mathsIndicator').css('cursor', 'pointer')
        $('#mathsIndicator').addClass('mathsOffClass')
      }
    } else {
      // $('#chatbubblemessage').addClass('showMessage')

      $('#mathsIndicator').removeClass('showI')
      $('#mathsIndicatorONN').addClass('showI')
      $('#mathsIndicator').css('cursor', 'pointer')
      $('#mathsIndicator').addClass('mathsOffClass')
    }

  }



  /**
  * Function called when the test or learning is crossed or closed
  * This function is triggered when the user crosses or closes the test.
  * @returns {void} - This function doesn't return anything.
  */
  oncrossTest() {
    this.pdfShow = ""
    console.log('true')
    // this.optionList = []
    // this.CorrectAnswer = ""
    // this.questionList = ""

    this.expandOn = true
    this.mobileAvatarOnOff = false
    this.refreshBtnPPT = false
    this.bubblewindowOnOff = true
    // if (window.screen.width < 480) {
    // this.mobileAvatarOnOff = true
    // this.hideAvatarForMobile()
    $('#chat-widget-minimized').css('display', 'block');
    $('#chat-widget-container').css('z-index', '11000');
    $('#chat-widget-container').css('height', '84px');
    //  }

    // var videoTag: any = document.querySelector('#smVideo')
    //  $(videoTag).css('margin-left', '0px')
    //  videoTag.style.width = '100%'
    //  videoTag.style.width = '100%'

    this.hideOptionTempraryFormobile = true
    localStorage.removeItem('screen')
    localStorage.setItem('AvatResCC', 'true')
    this.ser.updatePDFLinkData("");
    //  $('#hambergerBar').addClass('showI');
    $('#stopavatarId').addClass('stopHandIconSmallScreen');
    $('#stopavatarId').removeClass('stopHandIcon');
    $('#stopSoundId').addClass('stopIConsmallscreenImg');
    $('#stopSoundId').removeClass('stopIConImg');
    //
    $('#sidebarAvatar').css('display', 'none');
    $('#hambergerBar').addClass('showI');
    $('#pptPageLoad').addClass('hideMessage')
    $('#chatbubblemessage').css('margin-top', '8px')
    $('#chatbubblemessage').removeClass('learningchatBOx');
    // chat btn 
    // test series
    $('#showTestPrepItem').removeClass('showMessage')
    $('#newSummery').removeClass('showMessage')
    $('#optionMessage').css('margin-top', '0px')
    // $('#optionMessage').css('margin-top', '20px')
    // $('#movableCard').css('border-radius', '30px')
    $('#movableCard').css('border-radius', '8px')
    $('#QuestionDisplay').css('margin-top', '21px')
    // $('#pdfDataSet').css('margin-top', '15px')
    $('#pdfDataSet').css('margin-top', '25px')
    $('#muteVoiceId').addClass('showI');
    $('#music_Off').addClass('showI');
    $('#box3Main').addClass('showI');
    $('#mobileDotAni').addClass('showI');
    $('#speedspeech').addClass('showI');

    if (window.screen.width < 480) {
      this.bottomPositionWidth = '81.5%'
      this.inputWidthSize = '210px';
      this.bottomPosition = 0
      this.micMarginLeft2 = '2px'
      //  this.borderRadius = '0px 0px 20px 20px'
      this.borderRadius = '0px 0px 3px 3px'
      $('#settingId').addClass('showI');
      // this.inputmarginLeft = '35px'
      this.inputheightSize = '50px'
      this.bottomPositionheight = '60px'
      this.inputMarginLeft = '-2px'
      // this.stopBottomSize = '70%'
      // this.stopLeftSize = '8%'
      this.stopBottomSize = '75%'
      this.stopLeftSize = '10%'
      this.micWidth = '50'
      this.micWidthOnly = '50'
      this.micMarginleft = '0'
      this.micHeight = '50'
      this.micMarginLeft2 = '2px'
    } else {
      // this.bottomPosition = 67
      this.bottomPosition = 12
      //  this.borderRadius = '0px 0px 20px 20px'
      this.borderRadius = '0px 0px 9px 9px'
      // this.bottomPositionWidth = '90%'
      this.bottomPositionWidth = '77.5%'
      // this.inputWidthSize = '201px';
      this.inputWidthSize = '220px';
      $('#settingId').addClass('showI');
      this.inputheightSize = '50px'
      this.micMarginLeft2 = '0px'
      this.inputMarginLeft = '3px'
      this.bottomPositionheight = '60px'
      this.stopBottomSize = '75%'
      this.stopLeftSize = '10%'
      this.micWidth = '50'

      this.micWidthOnly = '50'
      this.micMarginleft = '0'
      this.micHeight = '50'
      this.micMarginLeft2 = '0px'
    }
    $('#refreshBtn').addClass('hideMessage')
    // card minimize

    $('#tourIcon').addClass('showI');
    // $('#speedspeech').addClass('showI');
    $('#tourIcon').css('z-index', '1');
    // $('#speedspeech').css('z-index', '1');
    $('#minimizeAvatar').addClass('showI');
    $('#maximizeAvatar').removeClass('showI');

    // $('#chat-widget-minimized').css('display', 'block');
    // $('#chat-widget-container').css('height', '84px');
    // $('#message').css('margin-top', '32px')
    $('#message').css('margin-top', '22px')
    $('#message').css('width', '')
    $('#iconShow').addClass('showI')
    $('#ImageDisplay').removeClass('showMessage')
    $('#pdfDataSet').addClass('hideMessage')
    $('#sm-video').removeClass('uneeqAv')
    $('#sm-video').addClass('uneeqAvatar')
    $('#message').removeClass('showMessage')
    $('#chatbubblemessage').removeClass('showMessage')
    //  $('#message').addClass('hideMessage')
    $('#movableCard').removeClass('full_screen')
    $('#movableCard-main').removeClass('full_screen')
    $('#stopIcon').removeClass('bottomleft-large')
    $('#stopIcon').addClass('bottomleft')
    $('#isvideo').removeClass('rightDiv-large')
    $('#isvideo').addClass('rightDIv')
    $('#ruleSeries').addClass('showI')
    $('#feedback').addClass('showI')
    $('#ccSubtitle').addClass('showI')
    this.changeAvatarSize()
    //  setTimeout(()=>{
    //   this.smallcardSetPosition()
    //  },0)
    // this.smallcardSetPosition() 
    $('#cross').removeClass('showI')
    $('#cross-test').addClass('showI')
    $('#chatbarOnly').addClass('showI')
    $('#chat-bar').css('display', 'block')
    $('#mobileAvatarButton').removeClass('showI')
    $('#exitExam').addClass('showI')
    $('#exitpresentation').addClass('showI')
    $('#downloadpdf').addClass('showI')
    $('#userCC').removeClass('showMessage')
    $('#cross').removeClass('speakingsss_large')
    $('#openOnTestOnly').addClass('showI')
    $('#openOnLearningOnly').addClass('showI')
    $('#normalOpen').removeClass('showI')
    $('#OnlyDisplayOnTest').addClass('showI')
    $('#audioMicBut').css('background-color', '#d9e9fd')
    $('#QuestionDisplay').addClass('hideMessage')
    $('#optionMessage').addClass('hideMessage')
    //mathematice
    this.ischatBoxOpen = false
    this.iconShow = false
    // this.defaultExitMessage()
    this.checkMathsIndicatorActive()



  }


  // default message 
  defaultExitMessage() {
    const avatarCanvas: any = document.querySelector('#sm-video canvas')
    if (avatarCanvas !== null) {
      //  avatarCanvas.style.width = '100%'
      //  avatarCanvas.style.height = '100%'
      $(avatarCanvas).css('margin-left', '')
    }
    var aa: any = document.getElementById('outputDesc')
    if (aa) aa.innerHTML = 'welcome to the world of edYOU. I am ' + this.avatarName + '. How can I help you?';

  }



  refreshSlidePPT() {
    this.childPdf.refreshCurrentSlide()
  }


  clickFunction(value: any) {
    if (this.ccBox == true) {
      $('#panel1').css('display', 'block')
    } else {
      $('#panel1').css('display', 'none')
    }
    this.ccBox = !this.ccBox
    this.isDropDownSetting = false;
  }
  @ViewChild('popup') popup!: ElementRef;
  @ViewChild('popup2') popup2!: ElementRef;
  @HostListener('document:click', ['$event'])

  clickout(event: any) {
    // this.muteDigitalPerson();
    var target = $(event.target);
    // var isBoxVisible = $('.box-1').css('margin-top') === '-500px'; // Check if box is visible
    // if (!target.closest('.box-1').length && isBoxVisible  && !target.closest('.ML__keyboard').length && !target.closest('.MLK__backdrop').length && !target.closest('.MLK__layer').length) {
    var marginTop = $('.box-1').css('margin-top');
    var isBoxVisible = marginTop === '-430px' || marginTop === '-500px' || marginTop === '-550px' || marginTop === '-600px' || marginTop === '-720px' || marginTop === '-650px' || marginTop === '-700px' || marginTop === '-750px';
    if (!target.closest('.box-1').length && isBoxVisible && !target.closest('.MLK__backdrop').length && !target.closest('.MLK__layer').length) {
      // console.warn('click event -------------')
      this.mathEventHit('');
    }
  }
  // swal confirm dialog bog dynamic function
  swalDataFun(event: any) {
    console.log(event)
    if (event?.type == 'welcome') {
      let p = { name: 'Avatar start speaking.', }
      console.log('swal function for welcome ------')
      this.customLogger(p)
      this.muteDigitalPerson()
    } else if (event?.type == 'ConnectionFaile') {
      this.router.navigate(['/'])
      setTimeout(() => {
        window.location.reload();
      }, 0)
    }
    if (event?.isConfirmed) {
      switch (event?.type) {
        case "livechatError":
          window.location.reload();
          break;
        case "initialCallTourist":

          break;
        case "status404":

          break;
        case "statusElse":
          localStorage.removeItem("sessionId");
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          location.reload();
          setTimeout(() => {
            this.router.navigate(['']);
          }, 2000)
          break;
      }
    }
    this.isSwal = false;
  }
  // --------------------------------------Gamification Badges--------------------------------------

  callBadgeByAvatar() {
    this.persona.conversationSend('Badges', {}, {});
  }

  @HostListener('document:keydown.enter', ['$event'])
  onEnterPress(event: KeyboardEvent) {
    console.log('enter key', event.key)
    this.enterKeyPress = event.key
    this.simulateClick();
    this.sendTextToAvatar()
  }

  @HostListener('document:keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      console.log('enter key (keypress)', event.key);
      this.enterKeyPress = event.key;
      this.simulateClick();
      this.sendTextToAvatar();
    }
  }


  simulateClick() {
    // Get the button element
    console.log('aaakakakakakskaskasiqieqieiqiqeiqeiei')
    const button: any = document.getElementById('sendId1');
    if (button) {
      // Create a new click event
      const event = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      });
      // Dispatch the event on the button
      button.dispatchEvent(event);
    } else {
      console.error('Button not found!');
    }
  }
  //kanx
  toggleGif(hovered: boolean) {
    this.isHovered = hovered;
  }


  
  speeds = [
    { value: '0.5x', label: '0.5x' },
    { value: '0.75x', label: '0.75x' },
    { value: '1x', label: '1x Normal' },
    { value: '1.25x', label: '1.25x' },
    { value: '1.5x', label: '1.5x' }
  ];
  
  selectedSpeed:any = '1x';
  selectSpeed(speed: any) {
    this.selectedSpeed = speed.value;
    
    this.isDropdownOpen = false; 
    this.dropdownToggle.nativeElement.checked = false;
    let speechSpeed = this.selectedSpeed.replace('x', '');
     let pay ={
      "speechSpeed":speechSpeed
    }
    this.ser.postAPI(`${environment.baseUrl}${API.speedChangeApi}`, pay).subscribe((res: any) => {
      console.log(res);
      if(res.statusCode  == 200){
        console.log(this.selectedSpeed)
        localStorage.setItem('speechSpeed',this.selectedSpeed)
      }
    });
  }
  
  toggleDropdown(event: MouseEvent) {
    // event.stopPropagation(); 
    this.isDropdownOpen = !this.isDropdownOpen; 
  }
  opnPopuponButton(){
    this.isDropdownOpen = !this.isDropdownOpen; 
  }
  // @HostListener('document:click', ['$event'])
  // closeDropdown(event: MouseEvent) {
  //   if (!this.popup1.nativeElement.contains(event.target)) {
  //     this.isDropdownOpen = false; 
  //     this.dropdownToggle.nativeElement.checked = false;
  //   }
  // }
  onRangeChange() {
  //  console.log('uneeeqavatar function called-------------------')
    // let pay ={
    //   "speechSpeed":rangeValue
    // }
    // this.ser.postAPI(`${environment.baseUrl}${API.speedChangeApi}`, pay).subscribe((res: any) => {
    //   console.log(res);
    // });

  }

  newpresentationapi(){
    let sessionId = localStorage.getItem('sessionId')
    let pay = {
      "email":this.user.email,
      "trigger":"Start",
      session_id :sessionId
    }
    console.log(pay)
    this.ser.Avatar_demo_Video_Presentation_status(pay).subscribe((res:any)=>{
      console.log(res)
      // this.pdfShow = res.PDF
      localStorage.setItem('learningId', res.id)
      this.ser.IndexLearningNo = 1
      var url = ""
      url = res.PDF
      let payload = {
        'url': url,
        'heading':res.SlidesName,
        'type': 'PDF'
      }
      this.ser.updatePDFLinkData(payload);
      this.onLoadAvatarCard()
      $('#avatarLoaders').css('display', 'none')
    })
  }

  onLoadAvatarCard() {
    // var avatar = localStorage.getItem('Avatar')
    var avatar = "Instructions"
    // var cc = localStorage.getItem('cc')
    var cc = localStorage.getItem('AvatResCC')
    if (avatar === 'Instructions') {
      
      $('#hambergerBar').removeClass('showI');
      $('#mathsIndicatorONN').addClass('showI')
      $('#mathsIndicator').removeClass('mathsOffClass')
      $('#mathsIndicator').removeClass('showI')
      $('#tourIcon').removeClass('showI');
      $('#tourIcon').css('z-index', '1000');
      // mic icons
      $('#settingId').removeClass('showI');
      $('#muteVoiceId').removeClass('showI');
      // end mic icons
      //
      $('#stopavatarId').removeClass('stopHandIconSmallScreen');
      $('#stopavatarId').addClass('stopHandIcon');
      $('#stopSoundId').removeClass('stopIConsmallscreenImg');
      $('#stopSoundId').addClass('stopIConImg');
      if (window.screen.width < 480) {
        $('#box3Main').addClass('showI');
        $('#mobileDotAni').removeClass('showI');
      } else {
        $('#box3Main').removeClass('showI');
      }
      localStorage.setItem('screen', 'LearningScreen')
      $('#pptPageLoad').removeClass('hideMessage')
      $('#chat-widget-minimized').css('display', 'none');
      $('#chat-widget-container').css('height', '4px');
      $('#movableCard').css('border-radius', '0px')
      $('#movableCard').removeClass('showI')
      $('#centerDiv').removeClass('showI')
      this.changeAvatarSize()
      //  this.checkOrientation()
      $('#iconShow').removeClass('showI')
      $('#sm-video').removeClass('uneeqAvatar')
      $('#message').addClass('showMessage')
      $('#pdfDataSet').removeClass('hideMessage')
      // $('#message').removeClass('hideMessage') 
      $('#sm-video').addClass('uneeqAv')
      //
      $('#movableCard').addClass('full_screen')
      // $('#message').removeClass('hideMessage')
      $('#movableCard-main').addClass('full_screen')
      $('#stopIcon').addClass('bottomleft-large')
      $('#stopIcon').removeClass('bottomleft')
      $('#cross').addClass('showI')
      if (cc == 'false') {
      } else {
        setTimeout(() => {
          $('#message').addClass('showMessage')
        }, 1000)

      }
      $('#MathameticsDisplay').removeClass('showMessage')
      $('#chatbubblemessage').removeClass('showMessage')
      $('#cross-test').removeClass('showI')
      $('#isvideo').addClass('rightDiv-large')
      $('#isvideo').removeClass('rightDIv')

      $('#chat-bar').css('display', 'none')
      $('#mobileAvatarButton').addClass('showI')
      $('#exitpresentation').removeClass('showI')

      $('#audioMicBut').css('background-color', '#7393c4')
      const description: any = document.getElementById('outputDesc')
      if (description) description.innerHTML = '';
    } else {
      // this.isOpen2 = true
      // this.err2 = "Hannah is currently busy, please wait for her to be available.";
      // console.log(this.err2)
      setTimeout(() => {
        // this.isOpen2 = false
      }, 3000)
    }
  }

  soulScene: any;

  setVideo(videoWidth: any, videoHeight: any) {
    const deviceWidth = Math.round(videoWidth * window.devicePixelRatio);
    const deviceHeight = Math.round(videoHeight * window.devicePixelRatio);
    // console.log('height', deviceHeight, '==', 'width', deviceWidth)
    this.soulScene.sendVideoBounds(deviceWidth, deviceHeight);

  }
  orientationEvent: any;
  orientationEventListener: any;
  checkOrientation() {
    //if (localStorage.getItem('screen') === "TestSeries") {
    var supportsOrientationChange = "onorientationchange" in window
    this.orientationEvent = supportsOrientationChange ? "orientationchange" : "resize";
    this.orientationEventListener = function () {
      if (window.screen.height < 600) {
        if (window.screen.width > window.screen.height) {
          this._swalService.default({ text: 'Kindly use in portrait mode', showConfirmButton: true })
        } else {

        }

      }
    }
    window.addEventListener(this.orientationEvent, this.orientationEventListener, false);

  }



  /**
  * Modify the size of the avatar.
  * This function is responsible for adjusting the size of the avatar,
  * providing a mechanism to dynamically change its dimensions in the UI.
  */


  videoSizeSet: boolean = false;
  changeAvatarSize() {


    // console.log('type of height',typeof(innerHeight))
    // var t: any = document.querySelector('#sm-video canvas')
    var t: any = document.querySelector('#sm-video canvas')
    var videoTag: any = document.querySelector('#smVideo')

    const innerWidth = window.innerWidth;
    const innerHeight = window.innerHeight;
    const isPortrait = innerHeight > innerWidth;

    // Determine the device type and apply styles accordingly
    if (innerWidth <= 576) {
      if (isPortrait) {
        // Apply styles for mobile devices in portrait orientation
        console.warn('mobile size')
        if (this.videoSizeSet == false) {

          // this.setVideo(600, 370);
          //  this.setVideo(900, 850);
        }
        this.videoSizeSet = true;
        $(videoTag).css('margin-left', '0px')
        t.style.height = '300px'
        t.style.width = '100%'
        $(t).css('margin-left', '0px')
        $('#message').css('margin-top', '-85px')
        // $('#message').css('margin-right', '20px')
        $('#message').css('margin-right', '30px')



        //   setTimeout(() => {
        $(videoTag).css('margin-left', '0px')

      } else {
        // Apply styles for mobile devices in landscape orientation
        const windowWidth = window.innerHeight;
        console.warn('runn with out any learnig', windowWidth)
        t.style.height = '40%'
        t.style.width = '40%';
        $('#message').css('width', '230px')
        // $('#message').css('margin-top', '-260px')
        $('#message').css('margin-top', '0px')
        $(t).css('margin-left', '450px')
        $('#message').css('margin-right', '20px')
        $('#pdfDataSet').css('margin-top', '7px')
      }
    } else if (innerWidth >= 579 && innerWidth <= 1024) {

      if (isPortrait) {
        // Apply styles for iPads in portrait orientation
        // this.setVideo(600, 300)
        if (this.videoSizeSet == false) {
          console.warn('fffffffffffffffffffffffffffffffffffffffffffff  protrait 1024 less')
          // this.setVideo(600, 320);
          //  this.setVideo(650, 500);
        }
        t.style.height = '30%'
        t.style.width = '100%';

        $(videoTag).css('margin-left', '0px')

        // if (innerHeight == 1366 && innerWidth == 1024) { //ipad pro 
        //   if ((innerHeight == 1366 || innerHeight == 1292 || innerHeight == 905) && (innerWidth == 1024)) { // ipad pro 12
        if ((innerHeight == 1366 || innerHeight == 1247 || innerHeight == 905 || innerHeight == 1292) && (innerWidth == 1024)) { // ipad pro 12 
          console.warn('ipad 12 size')
          // console.log('ipad pro 12 ')
          $(t).css('margin-left', '0px')
          // $('#message').css('width', '86%')
          $(videoTag).css('margin-left', '0px')
          $('#message').css('width', '77%')
          $('#message').css('margin-top', '-40px')
          // $('#message').css('margin-right', '130px')
          $('#message').css('margin-right', '130px')
        } else if ((innerHeight == 1180 || innerHeight == 1194 || innerHeight == 1112 || innerHeight == 1120 || innerHeight == 1061 || innerHeight == 1106 || innerHeight == 1075 || innerHeight == 715) && (innerWidth == 820 || innerWidth == 834)) { //ipad air , ipad pro 11 
          console.warn('ipad 11 size')
          // console.log('ipad pro 11 and ipad air ')
          t.style.height = '45%'
          t.style.width = '100%';
          $(t).css('margin-left', '0px')
          $('#message').css('width', '81%')
          $('#message').css('margin-top', '-50px')
          $('#message').css('margin-right', '90px')



        } else if ((innerHeight == 1180 || innerHeight == 1194 || innerHeight == 1112 || innerHeight == 1120 || innerHeight == 1061) && (innerWidth == 834)) { //ipad air , ipad pro 11 
          console.warn('ipad air size')
          // console.log('ipad pro 11 and ipad air ')
          t.style.height = '45%'
          t.style.width = '100%';
          $(t).css('margin-left', '0px')
          $('#message').css('width', '81%')
          $('#message').css('margin-top', '-50px')
          $('#message').css('margin-right', '90px')


        } else if ((innerHeight == 1080 || innerHeight == 1010 || innerHeight == 1112 || innerHeight == 987 || innerHeight == 965) && (innerWidth == 810)) {    //ipad 10 , 9 
          console.warn('ipad 10, 9 size')
          console.log('ipad pro 10 and ipad 9 ')
          t.style.height = '40%'
          t.style.width = '100%';
          $(t).css('margin-left', '0px')
          $('#message').css('width', '78%')
          $('#message').css('margin-top', '-50px')
          $('#message').css('margin-right', '100px')
        } else if (innerHeight == 1280 && innerWidth == 800) { //nexus 10

          t.style.height = '45%'
          t.style.width = '100%';
          $(t).css('margin-left', '0px')
          $('#message').css('width', '65%')
          $('#message').css('margin-top', '-90px')
          $('#message').css('margin-right', '140px')
        } else if (innerHeight == 1138 && innerWidth == 712) { // tab s4

          t.style.height = '40%'
          t.style.width = '100%';
          $(t).css('margin-left', '0px')
          $('#message').css('width', '74%')
          $('#message').css('margin-top', '-90px')
          $('#message').css('margin-right', '97px')
        }

        else {
          $('#message').css('width', '86%')
          $(t).css('margin-left', '0px')
          $('#message').css('margin-top', '-50px')
          $('#message').css('margin-right', '60px')
        }


      } else {
        // Apply styles for iPads in landscape orientation
        t.style.height = '40%'
        t.style.width = '70%';
        $('#message').css('width', '290px')
        $(t).css('margin-left', '440px')
        // $('#message').css('margin-top', '9px')
        $('#message').css('margin-top', '19px')
        $('#message').css('margin-right', '25px')
      }

    } else if (innerWidth >= 1025 && innerWidth <= 1366) {
      $(videoTag).css('margin-left', '0px')
      if (isPortrait) {
        // Apply styles for laptops in portrait orientation
        //  this.setVideo(600, 370)
        if (this.videoSizeSet == false) {
          console.warn('fffffffffffffffffffffffffffffffffffffffffffff  protrait 1025 and 1366 check')
          //  this.setVideo(600, 370);
          // this.setVideo(600, 440);
        }
        this.videoSizeSet = true;
        // console.log('ggffddssaaa')
        t.style.height = '45%'
        t.style.width = '100%';
        $(t).css('margin-left', '0px')
        $('#message').css('width', '81%')
        $('#message').css('margin-top', '-50px')
        $('#message').css('margin-right', '90px')
      } else {
        // Apply styles for laptops in landscape orientation
        // this.setVideo(650, 400)


        if ((innerHeight == 717 || innerHeight == 740) && (innerWidth == 1080)) { // landscape ipad 9 
          console.warn('ipad 9 landscape size')
          t.style.height = '30%'
          t.style.width = '60%';
          $(t).css('margin-left', '560px')
          $('#message').css('width', '300px')
          $('#message').css('margin-top', '9px')
          $('#message').css('margin-right', '20px')

        } else {
          console.warn('else ipad design  size')
          t.style.height = '30%'
          t.style.width = '60%';
          // $(t).css('margin-left', '560px')
          $(t).css('margin-left', '620px')
          $('#message').css('width', '345px')
          $('#message').css('margin-top', '9px')
          $('#message').css('margin-right', '20px')
        }


      }
    }
    else if (innerWidth >= 1367) {
      console.warn('desktop size')
      if (this.videoSizeSet == false) {
        console.warn('fffffffffffffffffffffffffffffffffffffffffffff  mere then 1367 ')
        //console.log('14.2 inch screen')
        // this.setVideo(650, 360)
        //  this.setVideo(850, 650)
      }
      this.videoSizeSet = true;
      $(videoTag).css('margin-left', '0px')

      if (innerHeight == 1152 && innerWidth == 2048) {   // Imac 21 inch
        console.warn('21 inch size')
        t.style.height = '55%'
        t.style.width = '50%';
        $('#message').css('width', '630px')
        $('#message').css('margin-top', '5px')
        $(t).css('margin-left', '990px')
        $('#message').css('margin-right', '40px')
      } else if (innerHeight == 1260 && innerWidth == 2240) {   // imac 24  inch
        t.style.height = '55%'
        t.style.width = '56%';
        $('#message').css('width', '730px')
        $('#message').css('margin-top', '20px')
        $(t).css('margin-left', '1100px')
        $('#message').css('margin-right', '40px')
      } else if (innerHeight == 1800 && innerWidth == 3200) {   // imac 24  inch
        t.style.height = '55%'
        t.style.width = '56%';
        $('#message').css('width', '1090px')
        $('#message').css('margin-top', '20px')
        $(t).css('margin-left', '1300px')
        $('#message').css('margin-right', '40px')
      }


      else {
        console.warn('else big size size')
        t.style.height = '40%'
        t.style.width = '55%';
        $(t).css('margin-left', '810px')
        $('#message').css('width', '400px')
        $('#message').css('margin-top', '10px')

      }
      // $('#message').css('margin-right', '13px')
    } else if (innerWidth >= 2500) {
      //  console.log('14.2 inch screen')
      if (this.videoSizeSet == false) {
        console.warn('fffffffffffffffffffffffffffffffffffffffffffff  more then 2500')
        // this.setVideo(650, 360)
      }
      this.videoSizeSet = true;

      t.style.height = '50%'
      t.style.width = '60%';
      $(t).css('margin-left', '850px')
      $('#message').css('width', '400px')
      $('#message').css('margin-top', '15px')
    }

  }

  // timer

  timer: string = '10:00'; 
  private interval: any; 
  private timeLeft: number = 600; 
  @Output("closeUneeq") closeUneeq: EventEmitter<any> = new EventEmitter();
  
  callTheTimer(){
    console.log('start timer')
    setTimeout(()=>{
      this.startTimer()
    },6000)
    
    
  }
  startTimer() {
    this.interval = setInterval(() => {
      this.timeLeft--;
      this.updateTimeDisplay();

      if (this.timeLeft === 0) {
        console.log('Timer ended!');
        clearInterval(this.interval); 

        this.closeUneeq.emit()
       
      }
    }, 1000); 
    
  }

  updateTimeDisplay() {
    const minutes = Math.floor(this.timeLeft / 60);
    const seconds = this.timeLeft % 60;
    this.timer = `${this.formatTime(minutes)}:${this.formatTime(seconds)}`;
  }

  formatTime(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  addResizeListener() {
    window.addEventListener('resize', this.changeAvatarSize);
  }

  /**
* Remove a resize listener to handle window resizing events.
*/
  removeResizeListener() {
    window.removeEventListener('resize', this.changeAvatarSize);
  }


}


