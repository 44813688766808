var tg = new tourguide.TourGuideClient({
  targetPadding: 15,
  exitOnEscape: false,
  exitOnClickOutside: true,
  dialogClass: "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn",
  closeButton: true,
  showStepProgress: false,
  steps: true,

  dialogClass: "ppp",
  steps: [
    {
      content:
        "Click the microphone button or press the spacebar once to speak.",
      title: "Microphone Button",
      target: "#voiceId",
      order: "0",

      group: "",
    },

    {
      content: "Use this button to send your text.",
      title: "Send Button",
      target: "#sendId1",
      order: "2",
      group: "",
    },
    {
      content: "Use this text box to ask a question.",
      title: "Text Input Box",
      target: "#textTourBox",
      order: "1",
      Image: "src/assets/interaction/arrow-left.svg",
      group: "",
    },
    {
      target: "#tourSetting",
      order: "2",
      content:
        "You can turn on/off captions, restart tour guide as well as access help page from the settings option.",
      title: "Settings",
      group: "",
      targetPadding: 25,
      dialogPlacement: "top",
      position: "top",
    },
    {
      target: "#GuideMathametic",
      order: "3",
      content:
        "By turning on this toggle, you will enter the mathematics zone where you can ask mathematical questions.",
      title: "Mathematics Button",
      group: "",
    },
    {
      // order: "3",
      order: "4",
      content:
        "You can interrupt AI being while speaking by clicking this button. <br><img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/stop+speaking.png'  style='width:280px; padding:10px ; background:grey' alt=''>",
      title: "Stop Speaking Button",
      group: "",
      position: {
        top: "50%",
        left: "50%",
        translateX: "-50%",
        translateY: "-50%",
      },
    },
    {
      content:
        "It will close the interaction screen and take you to Home page. <br><img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/uneeqHannah.png'  style='width:290px' alt=''>",
      title: "Exit Button",
      target: "#cross1",
      order: "5",
      group: "",
    },
  ],
});

export function tour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass: "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn",
    closeButton: true,
    showStepProgress: false,

    dialogClass: "ppp",
    steps: [
      {
        content:
          "Click the microphone button or press the spacebar once to speak.",
        title: "Microphone Button",
        target: "#voiceId",
        order: "0",

        group: "",
      },

      {
        content: "Use this button to send your text.",
        title: "Send Button",
        target: "#sendId1",
        order: "2",
        group: "",
      },
      {
        content: "Use this text box to ask a question.",
        title: "Text Input Box",
        target: "#textTourBox",
        order: "1",
        Image: "src/assets/interaction/arrow-left.svg",
        group: "",
      },
      {
        target: "#tourSetting",
        order: "4",
        content:
          "When you click on this button, It will close & open chat section on your the interaction screen.",
        title: "Chat on/off",
        group: "",
        targetPadding: 25,
        dialogPlacement: "top",
        position: "top",
      },
      // {
      //   target: "#GuideMathametic",
      //   order: "3",
      //   content:
      //     "By turning on this toggle, you will enter the mathematics zone where you can ask mathematical questions.",
      //   title: "Mathematics Button",
      //   group: "",
      // },
      {
        order: "5",
        content:
          "You can interrupt AI being while speaking by clicking this button. <br><img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/stop+speaking.png'  style='width:80px; margin-left:90px; padding:5px ; alt=''>",
        title: "Stop Speaking Button",
        group: "",
        position: {
          top: "50%",
          left: "50%",
          translateX: "-50%",
          translateY: "-50%",
        },
      },
      {
        content:
          "Click exit session to leave the interaction screen.",
        title: "Exit session",
        target: "#exitpresentation",
        order: "6",
        group: "",
      },

      {
        content: "Use the Previous button to navigate to the previous page.",
        title: "Previous Button",
        target: "#backTG",
        order: "7",

        group: "",
      },

      {
        content: "Use the Next button to move to the next page.",
        title: "Next Button",
        target: "#nextTG",
        order: "8",
        group: "",
      },
      {
        content:
          "Click on the Download button to download the current presentation.",
        title: "Download Button",
        target: "#downloadTG",
        order: "9",

        group: "",
      },
      {
        content:
          "Select a specific slide to view using the Slide dropdown.",
        title: "Slide Dropdown",
        target: "#slidedropdown",
        order: "10",

        group: "",
      },
      {
        content:
          "The Timer feature allows you to track a 10-minute interaction with the AI being.",
        title: "Timer",
        target: "#mintimer",
        order: "11",

        group: "",
      },
      {
        content:
          "The guided tour provides instructions on how to use various buttons effectively.",
        title: "Guided tour",
        target: "#tourIcon",
        order: "11",

        group: "",
      },
      {
        content:
          "The mute/unmute icon is use to silence or unsilence the AI being's voice.",
        title: "Mute/Unmute",
        target: "#muteVoiceId",
        order: "12",

        group: "",
      },
    ],
  });

  tg.start();

  console.log("tg===", tg);
}


export function tourForMath() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass: "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn",
    closeButton: true,
    showStepProgress: false,

    dialogClass: "ppp",
    steps: [
      {
        content:
          "Click the microphone button or press the spacebar once to speak.",
        title: "Microphone Button",
        target: "#voiceId",
        order: "0",

        group: "",
      },

      {
        content: "Use this button to send your text.",
        title: "Send Button",
        target: "#sendId1",
        order: "2",
        group: "",
      },
      {
        content: "Use this text box to ask a question.",
        title: "Text Input Box",
        target: "#textTourBox",
        order: "1",
        Image: "src/assets/interaction/arrow-left.svg",
        group: "",
      },

      {
        target: "#GuideMathametic",
        order: "2",
        content:
          "By turning on this toggle, you will enter the mathematics zone where you can ask mathematical questions.",
        title: "Mathematics Button",
        group: "",
      },
      {
        order: "3",
        content:
          "You can interrupt AI being while speaking by clicking this button. <br><img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/stop+speaking.png'  style='width:80px; margin-left:90px; padding:5px ; alt=''>",
        title: "Stop Speaking Button",
        group: "",
        position: {
          top: "50%",
          left: "50%",
          translateX: "-50%",
          translateY: "-50%",
        },
      },
      {
        content:
          "It will close the interaction screen and take you to Home page. <br><img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/newBox.png'  style='width:290px' alt=''>",
        title: "Exit Button",
        target: "#cross1",
        order: "4",
        group: "",
      },
      {
        content: "Use the Previous button to navigate to the previous page.",
        title: "Previous Button",
        target: "#backTG",
        order: "0",

        group: "",
      },

      {
        content: "Use the Next button to move to the next page.",
        title: "Next Button",
        target: "#nextTG",
        order: "1",
        group: "",
      },
      {
        content:
          "Click on the Download button to download the current presentation.",
        title: "Download Button",
        target: "#downloadTG",
        order: "2",

        group: "",
      },
    ],
  });

  tg.start();

  console.log("tg===", tg);
}

// ---- for non school user -----

export function noSchoolTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass: "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn",
    closeButton: true,
    showStepProgress: false,

    dialogClass: "ppp",
    steps: [
      {
        content:
          "Click the microphone button or press the spacebar once to speak.",
        title: "Microphone Button",
        target: "#voiceId",
        order: "0",
        group: "",
      },

      {
        content: "Use this button to send your text.",
        title: "Send Button",
        target: "#sendId1",
        order: "2",
        group: "",
      },
      {
        content: "Use this text box to ask a question.",
        title: "Text Input Box",
        target: "#textTourBox",
        order: "1",
        Image: "src/assets/interaction/arrow-left.svg",
        group: "",
      },
      {
        target: "#tourSetting",
        order: "2",
        content:
          "When you click on this button, It will close & open chat section on your the interaction screen.",
        title: "Chat on/off",
        group: "",
        targetPadding: 25,
        dialogPlacement: "top",
        position: "top",
      },

      {
        order: "3",
        content:
          "You can interrupt AI being while speaking by clicking this button. <br><img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/stop+speaking.png'  style='width:80px; margin-left:90px; padding:5px ; alt=''>",
        title: "Stop Speaking Button",
        group: "",
        position: {
          top: "50%",
          left: "50%",
          translateX: "-50%",
          translateY: "-50%",
        },
      },
      {
        content:
          "It will close the interaction screen and take you to Home page. <br><img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/uneeqHannah.png'  style='width:290px' alt=''>",
        title: "Exit Button",
        target: "#cross1",
        order: "4",
        group: "",
      },
    ],
  });

  tg.start();
}

//---------- learning -----//

export function LearningTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass: "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn",
    closeButton: true,
    showStepProgress: false,

    dialogClass: "ppp",
    steps: [
      {
        content: "Use the Previous button to navigate to the previous page.",
        title: "Previous Button",
        target: "#backTG",
        order: "0",

        group: "",
      },

      {
        content: "Use the Next button to move to the next page.",
        title: "Next Button",
        target: "#nextTG",
        order: "1",
        group: "",
      },
      {
        content:
          "Click on the Download button to download the current presentation.",
        title: "Download Button",
        target: "#downloadTG",
        order: "2",

        group: "",
      },
      // {
      //   target: "#refreshTG",
      //   order: "3",
      //   content: "Click on the repeat button to replay the slide. ",

      //   title: "Repeat Button",
      //   group: "",
      //   targetPadding: 25,
      //   dialogPlacement: "top",
      //   position: "top",
      // },
    ],
  });

  tg.start();
  console.log(tg);
}

// ----test prep ---------

export function TestPrepTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass: "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn",
    closeButton: true,
    showStepProgress: false,

    dialogClass: "ppp",
    steps: [
      {
        content:
          "Select your answer by clicking on one of the options provided for the given question. <br><img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/options.png'  style='width:290px' alt=''>",
        title: "Select Answer",
        order: "0",
        position: {
          top: "50%",
          left: "50%",
          translateX: "-50%",
          translateY: "-50%",
        },
        group: "",
      },

      {
        content:
          "This will provide a brief explanation of your chosen answer, indicating whether it is correct or incorrect. <br><img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/explainBox.png'  style='width:290px' alt=''>",
        title: "Explanation Box",
        order: "1",
        position: {
          top: "50%",
          left: "50%",
          translateX: "-50%",
          translateY: "-50%",
        },
        group: "",
      },
      {
        content:
          "Click the Back button to move to the previous question, and use the Next button to proceed to the next question. <br><img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/buttonTest.png'  style='width:290px' alt=''>",
        title: "Navigation Buttons",
        order: "2",
        position: {
          top: "50%",
          left: "50%",
          translateX: "-50%",
          translateY: "-50%",
        },
        group: "",
      },
    ],
  });

  tg.start();
}

//----userDashboard tour-----//
export function UserDashboardTour(nameProfile2) {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: true,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content: "Click on this button to expand or collapse the side bar.",
        title: "Expand/Collapse Sidebar",
        target: "#menuBarId",
        order: "0",

        group: "",
      },
      {
        content:
          "Here, you'll find all the menu options where you can learn and test your knowledge.",
        title: "Side Bar Menu",
        target: "#tourHomeMenu",
        order: "0",

        group: "",
      },

      {
        content:
          "For assistance, please use the live chat feature to connect with our support team. <br><img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/livechat.PNG'  style='margin-left:116px' alt=''> ",
        title: "Live Chat Support",
        target: "#live",
        order: "2",
        group: "",
      },
      {
        content: "You have the option to update your profile and log out.",
        title: "User Profile",
        target: "#profile",
        order: "3",
        group: "",
      },
      {
        content:
          "Here, you will find the total points earned, along with the number of lessons and test prep completed. ",
        title: "Your Progress ",
        target: "#statsCardmargin1",
        order: "4",
        group: "",
      },
      {
        content:
          "Use these buttons to view your streaks,<br> earned badges, and leaderboards.<br><img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/streaks.png'  style='width:288px;height:87px;margin-top:12px'><img src='assets/image/badge.png'  style='width:186px;height:90px;margin-top:12px;' alt=''> <img src='assets/image/Table 1.png'  style='width:100px;height:90px; margin-top:12px;' alt=''>",
        title: "Gamification Achievements",
        target: "#streakG",
        order: "5",
        Image: "src/assets/image/streaks.png",

        group: "",
      },

      {
        content: "Click here to access all lessons from the course.",
        title: "Click here",
        target: "#headingPicktoUp",
        order: "6",
        group: "",
      },
      {
        content:
          "Click on 'Go to Lesson' to start learning. <br><img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/gotolesson.png'  style='width: 179px;margin-left: 23px', alt=''> ",
        title: "Go to Lesson",
        target: "#pik",
        order: "7",
        group: "",
      },

      {
        content:
          "It will toggle the visibility of the AI Being. <br> <img src='assets/hannahCloseIcon.png'  style='margin-top:6px;margin-left:105px ;width:50px;margin-bottom=-25px' alt=''> ",
        title: "Show/Hide AI Being",
        target: "#avm",
        order: "8",
        group: "",
      },
    ],
  });

  tg.start();
  tg.exit();
}
export function UserDashboardmobileTour(nameProfile2) {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content:
          "For any assistance, please use the live chat feature to connect with our support team for help. <br><img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/livechat.PNG'  style='margin-left:116px' alt=''> ",
        title: "Live Chat Support",
        target: "#live",
        order: "0",
        group: "",
      },
      {
        content: "You can edit your profile and logout.",
        title: "User Profile",
        target: "#profile",
        order: "1",
        group: "",
      },

      {
        content:
          "Use these buttons to view your streaks,<br> earned badges, and leaderboards.<br><img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/streaks.png'  style='width:200px;height:87px;margin-left:29px;margin-top:4px'><img src='assets/image/badge.png'  style='width:113px;height:59px;margin-top:2px;margin-left:29px' alt=''> <img src='assets/image/Table 1.png'  style='width:85px;height:58px; margin-top:2px;' alt=''>",
        title: "Gamification Achievements",
        target: "#streakG",
        order: "2",
        Image: "src/assets/image/streaks.png",

        group: "",
      },
      {
        content:
          "Here, you will find the total points earned, along with the number of lessons and test prep completed.",
        title: "Your Progress",
        target: "#statsCardmargin1",
        order: "3",
        group: "",
      },

      {
        content:
          "Click here to access all lessons from the course.<br><img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/clickHere.png'  style='width: 290px;', alt=''> ",
        title: "Click here",
        target: "#pik",
        order: "4",
        group: "",
      },
      {
        content:
          "Click on 'Go to Lesson' to start learning. <br><img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/gotolesson.png'  style='width: 179px;margin-left: 35px', alt=''> ",
        title: "Go to Lesson",
        target: "#pik",
        order: "5",
        group: "",
      },
      {
        content:
          "It will toggle the visibility of the AI Being. <br> <img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/hannahMiniMize.png'  style='margin-left:90px ;width:69px' alt=''> ",
        title: "Show/Hide AI Being",
        target: "#avm",
        order: "6",
        group: "",
      },
    ],
  });
  tg.start();
}
export function CourseTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content: "You can select subject from available courses to learn.",
        title: "Course Name",
        target: "#courset", // target element
        order: "0",
        group: "",
      },
    ],
  });
  tg.start();
}
export function CourseDetailTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content: "You can select chapters.",
        title: "Chapter Name",
        target: "#chapSelect",
        order: "0",

        group: "",
      },
      {
        content:
          "You can see here lessons completed and how many more to learn.",
        title: "Lessons Completed",
        target: "#loadcomplete",
        order: "1",

        group: "",
      },
      {
        content: "You can select lessons to learn from the chapters.",
        title: "Lesson List",
        target: "#listchap",
        order: "2",

        group: "",
      },
    ],
  });
  tg.start();
}
export function SelectCourseTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content: "You can see the lesson heading.",
        title: "Lesson Heading ",
        target: "#headsc",
        order: "0",

        group: "",
      },
      {
        content: "This shows the type of learning associated with the lesson.",
        title: "In This Lesson ",
        target: "#partitionIcon",
        order: "1",

        group: "",
      },
      {
        content: "An overview of the lesson.",
        title: "Description ",
        target: "#descriptionbox",
        order: "2",

        group: "",
      },
      {
        content:
          "Click on 'Go to Lesson' to start learning, you can check the lesson completion progress.",
        title: "Go to lesson ",
        target: "#directaccesstolesson",
        order: "3",

        group: "",
      },
    ],
  });
  tg.start();
}
export function SelectCourseInstruction() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content: "You can proceed with intructions to learn.",
        title: "Start Learning",
        target: "#testT",
        order: "0",

        group: "",
      },
    ],
  });
  tg.start();
}
export function TestTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content: "You can select subject from available test prep.",
        title: "Test Prep Name",
        target: "#testT",
        order: "0",

        group: "",
      },
    ],
  });
  tg.start();
}
export function TestDetailTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content: "You can select chapters.",
        title: "Chapter name",
        target: "#testchap",
        order: "0",

        group: "",
      },
      {
        content:
          "You can see here tests completed and<br> how many more to tests left to complete.",
        title: "Test Completed",
        target: "#testTd",
        order: "1",

        group: "",
      },
      {
        content: "You can select topics to take your test.",
        title: "Test list",
        target: "#testch",
        order: "2",

        group: "",
      },
    ],
  });
  tg.start();
}
export function SelectTestTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content: "You can see test heading. ",
        title: "Test Name",
        target: "#headtest",
        order: "0",

        group: "",
      },
      {
        content: "An overview of test. ",
        title: "Description",
        target: "#desTest",
        order: "1",

        group: "",
      },
      {
        content:
          "Click on 'Go to Test' to start test and see the progress of test completion.  ",
        title: "Go To Test",
        target: "#gototestc",
        order: "2",

        group: "",
      },
    ],
  });
  tg.start();
}
export function SelectTestInstruction() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content: "You can proceed with intructions to take Test.",
        title: "Start Test",
        target: "#testT",
        order: "0",
        group: "",
      },
    ],
  });
  tg.start();
}

export function HistoryTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content: "You can see here the start time of your chat.",
        title: "Session Start",
        target: "#chatHt",
        order: "0",

        group: "",
      },
      {
        content: "You can see here how much time you spent on app.",
        title: "Session Duration",
        target: "#chatHts",
        order: "1",

        group: "",
      },
      {
        content:
          "Click on  eye icon to see conversation.  <br> <img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/historyEye.png'  style='width:240px' alt=''> ",
        title: "Eye Icon",
        target: "#gototestc",
        order: "3",

        group: "",
      },
      {
        content:
          "You can see your conversation with AI Being.  <br> <img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/chat-hs.png'  style='width:240px;margin-left:12px;' alt=''> ",
        title: "Chat History",
        target: "#gototestc",
        order: "4",
        group: "",
      },
    ],
  });
  tg.start();
}

export function ReadingSectionTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content:
          "This is reading section, click on the 'Read' to go through the docs.",
        title: "Reading Section",
        target: "#readT",
        order: "0",
        group: "",
      },
    ],
  });
  tg.start();
}

export function HelpnSupportTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content: "Follow the instructions for using app. ",
        title: "Instruction",
        target: "#h1t",
        order: "0",

        group: "",
      },
      {
        content: "List of commands which AI Being understand.",
        title: "Commands",
        target: "#h2t",
        order: "1",
        group: "",
      },
      {
        content: "This Page includes, common questions, answers and concerns.",
        title: "FAQ",
        target: "#h3t",
        order: "2",

        group: "",
      },
      {
        content: "Feel free to reach out for assistance.",
        title: "Contact Support",
        target: "#contactB",
        order: "3",

        group: "",
      },
    ],
  });
  tg.start();
}

export function ProfileTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content: "Here, you can edit your profile.",
        title: "Profile",
        target: "#h1t",
        order: "0",
        group: "",
      },
    ],
  });
  tg.start();
}

//-----non school tenant------//
export function NonSchoolCourseTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content: "You can see the lesson heading.",
        title: "Lesson Heading ",
        target: "#headsc1",
        order: "0",
        group: "",
      },
      {
        content: "This shows the type of learning associated with the lesson.",
        title: "In This Lesson ",
        target: "#partitionIcon1",
        order: "1",
        group: "",
      },
      {
        content: "An overview of the lesson.",
        title: "Description ",
        target: "#descriptionbox1",
        order: "2",
        group: "",
      },
      {
        content:
          "Click on 'Go to Lesson' to start learning, you can check the lesson completion progress.",
        title: "Go to lesson ",
        target: "#directaccesstolesson1",
        order: "3",
        group: "",
      },
    ],
  });
  tg.start();
}
export function NonSchoolSelectTestTour() {
  // console.log(nameProfile2)

  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content: "You can see test heading. ",
        title: "Test Name",
        target: "#headtest1",
        order: "0",

        group: "",
      },
      {
        content: "An overview of test. ",
        title: "Description",
        target: "#desTest1",
        order: "1",

        group: "",
      },
      {
        content:
          "click on 'Go to Test' to start test and<br> see the progress of test completion.  ",
        title: "Go To Test",
        target: "#gototestc1",
        order: "2",
        group: "",
      },
    ],
  });
  tg.start();
}
export function MobileNonSchoolCourseTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content: "You can see the lesson heading.",
        title: "Lesson Heading ",
        target: "#headsc1",
        order: "0",
        group: "",
      },
      {
        content: "This shows the type of learning associated with the lesson.",
        title: "In This Lesson ",
        target: "#partitionIcon1",
        order: "1",
        group: "",
      },
      {
        content: "An overview of the lesson.",
        title: "Description ",
        target: "#descriptionbox1",
        order: "2",
        group: "",
      },
      {
        content:
          "Click on 'Go to Lesson' to start<br> learning, you can check the lesson<br> completion progress. <br> <img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/lessonGoTo.PNG'  style='width:240px' alt=''> ",
        title: "Go to lesson ",
        target: "#directaccesstolesson12",
        order: "3",
        group: "",
      },
    ],
  });
  tg.start();
}
export function MobileNonSchoolSelectTestTour() {
  // console.log(nameProfile2)

  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content: "You can see test heading. ",
        title: "Test Name",
        target: "#headtest1",
        order: "0",

        group: "",
      },
      {
        content: "An overview of test. ",
        title: "Description",
        target: "#desTest1",
        order: "1",

        group: "",
      },
      {
        content:
          "click on 'Go to Test' to start test and<br> see the progress of test completion. <br> <img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/testGoTo.PNG'  style='width:240px' alt=''> ",
        title: "Go To Test",
        target: "#gototestc11",
        order: "2",
        group: "",
      },
    ],
  });
  tg.start();
}
export function MobileHistoryTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content:
          "You can see here the start time of your chat. <br> <img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/session+start.PNG'  style='width:135px;padding-top:12px;margin-left:80px;' alt=''>",
        title: "Session Start",
        target: "#ch",
        order: "0",

        group: "",
      },
      {
        content:
          "You can see here how much time you spent on app.<br> <img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/sessionduration.PNG'  style='width:135px;padding-top:12px;margin-left:80px;' alt=''>",
        title: "Session Duration",
        target: "#chs",
        order: "1",

        group: "",
      },
      {
        content:
          "Click on  eye icon to see conversation.  <br> <img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/historyEye.png'  style='width:240px' alt=''> ",
        title: "Eye Icon",
        target: "#gotc",
        order: "2",

        group: "",
      },
      {
        content:
          "You can see your conversation with AI Being.  <br> <img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/chat-hs.png'  style='width:240px;margin-left:12px;' alt=''> ",
        title: "Chat History",
        target: "#go",
        order: "3",

        group: "",
      },
    ],
  });
  tg.start();
}

export function MobileReadingSectionTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content:
          "This is reading section, click on the 'Read' to go through the docs.",
        title: "Reading Section",
        target: "#re",
        order: "0",

        group: "",
      },
    ],
  });
  tg.start();
}
export function MobileSelectCourseTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content: "You can see the lesson heading.",
        title: "Lesson Heading ",
        target: "#headsc",
        order: "0",

        group: "",
      },
      {
        content: "This shows the type of learning associated with the lesson.",
        title: "In This Lesson ",
        target: "#partitionIcon",
        order: "1",

        group: "",
      },
      {
        content: "An overview of the lesson.",
        title: "Description ",
        target: "#descriptionbox",
        order: "2",

        group: "",
      },
      {
        content:
          "Click on 'Go to Lesson' to start<br> learning, you can check the lesson<br> completion progress.<br> <img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/lessonGoTo.PNG'  style='width:222px' alt=''>",
        title: "Go to lesson",
        target: "#gototestc22222",
        order: "3",

        group: "",
      },
    ],
  });
  tg.start();
}
export function MobileCourseDetailTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content: "You can select chapters.",
        title: "Chapter Name",
        target: "#chapSelect",
        order: "0",

        group: "",
      },
      {
        content:
          "You can see here lessons completed <br>and how many more to learn.<br> <img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/coursecompletedBar.PNG'   style='width:240px;margin-top:9px;' alt=''>",
        title: "Lessons Completed",
        target: "#a",
        order: "1",

        group: "",
      },
      {
        content: "You can select lessons to learn <br>from the chapters.",
        title: "Lesson List",
        target: "#listchap",
        order: "2",

        group: "",
      },
    ],
  });
  tg.start();
}
export function MobileTestDetailTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,
    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content: "You can select chapters.",
        title: "Chapter Name",
        target: "#testchap",
        order: "0",

        group: "",
      },
      {
        content:
          "You can see here tests completed <br>and how many more to tests left <br>to complete.<br><img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/testcompletedBar.PNG'  style='width:225px' alt=''>",
        title: "Test Completed",
        target: "#testTd11",
        order: "1",

        group: "",
      },
      {
        content: "You can select topics to take your test.",
        title: "Test list",
        target: "#testch",
        order: "2",

        group: "",
      },
    ],
  });
  tg.start();
}
export function MobileSelectTestTour() {
  new tourguide.TourGuideClient({
    targetPadding: 15,
    exitOnEscape: false,
    exitOnClickOutside: true,
    dialogClass:
      "tg-dialog-btn , tg-dialog-body , tg-dialog-close-btn,tg-custom-dialog",
    closeButton: true,

    showStepDots: true,
    dialogClass: "ppp",
    steps: [
      {
        content: "You can see test heading. ",
        title: "Test Name",
        target: "#headtest",
        order: "0",

        group: "",
      },
      {
        content: "An overview of test. ",
        title: "Description",
        target: "#desTest",
        order: "1",

        group: "",
      },
      {
        content:
          "Click on 'Go to Test' to start test and<br>see the progress of test completion. <br> <img src='https://pollydemo2022.s3.us-west-2.amazonaws.com/icons/testGoTo.PNG'  style='width:240px' alt=''> ",
        title: "Go To Test",
        target: "#gototestc22222",
        order: "2",

        group: "",
      },
    ],
  });
  tg.start();
}
export function exitTestTour() {
  tg.exit();
}
