import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../service/user.service';
import { UneeqavatarComponent } from '../uneeqavatar/uneeqavatar.component';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { Location } from '@angular/common';
@Component({
  selector: 'app-playppt',
  templateUrl: './playppt.component.html',
  styleUrls: ['./playppt.component.css'],
})
export class PlaypptComponent implements OnInit {
  isLoading: boolean = false;
  displayuneeqcomponent: boolean = false;
  @ViewChild(UneeqavatarComponent) uneeqAvatar!: UneeqavatarComponent;
  displaymsg: any;
  openForm: boolean = false;
  triedcard: boolean = false;

  closedpptbutton: boolean = false;
  allmendatoryfield: boolean = false;
  hideparentemail: boolean = false;
  previousUrl: any;
  currentUrl: any;
  from: string | undefined;
  isLoggedIn: any;
  storedLogin: any;

  constructor(
    private service: UserService,
    private location: Location,
    private router: Router
  ) {
    const navigation = this.router.getCurrentNavigation();

    this.from = navigation?.extras.state?.['from']; // Access 'from' property
    
    console.log('Navigated from:', this.from);
  }

  ngOnInit(): void {
    this.openForm = !this.openForm;
    this.hideparentemail = true
    // this.triedcard = false
    const storedLoginState = localStorage.getItem('LoginState');
    const storedUserData = localStorage.getItem('user');
      if (storedUserData) {
        this.storedLogin = JSON.parse(storedUserData);
       

      }
    // this.storedLogin = JSON.parse(storedLoginMain);
    this.isLoggedIn = storedLoginState ? JSON.parse(storedLoginState) : false;
    if( this.isLoggedIn){
      this.openForm = false
      this.startSession();
    }else{
      this.openForm = true
    }
  }

  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    f_name: new FormControl('', [Validators.required]),
    l_name: new FormControl(''),
    phone: new FormControl('',[  Validators.pattern(/^(\+1\s?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/)]),
    P_email: new FormControl('', [Validators.email]),
    isOver13: new FormControl(true),
    termsAccepted: new FormControl(false, [Validators.requiredTrue]),
  });

  // validation  controls for form
  get email() {
    return this.form.controls['email'];
  }

  get f_name() {
    return this.form.controls['f_name'];
  }

  get l_name() {
    return this.form.controls['l_name'];
  }
  get phone() {
    return this.form.controls['phone'];
  }
  get P_email() {
    return this.form.controls['P_email'];
  }
  get isOver13() {
    return this.form.controls['isOver13'];
  }

  get termsAccepted() {
    return this.form.controls['termsAccepted'];
  }
  calendly() {
    window.open(
     this.service.salesLink()
    );
  }

  validateAllFormFields(formGroup: FormGroup) {
    // Iterate over each field in the form group
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      // Check if the control is an individual form control
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        // Check if the control is a nested form group
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  hasError(): boolean {
    return (
      (this.form.get('f_name')?.touched && this.form.get('f_name')?.invalid) ||
      false ||
      (this.form.get('email')?.touched && this.form.get('email')?.invalid) ||
      false ||
      (this.form.get('phone')?.touched && this.form.get('phone')?.invalid) ||
      false
    );
  }

  closebutton() {
    this.openForm = !this.openForm;
    this.form.reset();
  }
  backtomainpage() {
    this.location.back();
  }

  gotoTermsaPage() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/terms']));
    window.open(url, '_blank');
  }

  termsclicked() {
    if (this.form.get('termsAccepted')?.value) {
      this.allmendatoryfield = true;
    } else {
      this.allmendatoryfield = false;
    }
  }

  over13year() {
    if (this.form.get('isOver13')?.value) {
      this.allmendatoryfield = true;
      this.hideparentemail = false;
    } else {
      this.allmendatoryfield = false;
      this.hideparentemail = true;
    }
  }

  startSession() {
    (document.body.style as any).zoom = '1';
    console.log('Form submitted');
    //this.displayuneeqcomponent = true;
    // Retrieve the values of isOver13 and P_email controls
    const isOver13 = this.form.get('isOver13')?.value;
    const parentEmailControl = this.form.get('P_email');
    console.log(this.storedLogin)

    // Set or clear validators based on the isOver13 value
    if (!isOver13) {
      // Under 13: make P_email required
      parentEmailControl?.setValidators([
        Validators.required,
        Validators.email,
      ]);
    } else {
      // Over 13: clear validators
      parentEmailControl?.clearValidators();
      this.allmendatoryfield = false;
    }
    // Update validity status
    parentEmailControl?.updateValueAndValidity();

    if (this.form.valid || this.isLoggedIn) {
      this.allmendatoryfield = false;
    if(this.storedLogin){
      let payload = {
        name: this.storedLogin.f_name,
        email: this.storedLogin.email,
        Phone: this.storedLogin.phone,
        parentemail: this.storedLogin.Parent_email,
        agelimit: this.storedLogin.Parent_email?true:false,
        termscondition: true,
      };
      this.isLoading = true;
      this.service
        .new_Avatar_demo_video_details(payload)
        .subscribe((res: any) => {
          if (res.statusCode == 200) {
            this.isLoading = false;
            localStorage.setItem('name', JSON.stringify(payload));
            localStorage.setItem('tryuser', JSON.stringify(res.data));
          //  this.closebutton();
            this.displayuneeqcomponent = true;

            setTimeout(() => {
              this.uneeqAvatar.startAvatarFunction();
            }, 300);
          } else if (res.statusCode == 201) {
            this.isLoading = false;
            // this.displaymsg = res.body
            this.openForm = false;
            this.triedcard = true;
          } else {
            console.log('else');
            this.isLoading = false;
            this.displayuneeqcomponent = true;
          }
        });
    }else{
      let payload = {
        name: this.form.value.f_name,
        email: this.form.value.email,
        Phone: this.form.value.phone,
        parentemail: this.form.value.P_email,
        agelimit: this.form.value.isOver13,
        termscondition: this.form.value.termsAccepted,
      };
      this.isLoading = true;
      this.service
        .new_Avatar_demo_video_details(payload)
        .subscribe((res: any) => {
          if (res.statusCode == 200) {
            this.isLoading = false;
            localStorage.setItem('name', JSON.stringify(payload));
            localStorage.setItem('tryuser', JSON.stringify(res.data));
            this.closebutton();
            this.displayuneeqcomponent = true;

            setTimeout(() => {
              this.uneeqAvatar.startAvatarFunction();
            }, 300);
          } else if (res.statusCode == 201) {
            this.isLoading = false;
            // this.displaymsg = res.body
            this.openForm = false;
            this.triedcard = true;
          } else {
            console.log('else');
            this.isLoading = false;
            this.displayuneeqcomponent = true;
          }
        });
    }
      
    
  
    } else {
      console.log('invalid---[')
      let payload = {
        name: this.form.value.f_name,
        email: this.form.value.email,
        Phone: this.form.value.phone,
        parentemail: this.form.value.P_email,
        agelimit: this.form.value.isOver13,
        termscondition: this.form.value.termsAccepted,
      };
      console.log(payload);
      this.validateAllFormFields(this.form);
      this.allmendatoryfield = true;
    }
  }

  closeuneeqcomponent() {
    this.displayuneeqcomponent = false;
    this.uneeqAvatar.uneeq.endSession();
    console.log('redirect to main page-----------------------------');
    this.openForm = false;
    this.triedcard = true;
  }

  closepptclicked() {
    console.log('playpptcalled');
    this.displayuneeqcomponent = false;
    this.uneeqAvatar.uneeq.endSession();
    this.closedpptbutton = true;
  }
  closeviewallcard() {
    this.closedpptbutton = false;
    // this.openForm = true
    this.router.navigate(['/']);
  }
  goforsubscribe() {
     this.router
    //navigate(['/course'])
      .navigate(['/course'], { fragment: 'pricing_section_id' })
      .then(() => {
        setTimeout(() => {
          const element = document.getElementById('pricing_section_id');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100);
      });
  }


  allowOnlyNumbers(event: KeyboardEvent): void {
    const charCode = event.charCode || event.keyCode;
    // Allow only digits (0-9), backspace, and delete keys
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }
  
  validatePhoneInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    // Remove any non-numeric characters
    input.value = input.value.replace(/[^0-9]/g, '');
    // Update the form control value (if necessary)
    this.form.get('phone')?.setValue(input.value);
  }
}
