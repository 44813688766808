import { Component, OnInit,ElementRef,ViewChild, PLATFORM_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../service/user.service';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-b2b-institution',
  templateUrl: './b2b-institution.component.html',
  styleUrls: ['./b2b-institution.component.css']
})
export class B2bInstitutionComponent implements OnInit {
  isSuccessVisible:boolean = false
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,private router :Router,private el :ElementRef,private service :UserService) {  }
  videoLinks: string[] = [
    'https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/no-volume-ever-intro-vid.mp4',
    'https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/dr-emma-meet-ai-tutors.mp4',
'https://edyouwebsite.s3.us-west-2.amazonaws.com/b2c/eddie-meet-ai-tutors.mp4'
    
  ];


  currentVideoIndex = 0;
  isBrowser: boolean =false;
  @ViewChild('videoPlayer', { static: true }) videoPlayer: ElementRef<HTMLVideoElement> | any;


  ngOnInit(): void {
    this.isBrowser = isPlatformBrowser(this.platformId);
    // const videoElement = this.el.nativeElement.querySelector('#backgroundVideo') as HTMLVideoElement;
    const videoIds = ['#backgroundVideo', '#laptop'];
    videoIds.forEach((id) => {
      const videoElement = this.el.nativeElement.querySelector(id) as HTMLVideoElement;
      if (videoElement) {
        videoElement.muted = true; // Required for autoplay in modern browsers
        videoElement.playsInline = true; // For mobile devices
        videoElement.autoplay = true;
    
        videoElement
          .play()
          .then(() => {
            console.log(`Video ${id} is playing.`);
          })
          .catch((error) => {
            console.error(`Video playback failed for ${id}:`, error);
          });
      } else {
        console.error(`Video element not found for selector: ${id}`);
      }
    });
  }
  nextVideo() {
    if (this.currentVideoIndex < this.videoLinks.length - 1) {
      this.currentVideoIndex++;
    } else {
      this.currentVideoIndex = 0;
    }
    this.updateVideoSource();
  }

  previousVideo() {
    if (this.currentVideoIndex > 0) {
      this.currentVideoIndex--;
    } else {
      this.currentVideoIndex = this.videoLinks.length - 1;
    }
    this.updateVideoSource();
  }

   updateVideoSource() {
    const videoElement = this.videoPlayer.nativeElement;
    videoElement.src = this.videoLinks[this.currentVideoIndex];
    videoElement.load();
    videoElement.play();
  }

  navigateToAIAvatar(){
    this.router.navigate(['/AI_Tutor'] ,{
      state: { from: 'b2b' }})
  }

 
  navigateToEI(){
    this.router.navigate(['/educational-institute'])
  }
  showSuccessModal() {
    this.isSuccessVisible = true;
  }

  calendly() {
    if(this.isBrowser){
    window.open(this.service.salesLink());
    }
  }

}
