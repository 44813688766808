<div class="main">
<div class="d-flex justify-content-center">

    <div class="boxPricing">
        <span><img src="../../assets/successPayment.png" class="succesImg"></span><br><br>
        <span class="MainHeading ">Thank you for subscribing to our Newsletter</span> <br>
     
        <br><br>
        <span class="d-flex justify-content-center">
            <button class="btnHome" (click)="navigateToMain()"><span class="textbtn"> Go to Home</span></button>
        </span>

    </div>
    
</div>
</div>

<!-- <script type="text/javascript" src="//js.hs-scripts.com/YOUR-HUBSPOT-ID.js" async defer></script> -->
<!-- <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/24454500.js"></script> -->
<!-- <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/8174166.js?businessUnitId=2039573"></script> -->