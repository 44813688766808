
<br>
<div class="containerrrr">

<div class="container container-custom mainHead">
    <img src="../../assets/newDesignImage/edyou-labs-logo-new.png" alt="Logo of edYOU Labs" class="headerImg">
    <br><br>
    
    <p class="mt-5">
        At edYOU Labs, the future of learning takes shape. Our team pushes boundaries in AI and education to create the next generation of personalized student experiences.
    </p>
    <p>
        We blend leading technology with human-centered design to put students first. Whether building VR field trips or conversational agents that sense emotion, our goal is to unlock engagement and growth.
    </p>
    <p>
        Come explore the possibilities! edYOU Labs develops breakthroughs that reimagine the role of AI in education.
    </p>
  
  
</div>
<h1 class="subHeadingpatented">edYOU’s Patented Technology</h1>
<br>
<div class="container container-custom">
    <img src="../../assets/newDesignImage/Proprietary_Ingestion_Engine.png" alt="Header Image of woman standing in between walls of books, representing edYOU's Proprietary Ingestion Engine" class="header-img">
    <h1>Proprietary Ingestion Engine</h1>
    <p>
        The Proprietary Ingestion Engine (PIE) is the frontline of our cutting-edge learning platform. PIE intelligently
        processes massive amounts of data, structuring and filtering information to enable personalized education
        experiences.
    </p>
    <p>
        PIE combines machine learning with human insight to ingest data from diverse global sources. Sophisticated bots
        extract key learnings, generate quizzes, and tag metadata to make information interactive and engaging. PIE’s
        automated capabilities rapidly process orders of magnitude more content than any human could handle.
    </p>
    <p>
        Yet humans remain closely involved through oversight and quality control. PIE’s human-in-the-loop approach
        ensures ingestion aligns with educational standards and ethics. No content reaches learners without thorough
        vetting.
    </p>
    <p>
        Once ingested, PIE seamlessly hands off information to our Personalized AI module. Here, individualized learning
        begins through natural language conversations tailored to each student’s needs and interests. The synergy
        between PIE and PAI makes edYOU’s personalized education possible.
    </p>
    <p>
        PIE is education reimagined for the digital age, combining AI capabilities with human compassion. It allows
        unmatched breadth and depth of knowledge to be presented individually to each eager young mind. PIE is the
        engine powering the learning platforms of tomorrow, as infinite information transforms into wisdom and potential
        for humanity.
    </p>
</div>
<br>

<div class="container container-custom">
    <img src="../../assets/newDesignImage/Intelligent_Curation_Engine.png" alt="Header Image of a man walking between a wall of computer servers, represent edYOU's Intelligent Curation Engine" class="header-img">
    <h1>Intelligent Curation Engine</h1>
    <p>
        The Intelligent Curation Engine (ICE) is our innovative approach to fostering safe, ethical artificial
        intelligence. As AI systems interact with the open web, they risk exposure to toxic content, misinformation, and
        bias. ICE provides the necessary safeguards. Using a sophisticated combination of metadata and human-in-the-loop
        monitoring, ICE carefully curates the information reaching edYOU learners.
    </p>
    <p>
        ICE traces the origins of all data, so educators can understand the sources behind AI responses. Automated flags
        quickly identify inappropriate or biased content for human review. A customizable matrix blocks offensive
        language across cultures. ICE provides complete visibility into how the AI is trained, empowering educators to
        make adjustments.
    </p>
    <p>
        With our safety and security first approach, ICE prevents hacking or manipulation of training data. This ensures
        the system reflects diverse global knowledge. The result is an AI platform educators can trust, aligned with
        their ethics and values.
    </p>
    <p>
        ICE represents our deep commitment to transparency, diversity, and human oversight of AI. As technology grows
        more capable, maintaining human accountability becomes even more critical. ICE is setting the standard for AI
        curation that uplifts humanity. It provides a valuable model of AI built around ethics, not just capabilities.
        We are pioneering the AI of tomorrow – thoughtful, responsible, and trustworthy.
    </p>

</div>

<div class="center-line">
  <hr class="Straight-line">
</div>


<div class="container">
  <h3>Intellectual Property</h3>
<br>
  <!-- Header -->
  <div class="header">
    <div class="col-patent">Patent No.</div>
    <div class="col-description">Description</div>
    <div class="col-status">Status</div>
  </div>

  <!-- Item 1 -->
  <div class="item">
    <div class="col-patent">US-20240311688-A1</div>
    <div class="col-description">Apparatus and method for training an educational machine learning model</div>
    <div class="col-status approved">Approved</div>
  </div>

  <!-- Item 2 -->
  <div class="item">
    <div class="col-patent">US-20240312358-A1</div>
    <div class="col-description">Apparatus and method for generating an educational action datum using machine learning</div>
    <div class="col-status pending">Filed and pending approval</div>
  </div>

  <!-- Item 3 -->
  <div class="item">
    <div class="col-patent">US-20240312360-A1</div>
    <div class="col-description">Apparatus and method for an education platform and ecosystem using extended reality</div>
    <div class="col-status pending">Filed and pending approval</div>
  </div>

  <!-- Item 4 -->
  <div class="item">
    <div class="col-patent">18/124,018</div>
    <div class="col-description">Systems and methods for personalizing educational content based on user reactions</div>
    <div class="col-status pending">Filed and pending approval</div>
  </div>

  <!-- Item 5 -->
  <div class="item">
    <div class="col-patent">US-12242945-B1</div>
    <div class="col-description">Apparatus and method for personalization of machine learning models</div>
    <div class="col-status approved">Granted</div>
  </div>

  <!-- Item 6 -->
  <div class="item">
    <div class="col-patent">US-12125410-B1</div>
    <div class="col-description">Apparatus and method for data ingestion for user specific outputs of one or more machine learning models</div>
    <!-- <div class="col-status pending">Filed and pending approval</div> -->
    <div class="col-status approved">Granted</div>
  </div>

  <div class="item">
    <div class="col-patent">US-20240281211-A1</div>
    <div class="col-description">Augmented formulaic solver of cubic and quartic polynomials</div>
    <div class="col-status pending">Filed and pending approval</div>
  </div>

  <div class="item">
    <div class="col-patent">US-11893464-B1</div>
    <div class="col-description">Apparatus and methods for training an educational machine-learning model</div>
    <div class="col-status approved">Approved</div>
  </div>

</div>


<div class="container">
  <h3>Research</h3>
<br>

  <div class="item">
    <div class="col-patent">August 1, 2024</div>
    <a class="col-description bold-link" href="../../assets/research-papers/Feasibility_AI_Learning_Abstract_ACGME12.5.2024_Sanchez-Gonzalez[29].pdf" target="_blank"title="Feasibility of AI-Driven Personalized Learning for Internal Medicine Resident">
      <div>Feasibility of AI-Driven Personalized Learning for Internal Medicine Residents</div>
    </a>
  </div>

  <div class="item">
    <div class="col-patent">August 1, 2024</div>
    <a class="col-description bold-link" href="../../assets/research-papers/transforming-education-edyou-white-paper-1.0.pdf" target="_blank" title="Transforming Education with edYOU Conversational Al: A Case Study of Sierra Canyon School">
      <div>Transforming Education with edYOU Conversational Al: A Case Study of Sierra Canyon School</div>
    </a>
  </div>
  
  <div class="item">
    <div class="col-patent">September 3, 2023</div>
    <a class="col-description bold-link" href="https://investor.edyou.com/wp-content/uploads/2023/08/edYOU-Integrating-AI-into-Teaching-Strategies.pdf" target="_blank" title="Integrating artificial intelligence into teaching strategies & student assessments">
      <div>Integrating artificial intelligence into teaching strategies & student assessments</div>
    </a>
  </div>
  
  <div class="item">
    <div class="col-patent">August 30, 2023</div>
    <a class="col-description bold-link" href="https://investor.edyou.com/wp-content/uploads/2023/08/edYOU-Educational-Effectiveness.pdf" target="_blank" title="Flipped the classroom with artificial intelligence: Determining the educational effectiveness of combining voice-over presentations review games, and AI">
      <div>Flipped the classroom with artificial intelligence: Determining the educational effectiveness of combining voice-over presentations review games, and AI</div>
    </a>
  </div>
  
  <div class="item">
    <div class="col-patent">August 25, 2023</div>
    <a class="col-description bold-link" href="https://investor.edyou.com/wp-content/uploads/2023/08/edYOU-Educational-Effectiveness-Results.pdf" target="_blank"title="Determining the educational effectiveness of combining voice-over presentations and review games: Results">
      <div>Determining the educational effectiveness of combining voice-over presentations and review games: Results</div>
    </a>
  </div>

</div>


</div>