import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../service/user.service';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-success-payment',
  templateUrl: './success-payment.component.html',
  styleUrls: ['./success-payment.component.css']
})
export class SuccessPaymentComponent implements OnInit {
  isBrowser: any;

  constructor(private route: ActivatedRoute,private service:UserService, private router:Router,  @Inject(PLATFORM_ID) private platformId: Object) { }
  plan: string | null = '';
  amount: string | null = '';
  purchase: string | null = '';
  user: any;
  Url: any;
  subscribedata: any;
  loggedInDaTa: any;
  ngOnInit(): void {
    this.isBrowser = isPlatformBrowser(this.platformId); 
    this.user = JSON.parse(localStorage.getItem('user') || '{}')
    this.plan = this.route.snapshot.queryParamMap.get('plan');
    this.amount = this.route.snapshot.queryParamMap.get('amount');
    this.purchase = this.route.snapshot.queryParamMap.get('purchase');
 
    this.login();
  }

  login() {
    
    
    let loginPayload = {
      "email": this.user.email,
      "password": this.user.password,
    }
   
    this.service.signIn(loginPayload).subscribe(
      (data: any) => {
        if (data.statusCode == 200) {
      
          localStorage.setItem("url", JSON.stringify(data.url));
          localStorage.setItem("user", JSON.stringify(data.body))
         localStorage.setItem("user", JSON.stringify(data.body));
         localStorage.setItem("LoginState", JSON.stringify(true));
         localStorage.setItem("email",data.body.email);
       
          //localStorage.setItem("subscription", JSON.stringify(data));
      
        
        
          
         
       
          let payload ={
            "request": "get_customer_product",
           "customer_id": data.body.cus_id
          }
          
          this.service.getSubscriptionDetail(payload).subscribe((res: any) => {
           if(res.statusCode == 200){
           
          this.subscribedata = res.body
          this.service.setSubscriptionData(this.subscribedata);
          localStorage.setItem("subscription", JSON.stringify(this.subscribedata));
          }else{
            //localStorage.removeItem("subscription");
            this.subscribedata = ''
            console.log('no data')
            localStorage.removeItem("subscription");
          }
        } )
        
        //  localStorage.setItem("subscription", JSON.stringify(res.body));
        //  console.log(this.loggedInDaTa)
          if (this.loggedInDaTa.url.includes('/sc')) {
            console.log('URL contains /sc',this.loggedInDaTa.url);
            this.router.navigate(['/SC']);
            localStorage.setItem("Login_User", JSON.stringify('SC'));
            // Perform any logic if needed
          } else {
            console.log('URL does not contain /sc');
            localStorage.setItem("Login_User", JSON.stringify('B2C'));
            this.router.navigate(['/']);
          }
        

       
        }
      
      })
  } 


  navigateToMain(){
    
      this.router.navigate(['/']);
   
 
   // this.router.navigate(['/main']);
  }
  linkToedyouUser(){
    let user  = localStorage.getItem('user');
    console.log(user)
    if (user) {
      // Parse the string back into a JavaScript object
     let user1 = JSON.parse(user); 
    
      // Now you can access the 'link' property
      console.log(user1.link, 'urltomove', this.user);
     // const userLink: string = user1.link;
  
      // If you want to navigate, uncomment this:
      if(this.isBrowser){
      window.location.href = user1.link;
      }
    }else{
      window.location.href= 'https://smdev.edyou.com/' 
    }
  
   
  
  }
}
